The subscriber feature houses the logic for answering the age old question of if a user isSubscriber. It does not contain any of the logic for purchasing or renewing subscriber, however it handles any UI for expressing the state of the subscriber subscription.

### Features

- A route with a list of subscriber features & link to subscribe
- An upgraded/enabled version of certain features/overlays for Premium users only

### Critical Functions

- Injecting upgraded LOL postmatch features
- Enabling Premium only overlays
- Premium tile & modals for managing & activating subscriber subscription

### Testing

Coupons:

- HALFOFFMONTH - 1/2 off 1st Month
- HALF3MONTHS - 1/2 off for 3 Months
- FREEFOREVER - 100% off forever
