import React from "react";

import MatchTileScoreboard from "@/game-fortnite/components/MatchDataTablePostmatch.jsx";
import { TabLoading } from "@/shared/MatchTileExpand";

export default function MatchTileScoreboardMini(props) {
  if (!props.match) return <TabLoading />;
  return (
    <div
      className="overflow-hidden"
      style={{ height: "215px", overflowY: "auto" }}
    >
      {React.createElement(MatchTileScoreboard, props)}
    </div>
  );
}
