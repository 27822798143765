import type {
  PlayerMatch,
  PlayerMatches,
} from "@/game-fortnite/models/matchlist.mjs";

const RegExpHist = /Hist/;

type FilterPropsByKey<T, Key extends string> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [Property in keyof T as Property extends `${infer Prefix}${Key}${infer Suffix}`
    ? Property
    : never]: Array<number>;
};
export type Histogram = FilterPropsByKey<PlayerMatch, "Hist">;

export default function getHistogram(matches: PlayerMatches): Histogram {
  const result = Object.create(null);
  for (const match of matches) {
    for (const key in match) {
      if (!RegExpHist.test(key)) continue;
      result[key] = (result[key] ?? []).concat([match[key]]);
    }
  }
  return result;
}
