import getData from "@/__main__/get-data.mjs";
import CsgoPlayerWeaponStats from "@/data-models/csgo-player-weapon-stats.mjs";
import * as API from "@/game-csgo/api.mjs";
import type { GameMode } from "@/game-csgo/constants.mjs";
import { GAME_MODES } from "@/game-csgo/constants.mjs";
import { getModeParams } from "@/game-csgo/utils.mjs";

export default async function fetchPlayerWeaponStats(
  steamId: string,
  gameModeId: GameMode["id"] = GAME_MODES.ALL.id,
) {
  const getPlayerWeaponStats = await getData(
    API.getPlayerWeaponStats({ steamId, ...getModeParams(gameModeId) }),
    CsgoPlayerWeaponStats,
    ["csgo", "playerWeaponStats", steamId, gameModeId],
  );
  return getPlayerWeaponStats;
}
