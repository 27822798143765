import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile } from "clutch/src/Style/style.mjs";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { APP_SCROLLER, appURLs } from "@/app/constants.mjs";
import Augments from "@/game-tft/UpdatesSetTenAugments.jsx";
import Champions from "@/game-tft/UpdatesSetTenChampions.jsx";
import Items from "@/game-tft/UpdatesSetTenItems.jsx";
import Traits from "@/game-tft/UpdatesSetTenTraits.jsx";
import HelpIcon from "@/inline-assets/help.svg";
import Container from "@/shared/ContentContainer.jsx";
import { MatchLoading } from "@/shared/Match.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import Banner from "@/shared/SplashBanner.jsx";
import globals from "@/util/global-whitelist.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const tabs = [
  {
    key: "whatsnew",
    label: ["val:whatsNew", "What's new?"],
    Component: UpdatesWhatsNew,
  },
  {
    key: "traits",
    label: ["tft:common.traits", "Traits"],
    Component: UpdatesTraits,
  },
  {
    key: "champions",
    label: ["lol:champion_plural", "Champions"],
    Component: UpdatesChampions,
  },
  {
    key: "augments",
    label: ["tft:common:augments", "Augments"],
    Component: UpdatesAugments,
  },
  {
    key: "items",
    label: ["common:navigation.items", "Items"],
    Component: UpdatesItems,
  },
];
const image = `${appURLs.CDN}/blitz/tft/set-update-page/Set4_5/BG_Set45Revival.webp`;
const header = [
  "common:navigation.set45Revival",
  "Set 4.5 Revival - Festival of Beasts",
];
const title = [
  "tft:updates.set45Revival",
  "TFT Set 4.5 - Festival of Beasts is Back!",
];
const subTitle = ["tft:tftJan2025", "Teamfight Tactics - January 2025"];
const set = "set4_5";
const priorSet = "";
const gap = 48; // Represents the var(--sp-12) computed container spacing
const debounce = { indexes: [], delay: 100 };

const tabsTraits = [
  // { key: "new", label: ["common:new", "New"] },
  // { key: "removed", label: ["common:removed", "Removed"] },
  { key: "origins", label: ["tft:traits:origins", "Origins"] },
  { key: "classes", label: ["tft:traits:classes", "Classes"] },
];

const tabsChampions = [
  // { key: "new", label: ["common:new", "New"] },
  // { key: "removed", label: ["common:removed", "Removed"] },
  { key: "all", label: ["common:all", "All"] },
  { key: "1", label: ["", "1"] },
  { key: "2", label: ["", "2"] },
  { key: "3", label: ["", "3"] },
  { key: "4", label: ["", "4"] },
  { key: "5+", label: ["", "5+"] },
];

const tabsItems = [
  // { key: "new", label: ["common:new", "New"] },
  // { key: "changed", label: [`tft:common:changed`, `Changed`] },
  { key: "spatula", label: [`tft:emblems`, `Emblems`] },
  { key: "all", label: ["common:all", "All"] },
];
const tabsAugments = [
  // { key: "new", label: ["common:new", "New"] },
  // { key: "Hero", label: ["tft:hero", "Hero"] },
  { key: "Tier 1", label: ["", "Tier 1"] },
  { key: "Tier 2", label: ["", "Tier 2"] },
  { key: "Tier 3", label: ["", "Tier 3"] },
];

function isBottomOfContainer(target) {
  return target.scrollTop + target.clientHeight >= target.scrollHeight;
}

function Reveal({ id, timeout, children, headerOffset }) {
  const ref = useRef(null);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setReady(true);
    }, timeout);
    return () => {
      clearTimeout(id);
    };
  }, [timeout]);

  useLayoutEffect(() => {
    const node = ref?.current;
    if (!node) return;

    const index = tabs.findIndex((i) => i.key === id);
    const root = globals.document.getElementsByClassName(APP_SCROLLER)[0];
    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        // Intersect
        if (isIntersecting) {
          if (!debounce.indexes.some((i) => i === index)) {
            debounce.indexes.push(index);
          }
        } else {
          const indexOfIndex = debounce.indexes.findIndex((i) => i === index);
          if (index === tabs.length - 1 && debounce.indexes.length === 1) {
            // Handles the scroll event in Updates Component
            debounce.indexes.push(tabs.length - 2);
          }
          if (indexOfIndex >= 0) debounce.indexes.splice(indexOfIndex, 1);
        }
        // Handles multiple sections if we reached the bottom of the root
        if (isBottomOfContainer(root)) debounce.indexes = [tabs.length - 1];
        // Sort and navigate
        if (debounce.indexes.length > 1) debounce.indexes.sort();
      },
      {
        root,
        rootMargin: `-${headerOffset}px 0px 0px 0px`, // Only takes % or px values
      },
    );
    observer.observe(node);
    return () => {
      observer.disconnect();
    };
  }, [headerOffset, id]);
  return (
    <div id={id} ref={ref}>
      {isReady ? (
        children
      ) : (
        <LoadingContainer>
          <MatchLoading />
        </LoadingContainer>
      )}
    </div>
  );
}

function Updates() {
  const { t } = useTranslation();
  const [headerOffset, setHeaderOffset] = useState(0);

  const links = useMemo(() => {
    return tabs.map((i) => {
      return {
        text: t(...i.label),
        url: `/tft/set-${set.replace("set", "")}?section=${i.key}`,
        routeMatch: new RegExp(i.key, "i"),
        onClick(event) {
          event.preventDefault();
          const element = globals.document.getElementById(i.key);
          const main = globals.document.getElementsByClassName(APP_SCROLLER)[0];
          // Added 1px to offset IntersectionObservers intersecting range
          main.scrollTop = element.offsetTop - gap + 1;
          return false;
        },
      };
    });
  }, [t]);

  useEffect(() => {
    const view = globals.document.getElementsByClassName(APP_SCROLLER)[0];
    function scroll(event) {
      const target = event.target;
      if (isBottomOfContainer(target)) {
        debounce.indexes = [tabs.length - 1];
      }
      // This header offset number is used for IntersectionObserver's rootMargin property to accomodate for the sticky header
      const attrTarget = "data-sticky-cumulative-height";
      const stickyCumulativeHeights = Array.from(
        globals.document.querySelectorAll(`[${attrTarget}]`),
      ).map((i) => Number(i.getAttribute(attrTarget)));
      stickyCumulativeHeights.sort((a, b) => b - a);
      setHeaderOffset(stickyCumulativeHeights[0] ?? 0);
    }
    view.addEventListener("scroll", scroll);
    return () => {
      view.removeEventListener("scroll", scroll);
    };
  });

  return (
    <>
      <PageHeader title={t(...header)} links={links} />
      <Container style={{ display: "grid", gap: "var(--sp-12)" }}>
        {tabs.map((tab, idx) => {
          const Component = tab.Component;
          return (
            <Reveal
              key={idx}
              id={tab.key}
              timeout={idx * 500}
              headerOffset={headerOffset}
            >
              {idx === 0 ? (
                <Banner
                  image={image}
                  title={t(...title)}
                  subtitle={t(...subTitle)}
                  title2={
                    /* eslint-disable */
                    <div className="flex column type-subtitle">
                      <div>PBE Release: January 9th</div>
                      <div>Live Release: January 23rd</div>
                    </div>
                    /* eslint-enable */
                  }
                >
                  <Component />
                </Banner>
              ) : (
                <Component />
              )}
            </Reveal>
          );
        })}
      </Container>
    </>
  );
}

export function meta() {
  return {
    title: [
      "tft:helmet.updates.titleSet4_5Revival",
      "TFT Set 4.5 Revival - Patch Notes - Blitz Teamfight Tactics",
    ],
    description: [
      "tft:helmet.updates.descriptionSet4_5Revival",
      "Explore TFT Set 4.5 Revival with returning traits and mechanics! ",
    ],
    image: {
      url: `${appURLs.CDN}/blitz/tft/set-update-page/Set4_5/BG_Set45Revival.webp`,
      alt: [
        "tft:helmet.updates.titleSet4_5Revival",
        "TFT Set 4.5 Revival - Patch Notes - Blitz Teamfight Tactics",
      ],
      width: 984,
      height: 555,
    },
  };
}

export default Updates;

function UpdatesWhatsNew() {
  const { t } = useTranslation();
  /* eslint-disable */
  return (
    <>
      <Section className="whatsNew">
        <h2 className="type-h4 section-title">
          {t("tft:whatsNew", "What's New")}
          <IconWrapper
            data-tip={t(
              "tft:pageIsBasedOn",
              "Page is based on data Riot provided early. Page will automatically update once more info gets revealed.",
            )}
            data-place={"bottom"}
          >
            <HelpIcon />
          </IconWrapper>
        </h2>
        <ContentBlock padding="var(--sp-8)">
          <div className="flex img-text-block">
            <img
              src={`${appURLs.CDN}/blitz/tft/set-update-page/Set4_5/ChosenExample.webp`}
              alt="Chosen Example Image"
              width="261px"
            />
            <div className="right">
              <h4 className="title type-h6">Chosen</h4>
              <p className="content type-body1 color-shade1">
                Chosen champions appear in every fourth shop, even if you
                already have one on the board or bench. However, you won’t be
                able to buy them unless you sell your old one. When a Chosen
                champ is purchased, they will already be at a 2-star level and
                cost accordingly. Chosen champions give +1 to one of their
                traits.
              </p>
            </div>
          </div>
        </ContentBlock>
      </Section>
    </>
  );
  /* eslint-enable */
}
function UpdatesTraits() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const [currentTab, setTab] = useState(tabsTraits[0].key);
  const traits = state.tft?.[set]?.traits;
  const champions = state.tft?.[set]?.champions;

  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:traits", "Traits")}
      </h2>
      <ButtonGroup>
        {tabsTraits.map(({ key, label }) => (
          <Button
            key={key}
            active={key === currentTab}
            onClick={() => setTab(key)}
          >
            {t(...label)}
          </Button>
        ))}
      </ButtonGroup>
      <Traits
        buttons={tabsTraits}
        traitType={currentTab}
        traits={traits}
        champions={champions}
        set={set}
        t={t}
      />
    </Section>
  );
}

function UpdatesChampions() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const [currentTab, setTab] = useState(tabsChampions[0].key);
  const champions = state.tft[set]?.champions;
  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:champions", "Champions")}
      </h2>
      <ButtonGroup>
        {tabsChampions.map(({ key, label }) => (
          <Button
            key={key}
            active={key === currentTab}
            onClick={() => setTab(key)}
          >
            {t(...label)}
          </Button>
        ))}
      </ButtonGroup>
      <Champions
        champions={champions}
        set={set}
        cost={currentTab}
        buttons={tabsChampions}
        isSkinsEnabled={isSkinsEnabled}
        priorSet={priorSet}
      />
    </Section>
  );
}

function UpdatesItems() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const [currentTab, setTab] = useState(tabsItems[0]?.key);
  const locale = getLocale();
  const items = state.tft[set]?.items;
  const itemsLocalized = state.tft[set]?.localizedItems?.[locale];

  const buttonsWithItems = useMemo(() => {
    const result = tabsItems.reduce((acc, { key }) => {
      acc[key] = [];
      return acc;
    }, Object.create(null));
    if (items && !(items instanceof Error)) {
      const sorted = Object.entries(items).sort(
        (a, b) => b[1].buildsFrom?.length - a[1].buildsFrom?.length,
      );
      return sorted.reduce((acc, item) => {
        // if (item[1].state === "new") acc[buttons[0].key].push(item);
        // if (item[1].state === "change" || item[1].state === "rework") {
        //   acc[buttons[0].key].push(item);
        // }
        if (item[1].kind === "emblem") acc[tabsItems[0].key].push(item);
        if (item[1].kind !== "basic") acc[tabsItems[1].key].push(item);
        return acc;
      }, result);
    }
    return result;
  }, [items]);

  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:items", "Items")}
      </h2>
      <ButtonGroup>
        {tabsItems.map(({ key, label }) =>
          key === "reworked" ? null : (
            <Button
              key={key}
              active={key === currentTab}
              onClick={() => setTab(key)}
            >
              {t(...label)}
            </Button>
          ),
        )}
      </ButtonGroup>
      <Items
        buttonsWithItems={buttonsWithItems}
        itemType={currentTab}
        localizedItems={itemsLocalized}
        set={set}
      />
    </Section>
  );
}

function UpdatesAugments() {
  const { t } = useTranslation();
  const [currentTab, setTab] = useState(tabsAugments[0].key);
  const [searchInput, setSearchInput] = useState("");

  return (
    <Section className="section">
      <h2 className="type-h4 section-title">
        {t("tft:common:augments", "Augments")}
      </h2>
      <Filters>
        <ButtonGroup>
          {tabsAugments.map(({ key, label }) => (
            <Button
              key={key}
              active={key === currentTab}
              onClick={() => setTab(key)}
            >
              {t(label)}
            </Button>
          ))}
        </ButtonGroup>
        <TextInput
          placeholder={t("tft:searchAugment", "Search Augment")}
          onValueChange={setSearchInput}
          className="searchAugments"
        />
      </Filters>
      <Augments set={set} tier={currentTab} searchInput={searchInput} />
    </Section>
  );
}

//STYLES
const ContentBlock = styled(Card)`
  .content {
    max-width: 70ch;

    a {
      color: var(--primary);
    }
  }

  ul.content {
    margin-bottom: 1em;
    padding-left: var(--sp-8);
    list-style-type: initial;
  }

  img {
    max-width: 100%;
    filter: brightness(1.1);
  }

  video {
    border-radius: var(--br);
    &:hover {
      cursor: pointer;
    }
  }

  .vsc-controller {
    display: none;
  }

  .content-img {
    max-width: 85%;

    ${mobile} {
      max-width: 100%;
    }
  }

  .flex {
    display: flex;

    ${mobile} {
      flex-direction: column;
    }
  }

  .horizontal-tiles {
    display: flex;
    flex-direction: row;
    gap: var(--sp-10);

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: flex-end;
      gap: var(--sp-4);
    }

    hr {
      border: var(--sp-0_5) solid var(--shade6);
      border-right: none;
    }

    ${mobile} {
      flex-direction: column;

      hr {
        width: 100%;
      }
    }
  }

  .img-text-block {
    align-items: center;
    justify-content: center;
    gap: var(--sp-30);

    .right {
      max-width: 350px;
    }

    ${mobile} {
      gap: var(--sp-12);
    }
  }
`;

const IconWrapper = styled("div")`
  display: inline-block;
  cursor: pointer;
  margin: 0 var(--sp-2);

  svg {
    width: var(--sp-6);
    height: var(--sp-6);
    fill: var(--shade3);
  }
`;

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-3);

  &.newMechanics {
    margin-top: var(--sp-12);

    .container {
      gap: var(--sp-6);
      display: flex;
      flex-direction: column;
    }

    .flex {
      justify-content: space-around;
      align-items: center;
    }

    .right {
      max-width: 330px;

      .title {
        margin-bottom: var(--sp-2);
      }
    }

    hr {
      border: var(--sp-px) solid var(--shade6);
      width: 100%;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchPortals {
      width: 100%;
      max-width: 18ch;
    }

    ${mobile} {
      flex-direction: column;
      align-items: flex-start;

      label {
        width: 100%;
        margin-top: var(--sp-4);
      }
    }
  }

  .leveling-changes {
    list-style: disc;
    padding-left: var(--sp-6);
  }

  ${mobile} {
    &.whatsNew {
      margin-top: 200px;
    }

    .button-group {
      flex-wrap: wrap;
    }
  }
`;

// Not the same one used in game-lol or game-tft, just shadowed the name
const LoadingContainer = styled("div", forwardRef)`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeeMore = styled("div")`
  width: 100%;
  text-align: center;
  color: var(--shade1);
  background: var(--shade7);
  padding: var(--sp-2) 0;
  border-radius: 0 0 var(--br) var(--br);
  &:hover {
    color: var(--shade0);
    background: var(--shade6);
    cursor: pointer;
  }

  ${mobile} {
    width: 100%;
  }
`;

const Filters = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: var(--sp-2);

  .searchAugments {
    width: 100%;
    max-width: 18ch;
  }
`;
