import { appURLs } from "@/app/constants.mjs";

Cache, set in Pripyat, Ukraine near the Chernobyl Exclusion Zone, features an abandoned industrial area where quick thinking and adaptability are key. Bombsite A is located near the truck outside the warehouse, while Bombsite B is tucked inside a building, offering various hiding spots and sightlines. The map's greenish hue and post-apocalyptic feel add to its atmospheric gameplay.

**Bombsite A:** Located near a warehouse, Bombsite A features a mix of crates and open space, offering multiple tactical options. The red container and forklift are key strategic points.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/cache_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/cache_a_site.webp`} />
</div>

**Bombsite B:** Enclosed within a building and characterized by the "headshot" box, Bombsite B offers various sightlines and cover spots. The unique layout requires tactical finesse.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/cache_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/cache_b_site_2.webp`} />
</div>
