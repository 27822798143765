import { EVENTS } from "@/__main__/ipc-core.mjs";
import jsonPlayer from "@/feature-replay-blitz-message/fixtures/apex-player.json";
import jsonData from "@/feature-replay-blitz-message/fixtures/apex-trio-game-data.json";
import jsonDeploy from "@/feature-replay-blitz-message/fixtures/apex-trio-game-deploy.json";
import jsonStart from "@/feature-replay-blitz-message/fixtures/apex-trio-game-start.json";
import {
  eventReplayerExecQueue,
  replaceWith,
} from "@/feature-replay-blitz-message/utils.mjs";

export default function playApexBRTrioMatch(): Promise<void> {
  const now = Date.now();
  const matchId = btoa(`match-${now}`);
  const replaceArgs: Array<[string, string | number]> = [
    ["b1102692-89b2-58e3-b3d4-5e538fdc1c6b", matchId],
    ["1733445800", now / 1000],
  ];
  // @ts-ignore
  const [start, deploy, data] = replaceWith(replaceArgs, [
    jsonStart,
    jsonDeploy,
    jsonData,
  ]);
  return eventReplayerExecQueue(
    [
      [EVENTS.APEX_IS_RUNNING, true],
      [EVENTS.APEX_PLAYER, jsonPlayer],
      [EVENTS.APEX_GAME_START, start],
      [EVENTS.APEX_GAME_DEPLOY, deploy],
      [EVENTS.APEX_GAME_END, ""],
      [EVENTS.APEX_DATA, data],
      [EVENTS.APEX_IS_RUNNING, false],
    ],
    4000,
  );
}
