import getData from "@/__main__/get-data.mjs";
import GenericMatchListModel from "@/data-models/generic-match-list.mjs";
import LeagueAccountModel from "@/data-models/league-account.mjs";
import TFTMatchModel from "@/data-models/tft-match.mjs";
import TFTMatchRoundModel from "@/data-models/tft-match-round.mjs";
import { isPBE } from "@/game-lol/utils/util.mjs";
import * as API from "@/game-tft/api.mjs";
import { MATCH_LIMIT } from "@/game-tft/constants.mjs";

export default async function fetchData(parameters, urlSearchParams, state) {
  const [platform, fullName] = parameters;

  const shouldFetchIfPathExists = !state?.isUpdate;

  const [gameName, tagLine] = fullName.split("-");

  if (!gameName || !tagLine || isPBE(platform)) return;

  // Note that player should be fetched before matchlist, just to prime the
  // account cache.
  await getData(
    API.getPlayer(platform, gameName, tagLine),
    LeagueAccountModel,
    ["tft", "accounts_v2", fullName],
    {
      shouldFetchIfPathExists,
    },
  );

  const matchList = await getData(
    API.getMatchList(platform, gameName, tagLine),
    GenericMatchListModel,
    ["tft", "matchLists_v2", fullName],
    {
      shouldFetchIfPathExists,
    },
  );

  const matchesToLoad = Array.isArray(matchList)
    ? matchList.filter((matchId, i) => {
        const matchIsVisible = state.transient?.visibleMatches?.[matchId];
        return matchIsVisible || i < MATCH_LIMIT;
      })
    : [];

  await Promise.all(
    matchesToLoad.map((matchId) => {
      return getData(
        API.getMatch(matchId),
        TFTMatchModel,
        ["tft", "matches_v2", matchId],
        {
          shouldFetchIfPathExists: false,
        },
      );
    }),
  );

  await Promise.all(
    matchesToLoad.map((matchId) => {
      return getData(
        API.getMatchRoundData(matchId),
        TFTMatchRoundModel,
        ["tft", "matches_round", matchId],
        {
          shouldFetchIfPathExists,
        },
      );
    }),
  ).catch(() => {
    // it's okay if this fails since some matches don't have round data
    // console.log(e);
  });
}
