**August 18, 2016**
- Increased fidelity and reduced distortion for fire sounds of the Glock-18, P2000, USP-S and Desert Eagle.
- Unique distant, reload and draw sounds for Glock-18, P2000, USP-S and Desert Eagle.

**December 15, 2015**
- Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details).

**December 8, 2015**
- Increased move inaccuracy on pistols: Dual Berettas, Five-SeveN, Glock-18, P2000, P250, Tec-9, USP-S, CZ75-Auto.

**July 23, 2015**
- Fixed P2000 viewmodel hammer pop when reloading.
