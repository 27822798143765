import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { displayRate } from "@/util/helpers.mjs";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
`;

const Header = styled("div")`
  background: var(--shade10-75);
  height: calc(var(--sp-5e) * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: calc(var(--sp-1e) + var(--sp-pxe))
    calc(var(--sp-1e) + var(--sp-pxe)) 0 0;
  p {
    text-transform: uppercase;
    color: var(--shade1);
    margin-left: var(--sp-3e);
  }
`;
const Content = styled("div")`
  display: flex;
  flex-direction: column;
  background: var(--shade9-75);
  border-radius: 0 0 calc(var(--sp-1e) + var(--sp-pxe))
    calc(var(--sp-1e) + var(--sp-pxe));
  padding: var(--sp-3e);
  padding-top: var(--sp-2_5e);
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const WinPercent = styled("p")`
  &.your-team {
    color: #30d9d4;
    align-self: flex-start;
  }
  &.enemy-team {
    color: #e44c4d;
    align-self: flex-end;
  }
`;

const WinBar = styled("div")`
  flex: ${({ $winPercent }) => $winPercent};
  height: var(--sp-2e);
  border-radius: var(--sp-1e);
  width: 100%;
  margin-top: var(--sp-1_5e);
  margin-bottom: var(--sp-2e);

  &.your-team {
    background: #30d9d4;
  }
  &.enemy-team {
    background: #e44c4d;
  }
`;

const Team = styled("p")`
  color: var(--shade1);
  text-transform: uppercase;
`;

const WinPrediction = ({
  playerTeamWinPercent,
  enemyTeamWinPercent,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Container {...props}>
      <Header className="title">
        <p className="type-overline">
          {t("val:aiWinPrediction", "AI Win Prediction")}
        </p>
      </Header>
      <Content className="content">
        <Row>
          <WinPercent className="type-body2-form--active your-team">
            {displayRate(playerTeamWinPercent, 100)}
          </WinPercent>
          <WinPercent className="type-body2-form--active enemy-team">
            {displayRate(enemyTeamWinPercent, 100)}
          </WinPercent>
        </Row>
        <Row>
          <WinBar
            className="type-body2-form--active your-team"
            $winPercent={playerTeamWinPercent}
          />
          <WinBar
            className="type-body2-form--active enemy-team"
            $winPercent={enemyTeamWinPercent}
          />
        </Row>
        <Row>
          <Team className="type-overline">
            {t("lol:teams.your", "Your team")}
          </Team>
          <Team className="type-overline">
            {t("lol:teams.enemy", "Enemy team")}
          </Team>
        </Row>
      </Content>
    </Container>
  );
};

export default WinPrediction;
