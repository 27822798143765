The goal of the data model is to _cast types to what is expected, in order to prevent runtime errors due to external data sources out of our control_.

Given this definition:

```js
const Match = {
  id: String,
  data: {
    players: [{}],
  },
};
```

And this data:

```js
{
  id: 42,
  data: null,
}
```

It is expected to return:

```js
{
  id: "42",
  data: {
    players: [],
  },
}
```

## Model Helpers

```js
import { Any, Silent, Optional, Partial } from "@/__main__/data-model.mjs";
```

#### Any/Unknown

Allow any shape or value.

```js
dubiousShape: Any,
```

#### Silent

This will cause properties that are missing to be initialized, and values of differing types to be cast to the expected type.

This matches exactly with the default behavior except **but** it will silence warnings on any type mismatch. This is particularly useful if certain fields are expected to be missing from the data source.

With `dubiousValue = null`

```js
dubiousValue: Silent(Number),
```

you get `{ dubiousValue = 0 }`

#### Optional

Note: if the field is `null`, the key will be deleted instead of remaining `null`, however this can be overridden by using a `Union` with a `null` entry.

With `dubiousValue = null`

```js
dubiousValue: Optional(Number),
```

you get a shape without a dubiousValue key `{}`

#### Partial / DeepPartial

Allow properties on a shape to not exist, but will enforce their values if the key exists. DeepPartial does this recursively.

#### Union

Allow one of a set of values. These could be exact primitives, or other models, however only the first matched, valid model is used.

```js
dubiousValue: Union([shapeObj, Number]),
```

#### Tuple

Denotes a fixed list of values.

```js
dubiousValue: Tuple([String, Number, shapeObj]),
```

#### Mapped

Mark a field as being a mapping of arbitrary keys to values.

```js
dubiousMap: Mapped({ key: String, value: shapeObj }),
```

## Mapping Models (afterTransformModel)

The shape defined by BE is not necessarily the shape your data has to end in.

For example: BE offers an list of all events I have opted into:

```js
export const model = {
  id: String,
  optIn: {
    userAccountId: String,
  },
};

const apiValidationModel = createModel({ data: { events: [model] } });
```

Scanning through the list to tell if a user is opted in every time is tedious. Getting `userAccountId` by using `userOptIn.optIn.userAccountId` is tedious. Lets fix that:

```js
const afterTransformModel = {
  id: String,
  userAccountId: String,
};

const afterTransformValidation = createModel(
  Mapped({ key: String, value: afterTransformModel }),
);
```

Our new shape is set and (important) we can test/validate our mapping:

```js
const mapOptIns = (events) =>
  optIns.reduce((acc, e) => {
    acc[e.id] = { userAccountId: e.userAccountId };
    return acc;
  }, {});

function transform(data) {
  data = apiValidationModel(data);
  return afterTransformValidation(mapOptIns(data.data.events));
}
```

And finally you have a map of eventId to optIn info!

## Why not [popular schema validation library]?

A few have been evaluated, most notably [Zod](https://github.com/colinhacks/zod). However, by default it doesn't fulfill what we need it to do. Most libraries will default to throwing errors on type mismatch.

Therefore, even if we were to use a third-party library for this, we can't expose it directly because default usage is not suitable for our use case. Using a schema validation library in the way that it is intended may cause non-fatal errors to appear to the user as the app crashing, or at least make error recovery more difficult, since in most cases it would be possible to just typecast and continue.
