import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { winRatecolorRange } from "@/app/util.mjs";
import { AGENT_CLASS_ICONS } from "@/game-val/constants/constants-class-icons.mjs";
import {
  calcAvgScore,
  calcWinrate,
  getAgentName,
  kdaColor,
  scoreColorStyle,
} from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import useAgentId from "@/game-val/utils/use-agent-id.mjs";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--sp-3e);

  .agent-container {
    height: var(--sp-8e);
  }

  .agent-details {
    margin-left: var(--sp-3e);
    justify-content: space-between;
    height: 100%;
  }

  .agent-headshot {
    width: var(--sp-8e);
    height: var(--sp-8e);
    overflow: hidden;
    border-radius: calc(var(--sp-1e) + var(--sp-pxe));
    img {
      width: 100%;
      height: auto;
    }
  }

  .agent-class {
    svg {
      height: var(--sp-5e);
      width: var(--sp-5e);
      margin-right: var(--sp-1_5e);
    }
    color: var(--shade0-50);
  }

  .agent-stats {
    gap: var(--sp-4e);
    justify-content: space-between;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p.stat {
    font-size: var(--sp-3_5e);
  }

  p.stat-text {
    color: var(--shade0-50);
    line-height: var(--sp-4e);
  }

  .uppercase {
    font-size: 0.6em;
    text-transform: uppercase;
  }
`;

const EmptyAgentHeadshot = styled("div")`
  width: var(--sp-8e);
  height: var(--sp-8e);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  background-color: var(--shade6);
`;

const platformPath = getPlatformPath(false);

const SelectedAgent = ({ profileId, agentId, queue }) => {
  const { t } = useTranslation();
  const {
    val: {
      playerAgentStats,
      content: { acts = [] } = {},
      profiles: { [profileId]: { puuid } = {} },
    },
  } = useSnapshot(readState);
  const allAgentStats = useMemo(() => {
    const latestActId = acts.find(
      (act) => act.endedAt === null && act.episode.endedAt === null,
    )?.uuid;
    const result =
      playerAgentStats?.[puuid]?.[latestActId]?.[platformPath]?.[queue];
    return Array.isArray(result) ? result : [];
  }, [acts, playerAgentStats, puuid, queue]);
  const selectedAgentStats = useMemo(() => {
    return allAgentStats.find(
      (stats) => stats.agent.uuid.toLowerCase() === agentId?.toLowerCase(),
    );
  }, [agentId, allAgentStats]);
  const { agentKey, agentName, agentClass, agentImg } = useAgentId(agentId);

  const {
    matchesWon: wins,
    matchesPlayed: matches,
    matchesLost: losses,
    kills,
    deaths,
    assists,
    score,
    roundsPlayed,
  } = selectedAgentStats || {};
  const ties = selectedAgentStats ? matches - losses - wins : null;
  const winRate = calcWinrate({ wins, ties, matches });
  const kda = calcRate(kills + assists, deaths, 2);
  const kdaString = kda.toLocaleString(getLocale(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const avgScore = calcAvgScore(score, roundsPlayed);
  const avgScoreString = avgScore.toLocaleString(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const emptyStat = "-";
  const AgentClassIcon = AGENT_CLASS_ICONS[agentClass];

  return (
    <Container $agentKey={agentKey}>
      <div className="row agent-container">
        {agentImg ? (
          <div className="agent-headshot">
            <img src={agentImg} />
          </div>
        ) : (
          <EmptyAgentHeadshot />
        )}
        <div className="column agent-details">
          <p className="type-body2-form--active agent-name">
            {agentId
              ? getAgentName(t, agentKey, agentName)
              : t("val:overlay.selectAnAgent", "Select an agent")}
          </p>
          <p className="row agent-class uppercase">
            {AgentClassIcon ? <AgentClassIcon /> : null}
            {agentClass ? agentClass : null}
          </p>
        </div>
      </div>
      <div className="row agent-stats">
        <div className="column">
          <p
            className="stat type-body2-form--active "
            style={{
              color: matches > 0 ? winRatecolorRange(winRate) : "var(--shade2)",
            }}
          >
            {matches > 0 ? displayRate(winRate, 100) : emptyStat}
          </p>
          <p className="stat-text uppercase">{t("lol:winRate", "Win Rate")}</p>
        </div>
        <div className="column">
          <p
            className="stat type-body2-form--active "
            style={{
              color: matches > 0 ? kdaColor(kda) : "var(--shade2)",
            }}
          >
            {matches > 0 ? kdaString : emptyStat}
          </p>
          <p className="stat-text uppercase">{t("lol:kda", "KDA")}</p>
        </div>
        <div className="column">
          <p
            className="stat type-body2-form--active "
            style={{
              color: matches > 0 ? scoreColorStyle(avgScore) : "var(--shade2)",
            }}
          >
            {matches > 0 ? avgScoreString : emptyStat}
          </p>
          <p className="stat-text uppercase">
            {t("val:avgScore", "Avg. Score")}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default SelectedAgent;
