import globals from "@/util/global-whitelist.mjs";
import lazyFn from "@/util/lazy-fn.mjs";

/**
 * How to use
 * Dev enviroment: You must be using blitz-app + blitz-core at minimum to use this feature
 * Prod environment: You must enable __BLITZ_DEV__ to be true via query params
 * 1. Open console in electron instance
 * 2. Use the key(s) you created to execute the function you added within `__BLITZ_DEV__.eventReplay`
 *
 * How to add
 * 1. Dynamically import your event replayable functions
 * 2. Place your event replayable functions in the correct comment label (or create a comment label for it)
 *
 * Considerations
 * - Run the `./fixtures/index.cjs` to minify the JSON files in the fixtures directory
 */
const replayer = {
  // Play a full match
  playApexMixTapeFTDMMatch: lazyFn(
    () => import("@/feature-replay-blitz-message/play-apex-ftdm-match.mjs"),
  ),
  playApexMixTapeControlMatch: lazyFn(
    () => import("@/feature-replay-blitz-message/play-apex-control-match.mjs"),
  ),
  playApexBRTrioMatch: lazyFn(
    () => import("@/feature-replay-blitz-message/play-apex-trio-match.mjs"),
  ),
};

export function setup(): void {
  globals.__BLITZ_DEV__.eventReplay = Object.create(null);
  for (const key in replayer)
    globals.__BLITZ_DEV__.eventReplay[key] = replayer[key];
}

export function teardown(): void {
  for (const key in replayer) delete globals.__BLITZ_DEV__.eventReplay[key];
  delete globals.__BLITZ_DEV__.eventReplay;
}
