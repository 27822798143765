import React, { lazy, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import Accolade from "@/game-fortnite/components/Accolade.jsx";
import HitStatsPostmatch from "@/game-fortnite/components/HitStatsPostmatch.jsx";
import ItemsUsed from "@/game-fortnite/components/ItemsUsed.jsx";
import MatchDataTablePostmatch from "@/game-fortnite/components/MatchDataTablePostmatch.jsx";
import MatchWeaponsTable from "@/game-fortnite/components/MatchWeaponsTable";
import Quests from "@/game-fortnite/components/Quests.jsx";
import StatsBreakdown from "@/game-fortnite/components/StatsBreakdown.jsx";
import { FORTNITE_PLAYLISTS } from "@/game-fortnite/constants/playlists.mjs";
import { FORTNITE_QUEUES } from "@/game-fortnite/constants/queues.mjs";
import type { PlayerMatchBE } from "@/game-fortnite/models/match-player.mjs";
import type { MatchQuery } from "@/game-fortnite/models/match-query.mjs";
import type { Player } from "@/game-fortnite/models/profile.mjs";
import fnRefs from "@/game-fortnite/refs.mjs";
import { getItemsUsed } from "@/game-fortnite/utils/get-weapons-used.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import Mouse from "@/inline-assets/mouse.svg";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import matchRefs from "@/shared/Match.refs.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { MatchContextProvider } from "@/shared/use-match-context.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MatchProcessing = lazy(() => import("@/shared/MatchProcessing.jsx"));

export default function Match(): React.JSX.Element {
  const { t } = useTranslation();
  const {
    parameters: [profileId, _, matchId, tab, tabId],
  } = useRoute();
  const state = useSnapshot(readState);
  // @ts-ignore
  const match: MatchQuery | Error | undefined = state.fortnite.matches[matchId];
  const matchError = match instanceof Error;
  const itemsUsed = useMemo(
    () => getItemsUsed(profileId, match),
    [match, profileId],
  );

  const accounts = state.settings.loggedInAccounts.fortnite;
  const isOwnProfile = accounts && !!accounts[profileId];
  const matchContext = useMemo(
    () => ({
      tab,
      tabId,
      isOwnProfile,
      match,
    }),
    [isOwnProfile, match, tab, tabId],
  );

  if (!match || match instanceof Error)
    return (
      <Suspense fallback={null}>
        <MatchProcessing redirectUrl={`/fortnite/profile/${profileId}`} />
      </Suspense>
    );
  // Processed match calculations only, not for in-game use
  const sorted: Array<PlayerMatchBE> = !matchError
    ? orderArrayBy(match.playerMatches.slice(), "aliveTime", "desc")
    : [];
  const playerIndex = sorted.findIndex((i) => i.player.accountId === profileId);

  const mode = Object.getOwnPropertySymbols(FORTNITE_PLAYLISTS).find(
    (s) =>
      FORTNITE_PLAYLISTS[s].isRanked === match?.isRanked &&
      FORTNITE_PLAYLISTS[s].isZeroBuild === match?.isZeroBuild &&
      Object.getOwnPropertySymbols(FORTNITE_PLAYLISTS[s].queues).some(
        (q) => FORTNITE_QUEUES[q].key === match?.mode,
      ),
  );
  const queue = Object.getOwnPropertySymbols(FORTNITE_QUEUES).find(
    (s) => FORTNITE_QUEUES[s].key === match.mode,
  );

  const LayoutComponent = fnRefs.PostMatchLayout ?? SharedMatchLayout;
  return (
    <MatchContextProvider value={matchContext}>
      <LayoutComponent
        match={match} // SharedMatchLayout handles UI when there is an error
        title={
          matchError
            ? undefined
            : t("common:stats.placement", "{{placement, ordinal}}", {
                placement: sorted[playerIndex].placement,
              })
        }
        image={Static.getProfileImage(matchId, sorted[playerIndex]?.character)}
        imageLink={`/fortnite/profile/${profileId}`}
        borderColor={
          matchError
            ? "var(--shade3)"
            : sorted[playerIndex].placement === 1
              ? "var(--turq)"
              : "var(--red)"
        }
        underTitle={
          matchError ? undefined : (
            <div className="flex align-center gap-2 color-shade2">
              <Mouse style={{ width: "var(--sp-6)", height: "var(--sp-6)" }} />
              {mode && (
                <>
                  <div>{t(...FORTNITE_PLAYLISTS[mode].t)}</div>·
                </>
              )}
              <div style={{ textTransform: "capitalize" }}>
                {queue
                  ? t(...FORTNITE_QUEUES[queue].t)
                  : match.mode.toLowerCase()}
              </div>
              ·
              <TimeAgo date={match.startedAt} className="" />·
            </div>
          )
        }
      >
        <MainColumnsContainer
          className={matchRefs.columnsContainerClassName?.()}
        >
          <div
            className="span-1 flex direction-column"
            style={{ display: "flex" }} // This overrides MainColumnsContainer's display grid
          >
            <Accolade profileId={profileId} match={match as MatchQuery} />
            <ItemsUsed items={itemsUsed} />
            <Quests />
          </div>
          <div className="span-2">
            <HitStatsPostmatch
              match={match as MatchQuery}
              profileId={profileId}
            />
            {matchRefs.InContentBanner && <matchRefs.InContentBanner />}
            <StatsBreakdown match={match as MatchQuery} profileId={profileId} />
            <MatchWeaponsTable
              match={match as MatchQuery}
              profileId={profileId}
            />
            <MatchDataTablePostmatch
              match={match as MatchQuery}
              profileId={profileId}
            />
          </div>
        </MainColumnsContainer>
      </LayoutComponent>
    </MatchContextProvider>
  );
}

export function meta([profileId]) {
  const profile: Player | Error | undefined =
    readState.fortnite.profiles[profileId];
  const placeholder = "No User";
  // Error instance has a name property
  const name =
    profile instanceof Error ? placeholder : (profile?.name ?? placeholder);
  return {
    title: [
      `fn:matchTitle`,
      `{{name}}'s Match Stats – Fortnite – Blitz Fortnite`,
      { name },
    ],
    description: [`fn:matchDescription`, `Match Stats for {{name}}`, { name }],
  };
}
