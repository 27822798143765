import mainRefs from "@/__main__/refs.mjs";
import appRefs from "@/app/refs.mjs";
// import matchRefs from "@/shared/Match.refs.jsx";
// import profileRefs from "@/shared/Profile.refs.jsx";
// import HeaderWrapper from "@/feature-ads-baseline/HeaderWrapper.jsx";
import AdWrapper from "@/feature-ads-baseline/AdWrapper.jsx";
import {
  externalContainerCss,
  titleBarCss,
} from "@/feature-ads-baseline/HeaderWrapper.style.jsx";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  appShellRefs: appRefs.appShell,
  mainRefComponents: mainRefs.components,
  mainRefs,
  // matchRefs,
  // profileRefs,
});

export function setup() {
  original.set({
    mainRefComponents: {
      // HeaderWrapper: HeaderWrapper,
      ContentWrapper: AdWrapper,
    },
  });

  original.append({
    appShellRefs: {
      titleBarClasses: [titleBarCss],
    },
    mainRefs: {
      externalContainerClasses: [externalContainerCss],
      // internalContainerClasses: [cssAdStyles, IS_APP ? cssIframeEvents : null],
    },
  });
}

export function teardown() {
  original.restore();
}
