**March 15, 2017**
- Released Canals, available in all game modes.

**March 17, 2017**
- Fixed visible nodraw below CT bridge.
- Fixed several places where the bomb could get stuck if thrown.
- Fixed pixelwalk and skywalk exploits.
- Fixed sticky DM spawn point in tunnels.
- Fixed planting bomb on windowsill at B.
- Fixed missing collision on toolbox at back of B.

**March 20, 2017**
- Fixed more pixelwalk exploits.
- Fixed places where a dropped/thrown bomb could get stuck.

**March 24, 2017**
- Fixed more pixelwalk spots & places where bomb could get stuck.
- Fixed sticky clip brush at B.
- Fixed Z-fighting railing at bridge.
- Fixed visible nodraw underneath a bridge.
- Improved clipping under T side bridges.
- Fixed Z-fighting below CT arches.
- Adjusted courtyard statues and stairs for better movement.
- Fixed various fade distance issues.
- Widened B site and palace doors.
- Aligned palace windows better.
- Fixed floating clothesline.
- Adjusted pecklesteiner.
- Fixed missing texture at back alley.
- Removed clip from garden lamp post because it’s at jumping height.
- Prevented planting bomb on railing at A.
- Removed lamp posts between A and CT spawn.

**March 29, 2017**
- Fixed gap in bomb site B wall.
- Fixed missing texture in bridge visible from arch.
- Fixed overlapping geometry at A site.
- Fixed a few places near T spawn where bomb could get stuck.
- Increased fade distances on some props that were distracting.
- Adjusted wall geometry in prison stairs to fix lighting glitch.
- Increased lightmap grid resolution in prison lobby.
- Added a piece of cardboard under new toolbox at B to block glitchy sightline.
- Removed one of the picnic tables at Short.
- Fixed pixelwalk in shoe shop.
- Fixed errant clip brush on balcony.
- Made it more difficult to climb the wood sign to B from boost.
- Trimmed top corners of courtyard building facing the stairs.
- Fixed nav. file where lamp posts were removed.
- Adjusted balcony arch prop to fix gaps.

**April 20, 2017**
- Rebuilt tunnel stairs area with smoother movement.
- Fixed boat geometry overlapping a pier at CT spawn.
- Fixed box intersecting windowsill at B.
- Fixed pixelwalk on railing at CT spawn.
- Moved plywood down on scaffolding below B to block a bad sightline.
- Fixed sticky scaffolding clip brush below B.
- Fixed some fade distance pop-up issues.
- Added two picnic chairs at short and made clips smoother.
- Removed lamp at ivy/garbage.

**February 8, 2018**
- Closed off windows overlooking courtyard and A
- Cleaned up sightline from corner balcony toward A and Arch
- Made CT route to B more direct
- Joined two rooms on CT side of B into one space
- Simplified cover on back side of B
- Removed Ivy “laddice”, now a skill jump / boost spot
- Grenade-clipped CT bridge railings for more predictable smokes
- Moved courtyard statues forward for cleaner sightline
- Added ashtray for smokers

**October 9, 2018**
- Canals has been removed from Competitive Matchmaking.
