import { Figma } from "@/feature-docs/DocsComponents.jsx";

## Formerly Blitz Arena

Battles currently supports only League of Legends with plans to support for all blitz supported games.

Gamers around the world use Blitz to improve their skills and gameplay - and Blitz Battles is where it matters most. For every exciting event, players can put their skills to the test to see if they have what it takes to take home the prize. Each one will feature specific criteria and missions that will net points for the player, and every match offers the chance to be rewarded based on gameplay. The top scorers at the end of the event will win exciting prizes such as gift cards to gaming PCs and more.

### Features

- A new hub page and sidebar icon
- Several native ads around the app
- A scording and points overlay
- Tabs to the games postmatch screen with event performance data
- Generalization - No event items are hard coded they all come from the CMS

### Critical Functions

- Allow a user to sign up for an event.
- Battles hooks into the start and end of game hooks to send data to the CMS that a user has started/ended a game with blitz open.
- When a game starts, battles gets the games that a user has signed up for and sends that data to the overlay for scoring.

### [Spec](https://www.figma.com/file/94pLXWZZScsxLYHiNs2FQg/%5BCHA%5D-Arena-Hub---MVP?node-id=566%3A39411)

<Figma url="file%2F94pLXWZZScsxLYHiNs2FQg%2F%255BCHA%255D-Arena-Hub---MVP%3Fnode-id%3D566%253A39411" />
