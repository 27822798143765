**January 29, 2020**
- Opened up skybox over building separating Ivy/A site
- Various visibility improvements
- Various minor bug fixes

**April 25, 2019**
- Added nav blocker entities for Wingman
- Changed ground material to a brighter version
- Updated train car models with improved collision model
- Added white background near T entrance to A main

**March 31, 2015**
- Added to the Active Duty map group.

**December 18, 2014**
- Widened lower ladder room.
- Blocked visibility from Bombsite B lower ramp to T ramp.
- Pushed back T spawn slightly.
- Updated collision model on train bumper models.
- Fixed some spots where C4 could get stuck.
- Fixed sun orientation.

**December 11, 2014**
- Sadly fixed a hilarious bug where players could ride pigeons
- Fixed some spots where a dropped bomb could get stuck
- Fixed player collision on Bombsite A white boxes
- Fixed one-way wallbang through shack near connector

**December 10, 2014**
- Reintroduced Train, which has been added to the Operation Vanguard map group (available for free to all CS:GO players).
- For more information, visit www.counter-strike.net/reintroducing_train
