**September 21, 2021**
- Re-tired visblocker from T spawn to mid
- Blocked unintended wallbang

**September 21, 2021**
- Slight visibility tweak from T spawn through mid
- Improved visibility into B back site from upper tunnels

**June 10, 2020**
- Flipped B site doors
- Simplified background when looking from B tunnels towards truck in mid
- Changed fog start distance from 1000 to 512, changed max density from .2 to .4
- Raised windows by A site ramp to make characters stand out more

**January 29, 2020**
- Opened up skybox from B halls towards site

**March 29, 2018**
- Removed one-way wallbang from CT spawn up to cat
- Fixed some minor bugs
- Fix for mip bias exploit

**November 8, 2017**
- Increased overall luminance 20% for all Kasbah materials and models for visibility.
- Fixed missing textures on some polygons of tarp model.
- Fixed flashbangs not blinding player through chain-link fences.
- Fixed edging around Kasbah alcove window which had shadowing artifacts.
- Adjusted penetration value for wood doors.
- Fixed wallbang values on Mechanique Generale.
- Fixed ambient lighting values at CT spawn entrance.
- Smoothed out clipping on block stack at Mid.
- Fixed some models that were embedded in the ground.
- Fixed dev text on loading screen.
- Fixed cover props at B site that would expose player feet.

**October 27, 2017**
- Adjusted bullet penetration value of wooden doors
- Smoothed out ground displacements for more predictable grenades
- Adjusted shadow ambient color temperature
- Clip improvements to curbs
- Refined stairs at Cat to Short for movement
- Refined Window cutout model on B Bombsite to remove head shaped shadow
-
**October 24, 2017**
- Performance optimizations on low-end and medium-end computers.
- Removed windows and awning on Catwalk for visibility.
- Moved door awning on Short A for player movement.
- Fixed slit in boxes in Lower Tunnels.
- Removed small port hole windows from towers at Short A for visibility.
- Removed grain sack from Bombsite A.
- Removed awnings from Salon on Long.
- Grenade Clipped stone trim pieces.
- Fixed room at CT Spawn eating weapons.
- Moved windows and conduits from wall looking from CT Ramp to Short A.
- Fixed a number of small holes in the world.
- Fixed fade distance on barrel at Mid.
- Upped light value on a number of windows for visibility.
- Lowered noise and contrast on some texture sets for visibility.
- Fixed non-precise clipping on dumpsters at Outside Tunnels.
- Set ‘surfaceproperty’ on Cars to ‘metalvehicle’.
- Addressed visibility on Pit.
- Removed rollup door on Salon for visibility.
- Fixed some noisy walls in Tunnels for visibility.
- Addressed some visibility issues in Back B Plat.
- Upped contrast for some cover props at Bombsite B.
- Fixed clip that players could get stuck on in Pit Plat.
- Updated radar to reflect building at Car.
- Fixed errant displacement at Side Pit.
- Added a few rooftop faces that can be seen while boosting.

**October 20, 2017**
- Fixed sun not showing..
- Smoothed out terrain by CT spawn to allow smoking off from X-Box.
- Removed taxi sign from certain car models.
- Cleaned up alpha painting on Tunnels building for player reads.
- Added window shutter above Catwalk.
- Fixed glowing CT player models on low end settings.
- Adjusted fog values.
- Cleaned up player reads from CT Mid up toward Suicide.
- Improved collision of cover props at B Bombsite.
- Fixed bad surface property sounds on cover props at B.
- Some clipping refinements.
- Fixed a few wall-bang bugs on buildings.
- Grenade clips added to CT spawn.
- Fixed small walls at Outside Tunnels not being reliably jump-able.
- Adjusted a number of models that were not lined up properly.
- Fixed interpenetrating barrels at Mid.
- Fixed some crates at A there were hanging players up.
- Smoothed out wall behind Car at A.
- Fixed displacement that had too high an angle at CT Ramp.

**February 3, 2017**
- Fixed various spots where C4 could get stuck

**November 29, 2016**
- Removed barrels from Dust2 radar.

**November 28, 2016**
- Removed barrels outside of doors leading into B site
- Blocked visibility through some props around A site
- Fixed some spots where C4 could get stuck
- Fixed some minor graphical bugs

**September 3, 2015**
- Fixed a spot near mid doors where C4 could get stuck
- Fixed some texture bugs in A site
- Raised lamp near T spawn towards mid
- Added commemorative grafitti

**July 9, 2015**
- Fixed 30 exotic spots where C4 could get stuck[1]

**April 15, 2015**
- Fixed a bug where a spot in Long A had the wrong radar name.

**March 31, 2015**
- Opened up skybox around Long A double doors, Catwalk and Short
- Improved visibility around blue car in Long A
- Fixed render distance on a crate in CT spawn

**January 8, 2015**
- Fixed bullet penetration through arch models in tunnels
- Fixed collision for door top of mid
- Added Goose to Goose

**September 2, 2014**
- Expanded bomb plant zone at Bombsite A to include outside of boxes
- Smoothed out collision on door to Bombsite B

**July 10, 2014**
- Replaced all game-play affecting double doors with the new metal ones.
- Fixed some bad geometry in various places that allowed bullet penetration when it shouldn’t.

**July 1, 2014**
- Fixed some spots where C4 could get stuck.
- Reduced post processing effects.

**March 26, 2014**
- Removed some small gaps between crates in Bombsite B

**March 6, 2014**
- Fixed tiny gaps between boxes in Bombsite A
- Fixed killer box in Bombsite B

**February 5, 2014**
- Removed dark texture near tunnel stairs.
- Removed sky collision over building near T spawn.
- Covered up shadow that looked like a player near CT spawn.
- Fixed invisible ledges on curved corners.

**July 3, 2013**
- Improved visibility
- Bug fixes
