This is the first part of a "why vs. [why not](/docs/appendix-why-not)" series. Rather than being strictly technical, this document should explain the whys behind the meta-decisions in this codebase, or how did we get here.

## Raising the Floor of the Product

blitz-web at the time that we inherited it had very little documentation, no tests, no rules, and wasn't built to scale past one game, League of Legends (in fact there was even a separate Fortnite app). Little effort was made to fix underlying problems as we were building out features fast and growing our audience. There were contractors who came and went, adding technical debt by avoiding re-use, introducing new problems, and general disorganization due to lack of rules or enforcement. We quickly learned that rules are ineffective without enforcement, so the work began to unify the codebase.

### Domain-specific Improvements

One thing that was the cause of many bugs and misunderstandings was the lack of coherence around constants and utilities. For example, what is the ADC role? "ADC", "Bot", "Bottom", "ADC Carry", and many other variants. There were multiple utilities within the same game that did the same thing, such as reading champion data, often in different and incompatible ways.

It wasn't feasible to slowly replace the old constants and utilties; due to the ambiguity, developers would often copypaste the old way instead of new. It had to be done in one shot, and enforcement had to be in place to prevent the same issues from re-occuring.

### Baseline Improvements

There were many baseline improvements, such as enforcement of a data model when fetching data, making sure data is fetched in a consistent manner, and that routes are always linkable by serializing state in the URL. These are things that are hard to do consistently for inexperienced developers so these need to be enforced as well. The consequences for not doing so can lead to crashes when destructuring invalid data, missing data when rendering in SSR, or just having a web app where the links don't work because URLs don't change properly.

## Raising the Ceiling for Contributors

The codebase is designed with developers of all skill levels in mind, from the first year junior to the seasoned dev. The idea is that there should be enough documentation, enforcement, and cultural momentum to enable junior devs produce good output. Caveat: the codebase is _not_ designed for individuals who think they are above the rules, as that would spiral into chaos.

A major design goal is to unleash the potential of senior devs by not getting in their way too much (beyond the [basic rules](/docs/docs-architecture) of course). There is the potential to write entire routes using [a different view layer](https://github.com/theblitzapp/blitz-app/blob/master/src/game-lol/in-game.mjs), dynamically overwrite entire sections of the app using feature modules, define WASM modules to compress data or draw images, and freedom to extend the inner workings of the app (with unit tests).

## Fail Fast

Quick iteration enables us to fail fast. Every aspect of the app is designed to enable us to iterate quickly and ship faster, and learn more quickly from mistakes. We are not trying to ship perfection, in most cases, good enough is finding out what users find valuable. Quirks are acceptable as long as the value is there for users.

### Iteration and Reaction Speed

We want to be as minimally invested in building out new features for new games, reducing time and cost. This is achieved by templating the barebones game, "Super Meat Slicer" which provides basic components, routes, etc., "overlay routes" which include draggable components and a navigation-less app, and a selection of reusable components in the `clutch`, `shared` and `shared-fps` directories.

There are automated systems in place such as the user-submitted reports, and automated error reports that allow us to react quickly to feedback, and the OTA release system (or `npm version` script) which builds the web app and uploads it in seconds. The fastest reaction to finding a bug and releasing it on production has been about 10 minutes.

### Find Problems Before Users Do

Reviewing PRs is where we ideally find problems before they get released. Tests run on every commit, both locally and remotely. It is up to the reviewers discretion whether manual review is required after reviewing the diff. It is not always possible to catch every bug via tests and code/manual review, so we have our live staging environment, [the website](https://blitz.gg).
