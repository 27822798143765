**October 3, 2018**
- Enabled MP5-SD in official Competitive Matchmaking.

**August 29, 2018**
- Darkened exposed metal on ejection port and bolt.
- Added support for MP5-SD to workshop workbench.

**August 21, 2018**
- Enabled MP5-SD in Wingman matches.

**August 16, 2018**
- Slight increase in maximum audible distance of primary fire sound.
- Chickens are no longer scared when players fire the MP5-SD.

**August 15, 2018**
- Added the all new MP5-SD, available for testing Offline and in Official Casual and Deathmatch servers. It can be equipped in your MP7 loadout slot.
