import { readState } from "@/__main__/app-state.mjs";
import { postData, readData } from "@/__main__/get-data.mjs";
import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import { setRoute } from "@/__main__/router.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { appURLs } from "@/app/constants.mjs";
import noopModel from "@/data-models/no-op.mjs";
import { GamePhase } from "@/game-deadlock/constants/enums.mjs";
import { GAME_SYMBOL_DEADLOCK } from "@/game-deadlock/definition.mjs";
import type { Match } from "@/game-deadlock/models/match.mjs";
import {
  trackMatchStart,
  transformMatch,
  updateLastLoggedIn,
  updateLoggedInAccounts,
  writeMatchData,
} from "@/game-deadlock/utils/actions.mjs";
import {
  EVENT_DEADLOCK_EXIT_GAME,
  EVENT_DEADLOCK_HEARTBEAT,
  EVENT_DEADLOCK_METADATA,
} from "@/game-deadlock/utils/deadlock-client.api.mjs";
import { devDebug, devWarn } from "@/util/dev.mjs";
import { updateLatestPlayed } from "@/util/game-handlers.mjs";
import { steam3to64 } from "@/util/steam.mjs";

async function initDeadlock() {
  await initEvents;

  handleMessage(
    EVENTS.DEADLOCK_HEARTBEAT,
    (payload: { sessionId: string; steamId?: number; type: string }) => {
      devDebug("[Deadlock] Received DEADLOCK_HEARTBEAT", payload);
      const steam3 = payload?.steamId;
      eventBus.emit(EVENT_DEADLOCK_HEARTBEAT, {
        sessionId: payload.sessionId,
        type: payload.type,
        ...(steam3 && {
          steam3,
          steam64: steam3to64(steam3.toString()),
        }),
      });
    },
  );

  handleMessage(
    EVENTS.DEADLOCK_IS_RUNNING,
    (payload: { running: boolean; steamId?: number }) => {
      devDebug("[Deadlock] Received DEADLOCK_IS_RUNNING", payload);
      const isRunning = payload.running;
      if (!isRunning) {
        setVolatileKV("currentGameTuple", null);
        return;
      }
      const steam3Id = payload.steamId?.toString();
      setVolatileKV("currentGameTuple", [GAME_SYMBOL_DEADLOCK]);
      updateLatestPlayed(GAME_SYMBOL_DEADLOCK);
      const loggedInSteam3Id = readState.settings.lastLoggedInIdByGame.deadlock;
      updateLoggedInAccounts(steam3Id);
      if (steam3Id && loggedInSteam3Id !== steam3Id)
        updateLastLoggedIn(steam3Id);
      if (isRunning && steam3Id) {
        setRoute(`/deadlock/profile/${steam3Id}`);
      }
    },
  );

  handleMessage(EVENTS.DEADLOCK_GAME_END, (payload: unknown) => {
    devDebug("[Deadlock] Received DEADLOCK_GAME_END", payload);
    eventBus.emit(EVENT_DEADLOCK_EXIT_GAME, payload);
  });

  handleMessage(
    EVENTS.DEADLOCK_GAME_START,
    (payload: {
      match_mode: string | number;
      match_id: string | number;
      start_time: number;
    }) => {
      devDebug("[Deadlock] Received DEADLOCK_GAME_START", payload);
    },
  );

  handleMessage(
    EVENTS.DEADLOCK_METADATA_UPDATED,
    (payload: {
      timestamp: number;
      metadata: {
        match_id: number;
        match_metadata: {
          cluster_id: number;
          metadata_salt: number;
          replay_salt: number;
          result: number;
        };
      };
    }) => {
      devDebug("[Deadlock] Received DEADLOCK_METADATA_UPDATED", payload);
      eventBus.emit(EVENT_DEADLOCK_METADATA, payload);
    },
  );

  handleMessage(
    EVENTS.DEADLOCK_GAME_PHASE_CHANGED,
    (payload: DeadlockGamePhaseChanged) => {
      devDebug("[Deadlock] Received DEADLOCK_GAME_PHASE_CHANGED", payload);
      trackMatchStart(payload);
      switch (payload.game_phase) {
        case GamePhase.GameInProgress: {
          // @ts-ignore
          const heroId = payload.local_player.m_HeroData.m_HeroID;
          if (heroId) {
            setRoute(`/deadlock/heroes/${heroId}/starting-items`);
          }
          break;
        }
        // Not sure about this yet
        // case GamePhase.PostGame:
        // case GamePhase.Abandoned:
        // case GamePhase.End: {
        //   // redirect to profile while we are reworking the post-match page
        //   const steam3Id = readState.settings.lastLoggedInIdByGame.deadlock;
        //   if (steam3Id) {
        //     setRoute(`/deadlock/profile/${steam3Id}`);
        //   }
        //   break;
        // }
        default:
          break;
      }
    },
  );

  handleMessage(
    EVENTS.DEADLOCK_POST_MATCH,
    async (payload: { contents: { match_info: Match }; timestamp: number }) => {
      devDebug("[Deadlock] Received DEADLOCK_POST_MATCH", payload);
      const matchId = payload.contents.match_info.match_id.toString();
      const steam3Id = readState.settings.lastLoggedInIdByGame.deadlock;
      const match = transformMatch(payload.contents);
      const matchlist = await readData(["deadlock", "matchlist", steam3Id]);

      // don't upload matches that are already known to us
      if (Array.isArray(matchlist) && matchlist.includes(matchId)) return;

      try {
        await postData(
          {
            url: `${appURLs.DEADLOCK}/deadlock/match/${matchId}`,
            body: JSON.stringify({ match_info: match }),
            method: "PUT",
          },
          noopModel,
          undefined,
        );
      } catch (error) {
        devWarn("[Deadlock] Failed to upload match data", error);
      }
      await writeMatchData(steam3Id, matchId, match);

      // don't auto redirect if the match is less than a minute old
      if (
        (match.start_time + match.duration_s) * 1000 >
        Date.now() - 1 * 60 * 1000
      ) {
        const tabString = readState.settings.deadlock.preferredMatchTab
          ? `?tab=${readState.settings.deadlock.preferredMatchTab}`
          : "";
        setRoute(`/deadlock/match/${matchId}/${steam3Id}${tabString}`);
      }
    },
  );
}

export default initDeadlock;

type DeadlockGamePhaseChanged = {
  match_mode: number;
  match_id: number;
  start_time: number;
  events: Array<unknown>;
  local_player: unknown;
  rules: {
    m_bDontUploadStats: boolean;
    m_bEnemyInAmberBase: boolean;
    m_bEnemyInSapphireBase: boolean;
    m_bFastCooldownsEnabled: boolean;
    m_bFlexSlotsForcedUnlocked: boolean;
    m_bInfiniteResourcesEnabled: boolean;
    m_bMatchSafeToAbandon: boolean;
    m_bNoDeathEnabled: boolean;
    m_bServerPaused: boolean;
    m_bStaminaCooldownsEnabled: boolean;
    m_eGGTeam: number;
    m_eGameState: number;
    m_eMatchMode: number;
    m_fLevelStartTime: number;
    m_flGGEndsAtTime: number;
    m_flGameStartTime: number;
    m_flHeroDiedTime: number;
    m_flPauseTime: number;
    m_flPreGameWaitEndTime: number;
    m_flReportCardDismissalWaitStart: number;
    m_flRoundStartTime: number;
    m_iPauseTeam: number;
    m_nExperimentalGameplayState: number;
    m_nGameOverEvent: number;
    m_nLastPreGameCount: number;
    m_nPlayerDeathEventID: number;
    m_nReplayChangedEvent: number;
    m_pausingPlayerId: number;
    m_unMatchID: number;
    m_unpausingPlayerId: number;
  };
  game_phase: number;
};
