import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import globals from "@/util/global-whitelist.mjs";

export default async function getEmail() {
  const e = readState.user?.email;
  if (e) {
    const hash = await getSHA256Hash(e);
    return hash;
  }

  const hash = await blitzMessage(EVENTS.PLATFORM_ID);
  return hash;
}

const getSHA256Hash = async (input) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await globals.crypto.subtle.digest(
    "SHA-256",
    textAsBuffer,
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, "0"))
    .join("");
  return hash;
};
