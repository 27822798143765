import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, {
  EVENTS,
  handleMessage,
  initEvents,
} from "@/__main__/ipc-core.mjs";
import { setRoute } from "@/__main__/router.mjs";
import { setOverlayPositions, setVolatileKV } from "@/app/actions.mjs";
import { updateMatch } from "@/game-val/actions.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition.mjs";
import overlayRefs from "@/shared/OverlayContainerWithAd.refs.jsx";
import { devError } from "@/util/dev.mjs";
import { updateLatestPlayed } from "@/util/game-handlers.mjs";

let matchStartTimestamp = null;

function messageReceivedAfterGameStart() {
  return (
    typeof matchStartTimestamp === "number" &&
    matchStartTimestamp &&
    matchStartTimestamp > Date.now()
  );
}

/**
 * Global Val event handlers only for the overlay.
 */
async function handleMessages() {
  await initEvents;

  handleMessage(
    EVENTS.VALORANT_PREFETCH_DATA,
    ({ profileId, isPlaying, player }) => {
      if (!player) return;
      setVolatileKV("valCurrentPlayer", player);
      setRoute(`/valorant/overlay/benchmark`, undefined, {
        profileId,
        playerId: player.id,
      });
      if (!isPlaying) {
        blitzMessage(EVENTS.APP_OVERLAY_SETWINDOW_VISIBLE, true);
      }
    },
  );

  // For agent select

  handleMessage(
    EVENTS.VALORANT_ENTERING_PREGAME,
    ({ letterbox, fullscreen } = {}) => {
      setVolatileKV("valLetterbox", letterbox);
      setVolatileKV("valFullscreen", fullscreen);
      setRoute("/valorant/overlay/agent-select");
      blitzMessage(EVENTS.APP_OVERLAY_SETWINDOW_VISIBLE, true);
    },
  );

  // Code smell: since this handleMessage is immediately triggering
  // blitzMessage, it should be entirely contained in blitz-core??
  handleMessage(EVENTS.VALORANT_IN_PREGAME, () => {
    blitzMessage(EVENTS.APP_OVERLAY_SETWINDOW_VISIBLE, true);
  });

  handleMessage(EVENTS.VALORANT_EXIT_PREGAME, () => {
    blitzMessage(EVENTS.APP_OVERLAY_SETWINDOW_VISIBLE, true);
    matchStartTimestamp = null;
    const player = readState.volatile.valCurrentPlayer;
    if (player && player.name && player.tag && player.id) {
      const profileId = `${player.name}-${player.tag}`.toLowerCase();
      setRoute(`/valorant/overlay/benchmark`, undefined, {
        profileId,
        playerId: player.id,
      });
    }
  });

  // For Loading screen
  handleMessage(EVENTS.VALORANT_ENTERING_MATCH, () => {
    overlayRefs.destroyAd(EVENTS.VALORANT_ENTERING_MATCH);
    // Check the last emitted `matchStartTimestamp`. If this was emitted before it, it's likely this event was processed out of order
    if (messageReceivedAfterGameStart()) return;
    setRoute(`/valorant/overlay/loading-screen`);
    blitzMessage(EVENTS.APP_OVERLAY_SETWINDOW_VISIBLE, true);
  });

  // Once match starts
  handleMessage(EVENTS.VALORANT_MATCH_START, () => {
    matchStartTimestamp = Date.now();
    blitzMessage(EVENTS.APP_OVERLAY_SETWINDOW_VISIBLE, false);
    overlayRefs.destroyAd(EVENTS.VALORANT_MATCH_START);
    updateLatestPlayed(GAME_SYMBOL_VAL);
  });

  // For benchmark overlay
  handleMessage(
    EVENTS.VALORANT_MATCH_DETAILS,
    ({ match, profileId, player }) => {
      matchStartTimestamp = null;
      overlayRefs.destroyAd(EVENTS.VALORANT_MATCH_DETAILS);
      const actId = match.season;
      const matchId = match.id;
      const queue = match.queue;
      try {
        updateMatch(match, profileId);
      } catch (err) {
        devError("Unable to update match", err);
      }
      if (!player) return;
      setRoute(`/valorant/overlay/benchmark`, undefined, {
        actId,
        matchId,
        queue,
        profileId,
        playerId: player.id,
      });
      blitzMessage(EVENTS.APP_OVERLAY_SETWINDOW_VISIBLE, true);
    },
  );

  handleMessage(
    EVENTS.VALORANT_INITIATE_OVERLAY_POSITIONS,
    (overlayPositions) => {
      if (overlayPositions) setOverlayPositions(overlayPositions, "valorant");
    },
  );
}

export default handleMessages;
