import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";

import { PlayerImgCSS } from "@/game-fortnite/components/MatchDataTable.style.jsx";
import type { MatchCore } from "@/game-fortnite/models/game-events.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import DataTable from "@/shared/DataTable.jsx";

export default function MatchDataTableInGame({ match }: { match: MatchCore }) {
  const { t } = useTranslation();
  const row = useMemo<Array<Row>>(
    () =>
      match.localPlayer?.accountId &&
      match.localPlayer?.teammates &&
      match.matchLive?.players &&
      match.matchPlayers
        ? [...match.localPlayer.teammates, match.localPlayer.accountId]
            .map((accountId) => {
              const player =
                accountId === match.localPlayer.accountId
                  ? match.localPlayer
                  : match.matchPlayers[accountId];
              const stats =
                accountId === match.localPlayer.accountId // Current player has a different structure to access stats
                  ? {
                      killScore:
                        match.matchLive.killScore ||
                        match.matchLive.players[accountId].killScore,
                      downScore:
                        match.matchLive.downScore ||
                        match.matchLive.players[accountId].downScore,
                      health: Math.round(
                        match.matchLive.players[accountId].health,
                      ),
                      shield: Math.round(
                        match.matchLive.players[accountId].shield,
                      ),
                    }
                  : match.matchLive.players[accountId];
              if (!player || !stats) return undefined;
              return {
                accountId,
                anonymous: player.anonymous,
                bot: player.bot,
                name: player.anonymous
                  ? t("fortnite:match.anonymous", "Anonymous")
                  : player.name,
                kills: stats.killScore,
                knocks: stats.downScore,
                health: stats.health,
                shield: stats.shield,
                character: player.character,
              };
            })
            .filter((i) => i)
        : [],
    [match, t],
  );
  return (
    <DataTable
      indexCol
      indexColTitle="#"
      indexColHighlight
      sortCol={2}
      sortDir="DESC"
      sortColTiebreak={1}
      cols={[
        {
          display: t("fortnite:match.mySquad", "My squad"),
          isStat: false,
          align: "left",
        },
        {
          display: t("common:elims", "Elims"),
          isStat: true,
          align: "right",
        },
        {
          display: t("common:knocks", "Knocks"),
          isStat: true,
          align: "right",
        },
      ]}
      rows={row.map((i) => [
        {
          display: (
            <div className="flex align-center gap-2">
              <img
                src={Static.getProfileImage(i.accountId, i.character)}
                width="32"
                height="32"
                className={PlayerImgCSS()}
              />
              {i.bot ? <Bot>{t("csgo:bot", "Bot")}</Bot> : null}
              {i.name}
            </div>
          ),
          value: i.name,
          className:
            Boolean(
              i.accountId === match.localPlayer.accountId && !i.anonymous,
            ) && GoldTextCss(),
        },
        {
          display: i.kills || "-",
          value: i.kills,
        },
        {
          display: i.knocks || "-",
          value: i.knocks,
        },
      ])}
      noResultsTitle={[
        "fortnite:battleInsightsNotFound",
        "Your Battle Insights are gliding down to join you soon.",
      ]}
      noResultsDescription={[
        "fortnite:battleInsightsNotFoundDesc",
        "Please wait for your Battle Insights to load.",
      ]}
    />
  );
}

type Row = {
  accountId: string;
  anonymous: boolean;
  bot: boolean;
  name: string;
  kills: number;
  knocks: number;
  health: number;
  shield: number;
  character?: string;
};

const GoldTextCss = () => css`
  color: var(--rank-gold);
`;
const Bot = styled("div")`
  background-color: var(--shade2-50);
  padding: var(--sp-0_5) var(--sp-2);
  border-radius: var(--br);
  color: var(--shade1);
`;
