**June 15, 2022**
- Increased bomb explosion damage from 500 to 620.

**September 21, 2021**
- Made gate at top of banana solid
- Revised 1v1 layout
- Minor bug fixes

**November 22, 2019**
- Improved visibility from patio towards pit boost spot.
- Added fill lights in A site balcony hallway.
- Fixed a DM spawn in apartments that caused players to get stuck.

**April 25, 2019**
- Added nav blockers for Wingman mode
- Fixed a bug where fountain particle effects would sometimes not play
- Brightened up gate-area at top of banana

**August 1, 2017**
- Fixed some minor graphical bugs on Inferno.

**February 3, 2017**
- Optimized water materials used in Inferno, Nuke and Aztec maps.

**January 12, 2017**
- Fixed some minor graphical bugs
- Fixed a spot where players could get stuck near Boiler, Bombsite A
- Added backfaces to gate used at bottom of Banana, Bombsite B

**February 5, 2014**
- Fixed a bug where players could get stuck in ceilingfans.
- v*Fixed some areas where thrown C4 could get stuck.
- Increased brightness slightly in hallway leading to balcony in Bombsite A.
- Fixed gaps that players could see through in Bombsite B.
- Made wall penetration in construction more consistent (Thanks Pawlesslol!).
- Nerfed the refire rate on the churchbells (changed from infinite dings per second to 1 ding per second).
- General optimizations.
