{
  "show": true,
  "toggleKey": {
    "value": "TAB",
    "code": 9,
    "modifiers": {
      "ctrlKey": true,
      "altKey": false,
      "shiftKey": false
    }
  },
  "matchInProgress": true,
  "matchInfo": {
    "minimized": false,
    "profileId": null,
    "matchId": null,
    "actId": null
  },
  "isOverlayEnabled": true,
  "agentSelect": {
    "agentId": "117ed9e3-49f3-6512-3ccf-0cada7e3823b"
  },
  "topAgentsCollapsed": false,
  "currentMatch": {
    "playerId": "f47c643b-9343-5cf9-85ba-2a087eb88fb7",
    "gameName": "sdrsdf",
    "tagLine": "1773",
    "queue": "spikerush",
    "players": [
      {
        "teamId": "Blue",
        "agentId": "7f94d92c-4234-0a36-9646-3a87eb8b5c89",
        "isMe": false,
        "incognito": false,
        "isPartyMember": false,
        "internalUuid": "2d439771-e127-5312-8375-5b19caf879e4",
        "gameName": "slayer",
        "tagLine": "6067"
      },
      {
        "teamId": "Blue",
        "agentId": "8e253930-4c05-31dd-1b6c-968525494517",
        "isMe": false,
        "incognito": false,
        "isPartyMember": false,
        "internalUuid": "c39f552a-3e5b-5dec-a616-4861c3f33983",
        "gameName": "chupapi",
        "tagLine": "DBz"
      },
      {
        "teamId": "Blue",
        "agentId": "f94c3b30-42be-e959-889c-5aa313dba261",
        "isMe": false,
        "incognito": false,
        "isPartyMember": false,
        "internalUuid": "d6d9cafe-798f-59b9-9e02-1f4d567401af",
        "gameName": "OGinfernodragon",
        "tagLine": "INDRA"
      },
      {
        "teamId": "Blue",
        "agentId": "1e58de9c-4950-5125-93e9-a0aee9f98746",
        "isMe": true,
        "incognito": true,
        "isPartyMember": true,
        "internalUuid": "f47c643b-9343-5cf9-85ba-2a087eb88fb7",
        "gameName": "sdrsdf",
        "tagLine": "1773"
      },
      {
        "teamId": "Blue",
        "agentId": "a3bfb853-43b2-7238-a4f1-ad90e9e46bcc",
        "isMe": false,
        "incognito": false,
        "isPartyMember": false,
        "internalUuid": "98339d23-2d24-521d-b786-0f83882d991d",
        "gameName": "SupriseMfs",
        "tagLine": "Gamer"
      },
      {
        "teamId": "Red",
        "agentId": "9f0d8ba9-4140-b941-57d3-a7ad57c6b417",
        "isMe": false,
        "incognito": false,
        "isPartyMember": false,
        "internalUuid": "ba070b09-18a8-5020-80d8-4828a6726eca",
        "gameName": "Venom2OP",
        "tagLine": "4455"
      },
      {
        "teamId": "Red",
        "agentId": "add6443a-41bd-e414-f6ad-e58d267f4e95",
        "isMe": false,
        "incognito": false,
        "isPartyMember": false,
        "internalUuid": "2d7a3f50-f6d0-5a94-9ac9-6fc2a634e8b7",
        "gameName": "abhii",
        "tagLine": "0690"
      },
      {
        "teamId": "Red",
        "agentId": "bb2a4828-46eb-8cd1-e765-15848195d751",
        "isMe": false,
        "incognito": false,
        "isPartyMember": false,
        "internalUuid": "19faf560-e295-59da-b587-0a54142abe46",
        "gameName": "iUMZ4",
        "tagLine": "0069"
      },
      {
        "teamId": "Red",
        "agentId": "0e38b510-41a8-5780-5e8f-568b2a4f2d6c",
        "isMe": false,
        "incognito": true,
        "isPartyMember": false,
        "internalUuid": "9b0c86b3-8cb1-5c81-8f2c-083138b629ff",
        "gameName": "ReaLxNippo",
        "tagLine": "6929"
      },
      {
        "teamId": "Red",
        "agentId": "a3bfb853-43b2-7238-a4f1-ad90e9e46bcc",
        "isMe": false,
        "incognito": false,
        "isPartyMember": false,
        "internalUuid": "6c87da16-f390-5470-952e-4cdecf0897d8",
        "gameName": "pratikgamer99",
        "tagLine": "mfmf"
      }
    ]
  },
  "playersData": {
    "9af01b54-55b8-57ae-9c8e-0063e0f924bd": {
      "rank": 6,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 5,
      "matches": 5,
      "headshots": 46,
      "bodyshots": 216,
      "legshots": 51,
      "kills": 84,
      "deaths": 58,
      "assists": 26,
      "damage": 18337,
      "score": 23170,
      "roundsPlayed": 100,
      "matchHistory": [
        {
          "outcome": "win",
          "id": "fa6e797b-c8af-45a7-bc3b-21c71b6b3545"
        },
        {
          "outcome": "win",
          "id": "8373431e-26fa-4d46-b894-f11ddac4cc21"
        },
        {
          "outcome": "win",
          "id": "23b9f425-7b0d-46a8-86ac-ab2d150538a6"
        },
        {
          "outcome": "win",
          "id": "655cda1a-cb04-4abe-a2b1-9144395f6c13"
        },
        {
          "outcome": "win",
          "id": "257d6c7a-890b-4ec7-8520-8acb67db47da"
        }
      ]
    },
    "f47c643b-9343-5cf9-85ba-2a087eb88fb7": {
      "rank": 4,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 0,
      "matches": 0,
      "headshots": 0,
      "bodyshots": 0,
      "legshots": 0,
      "kills": 0,
      "deaths": 0,
      "assists": 0,
      "damage": 0,
      "score": 0,
      "roundsPlayed": 0,
      "matchHistory": []
    },
    "9fb180a1-f363-50a0-b4d4-952eb8071f93": {
      "rank": 8,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 3,
      "matches": 5,
      "headshots": 39,
      "bodyshots": 185,
      "legshots": 40,
      "kills": 63,
      "deaths": 80,
      "assists": 20,
      "damage": 12838,
      "score": 18473,
      "roundsPlayed": 96,
      "matchHistory": [
        {
          "outcome": "loss",
          "id": "9d1fdce4-ef43-4ba6-9595-6d3bef65c1ef"
        },
        {
          "outcome": "win",
          "id": "192f725b-633d-4732-87ec-393faed80877"
        },
        {
          "outcome": "win",
          "id": "8393ba3b-61d1-4d6c-9c59-08ff45b0fa44"
        },
        {
          "outcome": "loss",
          "id": "be286956-ae3f-400b-98ce-925f0f00eecf"
        },
        {
          "outcome": "win",
          "id": "a05baf23-aebe-4ba5-b7a0-a639e4075f54"
        }
      ]
    },
    "689349f5-3625-564e-8033-d43958b46fb0": {
      "rank": 5,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 3,
      "matches": 5,
      "headshots": 44,
      "bodyshots": 273,
      "legshots": 51,
      "kills": 82,
      "deaths": 75,
      "assists": 19,
      "damage": 15358,
      "score": 23835,
      "roundsPlayed": 104,
      "matchHistory": [
        {
          "outcome": "win",
          "id": "a05f54b2-7f2f-4d86-a3d0-b9072155a36c"
        },
        {
          "outcome": "loss",
          "id": "ade5c283-20b0-4b4f-be04-c2169dd641d1"
        },
        {
          "outcome": "win",
          "id": "95674a60-e8a7-40b8-901e-3820491ce887"
        },
        {
          "outcome": "win",
          "id": "959ccbb2-b026-48a1-80f4-a676882a1ba4"
        },
        {
          "outcome": "loss",
          "id": "fb831df1-62d9-4d0a-9010-f5f8b099be0e"
        }
      ]
    },
    "217f80d3-4adb-5d0e-af5c-6d0fe6b508cc": {
      "rank": 5,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 2,
      "matches": 5,
      "headshots": 36,
      "bodyshots": 270,
      "legshots": 36,
      "kills": 82,
      "deaths": 84,
      "assists": 29,
      "damage": 16482,
      "score": 23871,
      "roundsPlayed": 110,
      "matchHistory": [
        {
          "outcome": "loss",
          "id": "78a48500-2753-49c2-9e51-3410ab7fd3e1"
        },
        {
          "outcome": "win",
          "id": "a61ee84a-1036-4bb6-abaf-ed8b87def444"
        },
        {
          "outcome": "loss",
          "id": "8612961d-0674-42dc-9f6e-c1a38249527f"
        },
        {
          "outcome": "loss",
          "id": "db62fd7b-1f41-4c58-8b61-f27dd9a556ef"
        },
        {
          "outcome": "win",
          "id": "a712b091-d88b-442f-ac9b-0f640af6285b"
        }
      ]
    },
    "051ba0ef-cd79-5074-a489-d38ce6039bc6": {
      "rank": 5,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 2,
      "matches": 5,
      "headshots": 30,
      "bodyshots": 174,
      "legshots": 18,
      "kills": 64,
      "deaths": 69,
      "assists": 15,
      "damage": 15221,
      "score": 18297,
      "roundsPlayed": 111,
      "matchHistory": [
        {
          "outcome": "win",
          "id": "a247f334-ec1d-423d-92ad-78fa5aef1b0f"
        },
        {
          "outcome": "loss",
          "id": "6c7195c3-1f64-48ee-bc5d-bd82c7edf6dc"
        },
        {
          "outcome": "tie",
          "id": "58e65038-b0b7-4abb-9b45-414032cd73da"
        },
        {
          "outcome": "loss",
          "id": "1c56b478-5131-4d30-b194-b21fea697917"
        },
        {
          "outcome": "win",
          "id": "d07c7a53-73ba-49fe-84db-9c9006956dfa"
        }
      ]
    },
    "840f2f38-f70b-5518-9f00-02e4d351b130": {
      "rank": 27,
      "rankActId": "2de5423b-4aad-02ad-8d9b-c0a931958861",
      "wins": 3,
      "matches": 5,
      "headshots": 33,
      "bodyshots": 185,
      "legshots": 51,
      "kills": 55,
      "deaths": 65,
      "assists": 29,
      "damage": 12139,
      "score": 16342,
      "roundsPlayed": 100,
      "matchHistory": [
        {
          "outcome": "win",
          "id": "7aec5c7d-ed67-43c1-a9fc-3834ea3635f4"
        },
        {
          "outcome": "win",
          "id": "a6d0a0c1-2a01-4dc6-b347-bd7b3fc00ef4"
        },
        {
          "outcome": "win",
          "id": "f901142f-9a39-43ee-a1b0-05c2919046be"
        },
        {
          "outcome": "loss",
          "id": "a58065a0-e856-4554-afb6-c6576ad67f46"
        },
        {
          "outcome": "loss",
          "id": "706fb8fe-2809-4446-891d-2dc3efdf28a4"
        }
      ]
    },
    "80cc7c11-b97e-5061-9414-4d24f5c82c27": {
      "rank": 4,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 4,
      "matches": 5,
      "headshots": 34,
      "bodyshots": 240,
      "legshots": 82,
      "kills": 75,
      "deaths": 83,
      "assists": 41,
      "damage": 14297,
      "score": 22101,
      "roundsPlayed": 111,
      "matchHistory": [
        {
          "outcome": "win",
          "id": "125f536d-0283-4a54-92dc-0210bdea38dd"
        },
        {
          "outcome": "win",
          "id": "994519d9-ae1b-494b-939e-a28518c1627e"
        },
        {
          "outcome": "loss",
          "id": "8aae9e84-2b90-426a-a876-2aa96aebf776"
        },
        {
          "outcome": "win",
          "id": "913fbe1a-f11a-46c2-a5b0-5365f4bb1e91"
        },
        {
          "outcome": "win",
          "id": "02868c21-a87b-4c42-a1e8-970c919fa649"
        }
      ]
    },
    "023365ab-049f-5c0a-8e4c-f9cc06bb9354": {
      "rank": 0,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 0,
      "matches": 0,
      "headshots": 0,
      "bodyshots": 0,
      "legshots": 0,
      "kills": 0,
      "deaths": 0,
      "assists": 0,
      "damage": 0,
      "score": 0,
      "roundsPlayed": 0,
      "matchHistory": []
    },
    "03026f00-7a90-561a-b3dc-f76606306d14": {
      "rank": 0,
      "rankActId": "0981a882-4e7d-371a-70c4-c3b4f46c504a",
      "wins": 0,
      "matches": 0,
      "headshots": 0,
      "bodyshots": 0,
      "legshots": 0,
      "kills": 0,
      "deaths": 0,
      "assists": 0,
      "damage": 0,
      "score": 0,
      "roundsPlayed": 0,
      "matchHistory": []
    }
  },
  "overlayPositions": {
    "valMinimized": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valMinimizedAd": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valAgentSelectP1": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valAgentSelectP2": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valAgentSelectP3": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valAgentSelectP4": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valAgentSelectP5": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valAgentSelectInfo": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valAgentSelectAd": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valAgentSelectTopAgents": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP1": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP2": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP3": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP4": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP5": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP6": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP7": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP8": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP9": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchP10": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchInfo": {
      "x": 0,
      "y": 0,
      "isDragged": false
    },
    "valLoadingMatchAd": {
      "x": 1145,
      "y": 732,
      "isDragged": true
    },
    "valLoadingMatchWinPrediction": {
      "x": 0,
      "y": 0,
      "isDragged": false
    }
  },
  "syncedAt": "2022-07-14T12:29:49.946Z"
}
