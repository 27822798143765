import React from 'react';


```js
import ProfileRank from "@/shared/ProfileRank.jsx";
import { ProfileColumn } from "@/shared/Profile.style.jsx";
```

Most (if not all) player profiles for a game represent a singlar player. Many of the titles we support (like League) have ranks for the more competitive modes. This is represented by a `<ProfileRank />`. The ProfileRank displays some data about that particular game mode with icons/images and numbers for the rank. In the case where a game has multiple ranked modes, typically there is a "preferred/primary" mode. This is the one that we represent with `<ProfileRank />` and the others are represented with `<ProfileRank.Accordion />` below the primary rank

## Example Profile Rank (League of Legends)
```jsx preview
<ProfileColumn>
  <ProfileRank
    title="Ranked Solo"
    image="https://blitz-cdn.blitz.gg/80x0/ranks/2023/emerald.webp"
    name="Emerald 4"
    points="38 LP"
    percentFill={0.38}
    color="#56d744"
    wins={33}
    losses={29}
  />
  <ProfileRank.Accordion
    ranksList={[
      {
        label: "Ranked Flex",
        wins: 2,
        losses: 0,
        percentFill: 1,
        points: 87,
        rankName: "Gold 3",
        summaryColor: "#f1a64e",
        barColor: "var(--perf-pos3)",
        queueIcon: <LolFlexQueue />
      },
      {
        label: "Arena",
        wins: 3,
        losses: 5,
        percentFill: 0.75,
        points: 1596,
        rankName: "Bronze 1596",
        summaryColor: "#b97452",
        barColor: "var(--perf-neg2)",
        queueIcon: <LolRGM />
      }
    ]}
  />
</ProfileColumn>
```