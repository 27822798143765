**November 18, 2022**
- Reduced the M4A1-S range modifier from 0.99 to 0.94.

**June 15, 2022**
- Reduced the M4A1-S magazine size to 20 bullets, with 80 in reserve.

**September 21, 2021**
- Increased body damage

**April 10, 2020**
- M4A1-S – Price reduced to $2900.

**March 13, 2019**
- The M4A1-S now holds 25 bullets in the magazine, and has 75 in reserve.

**June 12, 2018**
- Increased reserve ammo for the M4A1-S from 40 to 60, to provide some additional utility without impacting brief engagements.

**September 28, 2016**
- Increased fidelity and reduced distortion in fire sounds for Famas, Galil, Aug, SG553, M4A4, M4A1-S, unsilenced M4A1-S, and AK47.

**August 3, 2016**
- New accuracy recovery method and new recovery rates for the M4A1-S, M4A4, and AK-47. See details HERE.

**September 15, 2015**
- Reduced price
- Reduced armor penetration*
- Reduced ROF
- Increased base spread
- N.B. "Reduced armor penetration" did not occur.

**March 31, 2015**
- Increased price of the M4A1-Silencer from 3100 to 3200 to align the weapon’s price with its utility.

**December 10, 2014**
- Increased price of M4A1-S to $3100
