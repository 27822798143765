import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, {
  Mapped,
  Optional,
  Silent,
  Union,
} from "@/__main__/data-model.mjs";
import {
  FORTNITE_PLAYLISTS,
  KNOWN_PLAYLISTS_INTERNAL,
} from "@/game-fortnite/constants/playlists.mjs";
import { getBEModeFromGameMode } from "@/game-fortnite/constants/queues.mjs";
import type { MatchMutationT } from "@/game-fortnite/models/match-mutation.mjs";
import { MatchMutation } from "@/game-fortnite/models/match-mutation.mjs";
import { ItemRarityEnum } from "@/game-fortnite/types/ItemRarity.mjs";

export const item = {
  name: String,
  name_localized: String,
  rarity: Number,
  quantity: Number,
} satisfies ModelDefinition;

export const liveItem = {
  ...item,
  slot: Number,
} satisfies ModelDefinition;

export const afterTransformItem = {
  ...item,
  rarity: Union(Object.values(ItemRarityEnum)),
} satisfies ModelDefinition;

export const itemValidator = createModel(item);

export type Item = FromModel<typeof item>;

const battlesResultShape = {
  kills: Number,
  placement: Number,
} satisfies ModelDefinition;

export type BattlesResult = FromModel<typeof battlesResultShape>;
const weapon = {
  critical_damage_done_to_players: Number,
  damage_done_to_players: Number,
  damage_done_to_structures: Number,
  headshots: Number,
  healing_amount: Number,
  hits_to_structures: Number,
  hits: Number,
  kills: Number,
  total_shots: Number,
} satisfies ModelDefinition;

const afterTransformWeapon = {
  ...weapon,
  id: String,
} satisfies ModelDefinition;

export const battleResult = {
  kills: Number,
  placement: Number,
} satisfies ModelDefinition;

const damageHistory = [
  {
    circle_phase: Number,
    // damage_dealt is only present for damage events
    damage_dealt: Optional(Number),
    damage_event_type: Union(["kill", "damage", "knockdown"]),
    dest: {
      name: String,
      pos: {
        x: Number,
        y: Number,
        z: Number,
      },
      weapon: String,
    },
    src: {
      name: String,
      pos: {
        x: Number,
        y: Number,
        z: Number,
      },
      weapon: String,
    },
    distance: Number,
    inventory: [String],
    is_local_player_src: Boolean,
    time: Number,
  },
] satisfies ModelDefinition;

export const localPlayer = {
  accountId: String,
  accuracy: Number,
  aliveTime: Number,
  anonymous: Boolean,
  assists: Number,
  battlesResults: Silent([Optional(battlesResultShape)]),
  bot: Boolean,
  damageDealt: Number,
  damageTaken: Number,
  damageToStructures: Number,
  distanceTraveled: Number,
  downs: Number,
  headshotsPercent: Number,
  inventory: [item],
  kills: Number,
  materials: {
    metal: { used: Number, gathered: Number },
    stone: { used: Number, gathered: Number },
    wood: { used: Number, gathered: Number },
  },
  materialsGathered: Number,
  materialsUsed: Number,
  name: String,
  placement: Number,
  revives: Number,
  team: Number,
  teammates: [String],
  totalShots: Number,
  totalHits: Number,
  totalHeadshots: Number,
  weapons: Mapped({
    key: String,
    value: weapon,
  }),
  party_owner_id: String,
  character: Optional(String),
  damageHistory,
} satisfies ModelDefinition;

export const afterTransformLocalPlayer = {
  ...localPlayer,
  inventory: [afterTransformItem],
  weapons: [afterTransformWeapon],
} satisfies ModelDefinition;

export const localPlayerValidator = createModel(localPlayer);

export type LocalPlayer = FromModel<typeof localPlayer>;

export const matchPlayer = {
  aliveTime: Number,
  anonymous: Boolean,
  bot: Boolean,
  downs: Number,
  kills: Number,
  name: String,
  placement: Number,
  team: Number,
  teammates: [String],
  party_owner_id: String,
  character: Optional(String),
} satisfies ModelDefinition;

export const afterTransformMatchPlayer = {
  ...matchPlayer,
  accountId: String,
} satisfies ModelDefinition;

export const matchPlayerValidator = createModel(matchPlayer);

export type MatchPlayer = FromModel<typeof matchPlayer>;

export const kill = {
  killerId: String,
  killedId: String,
} satisfies ModelDefinition;

export const MatchEnd = {
  gameId: String,
  islandCode: Optional(String),
  isRanked: Boolean,
  gameMode: String,
  kills: [kill],
  localPlayer: localPlayer,
  matchPlayers: Mapped({
    key: String,
    value: matchPlayer,
  }),
  startedAt: Date,
  gameVersion: Optional(String),
} satisfies ModelDefinition;

const MatchInit = {
  matchId: String,
  mode: Union(KNOWN_PLAYLISTS_INTERNAL),
  isRanked: Boolean,
  islandCode: String,
  startTime: Number,
  localPlayer: {
    accountId: String,
    name: String,
  },
} satisfies ModelDefinition;

const MatchStart = {
  gameId: String,
  islandCode: String,
  isRanked: Boolean,
  gameMode: Union(KNOWN_PLAYLISTS_INTERNAL),
  localPlayer: {
    accountId: String,
    anonymous: Boolean,
    bot: Boolean,
    name: String,
    team: Number,
    teammates: [String],
    party_owner_id: String,
    character: Optional(String),
  },
  matchPlayers: Mapped({
    key: String,
    value: {
      anonymous: Boolean,
      bot: Boolean,
      name: String,
      team: Number,
      teammates: [String],
      character: Optional(String),
    },
  }),
  startedAt: Number,
} satisfies ModelDefinition;

const MatchLive = {
  assists: Number,
  avatar: String,
  damage_dealt: Number,
  damage_taken: Number,
  down_score: Number,
  kill_score: Number,
  match_id: String,
  match_mode: Union(KNOWN_PLAYLISTS_INTERNAL),
  player_inventory: [liveItem],
  materials_gathered: Number,
  materials_used: Number,
  wood_current: Number,
  stone_current: Number,
  metal_current: Number,
  player_color: Number,
  players: Mapped({
    key: String,
    value: {
      alive: Boolean,
      down_score: Number,
      health: Number,
      kill_score: Number,
      shield: Number,
      survive_time: Number,
      character: Optional(String),
    },
  }),
  players_left: Number,
  team_kill_score: Number,
  total_bodyshots: Number,
  total_headshots: Number,
  total_shots: Number,
} satisfies ModelDefinition;

const MatchLiveAfter = {
  assists: Number,
  downScore: Number,
  killScore: Number,
  inventory: [liveItem],
  materialsGathered: Number,
  materialsUsed: Number,
  woodCurrent: Number,
  stoneCurrent: Number,
  metalCurrent: Number,
  playerColor: Number,
  players: Mapped({
    key: String,
    value: {
      alive: Boolean,
      downScore: Number,
      health: Number,
      killScore: Number,
      shield: Number,
      surviveTime: Number,
      character: Optional(String),
    },
  }),
  playersLeft: Number,
  teamKillScore: Number,
} satisfies ModelDefinition;

const MatchKill = {
  killerId: String,
  killedId: String,
} satisfies ModelDefinition;

const Player = {
  accountId: String,
  name: String,
  updatedAt: Date,
} satisfies ModelDefinition;

const Lobby = {
  avatar: String,
  leader: String,
  local_id: String,
  local_name: String,
  users: Mapped({
    key: String,
    value: String,
  }),
} satisfies ModelDefinition;

// Validators
export const MatchInitCoreValidator = createModel(MatchInit);
export const MatchStartCoreValidator = createModel(MatchStart);
export const MatchEndCoreValidator = createModel(MatchEnd);
export const MatchKillCoreValidator = createModel(MatchKill);
export const PlayerCoreValidator = createModel(Player);
export const LobbyCoreValidator = createModel(Lobby);
export const MatchLiveCoreValidator = createModel(MatchLive);

// Types
export type MatchInitCore = FromModel<typeof MatchInitCoreValidator>;
export type MatchStartCore = FromModel<typeof MatchStartCoreValidator>;
export type MatchLiveCore = FromModel<typeof MatchLiveCoreValidator>;
export type MatchEndCore = FromModel<typeof MatchEndCoreValidator>;
export type MatchKillCore = FromModel<typeof MatchKillCoreValidator>;
export type PlayerCore = FromModel<typeof PlayerCoreValidator>;
export type LobbyCore = FromModel<typeof LobbyCoreValidator>;
export type MatchLive = FromModel<typeof matchLiveTransformFn>;
export type MatchCore = Partial<
  MatchInitCore &
    MatchStartCore & { matchLive: MatchLive } & { kills: Array<MatchKillCore> }
>;

// Transformers
export const matchEndToMatchCreate = MatchEndCoreValidator.transform(
  MatchMutation,
  (matchEnd: MatchEndCore): MatchMutationT => {
    const { party_owner_id, weapons, inventory, ...localPlayer } =
      matchEnd.localPlayer;
    delete localPlayer.damageHistory;
    const playlist = Object.getOwnPropertySymbols(FORTNITE_PLAYLISTS).find(
      (s) => FORTNITE_PLAYLISTS[s]?.internal?.includes(matchEnd.gameMode),
    );
    return {
      ...matchEnd,
      playlist: matchEnd.gameMode,
      gameMode: getBEModeFromGameMode(matchEnd.gameMode), // Default to whatever value is sent by RE
      islandCode: matchEnd.islandCode ?? "", // RE is Optional but BE is required
      isZeroBuild:
        FORTNITE_PLAYLISTS[playlist]?.isZeroBuild ??
        /NoBuild/i.test(matchEnd.gameMode),
      // The localPlayer is derived from matchPlayers
      localPlayer: Object.assign(localPlayer, {
        partyOwnerId: party_owner_id, // BE <-> RE
        weapons: Object.entries(weapons).map(([id, weapon]) => ({
          criticalDamageDoneToPlayers: weapon.critical_damage_done_to_players,
          damageDoneToPlayers: weapon.damage_done_to_players,
          damageDoneToStructures: weapon.damage_done_to_structures,
          headshots: weapon.headshots,
          healingAmount: weapon.healing_amount,
          hits: weapon.hits,
          hitsToStructures: weapon.hits_to_structures,
          id,
          kills: weapon.kills,
          totalShots: weapon.total_shots,
        })),
        inventory: inventory.map((i) => ({
          key: i.name,
          name: i.name_localized?.trim() ?? i.name,
          quantity: i.quantity,
          rarity: ItemRarityEnum[i.rarity] ?? ItemRarityEnum[1], // Default to UNCOMMON
        })),
      }),
      matchPlayers: Object.entries(matchEnd.matchPlayers)
        .filter(([_accountId, matchPlayer]) => !matchPlayer.bot)
        .map(([accountId, { party_owner_id, ...rest }]) => ({
          ...rest,
          partyOwnerId: party_owner_id, // BE <-> RE
          battlesResults: [], // RE doesn't send battle results for other players
          accountId,
        })),
    };
  },
);
export const matchLiveTransformFn = MatchLiveCoreValidator.transform(
  MatchLiveAfter,
  (matchLive: MatchLiveCore) => ({
    assists: matchLive.assists,
    downScore: matchLive.down_score,
    killScore: matchLive.kill_score,
    inventory: matchLive.player_inventory,
    materialsGathered: matchLive.materials_gathered,
    materialsUsed: matchLive.materials_used,
    woodCurrent: matchLive.wood_current,
    stoneCurrent: matchLive.stone_current,
    metalCurrent: matchLive.metal_current,
    playerColor: matchLive.player_color,
    players: Object.fromEntries(
      Object.entries(matchLive.players).map(([accountId, player]) => [
        accountId,
        {
          alive: player.alive,
          downScore: player.down_score,
          health: player.health,
          killScore: player.kill_score,
          shield: player.shield,
          surviveTime: player.survive_time,
          character: player.character,
        },
      ]),
    ),
    playersLeft: matchLive.players_left,
    teamKillScore: matchLive.team_kill_score,
  }),
);
