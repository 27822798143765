**September 26, 2017**
- Reduced price by $100 for both the Revolver (now $600) and Dual Elites (now $400)

**April 12, 2017**
- For a limited time, the Negev and R8 Revolver are available in Competitive Matchmaking.
- R8 Revolver: Price reduced to 700

**March 24, 2017**
- The R8 Revolver and Negev have been removed from Competitive Matchmaking as they undergo substantial revision.
- Firing delay significantly reduced

**December 15, 2015**
- Other players can now hear the sound of the R8 Revolver primary fire hammer just before it fires.

**December 10, 2015**
- Today we’re updating the R8 Revolver to bring its values to be more in line with other weapons: among other changes, its damage has been reduced and it takes slightly longer to fire.
- Base Damage Reduced from 115 to 85
- Spread increased from 0.48 to 0.52
- Primary fire firing delay increased from 0.333 seconds to 0.4 seconds per shot

**December 9, 2015**
- Fixed exploits with the R8 Revolver, including being able to fire during freezetime or defusal, and the ability to hold the primary hammer back indefinitely.

