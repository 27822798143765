import { cssButton, cssButtonGrp } from "clutch/src/Button/Button.jsx";

import { benchCSS } from "@/game-lol/components/in-game-template-aram.style.mjs";
import html from "@/util/html-template.mjs";

const css = {
  container: benchCSS(),
  button: cssButton(),
  buttonGroup: cssButtonGrp(),
};

const template = html`
  <div class="${css.container} aram-bench">
    <div>
      <p class="caption type-caption--bold">{{titleTeammates}}</p>
      <div>
        <ol class="bench-list">
          {{#teammates}}
          <li
            onclick="{{onClick}}"
            style:--wr-color="{{winrateColor}}"
            data-is-available="{{isAvailable}}"
            data-is-playable="{{isPlayable}}"
            data-has-winrate="{{hasWinrate}}"
            data-high-winrate="{{highWinrate}}"
            data-is-user="{{isUser}}"
            data-tip="{{tooltip}}"
            data-place="bottom"
          >
            <div class="bench-item" onclick="{{onClick}}">
              <img src="{{image}}" class="champ-img" width="44" height="44" />
            </div>
            <span class="winrate type-subtitle--bold">{{winrate}}</span>
          </li>
          {{/teammates}}
        </ol>
      </div>
    </div>
    <div>
      <p class="caption type-caption--bold">{{titleBench}}</p>
      <div class="flex gap-sp-2">
        <ol class="bench-list">
          {{#bench}}
          <li
            onclick="{{onClick}}"
            style:--wr-color="{{winrateColor}}"
            style:--timer="{{timer}}"
            data-has-winrate="{{hasWinrate}}"
            data-is-playable="{{isPlayable}}"
            data-high-winrate="{{highWinrate}}"
            data-timer="{{timer}}"
          >
            <div class="bench-item">
              <img src="{{image}}" class="champ-img" width="44" height="44" />
            </div>
            <span class="winrate type-subtitle--bold">{{winrate}}</span>
          </li>
          {{/bench}}
        </ol>
        <div>
          <div class="reroll-buttons">
            {{#rerollAndKeep}}
            <button
              class="${css.button} reroll-button reroll-and-keep-button"
              data-emphasis="high"
              data-has-rerolls="{{hasRerolls}}"
              data-tip="{{tooltip}}"
              data-place="{{tooltipPlacement}}"
              onclick="{{onClick}}"
            >
              <span>{{{icon}}}</span>
              <span class="type-form--button">{{{text}}}</span>
            </button>
            {{/rerollAndKeep}} {{#rerollButton}}
            <button
              class="${css.button} reroll-button"
              data-emphasis="high"
              data-has-rerolls="{{hasRerolls}}"
              data-place="{{tooltipPlacement}}"
              onclick="{{onClick}}"
            >
              <span class="type-form--button">{{text}}</span>
              <span class="reroll-count type-caption--bold">
                {{rerollsRemaining}}
              </span>
            </button>
            {{/rerollButton}}
          </div>
          <div class="type-caption--bold" style="opacity: 0;">-</div>
        </div>
      </div>
    </div>
  </div>
`;

export default template;
