import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import LeagueAccountModel from "@/data-models/league-account.mjs";
import CoachingModel from "@/data-models/tft-coaching.mjs";
import TFTMatchRoundModel from "@/data-models/tft-match-round.mjs";
import { isPBE } from "@/game-lol/utils/util.mjs";
import * as API from "@/game-tft/api.mjs";
import { DataTftQueues, setPatches } from "@/game-tft/constants.mjs";
import { fixMatchId } from "@/game-tft/utils.mjs";
import { devError } from "@/util/dev.mjs";

export default async function fetchCoachingData(params) {
  const [region, fullName, matchId] = params;
  const correctMatchId = fixMatchId(region, matchId);
  const patch = readState.tft.latestPatch?.patch;
  const options = {
    shouldFetchIfPathExists: !readState?.isUpdate,
    skipSafetyCheck: true,
  };

  const [gameName, tagLine] = fullName.split("-");

  if (!gameName || !tagLine || isPBE(region)) return;

  // player account data
  await getData(
    API.getPlayer(region, gameName, tagLine),
    LeagueAccountModel,
    ["tft", "accounts_v2", fullName],
    options,
  );

  // round data
  await getData(
    API.getMatchRoundData(correctMatchId),
    TFTMatchRoundModel,
    ["tft", "matches_round", correctMatchId],
    options,
  ).catch(() => {
    // it's okay if this fails since some matches don't have round data
    // console.log(e);
  });

  const roundData = readState.tft.matches_round?.[correctMatchId]?.round_data;
  const postmatchData = readState.tft.matches_v2[correctMatchId];

  const queueId =
    postmatchData?.info?.queueId || readState.settings?.lol?.lastQueueId;
  let mode =
    DataTftQueues.find(
      (q) => Number.parseInt(q.queueId) === Number.parseInt(queueId),
    )?.key || "RANKED";
  if (mode === "NORMAL") {
    mode = "RANKED";
  }

  if (roundData || postmatchData) {
    const queries = [
      { queryName: "unit_item_scores", cacheKeySuffix: "unitItemScores" },
      // { queryName: "augment_scores", cacheKeySuffix: "augmentScores" },
      // { queryName: "augment_duo_scores", cacheKeySuffix: "augmentDuoScores" },
      // {
      //   queryName: "augment_unit_scores",
      //   cacheKeySuffix: "augmentUnitScores",
      // },
      { queryName: "carry_unit_scores", cacheKeySuffix: "carryUnitScores" },
      {
        queryName: "synergy_unit_scores",
        cacheKeySuffix: "synergyUnitScores",
      },
      {
        queryName: "postmatch_score_stats",
        cacheKeySuffix: "postmatchScoreStats",
      },
    ];

    await Promise.all(
      queries.map(({ queryName, cacheKeySuffix }) =>
        fetchDataAndHandleError({ queryName, patch, mode }, [
          "tft",
          "coaching",
          `${cacheKeySuffix}_${patch}_${mode}`,
        ]),
      ),
    );
  }
}

async function fetchDataAndHandleError({ queryName, patch, mode }, cacheKey) {
  const fetchLastPatchData = async () => {
    const patches = Object.keys(setPatches);
    const prevPatch = patches[patches.indexOf(patch) - 1];
    if (setPatches[patch] === setPatches[prevPatch])
      await getData(
        API.getPostmatchCoachingScores({ queryName, patch: prevPatch, mode }),
        CoachingModel,
        cacheKey,
        { skipSafetyCheck: true },
      ).catch(devError);
  };

  await getData(
    API.getPostmatchCoachingScores({ queryName, patch, mode }),
    CoachingModel,
    cacheKey,
    { skipSafetyCheck: true },
  )
    .then((data) => {
      if (!data.data.executeDatabricksQuery.payload.result?.dataArray?.length) {
        fetchLastPatchData();
      }
    })
    .catch(() => {
      fetchLastPatchData();
    });
}
