import React, { useEffect, useRef } from "react";
import { css, styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import { appURLs } from "@/app/constants.mjs";
import {
  setupAgentSelectListeners,
  teardownAgentSelectListeners,
} from "@/game-val/actions.mjs";
import BestAgents from "@/game-val/BestAgents.jsx";
import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import AgentSelectFixture from "@/game-val/fixtures/valorant-agent-select-fixture.json";
import { valAgentSelectRefs } from "@/game-val/refs.mjs";
import { getNameTag } from "@/game-val/utils.mjs";
import GameDraggableUnit from "@/shared/GameDraggableUnit.jsx";
import { appLog } from "@/util/dev.mjs";
import registerOverlays from "@/util/register-overlays.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const cssAdInitialPosition = () => css`
  position: absolute;
  bottom: var(--sp-3);
  right: var(--sp-5);
`;

const Container = styled("div")`
  aspect-ratio: 16/9;
  ${({ $letterbox }) => ($letterbox ? "margin: auto" : "align-self: baseline")};
  background: ${!IS_APP
    ? `url(${appURLs.CDN}/${AgentSelectFixture.background})`
    : "initial"};
  background-size: cover;
  overflow: hidden;
  position: ${({ $letterbox }) => ($letterbox ? "relative" : "initial")};
  @media (min-aspect-ratio: 16/9) {
    height: 100%;
    width: calc(100vh * 16 / 9);
    font-size: 1.481vh;
  }
  @media (max-aspect-ratio: 16/9) {
    width: 100%;
    height: calc(100vw * 9 / 16);
    font-size: 0.834vw;
    visibility: ${({ $letterbox, $fullscreen }) =>
      !$letterbox || $fullscreen ? "hidden" : "visible"};
  }
  @media (aspect-ratio: 16/9) {
    visibility: visible;
  }
`;

function AgentSelect() {
  const { currentPath, searchParams, state: routeState } = useRoute();
  const videoAdRef = useRef();

  useEffect(() => {
    setupAgentSelectListeners();

    // Testing purposes only
    if (!IS_APP && !IS_NODE) {
      setVolatileKV("valAgentId", AgentSelectFixture.agentSelect.agentId);
      setVolatileKV(
        "valSelectedAgents",
        AgentSelectFixture.currentMatch.playerAgents,
      );
      // setFeatureFlag("ads", true);

      updateRoute(currentPath, searchParams, {
        gameName: AgentSelectFixture.currentMatch.gameName,
        tagLine: AgentSelectFixture.currentMatch.tagLine,
        queue: AgentSelectFixture.currentMatch.queue,
        mode: AgentSelectFixture.currentMatch.mode,
        players: AgentSelectFixture.currentMatch.players,
      });
    }

    return () => {
      teardownAgentSelectListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const state = useSnapshot(readState);
  const {
    settings: {
      valorant: { overlays: { isAgentSelectEnabled } = {} },
    },
    volatile: {
      valAgentId: agentId,
      valLetterbox: letterbox,
      valFullscreen: fullscreen,
      valSelectedAgents: playerAgents = {},
    },
  } = state;
  const { players = [], queue, mode } = routeState;

  const player = players?.find((p) => p.isMe);
  const nameTag = getNameTag(player?.gameName, player?.tagLine);
  const hasData = players?.length > 0;
  const isReplication = queue === "onefa" || mode === "oneforall";
  const shouldRender = players && !isReplication;
  registerOverlays(
    GAME_SYMBOL_VAL,
    shouldRender && isAgentSelectEnabled ? ["agentSelect"] : [],
  );
  appLog(
    "should render agent select",
    shouldRender,
    players,
    isAgentSelectEnabled,
    hasData,
  );
  if (!shouldRender) return null;

  return (
    <>
      <Container $letterbox={letterbox} $fullscreen={fullscreen}>
        {isAgentSelectEnabled ? (
          <BestAgents
            agentId={agentId}
            profileId={nameTag}
            letterbox={letterbox}
            queue={queue}
            players={players}
            playerAgents={playerAgents}
          />
        ) : null}
      </Container>
      {valAgentSelectRefs.VideoAd ? (
        <GameDraggableUnit
          game="valorant"
          overlayId={OVERLAY_IDS.valAgentSelectAd}
          initialPosition={cssAdInitialPosition()}
          draggableAreaRef={videoAdRef}
        >
          <valAgentSelectRefs.VideoAd
            overlayId={OVERLAY_IDS.valAgentSelectAd}
            ref={videoAdRef}
          />
        </GameDraggableUnit>
      ) : null}
    </>
  );
}

export function meta() {
  return {
    title: [null, "VALORANT - Agent Select"],
    description: [null, "VALORANT Agent select overlay"],
  };
}

export default AgentSelect;
