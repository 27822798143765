**November 18, 2022**
- AWP magazine size reduced from 10 bullets to 5.

**February 24, 2020**
- AWP crouching movement acceleration is now constant and will not spike after firing a shot.

**March 31, 2015**
- Lowered scoped movement values for AWP and Autosnipers.

**August 28, 2014**
- Scaled back scope effects for AWP and SSG08, now that it uses accurate accuracy/recoil values.
- Fixed scope zoom pops in various places (on ladders, etc).
- Removed scope effect view fov scaling that used to match incorrect accuracy values (was in 8/27/14 patch).

**January 23, 2013**
- The AWP's kill reward was increased from $50 to $100.

**August 10, 2012**
- Increased the duration of the AWP deploy animation to 1.25 seconds.
