import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import staticMediaURLs from "@/game-apex/static.mjs";
import { getCurrentRank } from "@/game-apex/utils/get-division.mjs";
import * as Scorecard from "@/shared/Scorecard.jsx";
import { formatToFixedNumber, formatToPercent } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ScorecardTable = styled(Scorecard.Table)`
  th:nth-child(1) {
    width: 200px;
    color: ${({ $enemyTeam }) => ($enemyTeam ? "var(--red)" : "var(--turq)")};
  }
`;

const Scoreboard = ({ teams = [], cols = [] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    apex: { profiles, playerStats },
  } = useSnapshot(readState);

  const getProfileLink = useCallback((profileId) => {
    if (!profileId) return "/apex";
    return `/apex/profile/${profileId}`;
  }, []);

  return teams.map((team, index) => (
    <ScorecardTable key={index} $enemyTeam={index !== 0}>
      <Scorecard.TableHead>
        <Scorecard.TableRow className="type-caption">
          <Scorecard.TableHeader scope="col" className="type-subtitle2">
            {index === 0
              ? t("common:myTeam", "My Team")
              : t("common:enemyTeam", "Enemy Team")}
          </Scorecard.TableHeader>
          {cols.map((col) => (
            <Scorecard.TableHeader scope="col" key={col[0]}>
              {t(...col)}
            </Scorecard.TableHeader>
          ))}
        </Scorecard.TableRow>
      </Scorecard.TableHead>
      <Scorecard.TableBody>
        {team.players.map((player) => {
          const rank = getCurrentRank(
            playerStats[player.platformId]?.ALL?.RANKED?.rankedPoints,
          );
          return (
            <Scorecard.TableRow
              key={player.platformId}
              className="type-body2"
              $isUser={player.isUser}
            >
              <Scorecard.TableData>
                <div className="flex align-center gap-sp-3">
                  <img
                    src={staticMediaURLs.getLegendImage(
                      player.champion?.apexId,
                    )}
                    className="profile-icon"
                  />
                  {rank ? <rank.icon /> : null}
                  <a href={getProfileLink(player.platformId)}>
                    {profiles[player.platformId]?.username || player.playername}
                  </a>
                </div>
              </Scorecard.TableData>
              <Scorecard.TableData>
                {t("common:count.kills", "{{ kills, count }}", {
                  kills: player.kills || 0,
                })}
              </Scorecard.TableData>
              <Scorecard.TableData>
                {formatToFixedNumber(language, player.damageDone || 0)}
              </Scorecard.TableData>
              {cols.length >= 3 && (
                <Scorecard.TableData>
                  {formatToPercent(language, player.headshotPercentage)}
                </Scorecard.TableData>
              )}
            </Scorecard.TableRow>
          );
        })}
      </Scorecard.TableBody>
    </ScorecardTable>
  ));
};

export default memo(Scoreboard);
