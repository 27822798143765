import { readState } from "@/__main__/app-state.mjs";
import { readData } from "@/__main__/get-data.mjs";
import type { MatchFull, MatchStart } from "@/data-models/csgo-game-events.mjs";
import type { CsgoPlayer } from "@/data-models/csgo-player.mjs";
import { DEFAULT_MATCHES_LIMIT, GAME_MODES } from "@/game-csgo/constants.mjs";
import fetchMatch from "@/game-csgo/fetches/fetch-match.mjs";
import fetchMatchlist from "@/game-csgo/fetches/fetch-matchlist.mjs";
import fetchPlayer from "@/game-csgo/fetches/fetch-player.mjs";
import { getGameModeByMatch } from "@/game-csgo/utils.mjs";
import { devError } from "@/util/dev.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

async function fetchData([profileIdParam, matchIdParam], searchParam) {
  const steamId = profileIdParam ?? searchParam.get("profileId");
  const matchId = matchIdParam ?? searchParam.get("matchId");

  try {
    const profile: CsgoPlayer = steamId
      ? (readState.settings.loggedInAccounts.csgo[steamId] ??
        (await fetchPlayer(steamId)))
      : readState.settings.loggedInAccounts.csgo[
          readState.settings.lastLoggedInIdByGame.csgo
        ];
    if (!profile) return;

    const match: MatchStart | MatchFull | undefined = matchId
      ? (readState.csgo.matches[matchId] ?? (await fetchMatch(matchId)))
      : // Prefer liveGame since that should be truthy when there is a live game being played
        (readState.csgo.liveGame ?? readState.csgo.matches[matchId]);

    // Fetch all players in this match
    const steamIds: string[] = Object.keys(
      (match?.rounds ?? []).reduce((acc, cur) => {
        // No duplicate STEAM_IDs
        for (const i of cur.playerMatchRoundStats) acc[i.steamId] = 0;
        return acc;
      }, {}),
    );
    for (const steamId of steamIds) {
      try {
        // Historically, when we requested all steam ids for this match, the server crashed
        // We are fetching player data in a queue from start to finish
        await fetchPlayer(steamId);
      } catch {
        devError(`Failed to fetch CS2 player with steam id: ${steamId}`);
      }
    }

    const gameMode = getGameModeByMatch(match) ?? GAME_MODES.ALL;

    if (isRouteOverlay()) {
      // Overlay
      const lastGameMode = await readData(["csgo", "lastGameMode"], {
        forceReadFromDB: true,
      });
      if (lastGameMode && lastGameMode.id !== gameMode.id)
        await fetchMatchlistData(profile.steamId, lastGameMode.id);
    } else {
      // Client
      await fetchMatchlistData(profile.steamId, gameMode.id);
    }
  } catch (e) {
    devError(e);
  }
}

/**
 * Load matchlist and all match data for the given profile and
 * game mode.
 */
async function fetchMatchlistData(profileId, gameModeId) {
  const matchlist = await fetchMatchlist(profileId, gameModeId);
  const matches = await Promise.all(
    matchlist.slice(0, DEFAULT_MATCHES_LIMIT).map((i) => fetchMatch(i.matchId)),
  );
  return { matchlist, matches };
}

export default fetchData;
