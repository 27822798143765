import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { getPreviousAct } from "@/game-val/utils.mjs";
import { Footer } from "@/shared/SeasonReview.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ContainerCSS = () => css`
  img {
    object-position: top;
  }
`;

function SeasonReviewSearch() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const {
    val: {
      content: { acts },
    },
  } = state;

  const previousAct = getPreviousAct(acts);

  if (!previousAct) return null;

  return (
    <Footer
      hideRegion
      currentRegion={undefined}
      regions={[]}
      linkConstructor={(_, name) =>
        `/valorant/act-review/${name}/${previousAct.key}`
      }
      backgroundImage={`${appURLs.CDN}/blitz/val/act-review-background.webp`}
      titleOverride={t(
        "valorant:seasonReviewSearch.reviewTitle",
        "{{episode}} {{act}} Recap is here",
        { episode: previousAct.episode, act: previousAct.act },
      )}
      subtitleOverride={t(
        "valorant:seasonReviewSearch.subtitle",
        "Blitz has you covered, just input your Riot ID below to get started.",
      )}
      className={ContainerCSS()}
    />
  );
}

export default SeasonReviewSearch;

export function meta(_params): Meta {
  const title: [string, string, Record<string, unknown>?] = [
    "val:seasonReviewSearch.meta.title",
    "Get your Act Review",
  ];
  const description: [string, string, Record<string, unknown>?] = [
    "val:seasonReviewSearch.meta.description",
    "Explore your stats over the last act",
  ];

  return {
    title,
    description,
    image: {
      url: `${appURLs.CDN}/blitz/val/act-review-og.webp`,
      alt: title,
      height: 273,
      width: 416,
    },
  };
}
