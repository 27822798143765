Internationalization is a critical component at Blitz that enables the translation of text into 15+ languages. If you haven't worked with i18n before, we recommend you start with the Introduction at [i18next.com](https://www.i18next.com/).

The two most important things you should apply when working on Blitz:

- All strings **and** numbers rendered in Blitz need to be localized, typically through [react-i18next](https://react.i18next.com/) using the `t()` function from `useTranslation()` or the `<Trans />` component
- Never concatentate rendered strings or numbers in a component. Instead you should use [Interpolation](https://www.i18next.com/translation-function/interpolation) to work with dynamic values in translations.
- You may encounter some bad practices ported over from Blitz Web that we are still cleaning up. If you are unsure about a practice, reach out for some guidance.

## Usage with React

Translations are fairly straight forward once you become familiar with the framework. Typically you will leverage the hook `const { t } = useTranslation()` and provide: a key for translation lookup, fallback translation text if the key doesn't exist, and any values for interpolation:

```js
const { t } = useTranslation();

// renders: Welcome Clutch to Blitz!
t("common:welcomeMessage", "Welcome {{ username }} to Blitz!", {
  username: "Clutch",
});
```

### Formatting Numbers

You can specify a formatter such as `number` or `percent` after a parameter:

```js
// renders: 2.1 KDA
t("common:stats.kda", "{{ kda, number }} KDA", {
  kda: 2.156,
});
```

When working with percentages, always prefer to keep values in a range of `[0,1]` and let the formatter do the work.

```js
// renders: 15% HS
t("common:stats.hs", "{{ hs, percent }} HS", {
  hs: 0.156,
});
```

If you need to format the level of precision, you can pass in formatting options that are accepted by the standard [Intl API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl).

```js
// renders: 2.156 KDA
t("common:stats.kda", "{{ kda, number }} KDA", {
  kda: 2.156,
  formatParams: {
    maximumFractionDigits: 3,
  },
});
```

If you need to format a number by itself, you don't have to use the `t()` function. Number formatting should use the [Intl API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl) for formatting, and we have a few helpers available in `i18n-helpers.mjs` such as `formatToPercent(language, value, { min, max })` and `formatToFixedNumber(language, value, maximumFractionDigits)`:

```js
const {
  i18n: { language },
} = useTranslation();

// renders: 56.5%
return <span>{formatToPercent(language, 0.5653, { max: 1 })}</span>;

// renders: 10.4
return <span>{formatToFixedNumber(language, 10.453, 1)}</span>;
```

## Common Mistakes

Review these best practices for avoiding common mistakes that happen when a developer is new to localization.

### Always interpolate values in i18n fallbacks

Never render variables directly into your strings. Instead always use interpolation and provide the values in the third argument of the translation function.

Good example:

```js
t("common:greeting", "Hi {{name}}!", { name });
```

**NEVER DO THIS:**

```js
t("common:greeting", `Hi ${name}!`);
```

### Always use i18n for anything that is displayed to the user

If you are rendering any text to the user (strings or numbers) you should be passing it through localization using i18n or the Intl API.

Good example:

```js
function MyComponent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const text = t("namespace:label", "Some Label");

  return (
    <>
      <p>{text}</p>
      <p>{formatToPercent(language, 0.534)}</p>
    </>
  );
}
```

**NEVER DO THIS:**

```js
function MyComponent() {
  const text = "Noob dev do this";

  // No localization!
  return <p>{text}</p>;
}
```

### Assume the language may change at any time

Assume the language might change for your component at any time. The `useTranslation` hook will handle this for you. You can also access the current language and pass it to one of the formatting helpers or the Int API.

Good example:

```js
const {
  i18n: { language },
} = useTranslation();

return <span>{formatToPercent(language, 0.5653, { max: 0 })}</span>;
```

**NEVER DO THIS:**

```js
import i18n from "i18next";

return <span>{formatToPercent(i18n.language, 0.5653, { max: 0 })}</span>;
```

## Other Helpful Information

A few things you should be thinking about when working with i18n and React:

- We have several namespaces for our translation files. For example, we have a `common` namespace for translations used app-wide, and several that are game-specific such as `lol`, `apex`, etc.
- Keep a list of new translation keys that you add and share them with the team so we can make sure to get translations as needed for these new keys.
- Assume the language can change at any time - you should leverage `useTranslation()` so your component re-renders appropriately when the language changes.
- Use the [Trans component](https://react.i18next.com/latest/trans-component) if you need to render HTML in your translation such as `<strong>` or `<span>` tags.
- We override the default formatters at `src/i18n/i18n.mjs` - if you see unexpected formatting issues, you might confirm if the formatter needs to be updated.
- [Lists](https://www.i18next.com/translation-function/formatting#list) and [pluralization](https://www.i18next.com/translation-function/plurals) are also important to consider with i18n. Review these concepts if you encounter these in your feature.
