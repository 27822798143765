**April 10, 2020**
- Reduced firing inaccuracy

**August 8, 2017**
- Shipping initial changes to pistols with the goal of emphasizing skilful use of the weapons, beginning with the Tec-9.
- Tec-9: The Tec-9 has been adjusted to emphasize aiming while retaining the weapon’s high mobility. The current changes include:
- slightly improved accuracy when taking a single shot
- significantly reduced accuracy when firing rapidly
- magazine and reserve ammo reduced to 18 and 90, respectively

**December 15, 2015**
- Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details).

**December 8, 2015**
- Increased move inaccuracy on pistols: Dual Berettas, Five-SeveN, Glock-18, P2000, P250, Tec-9, USP-S, CZ75-Auto

**March 31, 2015**
- Increased Tec-9’s damage fall-off to reward players that close engagement distance.
- Lowered Tec-9’s magazine size to 24 to reward more discriminate firing.

**December 10, 2014**
- Updated the Tec-9 firing sound

**February 20, 2013**
- Reduced Five-SeveN and Tec-9 prices
