
TODO: the spec implies some extra logic paging that is left un-emplemented for now

```jsx preview hideSource
<StatsCard title="Weapons">
  {new Array(5).fill(0).map((_,i) => (
    <StatsCard.Tile key={i} title="Title/Name" subtitle="Subtitle" firstStat="First Stat" secondStat="Second Stat" />
  ))}
</StatsCard>
```

```ts
type StatsCardProps = {
  title: string;
  className?: string;
  /// *Should* be <StatsTiles>
  children: React.ReactNode;
};
```

### StatsAlert

```jsx preview hideSource
<StatsCard title="Weapons">
  <StatsCard.Alert text="Hint or warning" onDismiss={() => {}} />
  <StatsCard.Alert text="Hint or warning" />
  <StatsCard.Tile title="Title/Name" subtitle="Subtitle" firstStat="First Stat" secondStat="Second Stat" />
</StatsCard>
```

```ts
type StatsAlertProps = {
  text: string | React.ReactNode;
  onDismiss?(): void;
};
```

### StatsTile

Uses the [`<SquareAvatar>`](/docs/component-avatar) component under the hood. See it's docs for more info on the `avatar` prop.

```jsx preview hideSource
<StatsTile avatar={<StarIcon />} title="S.T.A.R.S. Alpha" subtitle="Raccoon City's Finest" firstStat="100% Success" secondStat="100% Casulties" />
<StatsTile avatar="https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg" title="S.T.A.R.S. Bravo" firstStat="100% Success" />
```

```ts
type StatsTileProps = {
  title: string;
  subtitle?: string;
  avatar: string | React.ReactNode;
  href?: string;
  firstStat?: string;
  secondStat?: string;
  className?: string;
};
```