import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, {
  EVENTS,
  handleMessage,
  initEvents,
} from "@/__main__/ipc-core.mjs";
import { setRoute } from "@/__main__/router.mjs";
import appEventBus from "@/app/app-event-bus.mjs";
import { EVENT_CUSTOM_ERROR } from "@/app/constants.mjs";
import {
  EVENT_FORTNITE_ENTER_GAME,
  EVENT_FORTNITE_EXIT_GAME,
  EVENT_FORTNITE_MATCH_LIVE,
} from "@/game-fortnite/constants/events.mjs";
import {
  FORTNITE_QUEUES,
  getBEModeFromGameMode,
} from "@/game-fortnite/constants/queues.mjs";
import matchCreateQuery from "@/game-fortnite/fetches/match-create.mjs";
import fetchMatchlist from "@/game-fortnite/fetches/matchlist.mjs";
import type {
  LobbyCore,
  MatchEndCore,
  MatchInitCore,
  MatchKillCore,
  MatchLiveCore,
  MatchStartCore,
  PlayerCore,
} from "@/game-fortnite/models/game-events.mjs";
import {
  MatchEndCoreValidator,
  matchEndToMatchCreate,
  MatchInitCoreValidator,
  MatchKillCoreValidator,
  MatchLiveCoreValidator,
  matchLiveTransformFn,
  MatchStartCoreValidator,
  PlayerCoreValidator,
} from "@/game-fortnite/models/game-events.mjs";
import type { Player } from "@/game-fortnite/models/profile.mjs";
import {
  addItemsToLocker,
  addMatchToMatches,
  addMatchToMatchlist,
  clearLiveGame,
  liveGameConcat as appendToLiveGame,
  matchIntegrityCheck,
  updateProfile,
} from "@/game-fortnite/utils/actions.mjs";
import clone from "@/util/clone.mjs";
import { devDebug, devError, devWarn } from "@/util/dev.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

let readLiveGameTimeout: NodeJS.Timeout;
function readLiveGame() {
  blitzMessage(EVENTS.FORTNITE_READ_LIVE_GAME);
  readLiveGameTimeout = setTimeout(readLiveGame, 1000);
}

export const registerEventListeners = async () => {
  await initEvents;

  handleMessage(EVENTS.FORTNITE_MATCH_INIT, (matchInit: MatchInitCore) => {
    devDebug("Received FORTNITE_MATCH_INIT", matchInit);
    matchInit = MatchInitCoreValidator(matchInit, { failOnError: true });
    if (matchInit === undefined) return;
    clearLiveGame();
    appendToLiveGame(matchInit);
    setRoute("/fortnite/in-game", undefined, undefined, true);
  });

  handleMessage(EVENTS.FORTNITE_MATCH_START, (matchStart: MatchStartCore) => {
    devDebug("Received FORTNITE_MATCH_START", matchStart);
    matchStart = MatchStartCoreValidator(matchStart, { failOnError: true });
    if (matchStart === undefined) return;
    appendToLiveGame(matchStart);
    setRoute("/fortnite/in-game", undefined, undefined, true);
    appEventBus.emit(EVENT_FORTNITE_ENTER_GAME, matchStart);
    /**
     * Non-match start side-effects
     * This is the soonest we can update the current player from BE + local state for their preference to be anonymous if it differs from what we have
     **/
    const accountId: string | undefined =
      readState.settings.lastLoggedInIdByGame.fortnite;
    const profile: Player | undefined = readState.fortnite.profiles[accountId];
    if (profile)
      updateProfile({
        accountId,
        name: profile.name,
        updatedAt: new Date(),
        anonymous: matchStart.localPlayer.anonymous,
        character: matchStart.localPlayer.character,
      });
    /**
     * Non-player side-effects
     * Fetch the matchlist for this account, this is for the recent avg statistic for the in-game page
     **/
    try {
      fetchMatchlist(accountId, getBEModeFromGameMode(matchStart.gameMode));
    } catch {
      devWarn("Failed to fetch the matchlist + matches for /fortnite/in-game");
    }

    readLiveGame();
  });

  handleMessage(EVENTS.FORTNITE_MATCH_END, async (matchEnd: MatchEndCore) => {
    devDebug("Received FORTNITE_MATCH_END", matchEnd);

    // Prevent overlay from receiving this event (for now)
    if (isRouteOverlay()) return;

    // Client only
    matchEnd = MatchEndCoreValidator(matchEnd);
    const profileId: string =
      readState.settings.lastLoggedInIdByGame.fortnite ??
      matchEnd.localPlayer.accountId;
    if (!readState.settings.lastLoggedInIdByGame.fortnite) {
      // If this occurs, it must mean that blitz-core is no longer sending a FORTNITE_PLAYER event before a match has started and/or ended
      devWarn(
        "FORTNITE_MATCH_END was triggered before a single FORTNITE_PLAYER was fired",
      );
    }
    try {
      appEventBus.emit(EVENT_FORTNITE_EXIT_GAME, clone(matchEnd));
      // Guards: Handle edge case logic that has been passed to FE from RE or BE
      matchIntegrityCheck(matchEnd);
      // Guards: If this is ever triggered, there is a major issue with game flow and data integrity from MATCH_END
      if (!profileId)
        throw new Error(
          "FORTNITE_PLAYER and FORTNITE_MATCH_END is severely broken",
        );
      // Process match
      const isSupportedMode = Object.getOwnPropertySymbols(
        FORTNITE_QUEUES,
      ).find((s) => FORTNITE_QUEUES[s]?.internal?.includes(matchEnd.gameMode));
      if (!isSupportedMode) return;
      const matchCreate = matchEndToMatchCreate(matchEnd);
      await matchCreateQuery(matchCreate);

      // During this time the match is being processed by back-end, it will take a few mins to be available
      const matchQuery = await addMatchToMatches(matchCreate);
      addMatchToMatchlist(profileId, matchQuery);
      setRoute(
        `/fortnite/match/${profileId}/${matchQuery.seasonId}/${matchEnd.gameId}`,
      );
    } catch (e) {
      devError("Failed FORTNITE_MATCH_END callback event", e);
      appEventBus.emit(EVENT_CUSTOM_ERROR, e);
    }
    clearTimeout(readLiveGameTimeout);
    clearLiveGame();
  });

  handleMessage(EVENTS.FORTNITE_IS_RUNNING, (isRunning: boolean) => {
    devDebug("Received FORTNITE_IS_RUNNING", isRunning);
  });

  handleMessage(
    EVENTS.FORTNITE_PLAYER,
    ({ player }: { player: PlayerCore }) => {
      devDebug("Received FORTNITE_PLAYER", player);
      player = PlayerCoreValidator(player);
      updateProfile(player);
      setRoute(`/fortnite/profile/${player.accountId}`);
    },
  );

  handleMessage(EVENTS.FORTNITE_LOBBY, (lobby: LobbyCore) => {
    devDebug("Received FORTNITE_LOBBY", lobby);
  });

  handleMessage(EVENTS.FORTNITE_KILL, (kill: MatchKillCore) => {
    devDebug("Received FORTNITE_KILL", kill);
    kill = MatchKillCoreValidator(kill);
    appendToLiveGame({ kills: [kill] });
  });

  handleMessage(EVENTS.FORTNITE_MATCH_LIVE, (matchLive: MatchLiveCore) => {
    devDebug("Received FORTNITE_MATCH_LIVE", matchLive);
    const live = MatchLiveCoreValidator(matchLive, { failOnError: true });
    if (live === undefined) return;
    appEventBus.emit(EVENT_FORTNITE_MATCH_LIVE, matchLive);
    appendToLiveGame({ matchLive: matchLiveTransformFn(matchLive) });
  });

  handleMessage(
    EVENTS.FORTNITE_PLAYER_COSMETICS,
    ({
      cosmetics,
    }: {
      cosmetics: { name: string; name_localized: string; type: string }[];
    }) => {
      devDebug("Received FORTNITE_PLAYER_COSMETICS", cosmetics);
      const accountId: string | undefined =
        readState.settings.lastLoggedInIdByGame.fortnite;
      const itemNames = cosmetics.map((c) => c.name);
      if (accountId) addItemsToLocker(accountId, itemNames);
    },
  );
};
