**September 21, 2021**
- Reduced price to $300

**September 26, 2017**
- Reduced price by $100 for both the R8 Revolver (now $600) and Dual Berettas (now $400).

**August 3, 2016**
- Increased fidelity of firing sounds for P250, Five-Seven, Tec-9, CZ75-Auto, and Dual Berettas.
- Added unique reload and distant sounds for P250, Five-Seven, Tec-9, and Dual Berettas.

**September 15, 2015**
- Increased armor penetration
- Increased range modifier

**July 1, 2014**
- Reduced price to $500

**December 11, 2006 (Counter-Strike: Source)**
- Increased ES Five-seveN, 228 Compact and Dual Elites damage by 25%
