**December 16, 2022**
- Increased bomb radius to 650 from 500
- Flattened out cubbies in red house top of mid
- Pushed back CT spawn 256 units, delaying CTs ~1 sec
- Compressed blocky things on B site, to create longer sightlines and more space to move around

**October 26, 2022**
- Fixed some boost exploits
- Fixed some minor graphical bugs

**June 15, 2022**
- Remove cratestack outside of “cave” location.
- Fixed pixelgap from inside “cave” towards T mid.
- Fixed ground clipping bugs in mid causing players to go slightly airborne.
- Smoothed out ground outside of “cave” down towards B ramp.
- Blocked archeological dig inside of “cave”.
- Smoothed out corners in “donut” room.
- Added additional cover near pillar on B.
- Blocked one sidepath exit towards A site, widened remaining exit.
- Fixed some visual glitches when looking out towards mid from “donut” room.
- Reduced wallbang damage through scaffolding at B site.
- Boosted light emitted from candles/lanterns.

**October 15, 2021**
- Fixed pixel gap through scaffolding near B

**September 21, 2021**
- Increased size of plantable area in both bombsites
- Fixed bullet penetration on plywood surfaces
- Further optimizations
- Added 1v1 arenas

**May 27, 2021**
- Art-revision on bombsite B and surrounding area
- Higher contrast base textures at B for better player reads
- Fixed random missing micro-polygons in bomb B totem/sculpture
- Recolored plywood throughout the map for better contrast
- Straightened out broken-wall passage to bombsite B
- Tweaked spawnpoint placement for both teams

**May 13, 2021**
- Implemented Cubby 2.0 in mid connector to A, to remove cheap shoulder angle towards site
- Slimmed down the fancy rock, also in mid connector to A
- Sparsed foliage and improved clipping on boost planter near A main
- Quieted down soundscape birds
- Removed clipping from top of bombsite sign in A site, allowing you to self-boost to planter by T main
- Added vphysics clip to T spawn water so guns don’t fully submerge
- So many clip brush tweaks
- Blocked grenades from entering inaccessible building by T entrance to mid
- Blocked pixelgaps through scaffolding near B site
- Blocked pixelgaps through doorway trim in new CT path to A site
- Simplified grenade collision on rooftops
- General optimizations

**May 6, 2021**
- Updated minimap image

**May 3, 2021**
- New route from CT spawn to A site
- Widened T entrance to left side of mid
- Opened up skylight in T tunnel to A site
- Extended plantable bomb zone in B site
- Opened up ledge in A site
- Various minor bugfixes

**January 7, 2021**
- Further optimizations
- Fixed some clipping bugs

**December 9, 2020**
- Reduced soundscape volume.
- Tweaked ambient light levels in some locations.
- Added additional lights to dark corners.
- Removed metal pillars by backdoor entrance to B site.
- Scaffolding by B site can now be shot through.
- Blocked visibility between boxes on A site.
- Tweaked blending for better player reads in some locations.
- Redid visual language of ledges around mid, more obvious what is/isnt pathable.
- Marked additional props for low end CPU/GPU culling.
- Mossy walls no longer surface type ‘grass’.
- Fixed invisible pottery.
- Removed some visual clutter around backdoor to B.
- General optimizations.
- General clipping fixes.
- Added de_ancient_zoo VMF to SDK for community map makers.

**December 3, 2020**
- Map added to the game with the Operation Broken Fang update.
