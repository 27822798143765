**December 9, 2020**
- Fixed clipbrushes for Retakes.

**January 29, 2020**
- Added AWP wallbang spot from palace to scaffolding close corner, A site
- Added bench in mid allowing jump up to window boost spot/peek towards cat
- Widened T entrance to mid
- Lowered trim around B site balcony
- Blocked a jump-peek from middle of B site towards cat

**December 13, 2019**
- Removed pixel-gaps on boxes in bombsite A.

**August 29, 2018**
- Fixed texture blends in T-spawn area.

**July 6, 2018**
- Adjusted player collision on bench wall at bombsite B.

**June 12, 2018**
- Removed skybox limitations
- Updated some landmark art
- Lowered skillceiling to get into apartments from T spawn
- Banked corner in hallway leading to A site scaffolding
- Removed ladder near entrance to apartments from T spawn, replaced with boxes
- Removed broken corner near van in bombsite B
- Improved visibility from T side of mid towards A connector (removed dark background door inside connector)
- Improved visibility from CT stairs towards T ramp in bombsite A (removed dark background door inside ramp)
- Disabled bullet collision on vertical fence columns in mid
- Removed wooden pillars at bottom of mid
- Fixed some player collision bugs

**June 9, 2016**
- Fixed pixelwalk in CT spawn
- Fixed some small seethrough gaps in bombsite A
- Fixed small seethrough gap from Short to Mid
- Fixed grenadecollision on van in bombsite B
- Van now plays proper surface sounds when walking on it
- Stairs in palace and B apartments will now play correct surface sounds
- Fixed potential rendering error on wall hole model used in CT sniper position
- Fixed various bugs (Thanks csgobugtracker.com contributors!)

**April 21, 2016**
- Vandalism

**February 17, 2016**
- Removed wall-lamps that blocked visibility from ticketbooth to mid in bombsite A
- Removed shelf inside market near bombsite B (thanks TheWhaleMan!)[citation needed]
- Added a plywood board to bomb-crate in bombsite A to make throwing grenades under scaffolding more consistent

**October 20, 2015**
- Fixed some exotic spots where C4 could get stuck (Thanks amitkilo!)
- Moved palmtree behind ticketbox slightly, to make CTs easier to see.

**May 26, 2015**
- Fixed some spots where C4 could get stuck
- Fixed some spots where players could get stuck on walls

**January 28, 2015**
- Fixed a bug where a boosted player in T spawn could peek into mid/CT sniper position.
- Moved a prop to make peeking into palace easier, CT side.
- Removed a strange boost in bombsite A.

**January 8, 2015**
- Fixed a bug where players could clip through a wall near CT spawn
- Removed center scaffolding near hole to CT sniper position

**December 15, 2014**
- Fixed a boost exploit near T ramp in Bombsite A

**August 27, 2014**
- Removed various unintended boost-spots
- Moved some flowers at top of mid

**July 16, 2014**
- Fixed upper shutters in kitchen having a NODRAW face inside that wall that prevented wallbanging from one direction (bottom wood is unchanged).
- Clipped a part behind van to prevent bomb from getting stuck.
- Thinned B “bench” wall a bit to allow more damage to penetrate.
- Clipped windows in palace so you can’t jump into them.

**July 10, 2014**
- Updated look of inner middle window wall materials.
- Updated the Catwalk to Kitchen window bang to better visually designate what is bangable and what isn’t.
- Replaced the vent cover in window room with one that allows more visibility through it.
- Connecter to A wall now has a blown out, wood covered wall on the left to visually designate a bangable spot to allow more options for taking/retaking A.

**July 1, 2014**
- Added to the Active Duty maps group
- Fixed a collision exploit near entrance to Underpass.

**February 5, 2014**
- Fixed a bug where you could peek over a crate at CT stairs.
- Added a peek position in Bombsite A connector, towards Palace exit.
- Clipped benches inside palace.
- Fixed some boost/exploit spots (Thanks F3RO!).
- Breakable metal panels can no longer be shot through without triggering break effect.
- Made it easier to get into hole leading to CT sniper window.
- Made it easier to move over cart leading to B route from CT spawn.
- Reworked cover in Bombsite A.
- Reworked cover in Bombsite B.

**September 19, 2013**
- Tweaked cover in Bombsite A.
- Removed trashcan near connector into Bombsite A.
- Added cover at top of CT stairs into Bombsite A.
- Widened box near T tunnel into Bombsite A.
- Blocked visibility under the van at Bombsite B.
- Removed the red pushcart near Bombsite B.
- Removed bed in apartments near Bombsite B.
- Removed center divider in CT sniper window.
- Added cover inside Palace.
- Removed scaffolding in alley near Apartments, tweaked skybox volume.
- Removed chairs in Underpass.

**June 26, 2013**
- Added more light to CT window into bombsite B.
- Fixed some graphical bugs.
- Removed small trashbags that could be mistaken for players.

**August 22, 2013**
- Minor bugfixes.

**June 19, 2013**
- Fixed see-through crates at bombsites, various other bugfixes.
