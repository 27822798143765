import { Fragment } from "react";

import {
  radii,
  RadiusBlock,
  Space,
  SpaceBlock,
  spacing,
} from "@/feature-docs/ThemeComponents.jsx";

<Space
  space="calc(var(--sp-1) * 100)"
  overrideDesc="0.25rem * 100 = 25rem"
  value="calc(var(--sp-1) * 100)"
/>

<Fragment>
  {Object.keys(spacing).map((key, i) => (
    <SpaceBlock key={i} title={key} spacing={spacing[key]} />
  ))}
</Fragment>

<RadiusBlock title="Border Radius" spacing={radii} />
