import { ITEM_CATEGORIES } from "@/game-palworld/constants/items.mjs"

## Overview

**{props.t(...props.sphere.label)}** is a <WikiLink href={`/palworld/database/${props.sphere.category}`}>{props.t(...ITEM_CATEGORIES[props.sphere.category].label)}</WikiLink> in [Palworld](/palworld/database). {props.t(...props.sphere.description)}

<ShowIf cond={!!props.technology}>
  It's a level {props.technology?.levelRequirement} [technology](/palworld/database/technology) item and requires {props.technology?.pointCost} point(s) to unlock.
</ShowIf>

## Tips

- Using a Pal Sphere from behind will increase the chances of a successful capture.
- [Vixy](pal:vixy) has a chance to dig up Pal Spheres from the ranch.

