import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { calcRate } from "@/util/helpers.mjs";

const Container = styled("div")`
  .your {
    color: var(--turq);
  }
  .enemy {
    color: var(--red);
  }
  .title {
    color: var(--shade0-50);
  }
`;

const Bar = styled<{ $percent: number }>("div")`
  width: ${(props) => props.$percent}%;
  height: var(--sp-1e);
  border-radius: var(--sp-1e);
  &:first-child {
    background: var(--turq);
  }
  &:last-child {
    background: var(--red);
  }
`;

function TeamComparison({
  title,
  enemy: { display: enemyDisplay, value: enemyValue },
  your: { display: yourDisplay, value: yourValue },
}) {
  const { t } = useTranslation();
  if (yourValue === enemyValue && yourValue === 0) {
    yourValue = 1;
    enemyValue = 1;
  }
  const yourPercent = calcRate(yourValue, yourValue + enemyValue, 4) * 100;
  const enemyPercent = calcRate(enemyValue, yourValue + enemyValue, 4) * 100;

  return (
    <Container className="flex column gap-2">
      <div className="flex justify-between">
        <p className="your type-body2-form--active">{yourDisplay}</p>
        <p className="title type-caption">{t(...title)}</p>
        <p className="enemy type-body2-form--active">{enemyDisplay}</p>
      </div>
      <div className="flex gap-1">
        <Bar $percent={yourPercent}></Bar>
        <Bar $percent={enemyPercent}></Bar>
      </div>
      <div className="flex justify-between">
        <p className="your type-overline">{t("lol:teams.your", "Your team")}</p>
        <p className="enemy type-overline">
          {t("lol:teams.enemy", "Enemy team")}
        </p>
      </div>
    </Container>
  );
}

export default TeamComparison;
