import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_DEV } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import GenericMatchListModel from "@/data-models/generic-match-list.mjs";
import LeagueAccountModel from "@/data-models/league-account.mjs";
import TFTMatchModel from "@/data-models/tft-match.mjs";
import {
  RANK_SYMBOL_TO_STR,
  RANK_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import {
  getDerivedIdFromSummoner,
  getDerivedRiotID,
  separateDerivedRiotID,
} from "@/game-lol/utils/derived-id.mjs";
import { isPBE } from "@/game-lol/utils/util.mjs";
import * as API_TFT from "@/game-tft/api.mjs";
import {
  QUEUE_SYMBOLS,
  QUEUE_SYMBOLS_TO_QUEUE_NAMES,
} from "@/game-tft/constants.mjs";
import matchlistMergeFn from "@/game-tft/merge-function-matchlist.mjs";
import fetchTftIncomeStats from "@/game-tft/tft-fetch-income-stats.mjs";
import { appLog, devError } from "@/util/dev.mjs";

export default async function fetchData() {
  let region, name;
  const derivedId =
    IS_DEV && !IS_APP
      ? "na1:Souless-001"
      : (
          readState.volatile.currentSummoner ?? // undefined unless set after overlays init and before this is func is called
          getDerivedIdFromSummoner(
            readState.settings.loggedInAccounts.lol[
              readState.settings.lastLoggedInIdByGame.lol
            ],
          )
        )?.toLowerCase();
  if (IS_DEV && !IS_APP) {
    name = "Souless-001";
  } else if (derivedId) {
    const result = derivedId.match(/^(.*?):(.*)$/);
    if (result?.[1]) region = result[1];
    if (result?.[2]) {
      const profile =
        readState.settings.loggedInAccounts.lol[
          readState.settings.lastLoggedInIdByGame.lol
        ];
      name = getDerivedRiotID(profile?.gameName, profile?.tagLine) || result[2];
    }
  }
  if (!region) region = readState.settings?.riotRegion;
  if (!region || !name) {
    onError(
      "[TFT] In Game Overlay: Cannot fetch player data without a region and name",
    );
    return;
  }

  const [gameName, tagLine] = separateDerivedRiotID(name);

  if (!gameName || !tagLine || isPBE(region)) return;

  const playerData = await getData(
    API_TFT.getPlayer(region, gameName, tagLine),
    LeagueAccountModel,
    ["tft", "accounts_v2", derivedId],
    { shouldFetchIfPathExists: true },
  );

  const tftOverlayData = {
    matchInfo: {
      queueId: readState.settings.lol.lastQueueId,
      matchId: `${region.toUpperCase()}_${readState.volatile?.currentGameTuple?.[1]}`,
      region,
      currentPatch: readState.tft.latestPatch?.patch,
    },
    language: readState.settings?.selectedLanguage,
    summonerInfo: {
      gameName: playerData?.account?.game_name,
      tagLine: playerData?.account?.tag_line,
      region,
      leagues: playerData?.league_tft,
    },
    blitzVersion: readState.volatile?.appVersion?.value,
  };

  let tier = tftOverlayData.summonerInfo.leagues?.find(
    (league) =>
      league.queue_type ===
      QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTft],
  )?.tier;
  if (tier === "NONE" || !tier) {
    tier = RANK_SYMBOL_TO_STR[RANK_SYMBOLS.gold].capped;
  }

  if (tier && tftOverlayData?.matchInfo?.currentPatch) {
    fetchTftIncomeStats(tier, tftOverlayData.matchInfo.currentPatch).catch(
      (e) => onError(`[TFT] In Game Overlay: Failed to fetch income stats`, e),
    );
  }

  const matchlist = await getData(
    API_TFT.getMatchList(region, gameName, tagLine),
    GenericMatchListModel,
    ["tft", "matchLists_v2", derivedId],
    {
      mergeFn: matchlistMergeFn,
      shouldFetchIfPathExists: true,
    },
  );

  const recentMatches = Array.isArray(matchlist) ? matchlist.slice(0, 20) : [];

  await Promise.all(
    recentMatches.map((matchId) => {
      return getData(
        API_TFT.getMatch(matchId),
        TFTMatchModel,
        ["tft", "matches_v2", matchId],
        {
          shouldFetchIfPathExists: false,
        },
      );
    }),
  );
}

const onError = (message, error) => {
  devError(message, error);
  if (!IS_DEV) appLog(message);
};
