**September 28, 2016**
- Increased fidelity and reduced distortion in fire sounds for Famas, Galil, Aug, SG553, M4A4, M4A1-S, unsilenced M4A1-S, and AK47.

**August 3, 2016**
- New accuracy recovery method and new recovery rates for the M4A1-S, M4A4, and AK-47. See details HERE.

**December 15, 2015**
- Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details).

**December 8, 2015**
- Adjusted recovery time on the AK47, M4A4, and M4A1-S assault rifles to reduce the range at which spraying is preferable to tapping/bursting.
