### How to enable console?

<Card>
To enable the console in CS2, you need to launch the game, **go to the settings** in the top left corner and navigate to the **Game tab**.\
You will now see a row called **Enable Developer Console**. Set it to Yes.\
By default, the **key shortcut is set to ~** but you can change it by going into **Keyboard / Mouse tab** and scroll to the section called **UI Keys**. You will see a row called **Toggle Console** and set the key shortcut you want.

![How to open console](https://blitz-cdn.blitz.gg/blitz/cs2/articles/how-to-open-console.webp)
</Card>


### Best console commands

<Card>
Here at Blitz, we have created a config file to download with the best console commands automatically executed when you launch your game.\
See detailed commands or download the file just below:

#### Netcode settings

The most optimized netcode commands to remove the feeling of lags in-game.
```
cl_updaterate "128"
cl_interp_ratio "1"
cl_interp "0.015625" // Set to 0.03125 for less stable connection
```

#### FPS settings

See your frames rate in real-time and remove the FPS limiter.
```
fps_max "400"
cq_netgraph "1" // Set to 0 to disable it (CSGO net_graph command)
cl_showfps "1" // Set to 0 to disable it
```

#### Jumpthrow bind

Just like CS:GO, equip the grenade you want, hold left-click and click the defined key to throw a perfect grenade.

```
alias "+boing" "+jump"
alias "+ding" "-attack; -attack2"
alias "+dong" "-jump"
bind "n" "+boing; +ding; +dong" // Change ‘n’ with your desired key shortcut
```

<Button href={`${props.appURLs.CDN_PLAIN}/blitz/cs2/cs2blitz.cfg`}>Download .cfg file</Button>

Once downloaded, **copy and paste cs2blitz.cfg** in this folder (default CS2 install) or right-click on CS:GO in **Steam library** and select **Properties** and in the **Local Files** nav item click **Browse** to open the installation folder:
```
C:\SteamLibrary\steamapps\common\Counter-Strike Global Offensive\game\csgo\cfg
```
</Card>

### CS2 Launch options

<Card>
In addition to in-game commands, you can set up launch options to avoid having to manually execute console commands by doing exec cs2blitz.

To add these options, go to **Settings** from **Steam library**, right-click on CS:GO and select **Properties**. Scroll to the bottom of the **General nav** item and in the **Launch option field**, copy this:
```
-novid -console -high -nod3d9ex -freq 144 +exec cs2blitz -allow_third_party_software
```

**-novid** will remove the intro video of CS:GO. Not yet available for CS2.

**-console** will show console without having to enable it from in-game settings.

**-high** will set CS2 as having higher priority in task manager, meaning you should experience less stutters.

**-nod3d9ex** will disable Direct3D 9Ex to improve performance on older systems.

**-freq &lt;YOUR_FREQUENCY&gt;** will set the refresh rate of your monitor to match your monitor’s capabilities.

**+exec cs2blitz** will automatically execute the config file you have downloaded.

**-allow_third_party_software** will allow apps like Blitz to run properly in the background.

![Launch Options](https://blitz-cdn.blitz.gg/blitz/cs2/articles/launch-options.webp)
</Card>

### Best CS2 settings

<Card>
Here is the best CS2 settings to optimize your FPS:

**Video > Advanced Video**
- Boost Player Contrast set to disabled.
- Wait for Vertical Sync set to disabled.
- Multisampling Anti-Aliasing Mode set to 8X MSAA.
- Global Shadow Quality set to low.
- Model / Texture Detail set to low.
- Shader detail set to low.
- Particle Detail set to low.
- Ambient Occlusion set to disabled.
- High Dynamic Range set to quality.
- FidelityFX Super Resolution set to disabled.
- NVIDIA Reflex Low Latency set to disabled.

![Best settings](https://blitz-cdn.blitz.gg/blitz/cs2/articles/best-settings.webp)
</Card>

### Other CS2 commands

<Card>
Here is a list of useful commands you can use in practice mode or you can download Blitz warmup config file to execute on practice server:

- **sv_cheats 1** to allow you to override some of basic game limitations and to enable some god mod commands. Don’t worry, you will not be Vac banned for using it in practice mode.
- **god** to enable god mode and take no damage.
- **noclip** to fly around the map.
- **sv_infinite_ammo** 1 to give you infinite ammo and grenades.
- **mp_roundtime 60** to set round time to 1 hour, ideal to practice grenades.
- **mp_maxmoney 60000** to extend the money limit.
- **mp_startmoney 60000** to start with $60,000 and buy multiple grenades or weapons.
- **mp_buytime 9**999 to buy whenever you want.
- **mp_buy_anywhere 1** to buy wherever you want.
- **mp_freezetime 0** to remove the freeze time before round starts.
- **sv_grenade_trajectory 1** to see the trajectory of your grenades.
- **sv_grenade_trajectory_prac_pipreview 1** to see where your grenade will land.
- **mp_restartgame 1** to restart round.
- **bot_add** to add bots to the server.
- **bot_kick** to remove bots to the server.
- **bot_add_t** to add Terrorist to the server.
- **bot_add_ct** to add Counter-Terrorist to the server.
- **bot_place** to place a bot at the exact location where your crosshair is watching.
- **bot_stop 1** to stop bot moving. Set to 0 to let the bots move again.
- **bot_crouch 1** to make the bots crouch. Set to 0 to disable.


<Button href={`${props.appURLs.CDN_PLAIN}/blitz/cs2/warmup.cfg`}>Download .cfg file</Button>

Once downloaded, **copy and paste warmup.cfg** in this folder (default CS2 install) or right-click on CS:GO in **Steam library** and select **Properties** and in the **Local Files** nav item click **Browse** to open the installation folder:

```
C:\SteamLibrary\steamapps\common\Counter-Strike Global Offensive\game\csgo\cfg
```

Then in the console, execute the following command:

```
exec warmup
```
</Card>
