import React, { useEffect, useRef } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { writeLocalState } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { useCurrentAccount } from "@/app/util.mjs";
import {
  CURR_SEASON_PROMO,
  DISMISSED_KEY,
  EVENT_SEASON_REVIEW_PROMO_CLICK,
  MIN_QUEUE_GAMES,
  SUPPORTED_QUEUES,
} from "@/game-lol/constants/season-review.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import { Promo } from "@/game-lol/SeasonReview.jsx";
import {
  shouldShowPromo,
  useSeasonAggregate,
} from "@/game-lol/utils/season-review.mjs";
import { getDerivedId } from "@/game-lol/utils/util.mjs";
import FullModal from "@/shared/FullModal.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function SeasonReviewPromoModal({ name, region }) {
  const { lol, localState, volatile } = useSnapshot(readState);
  const derivedId = name && getDerivedId(region, name);
  const profile = lol.profiles[derivedId];

  const modalRef = useRef();

  const hidePromo = !shouldShowPromo();
  const hasBeenDismissed = localState[DISMISSED_KEY];
  const isCMPBannerVisible = volatile.isCMPBannerVisible;

  const seasonReviewStats = useSeasonAggregate(region, name, CURR_SEASON_PROMO);
  const hasEnoughGames =
    seasonReviewStats.totalGames >= MIN_QUEUE_GAMES * SUPPORTED_QUEUES.length;

  const currentAccount = useCurrentAccount(GAME_SYMBOL_LOL);
  const isSameAccount =
    currentAccount &&
    currentAccount.gameName === profile?.riotAccount?.gameName;

  const shouldShowModal =
    !hasBeenDismissed &&
    !hidePromo &&
    isSameAccount &&
    !isCMPBannerVisible &&
    hasEnoughGames;

  useEffect(() => {
    if (!shouldShowModal) return;
    modalRef.current.showModal();
  }, [shouldShowModal]);

  if (hidePromo) return;

  return (
    <FullModal
      ref={modalRef}
      onModalClose={() => {
        writeLocalState([DISMISSED_KEY], true);
      }}
    >
      <div style={{ width: 672 }}>
        <Promo
          modal
          profile={profile}
          link={`/lol/season-review/${region}/${name}/${CURR_SEASON_PROMO}`}
          onClickLink={() => {
            writeLocalState([DISMISSED_KEY], true);
            eventBus.emit(EVENT_SEASON_REVIEW_PROMO_CLICK, {
              isModal: true,
              url: `/lol/season-review/${region}/${name}/${CURR_SEASON_PROMO}`,
            });
          }}
        />
      </div>
    </FullModal>
  );
}

export default SeasonReviewPromoModal;
