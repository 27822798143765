import { EVENTS } from "@/__main__/ipc-core.mjs";
import jsonData from "@/feature-replay-blitz-message/fixtures/apex-ftdm-data.json";
import jsonDeploy from "@/feature-replay-blitz-message/fixtures/apex-ftdm-game-deploy.json";
import jsonStart from "@/feature-replay-blitz-message/fixtures/apex-ftdm-game-start.json";
import jsonPlayer from "@/feature-replay-blitz-message/fixtures/apex-player.json";
import {
  eventReplayerExecQueue,
  replaceWith,
} from "@/feature-replay-blitz-message/utils.mjs";

export default function playApexMixTapeFTDMMatch(): Promise<void> {
  const now = Date.now();
  const matchId = btoa(`match-${now}`);
  const replaceArgs: Array<[string, string | number]> = [
    ["317ed07f-a463-58f4-80a8-686aa549c1b9", matchId],
    ["1733360066", now / 1000],
  ];
  // @ts-ignore
  const [start, deploy, data] = replaceWith(replaceArgs, [
    jsonStart,
    jsonDeploy,
    jsonData,
  ]);
  return eventReplayerExecQueue(
    [
      [EVENTS.APEX_IS_RUNNING, true],
      [EVENTS.APEX_PLAYER, jsonPlayer],
      [EVENTS.APEX_GAME_START, start],
      [EVENTS.APEX_GAME_DEPLOY, deploy],
      [EVENTS.APEX_GAME_END, ""],
      [EVENTS.APEX_DATA, data],
      [EVENTS.APEX_IS_RUNNING, false],
    ],
    4000,
  );
}
