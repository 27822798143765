import { appURLs } from "@/app/constants.mjs";

Overpass is set in a modern urban park environment, complete with underpasses, bridges, and interactive elements like the movable truck. Bombsite A's open area contrasts sharply with Bombsite B's more enclosed space, defined by tunnels and narrow entrances. The diverse vertical gameplay and graffiti-decorated walls make Overpass unique among CS:GO maps.

**Bombsite A:** Open and surrounded by stairs and elevated platforms, Bombsite A allows for multi-level combat. The bank and truck provide cover, and the positioning near the park adds visual appeal.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/overpass_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/overpass_a_site_2.webp`} />
</div>

**Bombsite B:** Nestled under an overpass and characterized by its unique graffiti, Bombsite B encourages close encounters, with water and pillars adding tactical complexity.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/overpass_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/overpass_b_site_2.webp`} />
</div>
