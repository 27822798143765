**March 13, 2019**
- Price reduction for some shotguns: Nova ($1050), Sawed-Off ($1100), MAG-7 ($1300).

**February 8, 2018**
- Enabled shotgun spread patterns on official matchmaking servers as described in https://blog.counter-strike.net/index.php/holiday-spread/

**July 1, 2014**
- Penetration Power increased
- Pellets reduced to 8
- Individual pellet damage increased to 30
- Reduced maximum range slightly

**May 8, 2013**
- Reduced cost to $1800.

**May 1, 2013**
- Increased cycletime. Increased cost.

**October 1, 2012**
- Reduced all shotguns' price by $300.
