import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs, FALLBACK_IMAGE_URL } from "@/app/constants.mjs";
import { isPBE } from "@/game-lol/utils/util.mjs";
import { companionDirectoryReplace } from "@/game-tft/constants.mjs";
import { ProfileOverview } from "@/game-tft/ProfileOverview.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile, { UnknownPlayerHeader } from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import RiotIDHeaderTitle from "@/shared/RiotIDHeaderTitle.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Profile() {
  const {
    parameters: [region, fullName],
  } = useRoute();
  const [gameName, tagLine] = fullName.split("-");
  const {
    tft: { accounts_v2, matchLists_v2, matches_v2 },
  } = useSnapshot(readState);
  const { t } = useTranslation();
  const account = accounts_v2[fullName];
  const error = account instanceof Error;
  const latestMatch = matches_v2[matchLists_v2[fullName]?.[0]];
  let avatar;
  if (latestMatch && !(latestMatch instanceof Error)) {
    const participant = latestMatch.info.participants.find((participant) => {
      return (
        participant.BLITZ_riot_account.account.game_name.toLowerCase() ===
        gameName.toLowerCase()
      );
    });
    if (participant) {
      avatar = companionDirectoryReplace(
        StaticTFT.getCompanionIcon(participant.companion.content_ID),
      );
    }
  }

  if (isPBE(region)) {
    return (
      <ProfileLayout>
        <PageHeader
          imageRound
          title={RiotIDHeaderTitle({ gameName, tagLine })}
          image={`${appURLs.CDN}/0x90/blitz/companions/tooltip_tft_avatar_blue.png`}
          accentText={account?.summoner?.summoner_level}
        />
        <Container>
          <Card
            style={{ display: "grid", placeContent: "center", minHeight: 320 }}
          >
            <h1>
              {t(
                "lol:pbeProfilesNotSupported",
                "Blitz Profiles are not supported on the PBE",
              )}
            </h1>
          </Card>
        </Container>
      </ProfileLayout>
    );
  }

  return (
    <ProfileLayout>
      {!error ? (
        <PageHeader
          imageRound
          title={RiotIDHeaderTitle({ gameName, tagLine })}
          image={avatar ?? FALLBACK_IMAGE_URL}
          accentText={account?.summoner?.summoner_level}
        />
      ) : (
        <UnknownPlayerHeader />
      )}
      <Container>
        <SharedProfile>
          <ProfileOverview account={account} />
        </SharedProfile>
      </Container>
    </ProfileLayout>
  );
}

export function meta([_region, name]) {
  return {
    title: [
      "tft:meta.profile.title",
      "{{name}}'s TFT Performance Overview",
      { name },
    ],
    description: [
      "tft:meta.profile.description",
      "{{name}}'s TFT profile and how they perform.",
      { name },
    ],
  };
}

export default Profile;
