import getData from "@/__main__/get-data.mjs";
import type { PlayerStats } from "@/data-models/csgo-player-stats.mjs";
import CsgoPlayerStats from "@/data-models/csgo-player-stats.mjs";
import * as API from "@/game-csgo/api.mjs";
import { GAME_MODES } from "@/game-csgo/constants.mjs";
import { getModeParams } from "@/game-csgo/utils.mjs";

const GAMEMODE_COMPETITIVE = GAME_MODES["0_1_24"];

export default async function fetchPlayerStats(
  steamId: string,
): Promise<PlayerStats> {
  const playerStats = await getData(
    API.getPlayerStats({
      steamId,
      ...getModeParams(GAMEMODE_COMPETITIVE.id),
    }),
    CsgoPlayerStats,
    ["csgo", "playerStats", steamId, GAMEMODE_COMPETITIVE.id],
  );
  return playerStats;
}
