**January 23, 2014**
- Added collision to top of trees
- Added more accurate model collision
- Removed particle effects that could be mistaken for grenades
- Blocked a sightline in battlements near A
- Blocked a hiding hole in underpass near A
- Removed light models near statue at B that could be mistaken for player heads
- Removed a portapotty at back of B
- Raised height of center fence near B
- Fixed various minor bugs

**May 28, 2014**
- Blocked long sightline from back of Bombsite B
- No longer possible to plant on top of hut at bombsite A
- Made statue base at bombsite B taller, so players can't peek over
- Made hut on bombsite B catwalk wider
- Moved all T spawns to upper level
- Decreased fog
- Removed small tree near platform in mid
- Pushed down some vegetation that players could hide in
- Disabled collision on small rocks
- Blocked visibility through cart in mid
- Revised cover in Bombsite B courtyard

**July 1, 2014**
- Added to the Active Duty map group

**July 16, 2014**
- Removed OP flower pot at back of B.
- Added a lip at the top of main B entrance to provide more cover to T team.
- Slightly extended large planter by B entrance to close a thin sight line from back of B to side door.
- Drop-down to alternative B entrance has been widened to allow a better view of the room below.
- Drop-down room roof beams have been moved further away from the stairs.
- Roof of alternative B entrance has been opened up for additional grenade throws.
- Locations where the bomb could be dropped out of players’ reach have been clipped off.
- Lighting changes at some locations to provide better visibility.
- Shifted tapestry in CT sniper room up and made it lighter color to make players more visible against it.

**August 1, 2014**
- Changed terrorist faction to Phoenix

**September 2, 2014**
- Added T spawns to lower position, prioritized them over battlement spawns
- Added ladder from bottom of T spawn to battlement near T sniper tower
- Neutralized corner when moving through archhouse on B upper catwalk
- Neutralized corner when entering B site through connector
- Moved back CT spawns
- Tightened entrance corridor to Long A, T side
- Reduced depth of CT sniper room and made windows more open
- Reconfigured B tunnels for faster T rotation time
- Added path from T side of underpass into B tunnels
- Reduced sightlines from back of B to entrances
- Lowered railing at back of B to make characters on platform easier to spot
- Cut out area behind the toilet in back of B
- Minor lighting/texturing changes at A for better visibility
- Rotated window overlooking connector

**January 14, 2015**
- Fixed sticky spot under roof overhang at B
- Fixed overview map not matching the geometry at Gallery
- Raised ceiling and added trim in Gallery
- Fixed sticky clip brush at A
- Added clip brush to tapestry in CT sniper room so you can’t walk through it
- Fixed floating electrical box in sewer stairwell
- Fixed missing texture above T ramp
- Removed handrail/clips and adjusted lighting in CT sniper hallway
- Fixed wooden platform clipping into stone in T spawn
- Made window bars in B tunnels & Gallery non-solid

**February 4, 2015**
- Major changes to Cobblestone
- Bombsite A:
  - Made bombsite A courtyard smaller
  - Removed spiral stairs at back of bombsite A
  - Pushed forward side-balcony at bombsite A, making it easier to check corners before jumping down
  - Added a static door to entrance to connector from bombsite A
  - Shortened battlement in long A, making it easier to check
  - Raised wall around bombsite A slightly, to give some cover when out in the open
  - Added additional cover in bombsite A
  - Removed balcony at CT side of long A
  - Added an additional chest-height wall at A ramp
  - Removed large boulders in A site
- Bombsite B:
  - Made bombsite B courtyard smaller
  - Rerouted T main entrance to bombsite B
  - Made it easier to throw grenades into B site from a safe position as T
  - Added some cover on T platform in bombsite B
  - Moved bombsite B more to the middle of the courtyard
  - Flattened ground at bombsite B
  - Removed back of B area
  - Hut at back of B can now be shot through
  - Added a static door to entrance to bombsite B from connector
- Other:
  - Removed stairs and overpass route from dropdown room
  - Widened upper dropdown passage
  - Added a ramp to dropdown to make it easier to peek into lower
  - Players can now jump up via flowerbed in connector to get into dropdown room
  - Removed walls in T tunnels
  - Removed CT sniper room
  - Added a static door to CT side of water room
  - Tweaked layout of water room slightly
  - Removed double window room near T spawn
  - Moved T spawns forward a bit
  - Added a direct ladder to T sniper tower
  - Various optimizations
  - Increased player visibility
  - Fixed a bug where very few chickens were spawned

**February 12, 2015**
- Removed various boosts
- Further optimizations
- Tweaked layout of underpass room
- Moved door from CT side to T side of underpass
- Made cubby at entrance to Bombsite A smaller
- Widened arch at end of long A
- Set correct surface types on textures
- Brightened haystacks at entrance to Long A
- Updated collision on broken wall in bombsite A and B

**February 19, 2015**
- Fixed some exploits and unintended boosts.
- Further optimizations.

**March 31, 2015**
- Made cubby near bottom of platform stairs in Bombsite B deeper
- Smoothed out movement around B ramp and dropdown room
- Fixed various spots where C4 could get stuck (Thanks Residents_evil!)
- Fixed some surfaces playing the wrong footstep sounds

**October 8, 2015**
- Fixed some areas where players could look through model backfaces

**March 23, 2016**
- Updated loading screen image

**February 14, 2017**
- Removed support beam in drop room which blocked line of sight to drop/door

**March 15, 2017**
- Raised arch on CT side of long A

**October 23, 2018**
- Removed connector between Long A and Underpass
- Blocked long view angle from Long A CT side towards courtyard
- Decreased height of doors in Underpass
- Added window in Underpass
- Removed slanted door leading into Underpass
- Removed a large section of old Bombsite A courtyard
- Moved up bombsite A zone towards ramp
- Removed access to most of T spawn area
- Moved up T spawn positions
- Added climbable props in dropdown
- Added cover in corridor leading to Bombsite B platform
- Added spooky Halloween theme

**March 19, 2019**
- Cobblestone has been removed from Competitive Matchmaking.
- Cobblestone and Vertigo have been moved into Defusal Group Sigma
