import { GAME_SHORT_NAMES, GAMES_COMING_SOON } from "@/app/constants.mjs";
import { GAME_SYMBOL_MINECRAFT } from "@/app/upcoming-game-definitions.mjs";
import { GAME_SYMBOL_MARVEL_RIVALS } from "@/game-marvel-rivals/definition-symbol.mjs";
import routes from "@/routes/routes.mjs";

const ALLOW_CONTENTFUL = [GAME_SYMBOL_MARVEL_RIVALS, GAME_SYMBOL_MINECRAFT];

export function setup() {
  if (!ALLOW_CONTENTFUL.length || !GAMES_COMING_SOON.length) return;
  for (const gameSymbol of ALLOW_CONTENTFUL) {
    const target = routes.find(
      (i) => i.path === `/${GAME_SHORT_NAMES[gameSymbol]}`,
    );
    if (!target) continue;
    // Todo: Need to overwrite the homepage for allowed games
  }
}

export function teardown() {}
