**June 15, 2022**
- Another attempt at fixing molly through floor bug at mid.
- Removed a position by ramp corner.

**February 1, 2022**
- Fixed molotov/incendiary grenades going through the wall and floor in mid.

**April 23, 2020**
- Fixed gap under box on B site.
- Added ramp-clipping to side of box.
- Fixed some minor graphical bugs.

**April 16, 2020**
- Brightened i-beam textures
- Replaced wooden fence on A site with metal version, decreasing wallbang damage
- Bombsite B:
  - Lowered height of site by 32 units
  - Tightened exit from T stairs
  - Removed “window” entrance from mid, replaced with a raised entrance near CT spawn
  - Flipped railings on stairs to site, to allow jump up from sandbag position
  - Reworked site layout and cover
  - Moved connector from B halls that went under mid, to further towards T spawn
  - Enabled B site in Wingman mode
  - Experiment: In Wingman mode, during Warmup, players will spawn in an 1v1 arena

**November 22, 2019**
- Updated radar for Vertigo.

**November 18, 2019**
- Added ramp to final stage of scaffolding on A ramp, no longer need to jump up
- Connected corridor to back of A with CT elevator room
- Widened top of mid
- Offset doors from CT spawn and to CT elevator room
- Made A site CT death funnel shorter, widened site area
- Removed big block in CT spawn
- Moved A ramp sandbags to allow jump up
- Various bug and clipping fixes

**April 02, 2019**
- Replaced red fence section at top of A ramp (Thanks SPUNJ!)
- Fixed pixel gaps on bombsite boxes
- Raised floor of B stairs pit by 64 units
- Reduced wallbang damage from CT elevator room towards T entrance to mid
- Increased height of crane concrete base on A site
- Improved visibility from B site towards T stairs
- Added cover on right side when exiting stairs to B/exiting window
- Fixed pixel gap from T spawn towards B catwalk
- Fixed slight gaps between tarp sections on scaffolding
- Fixed unintended boost on scaffolding at A ramp
- Blocked weapons from being dropped underneath A ramp
- Blocked weapons from being dropped underneath A site crane
- Blocked players from going on the outside of ibeams in T spawn
- Simplified collision on top of scaffolding models
- Fixed various clipping bugs (thanks bonna97!)

**March 28, 2019**
- Added new cover in bottom of mid, that doubles as a ramp towards “window” to B site
- Pushed up CT spawns slightly
- Prioritized spawn positions, CTs will be biased towards forward spawns, Ts biased towards rear spawns
- Added corner railing to catwalk on B site
- Blocked two-man boost on top of spools in CT spawn
- Blocked angle over wall when going down stairs from B site towards T spawn
- Tweaked T stairs up to bombsite B
- Tweaked layout of T spawn slightly
- Updated bombsite target models on sites
- Elevator shaft injuries now count towards the injury statistic
- Fixed various clipping issues

**March 21, 2019**
- Switched Wingman version to bombsite B
- Made white metal structures on B site slightly larger, to prevent excessive angles/boost positions
- Added some floor material variation for footstep differentiation (CT corridor to back of A, elevator room near A site, T entrance to mid)
- Lowered C4 explosion radius (from 500 to 400)
- Added grenade clip to roof props in forklift room/lower level that allowed molotovs to burn through to upper level
- Extended catwalk by top of T stairs to B
- Added more railings near A ramp
- Fixed various clipping issues and C4 stuck spots (Thanks bonna97!)
- Blocked weapons from falling into gap at bottom of A ramp (Thanks 3kliksphilip!)
- Boosted ambient light in elevator room near A site
- Removed a unintended boost on HVAC fan in B site

**March 19, 2019**
- Vertigo has been added to Competitive matchmaking
- Changed CT entrance to mid, so that CTs have a safer rotation between sites
- Moved forward plywood stack and wooden crate near T side of mid to make T boosts slightly safer
- Smoothed out player collision on metal stairs in T spawn and B site
- Updated soundscapes
- Moved generator in T spawn up to second floor
- Made it slightly easier to throw grenades out of ceiling in T corridor to mid
- Minor tweaks and fixes
