import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";
import parseDatalakeValue from "@/util/parse-datalake-value.mjs";

const DATA_KEY_MAPPER = {
  rank: "rank",
  avg_damage_dealt: "damage",
  avg_accuracy: "accuracy",
  avg_num_elims: "elims",
  avg_headshot_percentage: "headshotPercent",
  num_players: "players",
};

const model = Mapped({
  key: String,
  value: {
    damage: Number,
    elims: Number,
    accuracy: Number,
    headshotPercent: Number,
    players: Number,
  },
});

const columnModel = {
  name: String,
  position: Number,
  typeName: String,
  typeText: String,
} satisfies ModelDefinition;

export type RankStats = FromModel<typeof model>;

const validateFn = createModel({
  data: {
    executeDatabricksQuery: {
      payload: {
        manifest: {
          schema: {
            columns: [columnModel],
          },
        },
        result: {
          dataArray: [[String]],
        },
      },
    },
  },
}).transform(model, (data) => {
  const columns =
    data.data.executeDatabricksQuery.payload.manifest.schema.columns;
  const dataArray = data.data.executeDatabricksQuery.payload.result.dataArray;

  const transformedData: RankStats = {};

  for (const item of dataArray) {
    let rank: string;

    const rankData: RankStats[string] = {
      damage: 0,
      headshotPercent: 0,
      accuracy: 0,
      elims: 0,
      players: 0,
    };

    for (const [index, value] of Object.entries(item)) {
      const column = columns[index];

      if (!(column.name in DATA_KEY_MAPPER)) continue;

      if (column.name === "rank") {
        rank = value;
        continue;
      }

      rankData[DATA_KEY_MAPPER[column.name]] = parseDatalakeValue(
        value,
        column.typeName,
      );
    }

    if (!rank) continue;

    transformedData[rank] = rankData;
  }

  return transformedData;
});

export default validateFn;
