import type { DeepReadonly } from "ts-essentials";

import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Tuple } from "@/__main__/data-model.mjs";

const Translation = () => Tuple([String, String]);

const Point = () => Tuple([Number, Number]);
const LabeledPoint = () => Tuple([Translation(), Point()]);

const mapDataModel = {
  locations: {
    namedLocations: [LabeledPoint()],
    landmarks: [LabeledPoint()],
  },
  spawns: Mapped({
    key: String,
    value: [
      { name: String, subName: String, icon: Number, coordinates: Point() },
    ] satisfies ModelDefinition,
  }),
} satisfies ModelDefinition;

export type MapData = DeepReadonly<FromModel<typeof mapDataModel>>;

export const MapDataValidator = createModel(mapDataModel);

export const wikiData = {
  "battle-royale": MapDataValidator,
  "reload-venture": MapDataValidator,
  "reload-oasis": MapDataValidator,
  og: MapDataValidator,
} as const;
