April 10, 2020
- SG553 – Reduced rate of fire and accuracy to bring the weapon’s value to be more in line with other rifles.

November 18, 2019
- Increased price of SG553 to $3000 to bring its price more in line with its value.

October 9, 2018
- SG553 price reduced to $2750

September 28, 2016
- Increased fidelity and reduced distortion in fire sounds for Famas, Galil, Aug, SG553, M4A4, M4A1-S, unsilenced M4A1-S, and AK47.

July 27, 2016
- Added tracers to alt fire on Aug, Sg, Glock, and Famas.

February 20, 2014
- [AUG and SG553]
  - Scope dot no longer fades too quickly during online play.
  - Scope dot is slightly more visible against bright backgrounds.
  - Scope dot is now tinted using the player’s crosshair color settings.

February 12, 2014
- Reduced AUG and SG553 scoped run speeds
- Improved scope visuals for Aug and Sg553.

February 5, 2014
- Improved SG553 and AUG rates of fire.

June 26, 2013
- Further adjustments to the AUG/SG553.

June 19, 2013
- Tuned the AUG and SG553 to function more effectively as a rifle/sniper hybrid.
