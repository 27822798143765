import React, { useEffect, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useMap } from "react-leaflet";
import { t } from "i18next";
import L from "leaflet";

import { appURLs } from "@/app/app-urls.mjs";
import { useMapData } from "@/game-fortnite/utils/use-wiki-data.mjs";

const latitudeMax = 256;
const longitudeMax = 256;
const world_min = {
  X: 95.0,
  Y: -128,
  Z: 1.0,
};
const world_max = {
  X: 128.0,
  Y: -95,
  Z: 1.0,
};

function fortniteCoordinateToLeaflet([x, y]: readonly [
  number,
  number,
  number?,
]): [number, number] {
  const x1 = (y - world_min.X) / (world_max.X - world_min.X);
  const y1 = (x - world_min.Y) / (world_max.Y - world_min.Y);

  const lng = longitudeMax * y1;
  const lat = latitudeMax * x1 - latitudeMax;

  return [lat, lng];
}

const createCustomMarkerIcon = (
  component: React.ReactElement,
  size = 36,
  className?: string,
) => {
  const html = renderToStaticMarkup(component);
  return L.divIcon({
    html,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    className: `map-icon-wrapper ${className}`,
  });
};

const createCustomTextMarkerIcon = (
  component: React.ReactElement,
  className?: string,
) => {
  const html = renderToStaticMarkup(component);
  return L.divIcon({
    html,
    className: `map-icon-wrapper ${className}`,
  });
};

const CustomMarkerIcon = ({
  iconSrc,
  size = 36,
}: {
  iconSrc: string;
  size?: number;
}) => {
  return (
    <div className="custom-icon">
      <img width={size} height={size} src={iconSrc} />
    </div>
  );
};

const CustomMarkerTextIcon = ({ name }: { name: string }) => {
  return (
    <div className="custom-text-icon">
      <h3 className="custom-label">{name}</h3>
    </div>
  );
};

type CustomLocMarker = {
  name?: string;
  point?: [number, number];
  size?: number;
  iconSrc: string;
};

type CustomLocMarkerText = {
  name: string;
  point?: [number, number];
};

function CustomIconMarker({
  name,
  point,
  size = 36,
  iconSrc,
}: CustomLocMarker) {
  const map = useMap();
  useEffect(() => {
    const icon = createCustomMarkerIcon(
      <CustomMarkerIcon iconSrc={iconSrc} size={size} />,
    );
    const marker = L.marker(point, { icon, riseOnHover: true }).addTo(map);
    if (name) {
      const tooltip = name;
      marker.bindTooltip(tooltip, {
        offset: new L.Point(20, 0),
        direction: "right",
        sticky: false,
        permanent: false,
        className: "type-caption icon-tooltip",
      });
    }

    return () => {
      marker.remove();
    };
  }, [map, name, iconSrc, size, point]);

  return null;
}

function CustomTextMarker({ name, point }: CustomLocMarkerText) {
  const map = useMap();
  useEffect(() => {
    const icon = createCustomTextMarkerIcon(
      <CustomMarkerTextIcon name={name} />,
    );
    const marker = L.marker(point, { icon, riseOnHover: true }).addTo(map);

    return () => {
      marker.remove();
    };
  }, [map, name, point]);

  return null;
}

const landmarkIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/respawn_point.webp`;

export function NamedLocationMarkers() {
  const { t } = useTranslation();
  const mapData = useMapData();
  if (!mapData) return null;
  return mapData.locations.namedLocations.map(([label, point], i) => (
    <CustomTextMarker
      key={i}
      name={t(...label)}
      point={fortniteCoordinateToLeaflet(point)}
    />
  ));
}

export function LandmarkMarkers() {
  const { t } = useTranslation();
  const mapData = useMapData();
  if (!mapData) return null;

  return mapData.locations.landmarks.map(([label, point], i) => (
    <CustomIconMarker
      key={i}
      iconSrc={landmarkIcon}
      name={t(...label)}
      point={fortniteCoordinateToLeaflet(point)}
    />
  ));
}

export function Markers({
  type,
  category,
}: {
  type: "location" | "spawns";
  category: string;
}) {
  const mapData = useMapData();
  if (!mapData) return null;

  const data = mapData[type]?.[category];
  if (!data) return null;

  return data.map((item, i) => {
    const name = Array.isArray(item.name) ? t(item.name) : item.name;

    return (
      <CustomIconMarker
        key={`${category}-${i}`}
        iconSrc={`${appURLs.CDN_PLAIN}/blitz/fortnite/icons/${item.icon}.webp`}
        name={name}
        point={fortniteCoordinateToLeaflet(item.coordinates)}
      />
    );
  });
}

export function AllSpawnMarkers({ toggles }) {
  const mapData = useMapData();
  if (!mapData) return null;

  return (
    <>
      {Object.keys(mapData.spawns)
        .filter((spawnType) => !LINE_MARKERS.includes(spawnType))
        .map((spawnType) => {
          if (!toggles[spawnType]) return null;
          return <Markers key={spawnType} type="spawns" category={spawnType} />;
        })}
    </>
  );
}

const LINE_MARKERS = ["ziplines", "flushers"];

export function AllLineMarkers({ toggles }) {
  const mapData = useMapData();
  const refs = useRef([]);
  const map = useMap();

  useEffect(() => {
    const latlngs = [];

    LINE_MARKERS.forEach((cur) => {
      const result = toggles[cur] && mapData?.spawns?.[cur];
      if (!Array.isArray(result)) return;

      result.forEach(({ coordinates }) => {
        if (Array.isArray(coordinates) && coordinates.length >= 2) {
          latlngs.push({
            coordinates: fortniteCoordinateToLeaflet(coordinates),
            type: cur,
          });
        }
      });
    });

    refs.current.forEach((element) => element.remove());
    refs.current = [];

    latlngs.forEach((item, index) => {
      if (index % 2 === 0 && latlngs[index + 1]) {
        const nextItem = latlngs[index + 1];
        let polyline, outline;

        if (item.type === "ziplines") {
          // Black line with yellow outline
          outline = L.polyline([item.coordinates, nextItem.coordinates], {
            color: "yellow",
            weight: 6,
          }).addTo(map);
          polyline = L.polyline([item.coordinates, nextItem.coordinates], {
            color: "black",
            weight: 4,
          }).addTo(map);
        } else {
          // Blue line with black outline
          outline = L.polyline([item.coordinates, nextItem.coordinates], {
            color: "black",
            weight: 6,
          }).addTo(map);
          polyline = L.polyline([item.coordinates, nextItem.coordinates], {
            color: "blue",
            weight: 4,
          }).addTo(map);
        }

        refs.current.push(outline);
        refs.current.push(polyline);

        const startMarker = L.circleMarker(item.coordinates, {
          radius: 5,
          color: item.type === "ziplines" ? "yellow" : "black",
          fillColor: item.type === "ziplines" ? "black" : "blue",
          fillOpacity: 1,
          weight: 2,
        }).addTo(map);
        refs.current.push(startMarker);

        const endMarker = L.circleMarker(nextItem.coordinates, {
          radius: 5,
          color: item.type === "ziplines" ? "yellow" : "black",
          fillColor: item.type === "ziplines" ? "black" : "blue",
          fillOpacity: 1,
          weight: 2,
        }).addTo(map);
        refs.current.push(endMarker);
      }
    });

    return () => {
      refs.current.forEach((element) => element.remove());
      refs.current = [];
    };
  }, [map, mapData?.spawns, toggles]);

  return null;
}
