### Patch Highlights

<Card>
<blockquote>
Wake up with a fresh cup of Patch 13.21!
<br />
<br />
A large portion of this patch is a follow-up of 13.20. Overall, we hit our major goals for the changes—games are much less snowbally, players are fighting even more, and games are going only slightly longer on average. That said, late-game champions have been winning more than early-game champions, which means some re-balancing. The jungle changes have put junglers back on equal footing with other roles in the early game, but also really hurt AD junglers. So we’re buffing them back up. Lastly, K’Sante’s kit update achieved our goals around opening up his counterplay, but didn’t give him as much power as we wanted, so we’re serving him up some buffs.
<br />
<br />
In addition to follow-up changes, we’re aiming to make Morgana, Seraphine, Brand, and Zyra viable in multiple roles.
<br />
<br />
Also coming this patch, is the long awaited return of Nexus Blitz with a few quality of life improvements, a new host of Cafe Cuties ready to serve up some Pentakills (but not you Lulu, please don’t secure all my kills), a new rotation for the Mythic Shop, and finally another batch of ARAM balance adjustments. Don’t forget about the Worlds Clash that will be happening in this patch as well!
</blockquote>

<img src="https://images.contentstack.io/v3/assets/blt731acb42bb3d1659/bltfdb4b165f6ede727/6531d072d5a1c42809b4ad0d/LOL_13.21_Patch-Highlights_TW_1920x1080.png" />
</Card>

### The Return of Nexus Blitz
<Card>
<blockquote>
Nexus Blitz is finally back! The last time Nexus Blitz launched was back in patch 11.11. Since then, there’s been a slew of sweeping changes to League’s gameplay, as well as some under-the-hood tech changes that needed to be made to bring the mode back. We’ve been working behind the scenes to bring this fan favorite back, so we hope you enjoy it!
</blockquote> 
</Card>

<br/>

<Card>
#### Champion Balance Reset
<blockquote>
The Champion Balance Buff percent adjustments have been reset for all champions. From numerous item/champion reworks to the durability update, a lot has happened since the last time Nexus Blitz was live. We’re taking this opportunity to reset the balance and establish a new baseline. Don’t worry though, if certain champions get out of hand… we will find them… and we will nerf them. Or buff them.
</blockquote>
</Card>

<br/>

<Card>
#### You all get Jungle Pets!
<blockquote>
Jungle Pets have completed their migration from Summoner’s Rift to Nexus Blitz. Monsters here seem to be a little more nutritious, which means pets only need half the amount of stacks to level up! Here’s a list of differences between Summoner’s Rift and Nexus Blitz:
</blockquote>
- Stacks required for upgrade: 20 ⇒ 10 for stage 2, 40 ⇒ 20 for stage 3
- Players no longer obtain bonus treats after fully upgrading their companion
</Card>

<br/>

<Card>
#### Event Spawn Changes
<blockquote>
Event spawns have been a pain point on Nexus Blitz since its release. Previously, it was rare, but possible, to be behind in power but still have the events favor the winning team. The goal of this change is to improve the feeling of fairness for events. Additionally, Loot Veigar and Teemo will no longer wander into the top side of the enemy jungle until a team is behind and needs a little extra defense.
<br />
<br />
Event spawns for the following events will always spawn neutrally or favor the losing team:
</blockquote>
- Loot Teemo
- Loot Veigar
- All variants of Bardle Royale
- King of the Hill
- DPS Race
- **Small Event Bugfix** Prize Fight and URF Deathmatch should now properly reset ability Cooldowns at the start and end of each round.
</Card>

<br/>

<Card>
#### Champions

##### Akshan
<blockquote>
Akshan’s soul saving shenanigans didn’t do much for his allies when they had to traverse a map full of fire to make it back to the fight in the middle of a deathmatch. To compensate for the lack of travel time, his teammates revive with significantly reduced HP. If you die outside the edge of the closing ring in Bardle Royale though, you better hope you can outrun the fire!
</blockquote>
- During events with a ring of fire (Bardle Royale, URF Deathmatch, Prize Fight), killing scoundrels will revive teammates with 30% of their maximum health at the location of their death.
---

##### Bel’Veth
<blockquote>
Bel’Veth only having access to a single Epic Void Coral from Rift Herald kept her from reaching her Void Empress status even a single time in many games. Tying it into turret kills like ARAM presented its own problems, as it was a nearly guaranteed trigger that caused games to quickly snowball out of control. Having Enhanced Void Coral drop off of the enemy jungle guardian gives her a high risk, high reward opportunity to reach her True Form. 
</blockquote>

- Enemy jungle guardians drop an Enhanced Void Coral

---

##### Ivern
<blockquote>
Ivern’s unique style of passive jungling presented problems when entering a duo-jungling world. This has led to him being disabled in past releases of Nexus Blitz. Our goal with this change was to let him battle jungle monsters a bit more traditionally. We’ll be keeping a close eye on Ivern to see how this experiment plays out.
</blockquote>

- Ivern is joining the Nexus Blitz fun this time around. Sadly Ivern’s Friend of the Forest Passive has been disabled.
</Card>

<br/>

<Card>
#### Item Adjustments
- **Lifeline Active Cooldown:** 120 seconds ⇒ 60 seconds
- **Spectral Cutlass Active Cooldown:** 60 seconds ⇒ 45 seconds
- **Innervating Locket:** After using an ability, restore 3.8% of missing health and 3% of missing mana over 3 seconds.
</Card>

<br/>

<Card>
#### Jungle Monster Adjustments
<blockquote>
Some balance changes have also been applied to jungle camps to account for the overall increase in damage from the addition of jungle pets.
</blockquote>

- **Scuttler Health:** 2800-9520 ⇒ 3400 - 11,560
- **Ancient Krug Health:** 1300-3055 ⇒ 1650-3878
- **Krug Health:** 600-1410 ⇒ 900-2115
- **Mini Krugs Health:** 50-118 ⇒ 80-188
- **Greater Murk Wolf Health:** 2000-4700 ⇒ 2400-5640
- **Murk Wolves Health:** 600-1410 ⇒ 800-1880
- **Sir Gromp Health:** 2000-4700 ⇒ 2400-5640
- **Crimson Raptor Health:** 1250-2937 ⇒ 1600-3760
- **Raptors Health:** 500-1175 ⇒ 700-1645
- **Red Brambleback Health:** 4000-10,800 ⇒ 4600-12,420
- **Blue Sentinel Health:** 3000-8100 ⇒ 3500-9450
- **Jungle Guardian Health:** 3775-5521 ⇒ 4500-6584
- **Jungle Guardian Attack Damage:** 40-95 ⇒ 40-105
- **Rift Herald Health:** 7500-15,000 ⇒ 8400-16,800
</Card>

### Champions

<Card>
<ChampionHeader championName={"Aurelion Sol"} championKey={"AurelionSol"} summary={"Q damage decreased."} />
<blockquote>
Aurelion Sol was a big winner last patch as snowballing was reduced and as a result, this late-game scaling mage found himself winning many more games. In an effort to rein him in, we'll be nerfing him by targeting his late-game scaling. Since this comes automatically as opposed to being earned by farming stacks or buying ability power, this should tie his late game power more to his early game performance.
</blockquote>
---
**Q - Breath of Light**
- **Magic Damage per Second:** 15/25/35/45/55 (+30-80 (based on level)) (+60% AP) ⇒ 45/60/75/90/105 (+55% AP)
- **Burst Magic Damage:** 40/50/60/70/80 (+20-30 (based on level)) (+35% AP) (+ 3.1% Stardust) percent of target maximum health ⇒ 60/70/80/90/100 (+35% AP) (+ 3.1% Stardust) percent of target maximum health
</Card>

<br/>

<Card>
<ChampionHeader championName={"Bel’Veth"} championKey={"Belveth"} summary={"E cooldown decreased."} />
<blockquote>
Last patch we succeeded in taking our Void Empress down a peg, but we didn’t intend for the nerfs to completely dethrone her. With that in mind, we’re dialing back the nerfs a bit. Now that her E isn’t such a strong early game defensive tool we’re more comfortable giving it some power in the form of a reduced cooldown for more consistent access to this ability. 
</blockquote>
---
**E - Royal Maelstrom**
- Cooldown: 24/22.5/21/19.5/18 seconds ⇒ 20/19/18/17/16 seconds
</Card>

<br/>

<Card>
<ChampionHeader championName={"Brand"} championKey={"Brand"} summary={"Level 1 attack speed increased, attack speed growth increased, base armor increased, armor growth decreased, base health decreased, health growth increased. Passive monster damage increased. E damage decreased."} />
<blockquote>
Our goal with the changes this patch is to provide Brand with viable roles other than just support. Through a mix of stat adjustments and passive tuning we're giving Brand jungle the ability to reliably clear the jungle (plus some extra armor and attack speed to help players). Make sure to take Q at level 1 to make your clear as painless as possible.
<br />
<br />
Meanwhile, Brand mid should enjoy a significant increase in attack speed, which should help with last hitting minions and whittling down turrets any time he gets lane priority. We expect these changes to power up his support play as well, so we're just delivering a love tap to one of his abilities with the goal that all three roles can be viable and fun to play.
<br />
<br />
Also, Brand jungle players: Please take Q at level 1. It has the highest base damage and the lowest cooldown. Q at level 1. You don't even have to max it. Q. at. level. 1.
</blockquote>
---
**Base Stats**
- **Level 1 Attack Speed:** 0.625 ⇒ 0.681
- **Attack Speed Growth:** 1.36% ⇒ 2%
- **Base Armor:** 22 ⇒ 27
- **Armor Growth:** 4.7 ⇒ 4.2
- **Base Health:** 590 ⇒ 570
- **Health Growth:** 102 ⇒ 105
---
**Passive - Blaze**
- **Monster Damage Modifier:** 120% ⇒ 220%
- **Maximum Monster Damage per Tick:** 80 (Note: this is unchanged)
---
**E - Conflagration**
- **Magic Damage:** 70/95/120/145/170 (+ 55% AP) ⇒ **65/90/115/140/165** (+55% AP)
</Card>

<br/>

<Card>
<ChampionHeader championName={"Briar"} championKey={"Briar"} summary={"Attack speed growth decreased. Passive heal if target dies while bleeding increased. W damage decreased."} />
<blockquote>
After the jungle changes last patch, Briar emerged as one of the big winners. We like how she feels to play in the early game, so instead of making adjustments there we’re looking to reduce how well she scales with damage to stop her from snowballing out of control (ironic for a champion that constantly frenzies, right?). We’ve also seen some players trying out Briar in the top lane, so we’re buffing up her passive healing to give her some more reliable access to sustain when not in the jungle.
</blockquote>
---
**Base Stats**
- **Attack Speed Growth:** 2.3% ⇒ 2.0%
---
**Passive - Crimson Curse**
- **Heal if Bleeding Target Dies:** 100% of remaining bleed ⇒ 125% of remaining bleed
---
**W - Snack Attack**
- **Recast Bonus Physical Damage:** 5/20/35/50/65 (+5% AD) (+10% (+4% per 100 bonus AD) of target missing health) ⇒ 5/20/35/50/65 (+5% AD) (+10% **(+3.5% per 100 bonus AD)** of target missing health)
</Card>

<br/>

<Card>
<ChampionHeader championName={"Caitlyn"} championKey={"Caitlyn"} summary={"Headshot damage increased with critical strike chance. R cooldown increased, damage decreased, bonus damage with critical strike chance increased."} />
<blockquote>
Caitlyn’s lethality builds have started to catch on which leads to some pretty frustrating and non-interactive gameplay for her opponents—not much you can do to avoid her full lethality ultimates when she’s continually casting it on cooldown and you don’t have a tank to block it. This set of changes is meant to lower the potency and frequency of her ultimate when building full lethality without significantly affecting its role when Cait is building crit.
</blockquote>
---
**Passive - Headshot**
- **Bonus Physical Damage:** 60/90/120% (+ (81.25% Critical Strike Chance) * (100% Critical Strike Damage) ⇒ 60/90/120% (+ **(85% Critical Strike Chance)** * (100% Critical Strike Damage)
---
**R - Ace in the Hole**
- **Cooldown:** 90/75/60 seconds ⇒ 90 seconds at all ranks
- **Physical Damage:** 300/525/750 (+200% bonus Attack Damage) ⇒ 300/500/700 (+170% bonus Attack Damage)
- **Bonus Damage from Critical Strike Chance:** 0-35% ⇒ 0-50%
</Card>

<br/>

<Card>
<ChampionHeader championName={"Dr. Mundo"} championKey={"DrMundo"} summary={"E bonus attack damage decreased."} />
<blockquote>
Dr. Mundo benefitted a bit too much from our adjustments last patch which calls for some nerfs to the good doctor. We’re mainly looking to lower his damage which is a little too strong and allows us to maintain the “going where he pleases” gameplay style that his signature tankiness and durability support.
</blockquote>
---
**E - Blunt Force Trauma**
- **Passive Bonus Attack Damage:** 2/2.5/3/3.5/4% maximum health ⇒ 2/2.25/2.5/2.75/3% maximum health
- **Bonus Attack Damage based on Missing Health:** 0-60% ⇒ 0-40%
</Card>

<br/>

<Card>
<ChampionHeader championName={"Hecarim"} championKey={"Hecarim"} summary={"AD growth increased. W duration increased."} />
<blockquote>
Hecarim lost a lot of power from the Gustwalker nerfs last patch so we need to get some more pep into his gallop. We're aware that a new Youmuu's Ghostblade build is taking off, which will increase his overall power level as players adopt it, but we want to focus on strengthening his identity as a fighter. With substantially more base attack damage introduced in this patch, he'll be significantly stronger with a variety of fighter items like Ironspike Whip and Sheen. Additionally, we’re giving him more time to play around Spirit of Dread, meaning he won't be as reliant on up-front burst damage.
</blockquote>
---
**Base Stats**
- **Attack Damage Growth:** 3.2 ⇒ 3.7
---
**W - Spirit of Dread**
- **Duration:** 4 seconds ⇒ 5 seconds
</Card>

<br/>

<Card>
<ChampionHeader championName={"Jinx"} championKey={"Jinx"} summary={"Passive stacks adjusted."} />
<blockquote>
The Jinx buffs from last patch landed well, but the passive was a bit TOO CRAZY in situations when Jinx was allowed to run uncontested through the enemy base. Taking away her shimmer supply by capping the passive stacks and letting it only stack versus champions should help reduce her ability to end the game at the drop of a hat.
</blockquote>
---
**Passive - Get Excited!**
- **Get Excited Stacks:** All takedowns, epic monster kills, and structures destroyed grant Get Excited! stacks and refresh the buff ⇒ Epic monster kills and destroying structures will grant the first stack of Get Excited! and will refresh the buff, but only champion takedowns can increase the amount of stacks Jinx can get
- **[NEW!] Stacks Cap:** Jinx can now only get 5 stacks of Get Excited!
</Card>

<br/>

<Card>
<ChampionHeader championName={"K’Sante"} championKey={"KSante"} summary={"All abilities except E adjusted. Several bugfixes."} />
<blockquote>
We’re following up on 13.20’s K’Sante changes, which achieved many of the goals we wanted but not the one to make him more powerful, so we’re back to fix that! Not only are we fixing his power level, we’re also fixing a slew of bugs. The primary goal with this patch’s buffs is to significantly amp his power level in All Out to make sure shedding his resists is worth it. Additionally, we’re trying to increase his skill expression by allowing him to hold Path Maker for damage reduction for longer. This will allow him to embrace his most common builds and cap out his Ntofo Strikes cast speed more quickly with less bonus health.
</blockquote>
---
**Passive - Dauntless Instinct**
- **All Out Bonus Damage:** 35% ⇒ 45/60/75% (levels 6/11/16)
---
**Q - Ntofo Strikes**
- **Required Bonus Health for Minimum Cast Time:** 1800 ⇒ 1200
- **Tooltip Update:** Tooltip now always shows Resists/Health needed for cooldown and cast time reductions. All Out tooltip has been simplified and updated.
---
**W - Path Maker**
- **Disruptive Dashes:** W - Path Maker will no longer interrupt K’Sante’s E dash when the channeling starts
- **Maximum Channel Time:** 1.0 seconds ⇒ 1.5 seconds (Note: this will affect both the base and All Out version of W - Path Maker.)
- **Tooltip Update:** All Out tooltip has been simplified and updated.
---
**R - All Out**
- **Healing:** Calculated based on Physical and True Damage dealt to champions ⇒ Calculated based on all damage dealt to champions
- **Q + E Attack Resets:** 0.1 seconds ⇒ Instantaneous
---
**Bugfixes**
- Fixed a bug where K’Sante could occasionally Flash with his 3rd Q cast.
- Fixed a bug where being Charmed or Feared could interrupt K’Sante’s W charge.
- Fixed a bug where K’Sante could left click to cancel his All Out W.
- Fixed a bug where K’Sante could sometimes double cast his W.
- Fixed a bug where R's bonus AD and reduced resists would not dynamically update during All Out.
- Fixed a bug where R's healing would not apply from damage dealt to shields.
</Card>

<br/>

<Card>
<ChampionHeader championName={"LeBlanc"} championKey={"Leblanc"} summary={"W damage increased. E tether completion damage increased."} />
<blockquote>
After the 13.19 adjustments we’ve managed to accomplish our goal of getting LeBlanc off of Statikk Shiv, but her AP builds are currently struggling as a result. We’re looking to increase her scaling damage with these changes, as they directly buff her AP builds which should bring her damage output more in line with other assassins. We’re choosing Distortion and the end of Ethereal Chains because those are the spells with the richest gameplay for her and her opponents, placing LeBlanc’s power into her assassination, not her poke.
</blockquote>
---
**W - Distortion**
- **Magic Damage:** 75/115/155/195/235 (+65% AP) ⇒ 75/115/155/195/235 **(+75% AP)**
---
**E - Ethereal Chains**
- **Tether Proc Damage:** 80/120/160/200/240 (+70% AP) ⇒ 80/120/160/200/240 **(+80% AP)**
</Card>

<br/>

<Card>
<ChampionHeader championName={"Master Yi"} championKey={"MasterYi"} summary={"E cooldown decreased."} />
<blockquote>
Master Yi hasn't been having a good time this past patch due to the AD jungle changes so we'll be giving him back some power. Our goal with these changes is to increase his strength as an auto attacker above all else.
</blockquote>
---
**E - Wuju Style**
- **Cooldown:** 18 seconds ⇒ 14 seconds
</Card>

<br/>

<Card>
<ChampionHeader championName={"Morgana"} championKey={"Morgana"} summary={"W damage increased, damage to monsters increased."} />
<blockquote>
The Morgana changes from last patch accomplished a lot of the goals we had, but didn’t quite increase her power as much as we wanted in the mid lane and the jungle. Adding some more base damage to her W and increasing its damage to monsters should help give these roles a small buff without affecting her as a support too much, as we feel she’s at an appropriate power level in that role.
</blockquote>
---
**W - Tormented Shadow**
- **Magic Damage per Second:** 12/22/32/42/52 (+17% AP) ⇒ 12/23/34/45/56 (+17% AP)
- **Damage to Monsters Modifier:** 165% ⇒ 170%Damage to Monsters Modifier: 165% ⇒ 170%
</Card>

<br/>

<Card>
<ChampionHeader championName={"Rammus"} championKey={"Rammus"} summary={"W damage increased, damage to monsters increased."} />
<blockquote>
Rammus was a huge winner thanks to patch 13.20 for three primary reasons. First, his jungle DPS skyrocketed with pets getting a better armor ratio. Second, with games going later due to the anti-snowballing changes, his early weaknesses became less important. Lastly, he's also performing better than his actual power level due to players typically picking him to counter heavy AD teams, so we should expect Rammus to win more than half of his games even if he'd be theoretically weak. Regardless, he's just winning a bit too hard. This patch, we're aiming to reintroduce some weaknesses back into his early game since his early clear has gotten so much faster.
</blockquote>
---
**Q - Powerball**
- **Magic Damage:** 100/125/150/175/200 (+100% AP) ⇒ **80/110/140/170/200** (+100% AP)
---
**W - Defensive Ball Curl**
- **Bonus Armor:** 35 (+40/50/60/70/80% total armor) ⇒ 30 (+35/45/55/65/75% total armor)
</Card>

<br/>

<Card>
<ChampionHeader championName={"Seraphine"} championKey={"Seraphine"} summary={"Early mana regeneration buffed. Durability shifted from health to armor. Base ability values increased, AP ratios decreased. Cooldowns decreased."} />
<blockquote>
Despite initially creating her as a mid laner, players have overwhelmingly spoken via Champion Select that they enjoy Seraphine Support as her primary playstyle, despite it not being very strong by the numbers. With her current tuning, we can't buff her, as bot lane AP carry Seraphine is about as strong as we’re comfortable with. This patch, we're attempting to bring AP carry, support, and mid Seraphine closer together in power. Our goal is to allow all her builds to be strong and express their strengths in different ways. As always, if we overshoot our changes, we’ll walk some back next patch.
<br/>
<br/>
We've got a few changes here: First, we're aligning her base stats to those of other enchanters, which means having an early reliance on mana regeneration, which encourages her to pick up Spellthief's and other similar support items. By shifting her durability from health into armor, she synergizes better with her own shields and other enchanter items that Seraphine commonly buys like Echoes of Helia.
<br/>
<br/>
Second, we're moving her power budget away from AP ratios and into base power. As supports get less gold than other roles, more reliable base damage tends to help them out a lot. By reducing her cooldowns, she’ll also apply enchanter items like Echoes of Helia and Ardent Censer more reliably.
<br/>
<br/>
Third, we're re-allocating some power toward solo lane specific areas and away from grouped up power. Significantly buffing E's rank-up makes late levels grant more damage. W's heal, which not only heals each champion in the area, but ramps its heal based on the number of nearby champions, is receiving a nerf, which disproportionately affects lanes where she's grouped.
<br/>
<br/>
The end result we’re aiming for is a Seraphine that is a strong support and bot laner with those as her primary roles since that's where most players want to play her. That said, we also have some changes aimed at retaining some of her mid-lane power for the small but dedicated player base that wants to continue to play her there.
</blockquote>
---
**Base Stats**
- **Base Mana Regeneration:** 8 ⇒ 11.5
- **Mana Regeneration Growth:** 1 ⇒ 0.4
- **Base Mana:** 440 ⇒ 360
- **Mana Growth:** 40 ⇒ 50
- **Base Armor:** 19 ⇒ 26
- **Health Growth:** 104 ⇒ 90
- **Attack Speed Growth:** 1% ⇒ 2%
---
**Q - High Note**
- **Cooldown:** 10/8.75/7.5/6.25/5 seconds ⇒ 9/8/7/6/5 seconds
- **Damage:** 55/70/85/100/115 (+45/50/55/60/65% AP) ⇒ 55/80/105/130/155 (+50% AP)
---
**W - Surround Sound**
- **Mana Cost:** 50/60/70/80/90 ⇒ 80/85/90/95/100
- **Cooldown:** 28/26/24/22/20 ⇒ 28/25/22/19/16
- **Shield:** 50/70/90/110/130 (+25% AP) ⇒ 50/75/100/125/150 (+25% AP)
- **Missing Health Heal:** 5/5.5/6/6.5/7% (+0.4% per 100 AP) ⇒ 3/3.5/4/4.5/5%
---
**E - Beat Drop**
- **Mana Cost:** 60/70/80/90/100 ⇒ 60/65/70/75/80
- **Magic Damage:** 60/80/100/120/140 (+35% AP) ⇒ 60/95/130/165/200 (+35% AP)
</Card>

<br/>

<Card>
<ChampionHeader championName={"Tahm Kench"} championKey={"TahmKench"} summary={"Passive Bonus Magic Damage decreased. W Cooldown refund increased."} />
<blockquote>
The frog is clogged, thus we need to uninhibit the ribbit, unload the toad, and unbench the kench (did I miss any?). We want to avoid tuning Tahm in a way that will allow him to stat check his top lane matchups, so instead we’re opting to increase how well his passive scales once he gets some gold in his pockets. Additionally, we’re buffing up his W so that it feels more rewarding when successfully used to commit to engaging onto his opponents.
</blockquote>
---
**Passive - An Acquired Taste**
- **Bonus Magic Damage:** 8-60 (based on level) (+2% AP per 100 bonus health) (+3% bonus health) ⇒ 6-48 (based on level) (+2% AP per 100 bonus health) (+5% bonus health)
---
**W - Abyssal Dive**
- **Cooldown refund on champion hit:** 40% ⇒ 40/42.5/45/47.5/50%
</Card>

<br/>

<Card>
<ChampionHeader championName={"Varus"} championKey={"Varus"} summary={"W damage increased. E slow effect increased."} />
<blockquote>
Varus ended up losing out a bit from the anti-snowballing changes from 13.20 so he's getting a small compensation buff in this patch. We're aiming to keep his on-hit and lethality builds at similar power levels, so he's receiving light buffs that help both play styles. We specifically like that Varus provides some crowd control, so we're playing up that part of his kit. As an extra note, lethality Varus currently looks worse than it actually is as Duskblade is still the most often purchased first item despite Youmuu’s Ghostblade being the better option. So we’re avoiding buffing this build further as we expect it to get stronger as players update their build paths.
</blockquote>
---
**W - Blighted Quiver**
- **Magic Damage:** 7/12/17/22/27 (+35% AP) ⇒ 7/13/19/25/31 (+35% AP)
---
**E - Hail of Arrows**
- **Slow:** 25/30/35/40/45% ⇒ 30/35/40/45/50%
</Card>

<br />

<Card>
<ChampionHeader championName={"Zyra"} championKey={"Zyra"} summary={"Base attack speed increased. Plant damage adjusted. Q damage increased. E damage adjusted."} />
<blockquote>
Zyra has been a strong support for a very long time, but back in my day, she used to be a strong mid laner as well. While we expect Zyra to primarily be a support (which was where players have picked her ever since her release), we're attempting to buff up Zyra mid and jungle as strong alternate roles. Attack speed should help a last-hitting laner do a better job of sieging turrets when left alone in lane (primarily a mid lane phenomenon), while putting power into what mid Zyra maxes first should give her more lane control and better wave clear. Shifting some of her power from level scaling to AP ratios should also help buff her in a way that strengthens her jungle.
</blockquote>
---
**Base Stats**
- **Level 1 Attack Speed:** 0.625 ⇒ 0.681 (Note: Attack Speed Ratio is unchanged)
---
**Passive - Garden of Thorns**
- **Plant Magic Damage:** 20-100 (based on level) (+15% AP) ⇒ 20-88 (based on level) (+18% AP)
---
**PQ - Deadly Spines**
- **Magic Damage:** 60/95/130/165/200 (+60% AP) ⇒ 60/100/140/180/220 (+65% AP)
---
**E - Grasping Roots**
- **Magic Damage:** 60/105/150/195/240 (+50% AP) ⇒ 60/95/130/165/200 (+60% AP)
</Card>

### Items
<Card>
#### Hullbreaker
<blockquote>
Hullbreaker is performing exceptionally well on too many users at the moment, which means it’s approaching dangerous territory. In this patch we’re looking to reduce its ability to stat-check opponents by reducing the amount of Armor and MR it gives, as this makes it too hard to stop a Hullbreaker split pusher in a 1v1.
</blockquote>
- **Bonus Armor and Magic Resistance (Melee):** 10-75 (based on level) ⇒ 10-60 (based on level)
- **Bonus Armor and Magic Resistance (Ranged):** 5-37.5 (based on level) ⇒ 5-30 (based on level)
</Card>

### Runes
<Card>
#### First Strike
<blockquote>
Despite nerfing First Strike along with our other 13.20 rune adjustments, it's still outperforming other options and needs an additional nerf. This is a relatively straightforward nerf that should both reduce the amount of damage this rune provides and the amount of gold it can generate for users.
</blockquote>
- **Bonus Damage:** 8% ⇒ 7%
</Card>

<br />

<Card>
#### Phase Rush
<blockquote>
We nerfed a bunch of keystones in 13.20 but left out Phase Rush, as our primary goal was to reduce gold scaling, not blanket nerfing all runes. Out of concern for Phase Rush becoming stronger than we'd like, we've decided to nerf it for 13.21. We'll only be nerfing this rune for melee users as we're concerned about champions like Garen taking advantage of it to reduce their weaknesses, and also because melee users gain more value out of the rune and are its most prevalent users outside of a handful of other champions which could benefit from keeping the power.
</blockquote>
- **Bonus Movement Speed (Melee):** 30-60% (based on level) ⇒ 25-50% (based on level)
</Card>

### Jungle Sustain Adjustments
<Card>
#### Hullbreaker
<blockquote>
Last patch made meaningful gains in lowering the jungle's power in how games play out, but unfortunately this also resulted in junglers having less fun in the role. That said, we're looking to revert or buff some of the things that were nerfed in 13.20. Our main goal here is to return safety to the jungle. While this means that junglers will have higher health pools when they gank, it's important that they feel like obtaining their primary source of income by farming camps is roughly as safe as laners farming their waves.
<br />
<br />
Speaking more broadly to our goals with how the changes from last patch landed, we're happy that laners have relatively more stats in the early game, that jungle tanks have fallback patterns of farming, that junglers spend a bit more time in their jungle between ganks, and that junglers have fewer role-specific strengths not tied to their champion kits.
</blockquote>
- **Jungle Companion Damage:** 16 (+10% AP) (+20% bonus armor) (+20% bonus magic resistance) (+3% bonus health) ⇒ **15.5 (+12% AP) (+10% bonus AD)** (+20% bonus armor) (+20% bonus magic resistance) (+3% bonus health)
- **Jungle Companion Healing per Second:** 70% of damage dealt, capped at 12-45 (levels 1-12) ⇒ 12-35 (levels 1-10) (Note: This is now flat guaranteed healing instead of a ratio and a cap)
- **Heal on Monster Kills:** 12% Missing Health ⇒ 36-90 (levels 1-10) (up to 2.25x based on missing health)
- **Mana on Monster Kills:** 20% Missing Mana ⇒ 19-87 (levels 1-18) (up to 2.25x based on missing mana)
</Card>

### ARAM Adjustments
<Card>
**Buffs**
- **Ezreal:** 103% Damage Taken ⇒ 105% Damage Taken
- **Kindred:** Total attack speed increased by 2.5%
- **Naafiri:** 100% Damage Taken ⇒ 95% Damage Taken
- **Yone:** 103% Damage Dealt ⇒ 105% Damage Dealt
---
**Nerfs**
- **Briar:** 130% Healing ⇒ 120% Healing
- **Jinx:** 95% Damage Dealt ⇒ 90% Damage Dealt
- **Lillia:** E Cooldown; 14 seconds ⇒ 18 seconds
- **Morgana:** 94% Damage Dealt ⇒ 90% Damage Dealt
- **Milio:** 95% Shielding ⇒ 90% Shielding
- **Nunu & Willump:** 120% Healing ⇒ 110% Healing
---
**Adjustments**
- **Aurelion Sol:** E Cooldown; 12 seconds ⇒ 14 seconds. Ability Haste; -20 ⇒ 0
</Card>

### Worlds Clash
<Card>
<blockquote>
Worlds is here! Team formation for the second weekend of Worlds Clash will be open starting on October 30th and the tournaments will be on November 4th and 5th. Here’s a refresh on the rewards:
<br />
<br />
In addition to the Trophy, Banner, and Logo rewards of other Clash tournaments, Worlds Clash has an additional Loot Capsule as a reward. The Capsule contains the following:
</blockquote>
- For Worlds Clash participants with Premium tickets who finish between 1st - 7th place: (1) Championship Skin Permanent
- For all Worlds Clash participants (Premium and Basic tickets) who finish 1st place: (1) World's Event Capsule
- For Worlds Clash participants with Premium tickets who finish between 8th - 15th place: (1) Championship Skin Shard

Since Worlds Clash is a 16-team bracket instead of the usual 8-team bracket, there will be an additional level of rewards based on number of wins/losses in the orbs and capsules:

- **Basic Ticket:** 0W, 3L: 3 Win XP Boost, 1 Mystery Icon, 1 Clash Logo
- **Basic Ticket:** 1W, 3L: 1 Mystery Emote, 640 Ward Skin, 1 Clash Logo
- **Basic Ticket:** 2W, 2L: 640 Ward Skin, 975 Skin Shard,1 Mystery Icon, 1 Mystery Emote, 1 Clash Logo, 1 Basic Ticket
- **Basic Ticket:** 3W, 1L: 640 Ward Skin, 975 Skin Shard, 1350 Skin Shard, 1 Mystery Icon, 1 Mystery Emote, 1 Chibi Icon, 1 Clash Logo, 1 Basic Ticket
- **Basic Ticket:** [World's Clash ONLY] 4W: 640 Ward Skin, 975 Skin Shard, 1350 Skin Shard, 1 Mystery Icon, 1 Mystery Emote, 1 Chibi Icon, 1 Clash Logo, 1 Basic Ticket, Worlds Orb/Capsule
- **Premium Ticket:** 0W, 3L: 1 Basic Ticket, 500 OE, 750 Skin Shard, 1 Logo
- **Premium Ticket:** 1W, 3L: 1 Basic Ticket, 500 OE, 750 Skin Shard, 975 Skin Shard, 1350 Skin Shard, 1 Logo
- **Premium Ticket:** 2W, 2L: 1 Basic Ticket, 750 OE, 750 Skin Shard, 975 Skin Shard, 1350 Skin Shard, 1820 Skin Shard, 1 Logo
- **Premium Ticket:** 3W, 1L: 1 Basic Ticket, 750 OE, 750 Skin Shard, 975 Skin Shard, 1350 Skin Shard, 1820 Skin Shard,1 Chibi Icon, 10 Mythic Essence, 1350 Skin, 1 Logo
- **Premium Ticket:** [World's Clash ONLY] 4W: 1 Basic Ticket, 750 OE, 750 Skin Shard, 975 Skin Shard, 1350 Skin Shard, 1820 Skin Shard,1 Chibi Icon, 10 Mythic Essence, 1350 Skin, 1 Logo, Worlds Orb/Capsule
</Card>

### Mythic Shop Rotation
<Card>
<blockquote>
In this patch, Soulstealer Vayne is entering the shop off-cycle to account for the availability of her exclusive LPL chromas. We’re doing so to make sure players have a chance to pick her up, similar to the other skins that are receiving chromas. Soulstealer Vayne and chroma will remain in the Mythic Shop until November 28th (partway into 13.23). Soulstealer Vayne will cost 125 ME. The First Decennial chroma plus icon will cost 40 ME. The Tenfold Triumph chroma will be available for direct purchase in the Store for 290 RP.
</blockquote>
---
**Now Available**
- Soulstealer Vayne
- Soulstealer Vayne (First Decennial) chroma + icon
---
**Leaving the Mythic Shop**
- Prestige La Ilusion Renata Glasc
</Card>

### Bugfixes & QoL Changes
<Card>
**QoL Changes**
- The success threshold for an early AFK surrender has been lowered from 100% to 70%.
- The [Premade] ping prefix has been changed to [Party] to match the existing party chat pattern.
- Made adjustments to Coven Nilah’s E+Q combo so that it no longer looks visually similar to Coven Morgana’s Q.

---
**Bugfixes**

- Fixed a bug that caused Fiora’s Passive to ignore Shen’s W dodge.
- Fixed several VFX and SFX bugs that were occurring after Jax’s visual update.
- Fixed a bug that caused Tryndamere’s E hitbox to be much smaller than intended.
- Fixed a bug that caused Singed’s Q to not properly stack Conquer.
- Fixed a bug that caused Ezreal’s VO for First Blood and Epic Monster attacks to not play.
- Fixed a bug that caused Vex’s R projectile to not damage enemy minions or monsters.
- Fixed a bug that caused Milio’s R to not cleanse Renata’s Berserk.
- Fixed a bug that caused Ivern’s W passive to not trigger when attacking epic monsters.
- Fixed a bug that caused Varus’ W buff timer VFX to not display clearly.
- Fixed a bug that caused Viego to reset Crown of the Shattered Queen’s passive time to reset after ending a possession.
- Fixed a bug that caused Fiora’s Passive to ignore Shen’s W dodge.
- Fixed a bug where Cosmic Enchantress Lulu’s Pix used the Base trail VFX from the Enemy POV.
- Fixed a bug where Cosmic Enchantress Lulu’s Whimsy (W) VFX did not follow the target enemy’s movement as intended.
- Fixed a bug where Star Guardian and Pajama Guardian Lulu’s Whimsy (W) on-ground VFX did not follow target Enemy’s movement as intended.
- Fixed a bug where Coven Nami’s Tidal Wave (R) ability's SFX were not entirely audible when the ability was launched from the Fog of War.
- Fixed a bug where Bilgerat Rumble’s Equalizer (R) was missing some VFX.
- Fixed a bug where Super Galaxy Rumble’s Flamesplitter (Q) VFX was getting cut off by the ground.
- Fixed a bug where Super Galaxy Rumble’s Junkyard Titan (P) was missing the red overlay while overheated.
</Card>
