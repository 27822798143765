**May 3, 2021**
- Fixed a clipping issue on window in T lobby
- Centered a lamp that was bugging me

**November 22, 2019**
- Brightened up corner by far vent in A site.

**October 9, 2019**
- Fix for VPhysics Displacement bug
- Removed invisible collision that could block shots between ramp and toxic on Bombsite B

**August 19, 2019**
- Recompiled Nuke to fix several lighting problems on static props.
- April 2, 2019
- Fixed C4 stuck spot outside near silo

**October 3, 2018**
- Made corridor leading to ramp room from T side slightly wider
- Moved doors connected to trophy room slightly
- Changed CT team to FBI

**February 17, 2016**
- Nuke has returned (available in Matchmaking included in the Operation Wildfire map group). For more details, visit: http://counter-strike.net/reintroducing_nuke
- Updated the Nuke loading screen icon.
- Now available in Competitive Matchmaking, and other game modes in the Operation Wildfire Map Group
