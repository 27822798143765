import { appURLs } from "@/app/constants.mjs";

Depicting a rustic European village, Inferno provides a complex battlefield filled with narrow pathways, hidden corners, and strategic choke points. Bombsite A is known for its infamous truck and balcony, while Bombsite B is surrounded by a network of tight alleys. The map encourages team coordination and strategy, with a rich visual design inspired by the Italian countryside.

**Bombsite A:** Surrounded by the arch, truck, and balcony, Bombsite A offers multiple entry points, creating complex defensive and offensive strategies. The blend of open space and cover challenges players to carefully navigate the site.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/inferno_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/inferno_a_site_2.webp`} />
</div>

**Bombsite B:** Encased by stone walls and known for its famous "banana" approach, Bombsite B favors tactical movement and positioning. The fountain and sandbags offer unique cover spots.
<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/inferno_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/inferno_b_site2.webp`} />
</div>
