import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { TextInput } from "clutch/src/TextInput/TextInput";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox";
import { ItemBox } from "@/game-fortnite/components/ItemBox";
import Placement from "@/game-fortnite/components/Placement";
import {
  Modes,
  Queues,
  Seasons,
  Sort,
} from "@/game-fortnite/components/Selects";
import { PROFILE_WEAPON_STAT_COLUMNS } from "@/game-fortnite/constants/statColumns.mjs";
import type { PlayerMatches } from "@/game-fortnite/models/matchlist.mjs";
import type { PlayerWeaponStats } from "@/game-fortnite/models/player-weapon-stats.mjs";
import calcAccuracy from "@/game-fortnite/utils/calc-accuracy.mjs";
import calcHeadshotPercentWithLimits from "@/game-fortnite/utils/calc-headshot-percent.mjs";
import {
  getParams,
  getProfilePageParamsStr,
} from "@/game-fortnite/utils/get-params.mjs";
import getWeaponKey from "@/game-fortnite/utils/get-weapon-key.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import ContentContainer from "@/shared/ContentContainer.jsx";
import type { SORT_DIR } from "@/shared/DataTable.jsx";
import { DataTableExtSort as DataTable } from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import HighlightStatCard from "@/shared-fps/HighlightStatCard";
import TimescaleChart from "@/shared-fps/TimescaleChart";
import { displayRate } from "@/util/helpers.mjs";
import { formatDateTime, getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ItemContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sp-3);
`;

const Container = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
`;

const DataTableStyle = () => css`
  th:has(div.big-column),
  .big-column {
    white-space: nowrap;
    width: var(--sp-25) !important;
  }
`;

const WeaponStatsCards = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sp-4);

  @container content-container (width <= 700px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledFilterBar = styled(FilterBar)`
  input[type="text"] {
    max-width: var(--sp-25);
  }
`;

const TooltipTitle = styled("div")`
  display: flex;
  flex-direction: row;
  gap: var(--sp-1_5);
  align-items: center;
  img {
    width: var(--sp-5);
    height: var(--sp-5);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--br);
    object-fit: cover;
  }
  .placement {
    width: var(--sp-17_5);
  }
  .time-ago {
    color: var(--shade0-75);
  }
`;

const BANNED_WEAPON = "wid_athena_grenade_emp";

function Filters({ searchText, setSearch, sortBy, setSortBy }) {
  const { t } = useTranslation();
  return (
    <StyledFilterBar hiddenItems={undefined} className={undefined}>
      <TextInput
        defaultValue={searchText}
        placeholder={t("fortnite:search.weapons", "Search Weapons")}
        onValueChange={setSearch}
        Icon={SearchIcon}
      />
      <Modes />
      <Queues />
      <Seasons />
      <Sort
        sortBy={sortBy}
        setSortBy={setSortBy}
        cols={PROFILE_WEAPON_STAT_COLUMNS}
      />
    </StyledFilterBar>
  );
}

function ProfileWeapons({ profileId }) {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const state = useSnapshot(readState);
  const { season } = getParams(searchParams, {
    season: state.fortnite.seasons[0]?.id,
  });
  const key = getProfilePageParamsStr(searchParams);
  // @ts-ignore
  const rawStats: PlayerWeaponStats | Error | undefined =
    state.fortnite.playerWeaponStats[profileId]?.[season]?.[key];
  const weapons = useWeaponsFromItems();
  const stats = useMemo(() => {
    return rawStats && !(rawStats instanceof Error)
      ? rawStats.filter(
          (s) =>
            s.weapon.key !== BANNED_WEAPON &&
            weapons[s.weapon.key.toLowerCase()],
        )
      : [];
  }, [rawStats, weapons]);
  // @ts-ignore
  const matchList: PlayerMatches | Error | undefined =
    state.fortnite.playerMatches[profileId]?.[season]?.[key];
  const matches: PlayerMatches = useMemo(() => {
    if (!matchList || matchList instanceof Error) return [];
    return matchList
      .sort(
        (a, b) =>
          new Date(b.match.startedAt).getTime() -
          new Date(a.match.startedAt).getTime(),
      )
      .slice(0, 10)
      .reverse();
  }, [matchList]);

  const cols = useMemo(
    () => [
      {
        display: t("common:weapon", "Weapon"),
        align: "left",
        primary: true,
        key: "weapon",
      },
      ...PROFILE_WEAPON_STAT_COLUMNS.map((c) => ({
        ...c,
        display: t(...c.display),
      })),
    ],
    [t],
  );
  const [sortCol, setSortCol] = useState(1);
  const [sortDir, setSortDir] = useState<SORT_DIR>("DESC");
  const [searchText, setSearch] = useState("");
  const [sortBy, setSortBy] = useState(PROFILE_WEAPON_STAT_COLUMNS[0].key);
  const setSort = useCallback(
    (val) => {
      const sortedColIndex = cols.findIndex((c) => c.key === val);
      setSortBy(val);
      setSortCol(sortedColIndex);
    },
    [cols, setSortBy],
  );
  const sortedColumnIndex = PROFILE_WEAPON_STAT_COLUMNS.findIndex(
    (w) => w.key === sortBy,
  );
  const sortedColumn = PROFILE_WEAPON_STAT_COLUMNS[sortedColumnIndex];
  const totalColumns = PROFILE_WEAPON_STAT_COLUMNS.length;
  const nextColumn =
    PROFILE_WEAPON_STAT_COLUMNS[(sortedColumnIndex + 1) % totalColumns];
  const thirdColumn =
    PROFILE_WEAPON_STAT_COLUMNS[(sortedColumnIndex + 2) % totalColumns];

  const rows = useMemo(() => {
    return stats && !(stats instanceof Error)
      ? stats.map((stat) => {
          const headshotPercentage = calcHeadshotPercentWithLimits(
            stat.headshots,
            stat.hits - stat.headshots,
          );
          const accuracy = calcAccuracy(
            stat.headshots,
            stat.hits,
            stat.totalShots,
          );
          const weapon = weapons[stat.weapon.key.toLowerCase()];
          return [
            {
              display: (
                <ItemContainer>
                  <ItemBox
                    id={weapon.id}
                    name={weapon.displayName}
                    rarity={weapon.rarity.toUpperCase() as Rarity}
                    href={`/fortnite/database/weapon/${getWeaponKey(
                      weapon.displayName,
                    )}`}
                    src={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
                  />
                  <p className="type-callout--bold shade0">
                    {weapon.displayName}
                  </p>
                </ItemContainer>
              ),
              value: stat.weapon.key,
              link: `/fortnite/database/weapon/${getWeaponKey(
                weapon.displayName,
              )}`,
            },
            {
              display: stat.kills,
              value: stat.kills,
            },
            {
              display: displayRate(headshotPercentage, 100),
              value: headshotPercentage,
            },
            {
              display: displayRate(accuracy, 100),
              value: accuracy,
            },
            {
              display: stat.damageDoneToPlayers.toLocaleString(getLocale(), {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }),
              value: stat.damageDoneToPlayers,
            },
          ];
        })
      : [];
  }, [stats, weapons]);

  const statsWithPosition: {
    label: string;
    imageUrl: string;
    value: number;
    position: number;
  }[] = useMemo(() => {
    if (!stats || stats instanceof Error) return [];
    const mappedStats = stats.map((s, index) => {
      return {
        [PROFILE_WEAPON_STAT_COLUMNS[0].key]: s.kills,
        [PROFILE_WEAPON_STAT_COLUMNS[1].key]: calcHeadshotPercentWithLimits(
          s.headshots,
          s.hits - s.headshots,
        ),
        [PROFILE_WEAPON_STAT_COLUMNS[2].key]: calcAccuracy(
          s.headshots,
          s.hits,
          s.totalShots,
        ),
        [PROFILE_WEAPON_STAT_COLUMNS[3].key]:
          s.damageDoneToPlayers + s.criticalDamageDoneToPlayers,
        weapon: s.weapon,
        key: index,
      };
    });
    return mappedStats.map((s) => {
      const weapon = weapons[s.weapon.key.toLowerCase()];
      const result = {
        label: weapon.displayName,
        imageUrl: `${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`,
        value: 0,
        position: 0,
      };
      for (const col of PROFILE_WEAPON_STAT_COLUMNS) {
        const sortedStats = [...mappedStats].sort(
          (a, b) => (b[col.key] as number) - (a[col.key] as number),
        );
        const weaponIndex = sortedStats.findIndex((ss) => ss.key === s.key);
        result[col.key] = {
          value: s[col.key],
          position: weaponIndex + 1,
        };
      }
      return result;
    });
  }, [stats, weapons]);
  const topWeapon = statsWithPosition.find(
    (a) => a[sortedColumn.key].position === 1,
  );
  const timeScaleData = useMemo(() => {
    return matches.map((match) => {
      return {
        key: match.match.gameId,
        tooltipTitle: (
          <TooltipTitle>
            <img
              src={Static.getProfileImage(match.match.gameId, match.character)}
              width="var(--sp-5)"
              height="var(--sp-5)"
            />
            <Placement placement={match.placement} className="placement" />
            <TimeAgo
              date={match.match.startedAt}
              className="time-ago type-caption"
            />
          </TooltipTitle>
        ),
        stats: {
          startedAt: {
            value: new Date(match.match.startedAt).getTime(),
            display: formatDateTime(getLocale(), match.match.startedAt, {
              month: "short",
              year: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }),
          },
          headshotPercentage: {
            value: match.headshotPercentage,
            display: displayRate(match.headshotPercentage, 100),
          },
          kills: {
            value: match.kills,
            display: match.kills.toLocaleString(getLocale()),
          },
          accuracy: {
            value: match.accuracy,
            display: displayRate(match.accuracy, 100),
          },
          damage: {
            value: match.damageDealt,
            display: match.damageDealt.toLocaleString(getLocale()),
          },
        },
      };
    });
  }, [matches]);

  return (
    <>
      <Container>
        <Filters
          searchText={searchText}
          setSearch={setSearch}
          sortBy={sortBy}
          setSortBy={setSort}
        />
      </Container>
      <Container>
        {topWeapon ? (
          <WeaponStatsCards>
            <HighlightStatCard
              label={topWeapon.label}
              stat={topWeapon[sortedColumn.key].value}
              statLabel={t(...sortedColumn.display)}
              image={topWeapon.imageUrl}
            />
            <TimescaleChart
              label={nextColumn.trendGraphTitle}
              position={topWeapon[nextColumn.key].position}
              stat1Label={nextColumn.display}
              stat2Label={sortedColumn.display}
              color="var(--green)"
              id={nextColumn.key}
              data={timeScaleData}
              xField="startedAt"
              yField={nextColumn.key}
              y2Field={sortedColumn.key}
              hidePosition
            />
            <TimescaleChart
              label={thirdColumn.trendGraphTitle}
              position={topWeapon[thirdColumn.key].position}
              stat1Label={thirdColumn.display}
              stat2Label={sortedColumn.display}
              color="var(--yellow)"
              id={thirdColumn.key}
              data={timeScaleData}
              xField="startedAt"
              yField={thirdColumn.key}
              y2Field={sortedColumn.key}
              hidePosition
            />
          </WeaponStatsCards>
        ) : null}
        <DataTable
          cols={cols}
          rows={rows}
          sortDir={sortDir}
          sortCol={sortCol}
          sortColTiebreak={3}
          indexCol
          searchText={searchText}
          searchCol={0}
          className={DataTableStyle()}
          setSelectedSortCol={setSortCol}
          setSelectedSortDir={setSortDir}
        />
      </Container>
    </>
  );
}

export default ProfileWeapons;
