**September 14, 2017**
- The P250 has been adjusted to reward longer-distance engagements, particularly against unarmored opponents.
- Increased base damage
- Reduced armor penetration
- Improved damage at longer distances
- Reduced accuracy while moving

**August 3, 2016**
- Increased fidelity of firing sounds for P250, Five-Seven, Tec-9, CZ75-Auto, and Dual Berettas.
- Added unique reload and distant sounds for P250, Five-Seven, Tec-9, and Dual Berettas.

**December 15, 2015**
- Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details).

**December 8, 2015**
- Increased move inaccuracy on pistols: Dual Berettas, Five-SeveN, Glock-18, P2000, P250, Tec-9, USP-S, CZ75-Auto

**January 8, 2015**
- Reduced P250 ammo from 5 magazines to 3

**February 13, 2013**
- Reduced effective range.
