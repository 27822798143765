**September 14, 2017**
- Slight adjustments favoring accurate players who close the distance to their opponent.
- Increased base damage,
- Reduced damage at longer distances,
- Slightly improved accuracy while firing rapidly,
- Slightly improved accuracy while moving.

**August 18, 2016**
- Increased fidelity and reduced distortion for fire sounds of the Glock-18, P2000, USP-S and Desert Eagle.
- Unique distant, reload and draw sounds for Glock-18, P2000, USP-S and Desert Eagle.

**July 27, 2016**
- Added tracers to alt fire on AUG, SG 553, Glock-18, and FAMAS.

**December 15, 2015**
- Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details).

**December 8, 2015**
- Increased move inaccuracy on pistols: Dual Berettas, Five-SeveN, Glock-18, P2000, P250, Tec-9, USP-S, CZ75-Auto.

**August 22, 2013**
- Reduced damage from 33 to 28.

**February 20, 2013**
- Reduced damage.

**February 13, 2013**
- Increased recoil on burst.

**January 23, 2013**
- The Glock-18 is now a Terrorist only weapon.
