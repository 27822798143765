import { appURLs } from "@/app/constants.mjs";

Train is set in an industrial train yard filled with boxcars and locomotives. Its wide-open bombsites, located near and under train cars, create unique opportunities for both sniping and close-quarters combat. The juxtaposition of the interiors of train cars with the exteriors provides a multi-dimensional playing field that encourages creative strategies.

**Bombsite A:** Set among train cars, Bombsite A offers long sightlines and hiding spots between the carriages. The juxtaposition of open space and tight interiors creates a dynamic play area.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/train_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/train_a_site.webp`} />
</div>

**Bombsite B:** Known for its lower and upper ramp, Bombsite B is also surrounded by trains, providing a blend of cover and open combat zones. The oil train and spools add to the site's complexity.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/train_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/train_b_site_2.webp`} />
</div>

