```js
import { Disclosure } from "clutch/src/Accordion/Accordion.jsx";
```

## Disclosure

```jsx preview
<Disclosure>
  <Disclosure.Button>Who can participate in the event?</Disclosure.Button>
  <Disclosure.Panel>
    This event is only available for players in the United States.
  </Disclosure.Panel>
</Disclosure>
```
