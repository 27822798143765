## Common Components

### Rarity Background

Can be used as component via a prop or referenced directly

```jsx preview="flex gap-3" hideSource
  <RarityBoxIcon rarity="COMMON" />
  <RarityBoxIcon.UNCOMMON />
  <RarityBoxIcon.RARE />
  <RarityBoxIcon.EPIC />
  <RarityBoxIcon.LEGENDARY />
  <RarityBoxIcon.MYTHIC />
  <RarityBoxIcon.EXOTIC />
  <RarityBoxIcon.NEUTRAL />
```

```ts
type Rarity =
  | "COMMON"
  | "UNCOMMON"
  | "RARE"
  | "EPIC"
  | "LEGENDARY"
  | "MYTHIC"
  | "EXOTIC"
  | "NEUTRAL";

type RarityBoxIconProps = {
  rarity: Rarity;
  width?: number;
} & React.SVGProps<SVGSVGElement>;
```

### Weapon Type Icon

```jsx preview="flex gap-3" hideSource
<WeaponTypeIcon type="PISTOL" width={40} />
<WeaponTypeIcon.SHOTGUN />
<WeaponTypeIcon.SMG />
<WeaponTypeIcon.ASSAULT_RIFLE />
<WeaponTypeIcon.SNIPER_RIFLE />
<WeaponTypeIcon.ROCKET_LAUNCHER />
<WeaponTypeIcon.GRENADE />
<WeaponTypeIcon.TRAP />
<WeaponTypeIcon.VEHICLE />
```

```ts
type WeaponType =
  | "PISTOL"
  | "SHOTGUN"
  | "SMG"
  | "ASSAULT_RIFLE"
  | "SNIPER_RIFLE"
  | "ROCKET_LAUNCHER"
  | "GRENADE"
  | "TRAP"
  | "VEHICLE";

type WeaponTypeIconProps = {
  type: WeaponType;
  width?: number;
} & React.SVGProps<SVGSVGElement>;
```

### ItemBox

```jsx preview="flex gap-3" hideSource
  <ItemBox id="anvil_rocket_launcher" name="Anvil Rocket Launcher" rarity="EPIC" />
  <ItemBox id="apple" name="Apple" rarity="NEUTRAL" />
```

```ts
type ItemBoxProps = {
  id: string;
  name: string;
  rarity: Rarity;
  className?: string;
  width?: number;
}
```
