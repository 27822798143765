import React from "react";
import { Trans } from "react-i18next";
import { styled } from "goober";

import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import InfoTag from "@/inline-assets/info-tag.svg";
import { classNames } from "@/util/class-names.mjs";

const Container = styled("div", React.forwardRef)`
  &.${OVERLAY_IDS.valLoadingMatchInfo} {
    position: absolute;
    top: var(--sp-5e);
    left: var(--sp-5e);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--shade0-75);
  background: var(--shade10-75);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  padding: var(--sp-2_5e);
  width: max-content;

  .info-tag {
    fill: var(--shade0-75);
    width: var(--sp-4e);
    margin-right: var(--sp-1_5e);
  }

  &.${OVERLAY_IDS.valAgentSelectInfo} {
    position: relative;
    margin-left: var(--sp-15e);
    margin-top: var(--sp-12e);
  }

  .modifier {
    background: var(--shade2-15);
    text-transform: capitalize;
    border-radius: var(--br);
    padding: 0 var(--sp-1e);
    color: var(--shade0-50);
    font-size: var(--sp-3e);
  }
`;

const Info = ({ overlayId }) => {
  return (
    <Container className={classNames(overlayId).className}>
      <InfoTag className="info-tag" />
      <p className="type-caption--bold">
        <Trans i18nKey="val:loadingScreen.toggleMouse">
          Press <span className="type-form--shortcut modifier">ENTER</span> to
          toggle mouse cursor
        </Trans>
      </p>
    </Container>
  );
};

export default Info;
