There is a default way to get data and put it into the app state, using the `getData` function. It actually combines a few things which are all required:

- Either a URL, object, or function that returns a promise must be provided.
- A data model validator function.
- The path to write to.
- Options.

For example,

```js
await getData(
  `${appURLs.UNKNOWN_BACKEND}/profile/${id}`,
  UnknownProfileModel,
  ["unknown", "profiles", id],
  {
    /* options */
  },
);
```

The function call must be in a route's `fetchData` method, **not** from a component. See reasoning below.

## Why only from `fetchData`?

We use the router to instrument a lot of things, such as:

- Data fetching for SSR
- Automatic memory management, so that stale keys get removed safely
- Ensuring that all of the data is loaded for a given route, including other catch-all routes that may match

To provide these guarantees, `getData` must be called from within `fetchData` from a route, there should be very rare exceptions. This is similar to rules like how React hooks must be invoked in the same order unconditionally.

## Data model validation

A data model must be supplied, the purpose is so that we can _force the data into the defined shape to prevent runtime errors_ and also warn on missing data.

## Write state path

**The path to write to should almost always include a primary key.** For example,

```js
["lol", "profiles", derivedId];
```

The only exceptions may be static or aggregate data.

## Options

### `shouldFetchIfPathExists`

There is one rule regarding usage of this option, if the data is not expected to change it should be `false`, otherwise it defaults to `true`.

Examples of immutable data include past matches, static data (caveat: must have a version).

### `mergeFn`

Used to merge data with the data from the current path if it exists.

### `expiryTime`

Used to set a custom expiry time, useful for data which should last longer than the default expiry time, such as locally generated data.

### `skipSafetyCheck`

Ideally this should not be used, as the name implies, it can make the app unsafe or leak memory. This is used only as an escape hatch if there is no way around the safety checks.

---

## Post data

There is a `postData` function which wraps `getData`, which mainly changes the method to `POST` as well as pre-filling the path with an `isLoading` symbol during the request. This is truthy so make sure you are checking for this symbol.
