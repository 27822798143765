**August 15, 2017**
- Shipping more changes to pistols with the goal of emphasizing skillful use of the weapons.
- The Five-SeveN has been adjusted to reward tactical positioning and defensive play.
- Slightly improved accuracy when firing several shots rapidly.
- Substantially reduced accuracy while moving.

**February 20, 2013**
- Reduced Five-SeveN and Tec-9 prices.

**January 23, 2013**
- The Five-SeveN is now a Counter-Terrorist only weapon.
