Data models should be re-used to construct GraphQL queries. The `gql` function dynamically populates the fields given the model definition.

Our BE APIs generally have a `whatever-api-url/graphiql` route. On the right is a `SCHEMA` tab with docs on available queries and their shapes/types.

```js
import { model as eventsModel } from "@/data-models/model-user-events.mjs";
import gql from "@/util/graphql-query.mjs";

const getEvents = gql`
  query GetEvents {
    events { ${eventsModel} }
  }
`;
```

## gql + Models = magic

#### Naming

The string directly after `query`/`mutation` in a query is whatever arbitrary value you want. Use it to name the query and make it easily identifiable.

```js
const getEventsAgain = gql`
  query ThisTimesItsPersonal {
    events { ${eventsModel} }
  }
`;
```

#### String Building

Any string can be inserted directly

```js
const isDev = String(IS_DEV);
const getEvents = gql`
  query GetEvents {
    events(testEvents: ${isDev}) { ${eventsModel} }
  }
`;
```

#### Lvl 1 GraphQl fan

The individual queries are not rest endpoints and don't generally have to be treated as separate. Given the schema:

```
paymentPlan(...): PaymentPlan
isEligibleForFreeTrial: Boolean
```

You can hit both with a single query: (This pairs very nicely with using a [afterTransformModel](/docs/guide-data-model) to combine these results)

```js
const getPricing = gql`
  query GetProPricing($paymentMethodId: String, $includeUserInfo: Boolean!) {
    paymentPlan(paymentMethodId: $paymentMethodId) { ${pricingModel} }
    isEligibleForFreeTrial @include(if: $includeUserInfo)
  }
`;
```

#### Lvl 100 GraphQl enjoyer

We still have access to all the power of GraphQl when using `gql` and a model

For the model:

```js
const eventsModel = {
  game: String,
  userPoints: Optional(Number),
  leaderboard: [Optional(leaderboardShape)],
  userLeaderboard: [Optional(leaderboardShape)],
};
```

Using builtin [GraphQl directives](https://dgraph.io/docs/graphql/queries/skip-include/) we can optionally include parts of the query

```js
const getUserEvents = gql`
  query GetEvents($includeUserInfo: Boolean!) {
    events {
      ${gqlParams(eventsModel, {
        userPoints: "@include(if: $includeUserInfo)",
      })}
    }
  }
`;
```

Or access sub-query params:

```js
const getEvents = gql`
  query GetEventLeaderboard($sortBy: String) {
    events {
      ${gqlParams(eventsModel, { leaderboard: "(sortBy: $sortBy)" })}
    }
  }
`;
```

Or alias a field to request it twice with different params:

```js
const getEvents = gql`
  query GetEventLeaderboard($sortBy: String, $userId: String) {
    events {
      ${gqlParams(eventsModel, {
        leaderboard: "(sortBy: $sortBy)",
        userLeaderboard: ": leaderboard(userId: $userId)",
      })}
    }
  }
`;
```
