import {DocsExport} from "@/game-destiny2/components/LoadoutBuilderOverview.jsx";

export const {ItemTarget, Placeholder, SubclassSection, WeaponsSection, ArmorSection, StatsSection} = DocsExport;

## Build Calculator

### Stats Section

```jsx preview="flex column gap-3" hideSource
  <StatsSection />
```

### Item Placeholders

```jsx preview="flex column gap-3" hideSource
  <ItemTarget text="Kinetic Weapon" icon={<Placeholder.Weapon />} />
  <ItemTarget tooltip="With Tooltip" icon={<Placeholder.Aspect />} />
  <ItemTarget text="Right Aligned" icon={<Placeholder.Helmet />} className="right-aligned" />
  <ItemTarget text="Long Name With Text Plz Ellipsize" icon={<Placeholder.Weapon />} />
```

### Subclass Section

```jsx preview="flex column gap-3" hideSource
  <SubclassSection />
```

### Weapons Section

```jsx preview="flex column gap-3" hideSource
  <WeaponsSection />
```

### Armor Section

```jsx preview="flex column gap-3" hideSource
  <ArmorSection />
```