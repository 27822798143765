import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import {
  setupLoadingScreenListeners,
  teardownLoadingScreenListeners,
} from "@/game-val/actions.mjs";
import LoadingScreenPlayers from "@/game-val/components/LoadingScreenPlayers.jsx";
import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import LoadingScreenData from "@/game-val/fixtures/valorant-loading-screen-fixture.json";
import Info from "@/game-val/Info.jsx";
import registerOverlays from "@/util/register-overlays.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  aspect-ratio: 16/9;
  margin: auto;
  overflow: hidden;
  position: relative;
  @media (min-aspect-ratio: 16/9) {
    width: calc(100vh * 16 / 9);
    height: 100%;
    font-size: 1.481vh;
  }
  @media (max-aspect-ratio: 16/9) {
    height: calc(100vw * 9 / 16);
    width: 100%;
    font-size: 0.834vw;
  }
`;

function LoadingScreen() {
  const { searchParams, currentPath, state: routeState } = useRoute();

  useEffect(() => {
    setupLoadingScreenListeners();
    return () => {
      teardownLoadingScreenListeners();
    };
  }, []);

  useLayoutEffect(() => {
    if (!IS_APP && !IS_NODE) {
      // setFeatureFlag("ads", true);
      updateRoute(currentPath, searchParams, {
        gameName: LoadingScreenData.currentMatch.gameName,
        tagLine: LoadingScreenData.currentMatch.tagLine,
        queue: LoadingScreenData.currentMatch.queue,
        players: LoadingScreenData.currentMatch.players,
      });
    }
  }, [currentPath, searchParams]);

  const { players, queue } = routeState;

  const state = useSnapshot(readState);
  const isLoadingScreenEnabled =
    state?.settings?.valorant?.overlays?.isLoadingScreenEnabled;

  const playerTeamId = players?.find((p) => p.isMe)?.teamId;
  const orderedPlayers = useMemo(() => {
    if (!players || players.length === 0) return [];
    if (queue === "deathmatch" || queue === "snowball") {
      return players;
    } else if (players[0].teamId === playerTeamId) {
      return players;
    }

    return [
      ...players.slice(players.length / 2),
      ...players.slice(0, players.length / 2),
    ];
  }, [playerTeamId, players, queue]);

  const isDeathmatch = queue === "deathmatch";
  const isSnowball = queue === "snowball";
  const shouldRender =
    orderedPlayers.length > 0 && playerTeamId && !isDeathmatch && !isSnowball;
  registerOverlays(GAME_SYMBOL_VAL, shouldRender ? ["loadingScreen"] : []);

  if (!shouldRender) return null;

  return (
    <Container>
      {isLoadingScreenEnabled && players?.length > 0 ? (
        <Info overlayId={OVERLAY_IDS.valLoadingMatchInfo} />
      ) : null}
      <LoadingScreenPlayers players={players} queue={queue} />
    </Container>
  );
}

export function meta() {
  return {
    title: [null, "VALORANT - Loading Screen"],
    description: [null, "VALORANT Loading Screen overlay"],
  };
}

export default LoadingScreen;
