We have two kinds of overlays - Native (built using C++) and Electron overlays (built using HTML, CSS, Javascript).

Electron overlays are built using [Electron Off-Screen rendering](https://www.electronjs.org/docs/latest/tutorial/offscreen-rendering). A normal electron BrowserWindow renders html on the window itself. If Off-screen rendering is enabled when creating BrowserWindow object, instead of opening a window, it gives a bitmap of the rendered HTML. This bitmap can be taken and rendered however you want and wherever you want. We use this bitmap to render the web page on the game UI. How we can render something on another application is out of the scope of this guide, it involves some magic using dll injection, game memory manipulation, better explained by someone from native team.

## Implementing a new electron overlay

### In blitz-core

Start with [`gameManager`](https://github.com/theblitzapp/blitz-core/blob/master/src/gameHandlers/gameManager.js) module. Each game that has to render an overlay needs a gameHandler that satisfies the contract specified [here](https://github.com/theblitzapp/blitz-core/blob/master/src/gameHandlers/gameManager.js#L34-L47).

`getOverlayURL` should return the initial URL that the browser window has to load. Customize the initial route according to the game.

### In blitz-app

Overlays have few UI components turned off like network activity indicator, window controls, route error handler, left navigation, search etc. Other than that, the overlay routes are treated same as any other app routes.
Keep a note of the React hook `useIsOverlay` and `isOverlay` function that can be used to customize a component for overlay.

### Scaling the overlay UI

If player's monitor doesn't have the aspect ratio supported by the game, the game may render with pillar box, letter box or might stretch the game UI to fit the window. Lots of variables here and its dependent on game. For Valorant we use [this](https://github.com/theblitzapp/blitz-core/blob/master/src/gameHandlers/valorant/gameSettingsParser.js#L119-L173) logic to figure out which part of the game window is actually used by the game. This logic may not be perfect and may not work in all scenarios, so its a good idea to let the user customize the overlay size/position through settings/let them drag the overlay to their desired position.

A player with 2K monitor can run the game in 1080p resolution in windowed mode. If we simply render the overlay UI like we do on a browser using 'px', it will be too big on the game and can go out of bounds. Depending on the overlay, CSS _**might**_ have to be written specific to a resolution and using CSS transform, scale properties might have to be scaled to a different resolution to match the game UI. Similarly, UI elements might have to be positioned absolutely and the position have to be scaled according to view port (using vw, vh, % or a scaling factor).

### Syncing state with app

// TODO
As overlays are rendered in a separate window, it doesn't have the internal state of the app. So login, user data, settings that are only known to the app window have to be synced on a per-need basis to the overlay window. I am thinking of using feature flags to turn on/off ads. For example, a Pro user logged out, then electron main process from blitz-core would simply send a message to turn off `feature-ads`. Other things like turning off specific overlays can be done by syncing the app state with overlay state.
