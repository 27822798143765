import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import ApexColors from "@/game-apex/colors.mjs";
import { DEFAULT_MATCHES_LIMIT, GAME_MODES } from "@/game-apex/constants.mjs";
import LegendsStats from "@/game-apex/LegendsStats.jsx";
import ApexLiveTile from "@/game-apex/LiveTile.jsx";
import MatchHistoryHeader from "@/game-apex/MatchHistoryHeader.jsx";
import MatchTile from "@/game-apex/MatchTile.jsx";
import staticMediaURLs from "@/game-apex/static.mjs";
import useMatchlist from "@/game-apex/use-matchlist.mjs";
import useApexLast20 from "@/game-apex/useApexLast20.jsx";
import {
  getHeadshotPercent,
  getLastAvgHSList,
  getPlayerStatsByMatch,
} from "@/game-apex/utils.mjs";
import { getSplitRanksByPlayerSeason } from "@/game-apex/utils/get-split-ranks-by-player-season.mjs";
import useParams from "@/game-apex/utils/use-params.mjs";
import WeaponsStats from "@/game-apex/WeaponsStats.jsx";
import {
  MatchList as SharedMatchList,
  MatchTile as SharedMatchTile,
  ShowMoreFooter,
} from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import ProfileRank from "@/shared/ProfileRank.jsx";
import HeadShotStats from "@/shared-fps/HeadShotStats.jsx";
import { useRoute, useTransientRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function ProfileOverview({ profileId }: { profileId: string }) {
  const { searchParams, currentPath } = useRoute();
  const {
    state: { transient },
  } = useTransientRoute();
  const { season: seasonParam, mode: modeParam } = useParams(searchParams);
  const {
    apex: { matches },
  } = useSnapshot(readState);
  const matchlist = useMatchlist(profileId, seasonParam, modeParam);

  const { page, isEndOfMatchList } = useMemo(() => {
    const apiMatches = matchlist.length ? matchlist.filter((m) => m.id) : [];

    const page = Math.max(
      1,
      Math.ceil(apiMatches.length / DEFAULT_MATCHES_LIMIT),
    );

    const isEndOfMatchList = apiMatches.length < DEFAULT_MATCHES_LIMIT * page;

    return {
      page,
      isEndOfMatchList,
    };
  }, [matchlist]);

  const loadMoreMatches = () => {
    updateRoute(currentPath, searchParams, {
      transient,
      page,
      isUpdate: false,
    });
  };

  const { last20Stats } = useApexLast20({
    profileId,
    season: seasonParam,
    mode: modeParam,
  });

  const lastMatchesHitStats = useMemo(() => {
    const { headshotPercentage: headshots = 0, hits } = last20Stats;
    const lastHitStats = {
      headshots: headshots * hits,
      bodyshots: (1 - headshots) * hits,
      chestshots: 0,
      stomachshots: 0,
      legshots: 0,
    };
    return lastHitStats;
  }, [last20Stats]);

  const hitTrend = useMemo(() => {
    if (!matchlist.length) return [];

    const result = [];
    const lastHSList = [];
    const matchesWithHits = [];
    matchlist.forEach((matchObj) => {
      const match = matches[matchObj.apexId];
      const playerMatchStats = getPlayerStatsByMatch(match, profileId);
      const { hits, headshots } = playerMatchStats || {};
      if (hits) {
        lastHSList.push(getHeadshotPercent(headshots, hits));
        matchesWithHits.push({
          ...match,
          playerMatchStats,
        });
      }
    });

    const avgHSList = getLastAvgHSList(lastHSList);

    let count = 0;
    for (const match of matchesWithHits) {
      const { season, playerMatchStats, gameStartedAt } = match;

      if (count >= 20) break;
      const {
        champion: { apexId },
        headshots,
        hits,
        platformId,
      } = playerMatchStats;
      if (!hits) continue;
      const headshotPercent = getHeadshotPercent(headshots, hits);

      result.push({
        id: platformId,
        matchDate: gameStartedAt,
        imgUrl: staticMediaURLs.getLegendImage(apexId),
        hsPercent: headshotPercent,
        lastNAvgHS: avgHSList[count],
        season,
      });
      count++;
    }

    return result;
  }, [matchlist, matches, profileId]);

  return (
    <>
      <ProfileColumn className="sidebar">
        <ProfileRanks
          profileId={profileId}
          seasonParam={seasonParam}
          modeParam={GAME_MODES.RANKED.key}
        />
        <Card padding="0">
          <HeadShotStats
            lastNStats={lastMatchesHitStats}
            matches={hitTrend}
            hideHits
            hiddenLegshots
          />
        </Card>
        <LegendsStats
          season={seasonParam}
          mode={modeParam}
          profileId={profileId}
        />
        <WeaponsStats
          season={seasonParam}
          mode={modeParam}
          profileId={profileId}
        />
      </ProfileColumn>
      <ProfileColumn className="main ad-align">
        {!!matchlist?.length && (
          <MatchHistoryHeader
            matchList={matchlist}
            selectedAccountId={profileId}
          />
        )}
        <ApexLiveTile profileId={profileId} />
        <ProfileMatchlist>
          <SharedMatchList
            // @ts-ignore This file has been renamed from JSX to TSX but wasn't fully converted
            matchList={matchlist}
          >
            {(matchlist ?? []).map((i) => {
              const gameId = i.apexId;
              const seasonId = i.season.id;
              if (!gameId || !seasonId) return null;
              const fullId = `${gameId}|${seasonId}`; // Used in the fetchData to fetch the match (season id is absolutely required)
              const match = matches[gameId];

              return (
                <SharedMatchTile id={fullId} key={gameId} match={match}>
                  {Boolean(
                    !(match instanceof Error) &&
                      match &&
                      getPlayerStatsByMatch(match, profileId),
                  ) && <MatchTile gameId={gameId} profileId={profileId} />}
                </SharedMatchTile>
              );
            })}
          </SharedMatchList>
          {matchlist?.length ? (
            <ShowMoreFooter
              hasMore={!isEndOfMatchList}
              setShowMore={() => loadMoreMatches()}
            />
          ) : null}
        </ProfileMatchlist>
      </ProfileColumn>
    </>
  );
}

const ProfileRanks = memo(function _ProfileRanks({
  profileId,
  seasonParam,
  modeParam,
}: {
  profileId: string;
  seasonParam: string;
  modeParam: string;
}) {
  const { t } = useTranslation();
  const {
    apex: {
      playerStats,
      meta: { seasons },
    },
  } = useSnapshot(readState);
  const playerSeasonStatsByMode = playerStats?.[profileId]?.[seasonParam];
  const currentSeason = seasons?.[seasonParam];
  const splitStats = useMemo(() => {
    const result = getSplitRanksByPlayerSeason(
      playerSeasonStatsByMode,
      modeParam,
      seasonParam,
      currentSeason,
    );
    return result.firstSplit;
    // const { firstSplit, secondSplit } = result;
    // return secondSplit ? secondSplit : firstSplit;
  }, [modeParam, playerSeasonStatsByMode, currentSeason, seasonParam]);

  // Prevent rendering
  if (!splitStats || !playerSeasonStatsByMode) return null;

  const Icon = splitStats.iconLg;
  const tierString = t(splitStats.tKey, splitStats.tDefault, {
    tier: splitStats.rank,
  });

  const queueInfo = GAME_MODES[modeParam] ?? GAME_MODES.ALL;

  return (
    <ProfileRank
      key={modeParam}
      title={t(...queueInfo.t)}
      imageComponent={<Icon />}
      name={tierString}
      color={ApexColors.ranks[splitStats.key]?.fill}
      percentFill={splitStats.rankedRating / 100}
      below={undefined}
      belowTitle=""
      points=""
    />
  );
});

export default ProfileOverview;
