**April 10, 2020**
- AUG – Improved standing accuracy while unscoped. Slightly reduced scoped accuracy.

**June 18, 2019**
- Adjustments to the AUG to bring its utility more in line with other rifles.
- Slightly reduced rate of fire from 666 RPM to 600 RPM.
- Reduced accuracy while unscoped.

**March 13, 2019**
- Now that players have had an opportunity to learn to love the AUG, returning the weapon to its original price of $3300.

**October 9, 2018**
- AUG price reduced to $3150

**September 28, 2016**
- Increased fidelity and reduced distortion in fire sounds for Famas, Galil, Aug, SG553, M4A4, M4A1-S, unsilenced M4A1-S, and AK47.

**July 27, 2016**
- Added tracers to alt fire on Aug, Sg, Glock, and Famas.

**February 20, 2014**
- Scope dot no longer fades too quickly during online play.
- Scope dot is slightly more visible against bright backgrounds.
- Scope dot is now tinted using the player’s crosshair color settings.

**February 13, 2014**
- Aug and SG 553 scope modes now correctly respect players’ zoomed mouse sensitivity settings.

**February 12, 2014**
- Weapon adjustments based on data collected:
- Increased Aug recoil
- Reduced Aug rate of fire
- Reduced Aug and Sg553 scoped run speeds
- Improved scope visuals for Aug and Sg553.
- Aug now has a new firing sound.

**February 5, 2014**
- Weapon balance has been adjusted:
- Improved Sg553 and AUG rates of fire.
- Improved Sg553 and AUG scoped control ( reduced inaccuracy and recoil ).

**July 3, 2013**
- Additional minor buffs to the Aug and Sig.

**June 26, 2013**
- Further adjustments to the AUG/SG553.

**June 19, 2013**
- Tuned the AUG and SG553 to function more effectively as a rifle/sniper hybrid.
