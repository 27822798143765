import { appURLs } from "@/app/constants.mjs";

Set against the rugged backdrop of a Southwestern American town, Tucson serves up a unique blend of traditional Western elements and modern tactical gameplay. From dilapidated saloons to dusty open-air markets, this map provides a rich tapestry of settings for firefights.

**Bombsite A:** Featuring a wild west theme with wooden facades and open courtyards, this bombsite allows for creative utility use and lends itself to both short and long-range engagements.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/tuscan_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/tuscan_a_site.webp`} />
</div>

**Bombsite B:** Enclosed by sturdy buildings and littered with crates and barrels, this interior site puts a premium on close-quarter combat and fast reflexes.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/tuscan_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/tuscan_b_site_2.webp`} />
</div>
