import fetchMatch from "@/game-csgo/fetches/fetch-match.mjs";
import fetchMatchlist from "@/game-csgo/fetches/fetch-matchlist.mjs";
import fetchPlayer from "@/game-csgo/fetches/fetch-player.mjs";
import fetchPlayerStats from "@/game-csgo/fetches/fetch-player-stats.mjs";
import fetchPlayerMapStats from "@/game-csgo/fetches/fetch-player-stats-map.mjs";
import fetchPlayerWeaponStats from "@/game-csgo/fetches/fetch-player-stats-weapon.mjs";
import { getGameModeById } from "@/game-csgo/utils.mjs";
import { devError } from "@/util/dev.mjs";

async function fetchData([profileId, tab], searchParam, state) {
  try {
    await fetchPlayer(profileId);

    const { id: gameModeId } = getGameModeById(searchParam.get("mode"));

    await Promise.all([
      fetchMatchlist(profileId, gameModeId),
      (async () => {
        if (tab) return;
        await fetchPlayerStats(profileId);
      })(),
      (async () => {
        if (tab && tab !== "maps") return;
        await fetchPlayerMapStats(profileId, gameModeId);
      })(),
      (async () => {
        if (tab && tab !== "weapons") return;
        await fetchPlayerWeaponStats(profileId, gameModeId);
      })(),
    ]).catch(() => {});

    // Fetch matches (transient)
    await Promise.all(
      Object.entries(state.transient?.visibleMatches ?? {})
        .filter(([_, isVisible]) => isVisible)
        .map(([gameId]) => fetchMatch(gameId).catch(() => {})),
    );
  } catch (e) {
    devError("CS2: Failed to fetch profile", e);
  }
}

export default fetchData;
