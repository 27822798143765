import { appURLs } from "@/app/constants.mjs";

Located in a nuclear power plant, Nuke offers a multi-level layout with both above-ground and underground playing areas. Bombsite A features a silo, and Bombsite B is located one floor below, offering distinct tactical approaches. Its industrial aesthetics and intricate vent system provide a challenging environment that tests players' spatial awareness.

**Bombsite A:** Featuring the signature silo and multiple entry points, Bombsite A is a maze of industrial machinery and catwalks. The overhead rafters provide vertical play opportunities.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/nuke_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/nuke_a_site_2.webp`} />
</div>

**Bombsite B:** Located a floor below A, Bombsite B is characterized by its tight corridors and vent access. The control room and reactor add to the challenging layout.
<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/nuke_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/nuke_b_site_2.webp`} />
</div>
