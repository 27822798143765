**February 2, 2023**
- Fixed optimization regression

**January 25, 2023**
- Trimmed Ivy at B long.
- Reduced size of trim at B Long.
- Ruins are slightly less ruined.
- Adjusted clipping at Ruins and B long.
- Adjusted shape of Boat in Canal.
- Lowered the water grates at the back of Canal.
- Adjusted grenade clipping around bombsites.
- Minor optimizations.

**December 16, 2022**
- Fixed some spots where guns would fall through the world near canal
- Fixed grenades falling through floor on A site fountain
- Fixed surface properties on models to prevent bullet penetration
- Adjusted grenade clips throughout map
- Adjusted player clipping throughout map
- Reduced size of trim at mid
- Added collision to signs
- Closed various pixel gaps
- Minor optimizations

**November 18, 2022**
- Anubis has been added to Active Duty Group.

**September 27, 2022**
- Added nade clips on mid bridge to prevent nades getting stuck.
- Fixed nade physics on stairs in sewer.
- Fixed boost near canal.
- Added collision to large stone blocks above canal.
- Clipping and visual polish (Thanks Joaokaka1998!).

**September 7, 2022**
- Added a hip cover on the plateau near B main.
- Clipped the large stone cover on CT mid for smoother movement.
- Added a scaffolding on A catwalk to prevent the boost through the window to T stair at the canal. (Thanks poseidonskiy rofls).
- Allowed players to silently drop down from A long.
- Fixed stones colliding with the tarp on A catwalk.
- Removed stone at A sewer.
- Aligned floating pillar on A with the floor.
- Removed left-over clips on A main. (Thanks Hentie!).
- Removed clips on the stairs that bounce off grenades (Thanks Guidetti & dr!zzle).
- Clipping improvements (Thanks dr!zzle!).
- Cleaned up clipping under the bridge (Thanks Fnugz for your sacrifice).

**August 16, 2022**
- Re-added to game.

**May 3, 2021**
- Removed from game.

**February 17, 2021**
- Reskinned the signs and information boards
- Fixed the wallbang through the trim on the mid building between double door and the CT house
- Added some grenade clips to prevent weird grenade bouncing on A heaven

**December 17, 2020**
- Widened A main entrance
- Simplified A connector area
- Got rid of the deep corner below the windows on A
- Got rid of the wall near the pillar at Waterfalls A site and reduced some corners to hide in as a CT
- Some other minor things 3kliksphillip will probably find out

**March 31, 2020**
- Added to Scrimmage along with Chlorine.

**April 10, 2020**
- Moved from Scrimmage to Competitive mode.
- Fixed water reflection issues.
- Fixed all reports regarding clipping and unintended boost spots.
- Improved performance.
