```js
import DataTable from "@/shared/DataTable.jsx";
import { DataTablePaginated } from "@/shared/DataTable.jsx";
```

A data table consists of 2 arrays

```js
// Columns, used in the table header to allow for sorting
const cols = [
  { display: "name" },
  { display: "Win Rate" }
];

// Rows, an array of rows with an array of cells
const rows = [
  [
    { display: "Rio", value: "rio" },
    { display: "58.7%", value: 0.587 }
  ],
  [
    { display: "Dali", value: "dali" },
    { display: "51.5%", value: 0.514 }
  ],
]
```

Note: The columns indexes map to the row cells indexes. Therefore `cols.length === rows[0].length`

### Loading State
If data is loading, `rows` should be `undefined | null`, this will automatically show a loading state.

```jsx preview
<DataTable
  loadingRows={5} // Optional to control the height
  cols={[
    { display: "name" },
    { display: "Win Rate" }
  ]}
  rows={null}
/>
```

### No Results State
If the `rows` array is empty (`[]`), either due to no data being available or due to applied filters, it automatically shows a no result state.

```jsx preview
<DataTable
  loadingRows={5} // Optional to control the height
  cols={[
    { display: "Name" },
    { display: "Win Rate" }
  ]}
  rows={[]}
  noResultsDescription={["", "You can set any no results description here"]}
  noResultsReturnLink={"/docs/component-data-table"}
  noResultsReturnText={["", "Return To Docs"]}
/>
```

## Table Props
```ts
type TableProps = {
  cols: Column[];
  rows: undefined | null | Cell[][];
  tallRows?: boolean; /// Some designs call for a slightly taller row (default 44px, tall 60)
  indexCol?: boolean; /// Insert a column at the front for indexing
  indexColTitle?: string; /// The header title for the above index column. Default is "Rank"
  searchText?: string; /// Text to filter the rows by
  searchCol?: number; /// What column index should be searched? (note: the .value is searched)
  sortCol: number; /// What is the default sort column (if any)
  sortColTiebreak?: number; /// What is the default tie-break sort column (if any)
  sortDir?: "DESC" | "ASC"; /// What is the default sort direction (default "DESC")
  className?: string; /// Class name used on the container of the table
  classNameOuter?: string; /// Class name applied to the outer card of the table (note: this rarely needs to be used)
  style?: React.CSSProperties;
  loadingRows?: number; // Optional to control the height of table while loading
  noResultsTitle?: Translation; // Optional to override the default no results title
  noResultsDescription?: Translation; // Optional to override the default no results description
  noResultsReturnLink?: string; // Optional to set the no results return link
  noResultsReturnText?: Translation; // Optional to display and set the no results return text
  borderless:? boolean; // Optional to remove the border/background from the table so you can place it inside a card or whatever
}
```

## Column Props
```ts
type Column = {
  display: string | React.ReactNode;
  tooltip?: string;
  align?: string; /// "center" | "left" | "right"
  primary?: boolean;
  isStat?: boolean;
};
```

## Cell Props
```ts
type Cell = {
  display: number | string | React.ReactNode; /// What is rendered in the table
  value: number | string; /// The value that is sorted/searched
  className?: string; /// A class name for the cell for styling from the table's class name
  style?: React.CSSProperties;
  link?: string; /// Turns the cell into a link
}
```

## Example Table
```jsx preview
<DataTable
  cols={[
    { display: "name" },
    { display: "Win Rate" },
    { display: "Wins" },
    { display: "losses" },
    { display: "Games" }
  ]}
  rows={[
    [
      { display: "Rio", value: "rio" },
      { display: "81.8%", value: 0.8125 },
      { display: 13, value: 13 },
      { display: 3, value: 3 },
      { display: 16, value: 16 }
    ],
    [
      { display: "Dali", value: "dali" },
      { display: "78.3%", value: 0.7826 },
      { display: 18, value: 18 },
      { display: 5, value: 5 },
      { display: 23, value: 23 }
    ]
  ]}
  indexCol
  className="meat-slicer"
  sortCol={1} // Win Rate
  sortColTiebreak={4} // Games
  sortDir="DESC"
  searchText={null}
  searchCol={0}
/>
```

# Paginated Table

## Table Props

```ts
type PagedTableProps = {
  pageSize: number; /// loadingRows defaults to this as well
  onPage(): void; /// Called when the table is paged
} & TableProps;
```

## Example Table

```jsx preview hideSource
<DataTablePaginated
  cols={[
    { display: "name" },
    { display: "Win Rate" },
    { display: "Wins" },
    { display: "losses" },
    { display: "Games" }
  ]}
  rows={[
    [
      { display: "Rio", value: "rio" },
      { display: "81.8%", value: 0.8125 },
      { display: 13, value: 13 },
      { display: 3, value: 3 },
      { display: 16, value: 16 }
    ],
    [
      { display: "Dali", value: "dali" },
      { display: "78.3%", value: 0.7826 },
      { display: 18, value: 18 },
      { display: 5, value: 5 },
      { display: 23, value: 23 }
    ],
    [
      { display: "Evil Rio", value: "evil rio" },
      { display: "71.8%", value: 0.7125 },
      { display: 11, value: 11 },
      { display: 3, value: 3 },
      { display: 14, value: 14 }
    ],
    [
      { display: "Evil Dali", value: "evil dali" },
      { display: "68.3%", value: 0.6826 },
      { display: 16, value: 16 },
      { display: 5, value: 5 },
      { display: 21, value: 21 }
    ]
  ]}
  indexCol
  className="meat-slicer"
  sortCol={1} // Win Rate
  sortColTiebreak={4} // Games
  sortDir="DESC"
  pageSize={2}
/>
```

# DataTableAsync

## Table Props

```ts
type AsyncTable = {
  onMoreRows(req: RowsRequest): void;
  totalRows: number;
  pageSize: number;
} & TableProps;

type RowsRequest = {
  startRow: number;
  endRow: number;
  searchText?: string;
  sortCol: number;
  sortDir: SORT_DIR;
};
```

## Example Table

```jsx preview hideSource
<FakeRoute>
  {({
    rows,
    totalRows,
    debouncedUpdateRoute,
    columns,
  }) => {
      const handleMoreRows = ({
        startRow,
        endRow,
        searchText,
        sortCol,
        sortDir,
      }) => {
        debouncedUpdateRoute({}, {}, { transient: {startRow, endRow, searchText, sortCol, sortDir} });
      }
      return (
        <DataTableAsync
          cols={columns}
          rows={rows}
          totalRows={totalRows}
          onMoreRows={handleMoreRows}
          indexCol
          className="meat-slicer"
          sortCol={1}
          sortDir="DESC"
          pageSize={5}
        />
    )
  }}
</FakeRoute>
```
