This is a supplementary article that explains how the app state and router work in tandem.

## Problems to Solve

According to [this article](https://frontendmastery.com/posts/the-new-wave-of-react-state-management/) these are some problems that libraries need to solve:

- **Reading state from anywhere**: covered by `useSnapshot` hook.
- **Writing to state**: we extend this by not only writing to memory but also to disk (IndexedDB).
- **Mechanisms to optimize rendering**: Valtio snapshots handle this automatically by tracking which keys are read.
- **Optimize memory usage**: we have to manage this manually since our state lives outside of React.

## Caveats to Writing Data

As a rule, we are only loading data from routes, _not_ components. There are two ways that state gets updated:

- Using a `getData` call from a route.
- Calling an action that mutates `writeState`.

_The latter can cause unexpected errors if there is an unbounded number of action calls on a route._

Consider that each route has a budget of maximum paths to write to (as of writing, `MAX_LENGTH = 120`). If action calls write _multiple different paths_, then this may trigger state cleanup to prune older paths that were required for the route component to render. However, this may not occur in practice if the number of different paths written to from actions is bounded, and does not exceed the maximum length of paths.

## Routes Trigger State Cleanup

A consequence of only loading data from routes is that in general, routes cause data from older routes to be evicted. The layout of written paths may look like this:

```js
[A3, B1, B2, ..., C1, C2]
```

Notice that the data for route A was truncated (missing A1, A2). Once the max paths has been reached, it starts to prune older data.

## Global State Tree

Our app state combines all these types of state:

- URL state: it should be assumed that the paths needed for the current route are loading, present, or Error.
- Context: instead of using React.Context there's `state.volatile` which has the advantage of being able to be read from anywhere outside of the component hierarchy.
- Cache: using `getData` or `readData` handles caching automatically, so that by default, all data we fetch is cached and has an expiry time.

## Exceptions to the Rules

If there is functionality that spans across routes, that is if something needs to function regardless of what route a user is on, then it should not use app state at all because this whole model breaks down due to the assumption of writing data only from routes.

Alternatively, it can use app state with a big caveat that it needs to be exempt from state cleanup. That means memory must be managed manually either way, given that the use case demands that feature works for the entire memory lifetime of the app.

Currently the only exception so far is LoL champion select and live game.
