import { readState } from "@/__main__/app-state.mjs";
import fetchMatch from "@/game-fortnite/fetches/match.mjs";
import fetchMatchlist from "@/game-fortnite/fetches/matchlist.mjs";
import fetchPlayer from "@/game-fortnite/fetches/profile.mjs";
import { fetchData as fetchItems } from "@/game-fortnite/fetches/static.mjs";
import fnRefs from "@/game-fortnite/refs.mjs";
import { devError } from "@/util/dev.mjs";

// Fetch all the data required for `fortnite/match/:profileId/:matchId`
async function fetchData([
  profileId,
  seasonId,
  gameId,
  tab,
  tabId,
]: string[]): Promise<void> {
  try {
    const match = await fetchMatch({
      gameId,
      seasonId: parseInt(seasonId, 10),
    });

    const accounts = readState.settings.loggedInAccounts.fortnite;
    const isOwnProfile =
      accounts && Object.keys(accounts).some((a) => a === profileId);
    const additional = fnRefs.postmatchFetchFunctions.map((fn) =>
      fn({ gameId, isOwnProfile, tab, tabId }),
    );

    await Promise.all([
      fetchPlayer(profileId),
      fetchMatchlist(profileId, match.mode),
      ...additional,
      fetchItems(),
    ]);
  } catch (e) {
    devError("Failed to fetch match page", e);
  }
}
export default fetchData;
