import { appURLs } from "@/app/constants.mjs";

Vertigo takes place on a high-rise construction site that introduces vertical combat and unique sound cues. Its standout feature is the risk of falling off the building, creating an additional layer of tension. Bombsite A offers a scenic overlook, while Bombsite B is positioned in an enclosed room. The map's towering construction framework and panoramic views make it one-of-a-kind in CS:GO's lineup.

**Bombsite A:** Positioned with a stunning overlook, Bombsite A features multiple entry points and a risk of falling off the building. The blend of indoor and outdoor spaces adds to the tactical depth.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/vertigo_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/vertigo_a_site_2.webp`} />
</div>

**Bombsite B:** Situated in an enclosed room with adjoining corridors, Bombsite B emphasizes close-quarters combat. The scaffolding and construction materials add to the site's thematic design.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/vertigo_b_site.webp`} />
</div>
