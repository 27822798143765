**The design decisions are made to accommodate our use cases at Blitz.** We have these over-arching decisions in place:

- Data-centric state: allows us to keep the underlying functionality running, regardless of how the UI changes. We can even change lib/framework for specific routes as needed (micro-frontends).
- Heavy client: do things on the client wherever possible, it also saves on server resources.
- Runtime modification: this allows us to have one unified build that handles every configuration.

Computer programming is largely a design discipline, and so I will try to layout some of the design decisions here. Each decision made closes off some paths and opens new ones.

## Data-centric vs. Component-centric

There is an explicit choice to be data-centric, which extends to state management, data loading, and routing.

- State is managed in an external store (valtio)
- Data is fetched via the route, not from components
- The routes are defined as data rather than a component hierarchy

This is currently a less common approach to building single-page apps since most frameworks are heavily component-centric, and there are tradeoffs to be made here.

A component-centric design encourages coupling of everything to the component as the basic unit of organization, including business logic, data loading, state, routing, etc. However, specifications are always changing, so it may be easier to write first, but harder to maintain over a long run. A data-centric approach enables us to completely scrap UI components and rebuild them without loss of functionality, since the data shouldn't be coupled to the components.

_At Blitz, we expect components to churn a lot due to visual design, so it is better to move functionality outside of components to make this easier._

## Client heavy vs. Server heavy

_Blitz is inherently very client heavy, because we have to integrate with games running locally._ Also a lot of the most important views are client-rendered only, this is because they need to re-render based on changes in game data.

The architecture that best fits our main use case is a single-page application. Server-side rendering is an afterthought for us, in order to get search engine crawlers to parse our pages. What this means is that server-first frameworks such as Next.js and React server components are sub-optimally fitted for our use cases.

## Runtime vs. Compile time

_Our preference towards runtime is predicated by the app being a single build that is uploaded to an S3 bucket._ This makes things much easier to deploy and configure.

Most of the interesting stuff happens during runtime, since the app has to interface with a ton of external data sources. It is designed with runtime introspection in mind, via the `__BLITZ_DEV__` global.

A lot of functionality is built around runtime instead of compile time. For example, there are very few build flags in our build script, and no environment variables are used. Instead, there are objects in the codebase such as `appURLs` that allow for runtime introspection, so most things can be changed on the fly. This offers us a lot of flexibility without having to rebuild, such as changing backends for a different region (China).

Another consequence of using the runtime is dynamic import and using `refs` to mutate the application itself, rather than a bunch of `if (isFeatureEnabled)` statements littered throughout the codebase.

Do not rely on TypeScript for soundness, it merely exists to give type hints only. Please [see this](https://www.typescriptlang.org/play?strictFunctionTypes=false#example/soundness) to understand how TypeScript is not a panacea for bug-free kode.
