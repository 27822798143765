**March 13, 2019**
- Price reduction for some shotguns: Nova ($1050), Sawed-Off ($1100), MAG-7 ($1300).
- Range increased to 1400.
- Single shell shotguns in the middle of their reload sequence will no longer automatically continue to reload after a player holsters and deploys them.

**February 8, 2018**
- Enabled shotgun spread patterns on official matchmaking servers as described in https://blog.counter-strike.net/index.php/holiday-spread/

**July 1, 2014**
- Penetration power increased
- Pellets reduced to 8
- Individual pellet damage increased to 32
- Reduced maximum range slightly
- Spread increased slightly

**October 1, 2012**
- Reduced all shotguns' price by $300.
