import { readState } from "@/__main__/app-state.mjs";
import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import router, { setRoute } from "@/__main__/router.mjs";
import { initSettings, setVolatileKV, showSnackbar } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { GAME_BOX_ICONS } from "@/app/constants.mjs";
import type { ApexMatchStart } from "@/game-apex/actions.mjs";
import {
  getPlayerPlatformId,
  insertMatchlistItem,
  mutationUpdateMatch,
  setVolatileMatchId,
  updateLiveGame,
  updateLoggedInAccountId,
  updateProfile,
  upsertPlayers,
} from "@/game-apex/actions.mjs";
import {
  EVENT_APEX_ENTER_GAME,
  EVENT_APEX_EXIT_GAME,
} from "@/game-apex/apex-client-api.mjs";
import { GAME_MODES } from "@/game-apex/constants.mjs";
import { GAME_SYMBOL_APEX } from "@/game-apex/definition.mjs";
import OriginOverlaySnackbar from "@/game-apex/OriginOverlaySnackbar.jsx";
import {
  formatMode,
  getLastGameMode,
  ignoreModes,
} from "@/game-apex/utils.mjs";
import type { ApexDataRaw } from "@/game-apex/utils/handle-apex-data.mjs";
import handleApexData, {
  parseRawData,
} from "@/game-apex/utils/handle-apex-data.mjs";
import overlayRefs from "@/shared/OverlayContainerWithAd.refs.jsx";
import { devError, devLog } from "@/util/dev.mjs";
import { updateLatestPlayed } from "@/util/game-handlers.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";
import isEmpty from "@/util/is-empty.mjs";
import isObject from "@/util/is-object.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

const ROUTE_SUPRESS_MSGS = [undefined, undefined, true] as const;

export default async () => {
  await initEvents;
  const {
    APEX_PLAYER,
    APEX_GAME_START,
    APEX_GAME_END,
    APEX_GAME_DEPLOY,
    APEX_DATA,
    APEX_IS_RUNNING,
    APEX_VIEW_BLITZ_APP,
    APEX_HAS_ORIGIN_OVERLAY,
  } = EVENTS;
  handleMessage(
    "wndAttributes",
    ({
      width,
      height,
      name,
    }: {
      width: number;
      height: number;
      name: string;
    }) => {
      setVolatileKV("wndAttributes", { width, height, name });
    },
  );

  handleMessage(
    APEX_PLAYER,
    async (player: {
      playerId: string;
      username: string;
      hardwareId: string;
    }) => {
      devLog("Received APEX_PLAYER", player);
      const { playerId, username, hardwareId } = player;
      await initSettings();
      const isOverlay = isRouteOverlay();
      updateLoggedInAccountId(playerId);
      updateProfile(
        playerId,
        {
          username,
          platformId: playerId,
          hardwareId,
        },
        isOverlay,
      );
      if (playerId && findGameSymbol() === GAME_SYMBOL_APEX && !isOverlay) {
        setRoute(`/apex/profile/${playerId}`, ...ROUTE_SUPRESS_MSGS);
      }
    },
  );

  handleMessage(APEX_GAME_START, async (gameStart: ApexMatchStart) => {
    devLog("Received APEX_GAME_START", gameStart);
    const { apexId, map, gameStartedAt, gameMode } = gameStart;
    const mode = formatMode(getLastGameMode(gameMode), gameMode);
    const match = { apexId, map, gameStartedAt, gameMode: mode };
    if (ignoreModes(mode)) return;
    const isOverlay = isRouteOverlay();

    updateLiveGame(match, isOverlay);

    const loggedInAccountId = readState.settings.lastLoggedInIdByGame.apex;

    if (isOverlay) {
      overlayRefs.destroyAd(APEX_GAME_START);
      if (loggedInAccountId) {
        setRoute(
          `/apex/overlay/legend-select`,
          `profileId=${loggedInAccountId}&tab=legends&mode=${GAME_MODES[mode]?.statsMode || mode}`,
          undefined,
          true,
        );
      }
      return;
    }

    await initSettings();
    if (loggedInAccountId) {
      setRoute(`/apex/profile/${loggedInAccountId}`, ...ROUTE_SUPRESS_MSGS);
    } else {
      setRoute("/overlays/apex", ...ROUTE_SUPRESS_MSGS);
    }
    eventBus.emit(EVENT_APEX_ENTER_GAME, {
      matchId: apexId,
      playerId: loggedInAccountId,
      gameMode: mode,
    });
  });

  handleMessage(
    APEX_GAME_DEPLOY,
    (gameDeploy: {
      apexId: string;
      gameMode: string;
      gameStartedAt: number;
      map: string;
      players: Array<{
        apexId: string;
        hardwareId: string;
        modelName: string;
        platformId: string;
        team: { apexId: string };
        username: string;
      }>;
    }) => {
      devLog("Received APEX_GAME_DEPLOY", gameDeploy);
      const { players, gameMode, gameStartedAt, apexId, map } = gameDeploy;
      const match = {
        playerMatchStats: players,
        apexId,
        map,
        gameStartedAt,
        gameMode: formatMode(getLastGameMode(gameMode), gameMode),
      };
      const isOverlay = isRouteOverlay();

      // Overlay game flow
      if (isOverlay) {
        overlayRefs.destroyAd(APEX_GAME_DEPLOY);
        Promise.all([updateLiveGame(match, true), setRoute("/apex/overlay")]);
        return;
      }

      // Client game flow
      if (
        (players?.length ?? 0) <= 1 ||
        formatMode(getLastGameMode(gameMode), gameMode)
      )
        return;
      const loggedInAccountId = readState.settings.lastLoggedInIdByGame.apex;
      const nextRoute = loggedInAccountId
        ? `/apex/profile/${loggedInAccountId}`
        : "/overlays/apex";
      Promise.all([
        upsertPlayers(players),
        updateLiveGame(match, false),
        setRoute(nextRoute, ...ROUTE_SUPRESS_MSGS),
      ]);
    },
  );

  handleMessage(APEX_GAME_END, () => {
    devLog("Received APEX_GAME_END");
    updateLiveGame(null);
  });

  handleMessage(APEX_DATA, async ($apexData: ApexDataRaw) => {
    devLog("Received APEX_DATA", $apexData);
    const apexData = parseRawData($apexData);
    // Declarations
    const { lastMatch, diffs, data, diffRankSeasonId } = apexData;
    const { apexId: matchId, gameMode: rawMode } = lastMatch;
    const { games_played_any_mode: gamesPlayedDiff } = diffs;
    const {
      Enums: { eseasonflavor },
      lastgamemode,
      lastgamecharacter,
      lastgamesquadstats,
      xp,
    } = data;
    const isOverlay = isRouteOverlay();
    const currentSeason = eseasonflavor.slice(-1)[0];
    await initSettings();
    const loggedInAccountId = readState.settings.lastLoggedInIdByGame.apex;
    const isLiveGame = readState.apex.liveGame;
    const matchIdByParams = router.route?.searchParams?.get?.("matchId");
    const isMatchPlayed =
      typeof gamesPlayedDiff === "number" && gamesPlayedDiff !== 0;

    // Overlay
    if (isOverlay) {
      updateProfile(
        loggedInAccountId,
        {
          experiencePoints: xp,
          hoveredChampionApexId:
            typeof lastgamecharacter === "number"
              ? `said${String(lastgamecharacter).padStart(11, "0")}`
              : lastgamecharacter,
        },
        true,
      );
      // Handles overlay routing
      if (!isLiveGame && !isMatchPlayed && !matchIdByParams) {
        setRoute(
          "/apex/overlay/benchmark",
          `profileId=${loggedInAccountId}`,
          undefined,
          true,
        );
      } else if (isMatchPlayed && matchId) {
        // Overlay Match End: Treat the code below as if the match has ended
        overlayRefs.destroyAd(APEX_GAME_END);
        setRoute(
          "/apex/overlay/benchmark",
          `profileId=${loggedInAccountId}&season=${currentSeason}&matchId=${matchId}`,
          undefined,
          true,
        );
      }
      return;
    }

    // Guard: Essential data that is required. Encountering an error here is fatal.
    if (!data || !(isObject(lastMatch) && !isEmpty(lastMatch)) || !diffs)
      return;

    // Handle non-blocking data regardless if a match was played or not
    Promise.all([
      updateProfile(
        loggedInAccountId,
        {
          experiencePoints: xp,
          hoveredChampionApexId:
            typeof lastgamecharacter === "number"
              ? `said${String(lastgamecharacter).padStart(11, "0")}`
              : lastgamecharacter,
        },
        false,
      ),
      handleApexData(loggedInAccountId, data, diffRankSeasonId),
    ]);

    // Guard: No match played
    if (!isMatchPlayed) return;
    // Game event: Game end has occured, treat the code below as if the game has ended
    const gameMode = formatMode(lastgamemode, rawMode);
    eventBus.emit(EVENT_APEX_EXIT_GAME, {
      matchId,
      playerId: loggedInAccountId,
      gameMode,
    });
    const payload = await mutationUpdateMatch(apexData).catch((e) => {
      devError("Apex Legends: Failed to update match", e);
    });
    // BE doesn't return an updated matchlist for a player immediately
    // So we need to insert it into state to appear like it was updated instantly
    if (payload) {
      const seasonId = readState.apex.meta.seasons[currentSeason]?.id;
      insertMatchlistItem(
        loggedInAccountId ||
          getPlayerPlatformId(
            lastgamesquadstats.find((i) => i.character === lastgamecharacter),
          ),
        {
          apexId: matchId,
          gameMode,
          gameStartedAt: Date.now(),
          id: payload.id,
          season: {
            apexId: currentSeason,
            id: seasonId,
          },
        },
      );
    }
    setVolatileMatchId();
    /**
     * Delay route transition after match ends so players can send their player + weapon to back-end
     *
     * Why do we do this?
     * If users get redirected to the match page before Blitz users get a chance to send their personal match data,
     * then when users visit postmatch page, it might not include other players data, or the local player.
     *
     * Our default caching strategy will make it seem like the match is outdated for affected players.
     * To circumvent this we can simply delay match page transition by x seconds to give BE time to aggregate everything.
     */
    if ([loggedInAccountId, currentSeason, matchId].every(Boolean))
      setTimeout(() => {
        setRoute(
          `/apex/match/${loggedInAccountId}/${currentSeason}/${matchId}`,
          ...ROUTE_SUPRESS_MSGS,
        );
      }, 5e3);
  });

  handleMessage(APEX_IS_RUNNING, (apexGame) => {
    devLog("Received APEX_IS_RUNNING", apexGame);
    if (apexGame) updateLatestPlayed(GAME_SYMBOL_APEX);
    if (
      apexGame &&
      findGameSymbol() !== GAME_SYMBOL_APEX &&
      !router.route?.currentPath?.startsWith("/account")
    ) {
      const loggedInAccountId = readState.settings?.lastLoggedInIdByGame?.apex;
      if (loggedInAccountId) {
        setRoute(`/apex/profile/${loggedInAccountId}`, ...ROUTE_SUPRESS_MSGS);
      } else {
        setRoute("/overlays/apex", ...ROUTE_SUPRESS_MSGS);
      }
    }
    if (!apexGame) updateLiveGame(null);
  });

  handleMessage(
    APEX_VIEW_BLITZ_APP,
    ({
      matchId,
      profileId,
      seasonId,
    }: {
      matchId: string;
      profileId: string;
      seasonId: string;
    }) => {
      if (profileId && matchId && seasonId) {
        setRoute(
          `/apex/match/${profileId}/${seasonId}/${matchId}`,
          ...ROUTE_SUPRESS_MSGS,
        );
      } else if (profileId) {
        setRoute(`/apex/profile/${profileId}`, ...ROUTE_SUPRESS_MSGS);
      }
    },
  );

  handleMessage(APEX_HAS_ORIGIN_OVERLAY, () => {
    devLog("Received APEX_HAS_ORIGIN_OVERLAY");
    showSnackbar({
      id: "disableOriginOverlay",
      priority: "high",
      Icon: GAME_BOX_ICONS[GAME_SYMBOL_APEX],
      Text: () => OriginOverlaySnackbar(),
      dismissable: true,
    });
  });
};
