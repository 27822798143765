import React, { useEffect, useState } from "react";
import { styled } from "goober";

import CloseIcon from "@/inline-assets/close.svg";
import EditIcon from "@/inline-assets/edit.svg";
import ArrowDown from "@/inline-assets/keybind-arrow-down.svg";
import ArrowLeft from "@/inline-assets/keybind-arrow-left.svg";
import ArrowRight from "@/inline-assets/keybind-arrow-right.svg";
import ArrowUp from "@/inline-assets/keybind-arrow-up.svg";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";

type Size = "sm" | "md";

type Props = {
  keybind: string;
  editable?: boolean;
  size?: Size;
  onChange?: (data: {
    code: number;
    value: string;
    modifiers: EventModifierInit;
  }) => void;
  dimmed?: boolean;
};

const typeClasses = {
  sm: "type-form--shortcut",
  md: "type-form--button",
};

const KeybindView: React.FC<Props> = ({
  keybind,
  editable,
  size = "md",
  dimmed,
  onChange,
}) => {
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(keybind);

  useEffect(() => {
    if (!editable) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!editing) return;
      if (e.key === "Escape") {
        setEditing(false);
        return;
      }

      e.stopPropagation();
      e.preventDefault();

      setTitle(e.key);

      let key = e.key;
      if (key === "Control") key = "Ctrl";
      if (key === " ") key = "Space";
      onChange?.({
        code: e.keyCode,
        value: key,
        modifiers: {
          shiftKey: e.shiftKey,
          ctrlKey: e.ctrlKey,
          altKey: e.altKey,
        },
      });
      setEditing(false);
    };

    globals.document.addEventListener("keydown", handleKeyDown);
    return () => {
      globals.document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editable, editing, onChange]);

  const tag = resolveKey(title);

  return (
    <Container
      $dimmed={dimmed}
      $isIcon={typeof tag !== "string"}
      onClick={() => {
        if (editable && !editing) {
          setEditing((editing) => !editing);
        }
      }}
      data-size={size}
      {...classNames(
        "flex",
        "gap-sp-2",
        "align-center",
        typeClasses[size],
        editing ? "editing" : null,
      )}
    >
      <span className="tag">{tag}</span>
      {editable &&
        (!editing ? (
          <EditIcon />
        ) : (
          <span
            onClick={() => {
              setEditing(false);
            }}
          >
            <CloseIcon height={16} width={16} className="close" />
          </span>
        ))}
    </Container>
  );
};

const resolveKey = (key: string) => {
  switch (key) {
    case "Up":
    case "ArrowUp":
      return <ArrowUp />;
    case "Down":
    case "ArrowDown":
      return <ArrowDown />;
    case "Left":
    case "ArrowLeft":
      return <ArrowLeft />;
    case "Right":
    case "ArrowRight":
      return <ArrowRight />;
    case " ":
      return "Space";
    case "Backspace":
      return "⌫";
    case "Escape":
      return "Esc";
    case "Control":
      return "⌃";
    case "Alt":
      return "⌥";
    case "Shift":
      return "⇧";
    case "Meta":
      return "⌘";
    default:
      return key;
  }
};

const Container = styled("div")<{
  $dimmed?: boolean;
  $isIcon?: boolean;
}>`
  cursor: pointer;
  position: relative;
  padding: ${({ $isIcon }) =>
    $isIcon ? "var(--sp-1_5)" : "var(--sp-1_5) var(--sp-2_5)"};
  border-radius: var(--br);
  background: var(--shade6);
  color: ${({ $dimmed }) => ($dimmed ? "var(--shade2)" : "var(--shade1)")};
  text-transform: capitalize;
  box-shadow: var(--highlight);

  &:hover {
    background-color: var(--shade5);
  }

  &[data-size="sm"] {
    height: var(--sp-6);
    border-radius: var(--br);
    padding: ${({ $isIcon }) =>
      $isIcon ? "var(--sp-1)" : "var(--sp-0_5) var(--sp-1_5)"};
  }

  &.editing {
    cursor: default;
    background-color: var(--shade8);
    outline: 3px solid var(--shade1-15);
  }

  .close {
    cursor: pointer;

    &:hover {
      color: var(--shade0);
    }
  }
`;

export default KeybindView;
