**June 15, 2022**
- Fixed an exploit. (Thanks El_Exodus!)

**May 27, 2021**
- Performance optimizations. (Thanks leplubodeslapin!)
- Reduced bird sounds volume.
- Reduced contrast on some textures.
- Brightened sky slightly.
- Fixed minor issues. (Thanks imik!)
- Restored vivid color to select textures and models.
- Restored select details even on lowest graphical settings.

**January 7, 2021**
- Fixed a pixel-gap in the Cargo containers in T-Spawn (Thanks to the perceptive individual that reported it to 3kliksphilip!)
- Fixed Cargo containers not having their proper colors.
- Fixed bomb explosions being visible above the A site. (Thanks markman2575!)

**January 29, 2020**
- Various visibility improvements
- Optimizations
- Various minor bug fixes
- Updated radar

**November 18, 2019**
- Cache has been moved from Scrimmage to Competitive Matchmaking

**October 18, 2019**
- Cache has been updated to the latest version from the Steam Workshop and is now available in Casual, Deathmatch, and Scrimmage.

**July 6, 2018**
- Updated the BSP embedded radar map image to the new version.

**March 20, 2017**
- Fix for vent break sound.

**January 12, 2017**
- Fixed sound-spam when shooting at vent in Checkers through a prop
- Clipping improvements
- Optimizations

**October 25, 2016**
- Minor bug fixes

**July 15, 2016**
- Added graffiti to commemorate ESL One Cologne 2016.

**June 9, 2016**
- Minor bug fixes

**May 19, 2016**
- Forklift
- with
- Roof

**May 4, 2016**
- Visibility improvements based on color-blind player feedback
- Texture improvements
- New vent models
- Added subtle markers for grenade throws (thanks James Bardolph)
- Added plant-zone decals to Bombsite B
- Added graphic for “Sun-room” (thanks Thurnip)
- Fixed fade distance on crate in checkers
- Fixed weapons being irretrievable under vent
- Fixed all known bomb-stuck spots
- Fixed an exploit involving flashbangs from mid into checkers
- Fixed pixelwalks at mid, T-Spawn, A main, A site
- Improved .nav mesh (thanks p_NM)

**February 26, 2016**
- Fixed one-way texture at Quad boxes
- Improved visibility of vents
- Fixed pixelwalk at B fences
- Improved matching of foot-step sounds and ground materials across the map

**February 19, 2016**
- Fixed various minor bugs

**May 26, 2015**
- Fixed multiple bomb-stuck spots
- Fixed an issue where saving CTs could be seen on radar from outside spawn
- Fixed an annoying issue where grenades would collide with ground geometry map-wide
- Deleted a problematic glass pane in mid (thanks RoF Anders)
- Ensured tarps on crates are actually spammable

**January 14, 2015**
- Exploit fix in Bombsite B.

**July 23, 2014**
- Added solidity to gates in b halls and ct garage to allow for nade creativity.
- Improved readability in B heaven.
- Improved readability by quad.
- Improved lighting by forklift.
- Improved prop-consistency by quad by adding more rigid edges.
- Increased visibility in vent.
- Fixed missing texture issues.
- Fixed various lighting issues.
- Fixed texture translation issues.
- Added clipping to prevent an exploit boost.
- Slightly increased vent lighting.
- Minor but important change to shadowing in some rooms.

**August 1, 2014**
- Fixed visibility exploit around pipe at mid.
- More translation fixes.
- Fixed multiple one-way wallbanging issues.
- Made catwalks wallbangable.
- Fixed issue where grenades would get stuck on thin wall panels.
- Smoothed movement around generator for less abrupt movement restriction.
- Improved readability around Quad.
- Further improved readability at Heaven.

**November 6, 2013**
- Fixed lighting on prop in CT spawn.
- Fixed buggy wallbangs at A main and B halls.
- Fixed issue with dropping bomb behind spools in mid warehouse.

**September 26, 2013**
- Fixed bugs in Gwalior, Cache, Ali, Seaside and Agency.
