import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import { EVENTS, handleMessage } from "@/__main__/ipc-core.mjs";
import { getModifier } from "@/app/util.mjs";
import PlayersTable from "@/game-val/components/PlayerTable.jsx";
import { AgentHeadshot } from "@/game-val/components/PlayerTable.style.jsx";
import { AGENT_CLASS_ICONS } from "@/game-val/constants/constants-class-icons.mjs";
import SelectedAgent from "@/game-val/SelectedAgent.jsx";
import {
  getAgentImage,
  getWinRateColor,
  kdaColor,
  scoreColorStyle,
} from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import CaretDown from "@/inline-assets/caret-down.svg";
import ChevronDown from "@/inline-assets/chevron-down.svg";
import ChevronUp from "@/inline-assets/chevron-up.svg";
import Warning from "@/inline-assets/Warning.svg";
import BlitzWordmarkWithGG from "@/inline-assets/wordmark-with-gg.svg";
import {
  LogoContainer,
  Toggle,
  ToggleKey,
} from "@/shared/OverlayContainerWithAd.style.jsx";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import orderBy from "@/util/order-array-by.mjs";
import useKeypress from "@/util/use-key-press.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  position: fixed;
  &.letterbox {
    position: absolute;
  }
  top: calc(var(--sp-5e));
  right: calc(var(--sp-5e));

  display: flex;
  flex-direction: column;
  width: calc(var(--sp-10e) * 10);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
`;

const Header = styled("div", React.forwardRef)`
  background: var(--shade10-75);
  display: flex;
  border-radius: calc(var(--sp-1e) + var(--sp-pxe))
    calc(var(--sp-1e) + var(--sp-pxe)) 0 0;
  flex-direction: row;
  justify-content: space-between;
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  background: var(--shade9-75);
  border-radius: 0 0 calc(var(--sp-1e) + var(--sp-pxe))
    calc(var(--sp-1e) + var(--sp-pxe));
  overflow: hidden;

  .filters-container {
    height: var(--sp-8e);
    color: var(--shade0-50);
    margin: var(--sp-3e);
    .filters div {
      position: relative;
      cursor: pointer;
      &.active {
        color: var(--shade0);
      }
      svg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: var(--sp-4e);
        width: var(--sp-4e);
        margin-top: calc(-1 * var(--sp-2_5e));
      }
    }
  }
  .agents {
    margin-left: var(--sp-3e);
    margin-right: var(--sp-3e);
    margin-bottom: var(--sp-3e);
    gap: var(--sp-0_5e);
  }
`;

const AgentContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--shade4-15);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  height: var(--sp-7e);

  .agent-name {
    display: flex;
    align-content: center;
    align-items: center;
    margin-left: var(--sp-1_5e);
    flex: 1;
    svg {
      width: var(--sp-4e);
      height: var(--sp-4e);
      color: var(--shade0-50);
      margin-right: var(--sp-1e);
    }
  }
  .agent-stat {
    margin-right: var(--sp-2e);
  }

  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    background: var(--shade1-15);
    height: 100%;
    width: ${({ $stat }) => $stat * 100}%;
  }
`;

const EmptyAgentsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 var(--sp-3e) var(--sp-6e);

  svg {
    font-size: var(--sp-7e);
    color: var(--shade1-75);
    margin-left: var(--sp-3e);
    margin-right: var(--sp-3e);
    path {
      fill: var(--shade1-75);
    }
  }
`;

const WarningText = styled("p")`
  font-size: var(--sp-3e);
  line-height: var(--sp-5e);
  color: var(--shade1-75);
`;

const Separator = styled("div")`
  height: var(--sp-pxe);
  margin: 0 var(--sp-3e);
  background: var(--shade4-25);
`;

const SORT_BY = {
  winRate: {
    key: "winRate",
    label: ["lol:winRate", "Win rate"],
  },
  kda: {
    key: "kda",
    label: ["common:stats.kda", "KDA"],
  },
  score: {
    key: "score",
    label: ["common:stats.avgScore", "Avg. Score"],
  },
};

const TOGGLE_KEY = {
  key: "Tab",
  modifiers: {
    ctrlKey: IS_APP,
    altKey: false,
    shiftKey: false,
  },
};

const platformPath = getPlatformPath(false);

const BestAgents = ({
  profileId,
  agentId,
  letterbox,
  queue,
  players,
  playerAgents,
}) => {
  const { t } = useTranslation();
  const {
    val: {
      content: { acts = [], agents = [] } = {},
      meta: { agents: { list: agentsMeta } = {} } = {},
      playerAgentStats,
      profiles: { [profileId]: { puuid } = {} },
    },
  } = useSnapshot(readState);
  const [collapsed, setCollapsed] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_BY.winRate.key);
  const allAgentStats = useMemo(() => {
    const latestActId = acts.find(
      (act) => act.endedAt === null && act.episode.endedAt === null,
    )?.uuid;
    const result =
      playerAgentStats?.[puuid]?.[latestActId]?.[platformPath]?.[queue];
    return Array.isArray(result) ? result : [];
  }, [acts, playerAgentStats, puuid, queue]);
  const top3Agents = useMemo(() => {
    return orderBy(
      allAgentStats.map((stats) => {
        const {
          matchesWon,
          matchesLost,
          kills,
          assists,
          deaths,
          score,
          matchesPlayed,
          roundsPlayed,
          agent: { uuid },
        } = stats;
        return {
          id: uuid,
          winRate: calcRate(
            matchesWon,
            // remove ties from matchesPlayed
            matchesPlayed - (matchesPlayed - matchesLost - matchesWon),
            2,
          ),
          kda: calcRate(kills + assists, deaths, 2),
          matches: matchesPlayed,
          wins: matchesWon,
          ties: matchesPlayed - matchesLost - matchesWon,
          losses: matchesLost,
          score: calcRate(score, roundsPlayed),
        };
      }),
      sortBy,
      "desc",
    ).slice(0, 3);
  }, [allAgentStats, sortBy]);

  const modifier = getModifier(TOGGLE_KEY.modifiers);

  useKeypress(
    "Tab",
    (e) => {
      if (!IS_APP && !IS_NODE) {
        e?.preventDefault();
        setCollapsed(!collapsed);
      }
    },
    [collapsed],
  );

  useEffect(() => {
    handleMessage(EVENTS.ELECTRON_OVERLAY_HOTKEY_MSG, ({ action }) => {
      if (action === "game_hotkey_up") {
        setCollapsed(!collapsed);
      }
    });

    return () => {
      handleMessage(EVENTS.ELECTRON_OVERLAY_HOTKEY_MSG, () => {});
    };
  }, [collapsed]);

  const toggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <Container className={letterbox ? "letterbox" : ""}>
      <Header>
        <LogoContainer>
          <BlitzWordmarkWithGG />
        </LogoContainer>
        <Toggle>
          {modifier ? (
            <ToggleKey className="type-form--shortcut">{modifier}</ToggleKey>
          ) : null}
          <ToggleKey className="type-form--shortcut">
            {TOGGLE_KEY.key}
          </ToggleKey>
          <span onClick={toggleCollapse}>
            {collapsed ? <ChevronDown /> : <ChevronUp />}
          </span>
        </Toggle>
      </Header>
      <Content>
        <SelectedAgent agentId={agentId} queue={queue} profileId={profileId} />
        {!collapsed ? (
          <>
            <Separator />
            <div className="flex justify-between align-center filters-container">
              <p className="type-mini">
                {t("val:suggestedAgent", "Suggested Agent")}
              </p>
              <div className="flex justify-between gap-sp-2 filters">
                {Object.values(SORT_BY).map(({ key, label }) => (
                  <div
                    key={key}
                    className={sortBy === key && "active"}
                    onClick={() => setSortBy(key)}
                  >
                    {sortBy === key ? <CaretDown /> : null}
                    <p className="type-mini">{t(...label)}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex column agents">
              {top3Agents.map((topAgent) => {
                const { id, winRate, kda, score } = topAgent;
                const agentKey = agents
                  .find((agentObj) => agentObj.uuid === id)
                  ?.name?.toLowerCase()
                  ?.replaceAll("/", "");
                const agent = agentsMeta?.find((o) => o.key === agentKey);
                const agentImg = getAgentImage(agent?.key);
                const kdaRatio = kda.toLocaleString(getLocale(), {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
                const avgScore = score.toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
                const AgentClassIcon = AGENT_CLASS_ICONS[agent?.Class];
                const stat = calcRate(topAgent[sortBy], top3Agents[0][sortBy]);
                return (
                  <AgentContainer key={id} $stat={stat}>
                    <AgentHeadshot>
                      <img
                        src={agentImg}
                        width={20}
                        height={20}
                        alt={agentKey}
                      />
                    </AgentHeadshot>
                    <div className="agent-name">
                      {AgentClassIcon ? <AgentClassIcon /> : null}
                      <p className="type-caption--semi shade0">
                        {t(`val:agents.${agentKey}`, agent?.name)}
                      </p>
                    </div>
                    <div className="agent-stat">
                      {sortBy === SORT_BY.winRate.key ? (
                        <p
                          className="type-subtitle2"
                          style={{ color: getWinRateColor(winRate) }}
                        >
                          {displayRate(winRate, 1)}
                        </p>
                      ) : sortBy === SORT_BY.kda.key ? (
                        <p
                          className="type-subtitle2"
                          style={{ color: kdaColor(kda) }}
                        >
                          {kdaRatio}
                        </p>
                      ) : sortBy === SORT_BY.score.key ? (
                        <p
                          className="type-subtitle2"
                          style={{ color: scoreColorStyle(score) }}
                        >
                          {avgScore}
                        </p>
                      ) : null}
                    </div>
                  </AgentContainer>
                );
              })}
            </div>
            {top3Agents.length === 0 ? (
              <EmptyAgentsContainer>
                <Warning />
                <WarningText className="type-body2">
                  {t(
                    "val:overlay.playMore",
                    "Play more matches to generate agent suggestions.",
                  )}
                </WarningText>
              </EmptyAgentsContainer>
            ) : null}
            <Separator />
            {players.length > 0 ? (
              <PlayersTable
                players={players}
                queue={queue}
                playerAgents={playerAgents}
              />
            ) : null}
          </>
        ) : null}
      </Content>
    </Container>
  );
};

export default BestAgents;
