import damageTemplate from "@/game-lol/components/in-game-template-damage.mjs";
import { cssPlayerCard } from "@/game-lol/components/in-game-template-summoner.style.mjs";
import html from "@/util/html-template.mjs";

const template = html`<div
  id="{{id}}"
  class="${cssPlayerCard()}"
  style="{{style}}"
  data-in-progress="{{isInProgress}}"
  data-has-position="{{hasAssignedPosition}}"
  data-has-runes="{{hasRunes}}"
  data-has-summoner-spells="{{hasSummonerSpells}}"
>
  <div class="bg-img-container">
    <img class="bg-img" src="{{championSplashBackgroundImg}}" />
  </div>
  {{#party}}
  <div
    class="premade"
    data-size="{{size}}"
    data-party="{{party}}"
    data-tip="{{tooltip}}"
  >
    {{#dots}}
    <span class="dot"></span>
    {{/dots}} {{#label}}
    <span class="type-caption--bold label">{{text}}</span>
    {{/label}}
  </div>
  {{/party}} {{#mostPlayed}}
  <div class="most-played" data-tip="{{tooltip}}">
    {{#list}}
    <img src="{{src}}" width="16" height="16" />
    {{/list}}
  </div>
  {{/mostPlayed}} {{#rankBorder}}
  <div class="{{class}}">
    <div
      class="rank-icon"
      data-place="{{tooltipPlacement}}"
      data-tip="{{tooltip}}"
    >
      <div>{{{icon}}}</div>
      <span class="type-caption--bold">{{romanNumeral}}</span>
      <span class="type-caption--bold">{{wl}}</span>
    </div>
  </div>
  {{/rankBorder}}
  <div class="name">
    <span class="player-name type-subtitle1">
      {{#usingBlitz}}
      <span class="using-blitz">{{{logoSvg}}}</span>
      {{/usingBlitz}}
      <a href="{{profileLink}}">
        <span class="text">{{name}}</span>
      </a>
    </span>
    <span class="champion-name type-mini">{{championName}}</span>
  </div>
  <div class="info">
    <div class="summoner-spells">
      <img src="{{spell1}}" width="20" height="20" />
      <img src="{{spell2}}" width="20" height="20" />
    </div>
    <div class="champ-container">
      <a href="{{championLink}}">
        <img
          src="{{championImgUrl}}"
          class="champ-img"
          width="48"
          height="48"
        />
      </a>
      {{#roleSvg}}
      <button
        class="player-role"
        onclick="{{onClick}}"
        data-tip="{{tooltip}}"
        style="{{style}}"
      >
        <div class="role-icon">{{{roleSvg}}}</div>
        <div class="role-proficiency">{{{proficiencySvg}}}</div>
      </button>
      {{/roleSvg}} {{#champProficiency}}
      <div class="champion-proficiency" data-tip="{{tooltip}}">{{{svg}}}</div>
      {{/champProficiency}}
    </div>
    <div class="runes">
      {{#runes}}
      <div class="rune keystone">
        <img src="{{keystone}}" width="24" height="24" />
      </div>
      <div class="rune secondary-tree">
        <img src="{{secondary}}" width="12" height="12" />
      </div>
      {{/runes}}
    </div>
  </div>
  <div class="tags" onclick="{{showTags}}">
    {{#tags}}
    <div
      class="{{tagClass}}"
      style="{{tagStyle}}"
      data-tooltip="{{tagTooltip}}"
    >
      <span class="type-caption">{{tagText}}</span>
    </div>
    {{/tags}}
  </div>
  <div class="stats-blocks">
    {{#stats}}
    <div
      class="stat-block"
      data-no-stats="{{noStats}}"
      data-hide="{{hide}}"
      style="{{style}}"
    >
      <div class="champion">
        <img src="{{champImage}}" class="champ-img" width="24" height="24" />
      </div>
      <div class="winrate">
        <span class="stat stat-val type-caption--bold">{{winrate}}</span>
        <span class="stat type-caption">{{gamesPlayed}}</span>
      </div>
      <div class="kda">
        <span class="stat stat-val type-caption--bold">{{kda}}</span>
        <span class="stat type-caption">{{kdaString}}</span>
      </div>
      {{#rankIcon}}
      <div class="rank">{{{svg}}}</div>
      {{/rankIcon}}
    </div>
    {{/stats}}
  </div>
  <div class="damage" data-hide="{{hideDamage}}">
    {{#damage}} ${damageTemplate} {{/damage}}
  </div>
  {{#tradeAccept}}
  <div
    class="trade-accept"
    style:--tier-color="{{tierColor}}"
    style:--wr-color="{{winrateColor}}"
  >
    <img src="{{championBgImgUrl}}" class="trade-bg" />
    <span class="type-caption--bold" style="color: var(--shade1);">
      {{title}}
    </span>
    <div class="trade-info">
      <img src="{{championImgUrl}}" class="champ-img" width="48" height="48" />
      <div class="trade-tier">{{{tierIcon}}}</div>
    </div>
    <div>
      <h3 class="type-article-headline">{{championName}}</h3>
      <h6 class="winrate type-caption--bold">{{winrate}}</h6>
    </div>
    <div class="trade-btns">
      <button class="accept type-form--button" onclick="{{accept}}">
        {{acceptText}}
      </button>
      <button class=" type-form--button" onclick="{{decline}}">
        {{declineText}}
      </button>
    </div>
  </div>
  {{/tradeAccept}}
</div>`;

export default template;
