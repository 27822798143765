import { Avatar } from "@/shared/Avatar.jsx";
import { GAME_BOX_ICONS } from "@/app/constants.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition.mjs";

export const LoLIconInner = GAME_BOX_ICONS[GAME_SYMBOL_LOL];

export const LoLIcon = ({width}) => <div style={{width, height: width, color: "black", background: "#D5A038", borderRadius: 8}}><LoLIconInner width={width} height={width} /></div>

Should always try to include the `href`, `linkDesc`, and `alt` prop

```jsx preview="flex column align-start gap-3" hideSource
<Avatar
  avatarWidth={128}
  avatar="https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg"
  variant="square"
  dataMask="square"
  dataIcon={<LoLIcon />}
/>
```

### SquareAvatar

```jsx preview="flex column align-start gap-3" hideSource
<SquareAvatar avatar={null} title="Character name" />
<SquareAvatar avatar="https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg" title="Character name" />
<SquareAvatar avatar={<StarIcon />} title="Character name" />
<SquareAvatar roleIcon={<StarIcon />} avatar="https://blitz-cdn-plain.blitz.gg/blitz/ui/img/fallback.svg" title="Character name" />
```

```ts
type SquareAvatarProps = {
  avatar: string | React.ReactNode;
  title: string | React.ReactNode;
  href?: string;
  roleIcon?: React.ReactNode;
  avatarWidth?: number;
  className?: string;
  linkDesc?: string;
};
```