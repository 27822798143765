**September 21, 2021**
- Reduced body damage

**April 16, 2020**
- Added a new weapon parameter “inaccuracy jump apex”, set to 331.55 for the Desert Eagle

**April 10, 2020**
- Improved accuracy while jumping, lowering the time to recover accuracy after the player lands.

**August 18, 2016**
- Increased fidelity and reduced distortion for fire sounds of the Glock-18, P2000, USP-S and Desert Eagle.
- Unique distant, reload and draw sounds for Glock-18, P2000, USP-S and Desert Eagle

**December 10, 2014**
- Reduced price of Desert Eagle to $700

**July 1, 2014**
- Increased standing and crouching Accurate Range

**February 13, 2014**
- Weapon adjustments based on data collected:
- Increased Desert Eagle accuracy recovery by 2.5%

**May 1, 2013**
- Reduced inaccuracy.

**February 20, 2013**
- Reduced inaccuracy.

**February 13, 2013**
- Increased effective range.

**January 30, 2013**
- Nerfed the Desert Eagle.
