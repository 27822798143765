import { Highlight } from "@/feature-docs/DocsWrapper.style.jsx";

## Introduction

Welcome to the wonderful world of ads!

Some quick notes before we begin!

1. **Ads are a great way to _make money_, but also a great way to
   _lose money_.** Just remember to be both confident and careful,
   don't be afraid to get a second opinion, and everything will be okay!
2. **Ads will break.** Sometimes it's your fault, sometimes it's the ad network's
   fault, and sometimes it's simply an act of The Great God Almighty
   (aka. Google). It's important to be able to _identify the source of the
   problem_ and _fix it_ as soon as possible.
3. **Most of ads engineering is not programming**, but rather _critical thinking_
   and _decision making_ which directly affect our revenue. And also reading
   documentation. Lots of documentation.
4. **Ads are a team effort**. You are not alone in this. We make decisions
   together, we solve problems together, and we celebrate together.
   _We are a team._

With all that being said, let's get started!

## What are ads?

This may seem like a very obvious question, of course they are the intrusive
flashy boxes that no one likes, right? Well, yes, but also no. Ads are a lot
more than images on a screen. They are a way for us to make money, and they
are a way for us to provide value to our users and our partners, that same
value which is passed to us in the form of ad revenue.

Let's take a look at a basic "ad delivery pipeline":

1. **Request** - The user opens the app, and we send a request to the ad
   network for an ad.
2. **Response** - The ad network responds with an ad.
3. **Display** - We display the ad to the user.

Our job on the frontend is to make sure that we are properly sending requests
to the ad network and properly displaying the ads to our users.

## Let's just show a bunch of ads and make lots of m o ne y !!!

Well, not quite. There are a few things we need to consider before we can
start showing ads. Lots of technical considerations, but also some regulations
and laws. I won't be reciting all of the laws here, but there are a few main
points that we need to be aware of.

### GDPR

GDPR is a regulation in EU law on data protection and privacy in the European
Union and the European Economic Area. It also addresses the transfer of
personal data outside the EU and EEA areas. The GDPR aims primarily to give
control to individuals over their personal data and to simplify the regulatory
environment for international business by unifying the regulation within the EU.

In short, we need to make sure that we are not collecting any personal data
from our users without their consent. This includes things like IP addresses,
device IDs, and so on. More importantly for us, we also need to make sure that
we are not sending personal data to our ad networks without consent.

This is why we have a consent banner on our site in EU countries, managed by
Quantcast Choice (feature-consent).

### CCPA

The California Consumer Privacy Act (CCPA) is a state statute intended to
enhance privacy rights and consumer protection for residents of California,
United States.

This sounds a lot like GDPR, but with one key difference: consent isn't
demanded by the CCPA, but rather the ability to opt-out of data collection.

This means we do not need explicity consent (opt-in), we just need a way for
users to opt-out of data collection.

### Google Policy

Google is the reason for most of the visible regulatory changes to our site.
While the previous data privacy regulations are managed by a 3rd party, Google
has additional requirements that we must follow in order to use their ad
networks.

One of the most important requirements is that we must have a privacy policy
on our site. This is a legal document that explains to our users what data we
collect and how we use it. This is a requirement for all sites that use Google
Ad Manager, and it is enforced by Google. Luckily that is basically a one-and-done
deal, and we don't have to worry about it too much after we have this in place.

We must also distinguish the advertising content from the rest of the site.
This is why we have the "Ads" label on our ads along with the backdrop around
the ads that has at least a minimum of 4px margin around the ads.

One that's specific for us to be able to show Google AdX ads is that we must
make our app look like a "browser" instead of an "app". This means that we
must have a URL bar, and we must have a "back" button. This is why we have
the title bar on the app (and not on web).

## So how do we do ads?

In the world of ads there is no permanent solution, APIs and requirements are
always evolving as the industry changes.
So, let's explore our current implementation of ads and how it works.

### Register slots

Alright! So first thing we should do is request an ad, right?
But wait! Where will we show the ads?
Well, first we have to register the slot, or the container where the ad
will be shown.
We do this by calling the appropriate Google Tag Manager function.

<figure>
```js
// This is the name of the slot. These are defined in Google Ad Manager.
const slotName = "/6355419/Travel/Europe/France/Paris";

// This is the size of the ad. For display ads we generally use 300x250.
const sizes = [[300, 250]];

// This is the id of the element where the ad will be shown.
const id = "arbitrary-unique-element-id";

// Defer the call to googletag until the library is loaded.
return googleCmd(() => {
// This returns a googletag.Slot object.
// We can safely set and get element-persistent properties on this object,
// as long as we do not shadow any of the properties defined by Google.
const slot = googletag.defineSlot(slotName, sizes, id);
slot.addService(googletag.pubads()); // This is required to show ads
return slot;
});

````
<figcaption>Ex: Registering a slot</figcaption>
</figure>

<Highlight color="yellow">
> It is important that all code that references `googletag` are wrapped
in `googleCmd()`. This ensures that the code is only run after the
Google Tag Manager library is loaded.
</Highlight>

<blockquote>
**Q:** Why not use Promises instead?

---
**A:** Well, we do know exactly when the library is loaded,
but using googleCmd is a good practice to follow as there are some cases
where we want  to enqueue a function, such as our MutationObserver
implmentation below.
The benefit of using `googleCmd` in this case is that we do not *wait* for
the library to be loaded, we tell the library to run it when it loads.
So, in the case that we do not load GAM, we are never creating a dangling
Promise.
</blockquote>

We accomplish slot registration by attaching a MutationObserver which will
watch for new elements. When we find an element with an id in our master list,
we register the slot with the appropriate slot name and sizes.

**The master list is a map of element ids to slot configs,
including the slot name and sizes.**

<figure>
```json
{
  "display-rr-1": {
    "slot": "/6355419/Travel/Europe/France/Paris",
    "sizes": [[300, 250]]
  }
}
````

<figcaption>Master list structure</figcaption>
</figure>

Using this method,
we will always know that a given element id will be registered with
the same slot name and sizes.

### Ad request

We use a technology called header bidding to send ad requests to multiple
networks (SSPs) at the same time.
An SSP (Supply Side Platform) is a company that connects publishers (us) with
advertisers (the people who pay us money).
Our ad networks include Google, Amazon, and Pubmatic. Each of the ad networks
has its own own demand (Google: AdX/Adsense, Amazon: A9, Pubmatic: OpenWrap).
You may see these names floating around, as well as some more SSPs that are
managed by one of these networks (for example, Adagio, Appnexus, and Rubicon
are aggregated through OpenWrap).

#### KVs

We compile our request by setting the targeting of the page or the slot.

> Targeting is also referred to as "key-value pairs", "kvp", or just KVs.

Targeting includes info about whether the user is on web or app,
the app version, which game the user is on, and so on.
For the most part, we use these for our internal reporting, but they are also
passed to the ad networks so that they may target the ads to this data.

<Highlight>
  > It's important that KVs are set properly. This means that they are set to
  the correct values AND they are set at the proper time, which is *before* the
  ad request is sent.
</Highlight>

<figure>
```json
{
  "platform": "blitz-chrome",
  "version": "2.1.64",
  "prod": "0",
  "display_provider": "blitz",
  "game": "lol",
  "route": "lol/tier-list",
  "refresh_rate": "30",
  "mod": "0",
  "rr_count": "3",
  "refresh_time": "30",
  "refresh_count": "5"
}
```
<figcaption>Ex: Key-value pairs</figcaption>
</figure>

<figure>
```js
googleCmd(() => {
  // Some KVs are page-level, which apply to all slots on the page.
  googletag.pubads().setTargeting("platform", "blitz-chrome");
  googletag.pubads().setTargeting("version", "2.1.64");
  googletag.pubads().setTargeting("prod", "0");
  // Some KVs are slot-level, which apply to only one slot.
  // Slot-level KVs override page-level KVs, since they are more specific.
  slot.setTargeting("refresh_time", "30");
  slot.setTargeting("refresh_count", "5");
});
```
<figcaption>
  Ex: Setting KVs. *Note that all Values must be `string`!*
</figcaption>
</figure>

#### Outgoing request

Once the slot is registered and targeting is set,
we can send the request to the ad servers.

We send these requests to all of our SSPs, and then we choose the best ad
(based on the money they want to pay) from the responses we get.
This process of selecting the best ad is called "auction", and this "bidding"
process is done by the ad server.

Generally speaking, our requests follow this structure:

```diff
- Google (Ad Manager / Network)
  - AdX/AdSense (SSP)
  - Pubmatic (Ad Network)
    - OpenWrap
      - Adagio (SSP)
      - Appnexus (SSP)
      - Other SSPs
  - Amazon (Ad Network)
    - A9 (SSP)
```

In our use case, OpenWrap and all other SSPs are managed by Pubmatic,
so we can think of it as a single request to Pubmatic.
Likewise, A9 is managed by Amazon and AdX is managed by Google.

```diff
- Google
  - Pubmatic
  - Amazon
```

So, now we have a request to Google, Pubmatic, and Amazon. But we can't just
fire all 3 requests at the same time! To understand why, we need to understand
what the Pubmatic and Amazon requests actually do.

When we receive a response from Pubmatic or Amazon, we are not actually
getting an ad at that time. Instead, we are getting a "bid", which is a
promise to pay us a certain amount of money if we show their ad.
This is called "header bidding", and it is a way for us to get the best price
for our ad space using multiple networks. The bid info is stored in the
KVPs of the slot for the ad server (in this case, Google Ad Manager) to
use when it performs the auction.

<figure>
```js
function requestPubmatic(slots) {
  return new Promise((resolve) => {
    // removes previous bid results
    PWT.removeKeyValuePairsFromGPTSlots(slots);
    const pwtSlots = PWT.generateConfForGPT(slots);

    PWT.requestBids(pwtSlots, function addValuesToGPT(response) {
      // adds new bid results to the KVs
      PWT.addKeyValuePairsToGPTSlots(response);
      resolve(response);
    });

});
}

````
<figcaption>
Ex: Requesting bids from Pubmatic. We wrap it in a Promise to know
when we have received a bid response.
</figcaption>
</figure>

<figure>
```js
function requestAmazon(slots) {
  return new Promise((resolve) => {
    apstag.fetchBids({ slots }, function addValuesToGPT(response) {
      // adds new bid results to the KVs
      apstag.setDisplayBids();
      resolve(response);
    });
  });
}
````

<figcaption>
Ex: Requesting bids from Amazon. We wrap it in a Promise to know
when we have received a bid response.
</figcaption>
</figure>

After making these requests and receiving the responses, we now have all of
the requirements to send the request to Google Ad Manager.

When we request ads from Google, we pass in the slots that we want to refresh.
Google then will run the auction and select the best ad from the bids we
received from Pubmatic and Amazon, as well as their own ad network.

<figure>
```js
function requestGoogle(slots) {
  return googleCmd(() => {
    // This function actually does A LOT!!
    googletag.pubads().refresh(slots);
  });
}
```
<figcaption>
Ex: Requesting ads from Google.
</figcaption>
</figure>

The pubads `refresh()` function does a lot more than run the auction.
This function from Google actually takes care of sending the request to
Google Ad Manager, and then displaying the ad to the user. It is only our
responsibility to make sure that we have properly constructed the request,
prior to calling this function.

Putting it all together, a single display request will look like this:

```js
async function requestAds(slots) {
  // note we can run Amazon and Pubmatic in parallel
  await Promise.all(requestPubmatic(slots), requestAmazon(slots));
  // Google MUST run last here! Once this is called, no further changes
  // will affect the outgoing request.
  return requestGoogle(slots);
}
```

As noted in the comment, Google MUST run last. This is because once we call
`googletag.pubads().refresh()`, no further changes will affect the outgoing
request. This means that if we call `setTargeting()` after `refresh()`,
the KVs will not be sent to the ad server.

<Highlight color="red">
  > Mistiming these requests can result in using stale bids, which may mean we
  are not getting the best price for our ad space, or even worse, re-use of
  bids, which will result in total loss of revenue for that ad request.
</Highlight>

Okay, so the bids were run and the ad is now shown! Great, time to swim in
money, right? Well, not quite.

- First of all, we have to make sure we are only showing ads when it would
  actually be visible to the user. This is called "viewability", and it is
  a very important metric for advertisers.
- In order to show more than the first ads that load for the user,
  we have to **refresh** the ads. This means that we send another request to
  the ad server to get a new ad, and then we replace the old ad with the new ad.
  We do this periodically to ensure that we are generating revenue throughout
  the entirety user's session, not just the start.
- We also have to make sure that we are collecting data about the ad impressions
  so we can further optimize our ad stack.

_To be continued..._
