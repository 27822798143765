**March 22, 2018**
- Improved accuracy while firing in bursts
- Slightly reduced time until significant accuracy recovery
- Price lowered to $1700
- Reduced armor penetration
- Movement speed is significantly reduced while firing

**April 12, 2017**
- For a limited time, the Negev and R8 Revolver are available in Competitive Matchmaking.
- Price reduced to 2000 as a starting point, to promote experimentation with the weapon.

**March 24, 2017**
- R8 Revolver and Negev have been removed from Competitive Matchmaking as they undergo substantial revision.
- New attributes to promote suppressive fire.
- Reduced price.
- New sounds that dynamically indicate weapon accuracy.
