**April 30, 2018**
- Replaced custom Terrorist one-handed walking animations for the MAC10, MP7, and MP9 with an alternative two-handed version to improve weapon recognition at a glance.

**July 27, 2016**
- Increased fidelity of firing sounds for P90, MP7 and MP9
- Added unique reload and draw sounds for P90, MP7 and MP9

**March 31, 2015**
- The accuracy of the MP9, MP7, and Mac-10 have been improved.

**October 2, 2014**
- All SMGs now have ~20% more armor penetration.
