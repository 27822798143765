import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { Outcome } from "@/game-val/CommonComponent.jsx";
import { Separator } from "@/game-val/components/Overlays.style.jsx";
import { PlayerImage } from "@/game-val/components/PlayerTable.jsx";
import {
  PlayerName,
  RankIcon,
} from "@/game-val/components/PlayerTable.style.jsx";
import TeamComparison from "@/game-val/components/TeamComparison";
import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import { Rank } from "@/game-val/OverlayPlayerRank.jsx";
import { valLoadingScreenRefs } from "@/game-val/refs.mjs";
import {
  calcWinrate,
  getAgentName,
  getNameTag,
  getOutcomeShort,
  getOutcomeShortColor,
  getWinRateColor,
  scoreColorStyle,
} from "@/game-val/utils.mjs";
import useAgentId from "@/game-val/utils/use-agent-id.mjs";
import CaretDown from "@/inline-assets/caret-down.svg";
import Party from "@/inline-assets/party.svg";
import BlitzWordmarkWithGG from "@/inline-assets/wordmark-with-gg.svg";
import { LogoContainer } from "@/shared/OverlayContainerWithAd.style.jsx";
import { classNames } from "@/util/class-names.mjs";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(10 * var(--sp-10e));
  bottom: var(--sp-4_5e);
  background: var(--shade9-75);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  overflow: hidden;

  .header {
    background: var(--shade10-75);
    display: flex;
    calc(var(--sp-1e) + var(--sp-pxe)) 0 0;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .content {
    background: var(--shade9-75);
    padding: var(--sp-3e);
  }

  .filters-container {
    height: var(--sp-8e);
    color: var(--shade0-50);
    .filters div {
      position: relative;
      cursor: pointer;
      &.active {
        color: var(--shade0);
      }
      svg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: var(--sp-4e);
        width: var(--sp-4e);
        margin-top: calc(-1 * var(--sp-2_5e));
      }
    }
  }
  .players-table {
    gap: var(--sp-0_5);
    .player-detail {
      &.player {
        color: var(--yellow);
      }
    }
    .no-match {
      color: var(--shade0-50);
      text-transform: uppercase;
    }
  }
`;

const PlayerRow = styled("div")<{ $stat: number }>`
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  align-items: center;
  padding-left: var(--sp-0_5e);
  padding-right: var(--sp-2e);
  background: var(--shade4-15);
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background: var(--shade1-15);
    height: 100%;
    width: ${({ $stat }) => $stat * 100}%;
    border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  }
  &.player-team {
    &:after {
      background: var(--turq-15);
    }
  }
  &.enemy-team {
    &:after {
      background: var(--red-15);
    }
  }
  &.player {
    &:after {
      background: var(--yellow-15);
    }
  }
  .stat {
    font-size: var(--sp-3e);
  }
`;

const SORT_BY = {
  winRate: {
    key: "winRate",
    label: ["lol:winRate", "Win rate"] as Translation,
    teamCompLabel: ["val:aiWinPrediction", "AI Win Prediction"] as Translation,
  },
  damage: {
    key: "damage",
    label: ["common:stats.damage", "Damage"] as Translation,
    teamCompLabel: ["val:stats.avgDamage", "Avg. Damage"] as Translation,
  },
  headshot: {
    key: "headshot",
    label: ["common:headshot", "Headshot"] as Translation,
    teamCompLabel: ["val:stats.avgHeadshot", "Avg. Headshot"] as Translation,
  },
  combatScore: {
    key: "combatScore",
    label: ["common:combatScore", "Combat Score"] as Translation,
    teamCompLabel: [
      "val:stats.avgCombatScore",
      "Avg. Combat Score",
    ] as Translation,
  },
  matchHistory: {
    key: "matchHistory",
    label: ["val:matchHistory", "Match History"] as Translation,
    teamCompLabel: [
      "val:recentMatchHistory",
      "Recent Match History",
    ] as Translation,
  },
};

function PlayerNameComp({ gameName, agentId, incognito, isMe }) {
  const { t } = useTranslation();
  const { agentKey, agentName } = useAgentId(agentId);
  return (
    <p
      className={
        classNames("type-caption--bold", PlayerName(), "long").className
      }
    >
      {isMe || !incognito ? gameName : getAgentName(t, agentKey, agentName)}
    </p>
  );
}

function LoadingScreenPlayers({ players = [], queue }) {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState(SORT_BY.winRate.key);
  const state = useSnapshot(readState);
  const {
    val: {
      profiles,
      playerMatchWinHistory,
      content: { acts, ranks },
    },
  } = state;

  const playerTeamId = players?.find((p) => p.isMe)?.teamId;
  const [playerList, rawTeamStats] = useMemo(() => {
    const latestActId = acts.find((act) => act.endedAt === null)?.uuid;
    const teamStats = {
      enemy: {
        matchesWon: 0,
        matchesPlayed: 0,
        damagePerRound: 0,
        headshots: 0,
        bodyshots: 0,
        legshots: 0,
        score: 0,
        roundsPlayed: 0,
      },
      your: {
        matchesWon: 0,
        matchesPlayed: 0,
        damagePerRound: 0,
        headshots: 0,
        bodyshots: 0,
        legshots: 0,
        score: 0,
        roundsPlayed: 0,
      },
    };
    const partyMembers = players.filter(
      ({ isPartyMember }) => isPartyMember,
    ).length;

    const playerList = players.map((player) => {
      const {
        gameName,
        tagLine,
        isMe,
        agentId,
        teamId,
        isPartyMember,
        incognito,
      } = player;
      const profile = profiles[getNameTag(gameName, tagLine)];
      const rankInfo = ranks?.find(
        (r) => r.position === profile?.valorantProfile?.latestTier,
      );
      const winHistoryRaw =
        playerMatchWinHistory[profile?.puuid]?.[latestActId]?.[queue];
      const winHistory =
        winHistoryRaw &&
        !(winHistoryRaw instanceof Error) &&
        Array.isArray(winHistoryRaw)
          ? winHistoryRaw
          : [];
      const teamToAdd =
        teamId === playerTeamId ? teamStats.your : teamStats.enemy;
      const {
        matchesWon,
        matchesPlayed,
        damagePerRound,
        headshots,
        bodyshots,
        legshots,
        score,
        roundsPlayed,
      } = winHistory.reduce(
        (acc, match) => {
          if (match.won) {
            acc.matchesWon += 1;
            teamToAdd.matchesWon += 1;
          }
          acc.matchesPlayed += 1;
          teamToAdd.matchesPlayed += 1;
          Object.keys(match).forEach((key) => {
            if (key in acc) acc[key] += match[key];
            if (key in teamToAdd) teamToAdd[key] += match[key];
          });
          return acc;
        },
        {
          matchesWon: 0,
          matchesPlayed: 0,
          damagePerRound: 0,
          headshots: 0,
          bodyshots: 0,
          legshots: 0,
          score: 0,
          roundsPlayed: 0,
        },
      );
      const winRate = calcWinrate({
        wins: matchesWon,
        matches: matchesPlayed,
      });
      const adr = calcRate(damagePerRound, matchesPlayed);
      const avgScore = calcRate(score, roundsPlayed);
      return {
        player: (
          <div
            className={
              classNames(
                "flex align-center gap-1 player-detail",
                isMe ? "player" : "",
              ).className
            }
          >
            <PlayerImage
              isMe={isMe}
              agentId={agentId}
              isEnemy={teamId !== playerTeamId}
            />
            <Rank rankInfo={rankInfo} isRankVisible className={RankIcon()} />
            <PlayerNameComp
              isMe={isMe}
              incognito={incognito}
              agentId={agentId}
              gameName={gameName}
            />
            {(isPartyMember || isMe) && partyMembers > 1 ? (
              <div className="flex align-center justify-center gap-1">
                <Party />
                <p className="type-caption--bold">{partyMembers}</p>
              </div>
            ) : null}
          </div>
        ),
        matchesPlayed,
        isMe,
        isEnemy: teamId !== playerTeamId,
        [SORT_BY.winRate.key]: {
          display: (
            <p
              className="type-overline stat"
              style={{ color: getWinRateColor(winRate) }}
            >
              {displayRate(winRate, 100)}
            </p>
          ),
          value: winRate,
        },
        [SORT_BY.damage.key]: {
          display: (
            <p className="type-overline stat">
              {adr.toLocaleString(getLocale(), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </p>
          ),
          value: adr,
        },
        [SORT_BY.headshot.key]: {
          display: (
            <p className="type-overline stat">
              {displayRate(headshots, headshots + bodyshots + legshots, 0, 1)}
            </p>
          ),
          value: calcRate(headshots, headshots + bodyshots + legshots, 1),
        },
        [SORT_BY.combatScore.key]: {
          display: (
            <p
              className="type-overline stat"
              style={{ color: scoreColorStyle(score) }}
            >
              {avgScore.toLocaleString(getLocale(), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </p>
          ),
          value: avgScore,
        },
        [SORT_BY.matchHistory.key]: {
          display: (
            <div className="flex justify-center">
              {winHistory.map(({ won }, index) => {
                const outcome = won ? "win" : "loss";
                return (
                  <Outcome className={outcome} key={index}>
                    <span
                      className="type-caption--bold"
                      style={{ color: getOutcomeShortColor(outcome) }}
                    >
                      {getOutcomeShort(t, outcome)}
                    </span>
                  </Outcome>
                );
              })}
            </div>
          ),
          value: matchesWon,
        },
      };
    });

    return [playerList, teamStats];
  }, [
    acts,
    playerMatchWinHistory,
    playerTeamId,
    players,
    profiles,
    queue,
    ranks,
    t,
  ]);

  const teamStats = useMemo(() => {
    const stats = { enemy: {}, your: {} };
    const enemyWinRate = calcRate(
      rawTeamStats.enemy.matchesWon,
      rawTeamStats.enemy.matchesPlayed,
    );
    const playerWinRate = calcRate(
      rawTeamStats.your.matchesWon,
      rawTeamStats.your.matchesPlayed,
    );
    for (const team of Object.keys(stats)) {
      const {
        score,
        roundsPlayed,
        headshots,
        bodyshots,
        legshots,
        damagePerRound,
        matchesWon,
        matchesPlayed,
      } = rawTeamStats[team];
      const winRate = calcRate(matchesWon, matchesPlayed);
      const adr = calcRate(damagePerRound, matchesPlayed);
      const avgScore = calcRate(score, roundsPlayed);
      stats[team] = {
        [SORT_BY.winRate.key]: {
          display:
            matchesPlayed > 0
              ? displayRate(winRate, playerWinRate + enemyWinRate)
              : "-",
          value: winRate,
        },
        [SORT_BY.damage.key]: {
          display:
            matchesPlayed > 0
              ? adr.toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : "-",
          value: adr,
        },
        [SORT_BY.headshot.key]: {
          display: displayRate(
            headshots,
            headshots + bodyshots + legshots,
            0,
            1,
          ),
          value: calcRate(headshots, headshots + bodyshots + legshots, 1),
        },
        [SORT_BY.combatScore.key]: {
          display: avgScore.toLocaleString(getLocale(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          value: avgScore,
        },
        [SORT_BY.matchHistory.key]: {
          display: t("val:matchWinsLosses", "{{wins}}W {{losses}}L", {
            wins: matchesWon,
            losses: matchesPlayed - matchesWon,
          }),
          value: matchesPlayed,
        },
      };
    }
    return stats;
  }, [t, rawTeamStats]);

  const sortedPlayerList = playerList.sort(
    (a, b) => b[sortBy].value - a[sortBy].value,
  );
  const topPlayer = sortedPlayerList[0];

  return (
    <Container>
      <div className="header">
        <LogoContainer>
          <BlitzWordmarkWithGG />
        </LogoContainer>
      </div>
      <div className="content">
        <TeamComparison
          title={SORT_BY[sortBy].teamCompLabel}
          enemy={teamStats.enemy[sortBy]}
          your={teamStats.your[sortBy]}
        />
        <Separator />
        <div className="flex justify-between align-center filters-container">
          <div className="flex justify-between gap-sp-2 filters">
            {Object.values(SORT_BY).map(({ key, label }) => (
              <div
                key={key}
                className={sortBy === key && "active"}
                onClick={() => setSortBy(key)}
              >
                {sortBy === key ? <CaretDown /> : null}
                <p className="type-overline">{t(...label)}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="players-table flex column">
          {sortedPlayerList.map((playerListItem, i) => {
            const stat = calcRate(
              playerListItem[sortBy].value,
              topPlayer[sortBy].value,
            );
            return (
              <PlayerRow
                key={i}
                className={
                  classNames(
                    "flex justify-between",
                    playerListItem.isMe
                      ? "player"
                      : playerListItem.isEnemy
                        ? "enemy-team"
                        : "player-team",
                  ).className
                }
                $stat={stat}
              >
                {playerListItem.player}
                {playerListItem.matchesPlayed === 0 ? (
                  <p className="type-caption--bold no-match">
                    {t("val:noMatchFoundThisAct", "No match found this act")}
                  </p>
                ) : (
                  playerListItem[sortBy].display
                )}
              </PlayerRow>
            );
          })}
        </div>
      </div>
      {valLoadingScreenRefs.VideoAd ? (
        <valLoadingScreenRefs.VideoAd
          overlayId={OVERLAY_IDS.valLoadingMatchAd}
        />
      ) : null}
    </Container>
  );
}

export default LoadingScreenPlayers;
