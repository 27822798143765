**December 13, 2022**
- Fixed Molotov and Incendiary grenades detonating in midair when flying over skybox areas.

**September 16, 2016**
- Molotovs are no longer extinguished by smokes that are significantly below them, so now it is possible to smoke inside hut on Nuke and throw a molotov on top of hut, or smoke under palace balcony on Mirage and throw a molotov on balcony floor.
- Molotovs are no longer extinguished by smokes that are above the fire height.
- Molotovs no longer spread under closed doors.

**August 22, 2013**
- Price reduced from$500 to $400

**January 23, 2013**
- Price reduced from $600 to $500.

**December 3, 2012**
- Molly/Inc Grenade fire now goes out slightly faster when extinguished and updated the overall effects a bit.

**November 16, 2012**
- Improved some cases of particle sorting between smoke and molotovs and other particle systems.
