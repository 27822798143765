import React from 'react';

import {
  MatchList as SharedMatchList,
  MatchTile as SharedMatchTile,
} from "@/shared/Profile.jsx";
import {
  ProfileMatchlist,
} from "@/shared/Profile.style.jsx";


```js
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
```

Most (if not all) player profiles for a game have a running list of matches played, each of which is represented by a `MatchTile`. The `MatchTile` is a compact representation of a match, showing the match's date, result, and various stats. There are various click locations: the match tile itself, which should navigate to the match details page/route, and the expand button which should lazily render some additional info.

## Example Match Tile (League of Legends)
```jsx preview
<ProfileMatchlist>
  <SharedMatchList matchList={[5012856788]}>
    <React.Fragment key={5012856788}>
      <SharedMatchTile
        id={5012856788}
        height={142} // Roughly the height of the rendered match tile, for lazy loading
        match={{}}
      >
        <MatchTileExpand
          isWin={true}
          isMvp={true}
          image={{
            src: "https://blitz-cdn.blitz.gg/blitz/lol/champion/63.webp",
            alt: "Brand - League of Legends",
            style: {},
          }}
          tabDefault="MatchTileTabsEnum.Scoreboard"
          tabsToElement={{ "MatchTileTabsEnum.Scoreboard": () => import("@/game-lol/components/MatchScoreboard.jsx") }}
          tabsLabel={[
            {
              name: ["tft:matchtabs.scoreboard", "Scoreboard"],
              key: "MatchTileTabsEnum.Scoreboard",
            }
          ]}
          tabArgs={{
            matchId: 5012856788,
            region: "na1",
          }}
          urlWeb={"#"}
          urlCanonical={"#"}
          titles={[
            {
              text: "Victory",
              bold: true,
              color: "var(--turq)",
            },
            {
              text: "Ranked",
            }
          ]}
          matchDate={new Date()}
          aside={
            <div>
              <img src="https://blitz-cdn.blitz.gg/blitz/lol/champion/11.webp" width="60" height="60" />
              <span>Arbirary side component</span>
            </div>
          }
        >
          <div>Misc. custom component for rendering League specific info</div>
        </MatchTileExpand>
      </SharedMatchTile>
    </React.Fragment>
    <React.Fragment key={5012856789}></React.Fragment>
  </SharedMatchList>
</ProfileMatchlist>
```

## Visual Reference
Base parts of the core of a match tile:
![docs match tile 1](https://blitz-cdn.blitz.gg/dev/docs-match-tile-1.jpg)

Expanded match tile components. These are optional, without them there will not be an expand button
![docs match tile 2](https://blitz-cdn.blitz.gg/dev/docs-match-tile-2.jpg)