import { Highlight } from "@/feature-docs/DocsWrapper.style.jsx";

**The app is architected around a few core assumptions about the product.**

- Most of our users will be using the desktop app as a Single-Page Application. This is partly because SSR would be prohibitively expensive for the scale, but also SPA is better user experience for a desktop app.
- SSR is only needed for search engines, pretty much nothing else. It can be considered as sort of an afterthought, besides our main use case which is SPA.
- Most users will use Blitz primarily for one or two games (that is the LoL app right now), so focus on making those experiences feel good to use.
- The backend may not always be available, and that may not even be our fault either but rather external APIs. Support the offline use case well because we anticipate outages.

---

The following details the technical decisions.

## React as a View Layer

We are using React as a view layer, not as a framework, so we are not starting with the defaults provided by `create-react-app`. The architecture is actually a featherweight:

```sh
$ cloc --force-lang="TypeScript",mts src/__main__
      14 text files.
      14 unique files.
       1 file ignored.

github.com/AlDanial/cloc v 1.86  T=0.04 s (311.2 files/s, 116841.3 lines/s)
-------------------------------------------------------------------------------
Language                     files          blank        comment           code
-------------------------------------------------------------------------------
TypeScript                      11            763            415           3441
JavaScript                       2             83             35            456
Markdown                         1             29              0             34
-------------------------------------------------------------------------------
SUM:                            14            875            450           3931
-------------------------------------------------------------------------------
```

The lines of code in the main modules might be shorter than expected, because it is only a small set of rules to build upon.

## The Rules

<Highlight>
**The basic rules should be easy to grasp:**

- Only read from `readState` to avoid accidental mutation, only write to `writeState`.
- Use `getData` to fetch data.
  - Define data models to pass into `getData` to ensure runtime safety.
- Everything is loaded in the app as native ES modules, and there are only two kinds:
  - **Routes** are actually ES module entry points.
    - Only fetch data via a route's `fetchData` function.
    - Using the `useRoute` hook from a React component will tell you everything about the current route.
  - **Feature modules** are also ES module entry points.
    - Features must be able to `setup` and `teardown` in runtime.
    - The base app can not import from a feature module, but features can import anything (see `refs`).

That's all there is to it! Those are all of the fundamentals. _You don't need to know how they're implemented to use them effectively._

</Highlight>

---

The app is organized around a build tool, esbuild, that supports ES modules natively, a few core modules and static/runtime enforcement of rules.

**Most of the architectural decisions are based on real-world constraints.**

- We're using React simply because most of the team knows React and hiring is fairly easy.
- We're using `ReactDOMServer.hydrateRoot` instead of using a server-side framework like Next.js because SSR is a secondary use case.
- We're using a custom router because it is built specifically with native ES modules and code-splitting in mind, and it supports SSR without buy-in from frameworks.
- We need to support offline really well because our main use case is a desktop app, and some of the data may be user-generated. To support this, we are using an indexeddb wrapper that integrates with our app state seamlessly.

---

There are really only a few custom core modules that make blitz-app work:

- `app-state.mts` - thin wrapper around valtio that handles reading, writing, and memory management.
- `router.mts` - a custom router that loads an ES module for a route.
- `get-data.mts` - convenience function that combines fetching, caching, data validation, and app state all in one. This is the "for dummies" way to get data into the app and should be mostly foolproof.
- `data-model.mts` - casting data to pre-defined models. **This module alone drastically reduces runtime errors.**
- `feature-flags.mts` - runtime ES modules loading/unloading.
- `db.mjs` - indexeddb wrapper using idb-keyval.

## Architectural Components

### State management using Valtio + IndexedDB

[Valtio](https://github.com/pmndrs/valtio) is used for state management. It is chosen over Redux because it results is much less boilerplate: no selectors, actions are optional, no reducers. We have integrated it with idb-keyval, so that individual branches of the state tree are persisted automatically. Data eventually becomes stale and purged if it hasn't been accessed in a while, so it doesn't grow indefinitely.

### ES Module dynamic imports

We are using native dynamic imports to do code-splitting by route (URL). Each route has an entry point defined by its `component` file.

### Standalone router, router hooks

The router is implemented in-house, because of the above constraint. Not only that, the router is built with only React hooks in mind, so using the router is as simple as including the router hooks. Links are implemented as basic HTML `<a href="...">` tags, there are no special components to use.

### Shared component architecture

There are "headless" components in the `shared` directory that provide functionality without dictating what elements may appear. This reduces maintenance costs across the entire app by re-using as much common functionality as possible.

### 1:1 codebase for web/desktop

The entry point for both web and desktop builds are the same. The main difference is that the desktop build will have functioning IPC using `blitzMessage`, and this is a no-op on web.

### SSR with dynamic imports

Due to how the router works, we can await the promise for when a module is dynamically imported, and also for all the data on the route, before rendering to string. There are no (popular?) open-source solutions at the time of writing that does this, with the possible exception of [Remix](https://remix.run/).
