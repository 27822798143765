import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { appURLs } from "@/app/constants.mjs";
import {
  shouldDisplayActReviewPromo,
  useActStats,
} from "@/game-val/use-act-review-data.mjs";
import { getActOptions, getAgentImage, getNameTag } from "@/game-val/utils.mjs";
import { useConsoleState } from "@/game-val/utils/console.mjs";
import { PromoSplash } from "@/shared/SeasonReview.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const EVENT_SEASON_REVIEW_PROMO_CLICK = "EVENT_SEASON_REVIEW_PROMO_CLICK";

const imageCSS = () => css`
  & {
    display: none;
  }
`;

const borderCSS = () => css`
  & {
    width: 35cqw !important;
    translate: -50% -40% !important;
  }
`;

function SeasonReviewPromo({ profile }) {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const { isConsole } = useConsoleState();
  const {
    val: {
      content: { acts },
    },
  } = state;

  const {
    gameName,
    tagLine,
    puuid,
    valorantProfile: { lastAgent = {} } = {},
  } = profile ?? {};
  const profileId = getNameTag(gameName, tagLine);

  // @ts-ignore
  const actOptions = getActOptions(acts);
  const actId = actOptions[1]?.key;
  const actEndDate = actOptions[1]?.endedAt;
  const actStats = useActStats(puuid, actId);

  if (!actStats || !actOptions[1] || !shouldDisplayActReviewPromo(actEndDate))
    return null;

  const actReviewLink = `/valorant/act-review/${profileId}/${actId}${isConsole ? "?console=1" : ""}`;

  return (
    <PromoSplash
      image={undefined}
      imageClassName={imageCSS()}
      borderImage={getAgentImage(
        lastAgent?.name?.toLowerCase() || "cypher",
        "hero",
      )}
      borderImageClassName={borderCSS()}
      link={actReviewLink}
      onClickLink={() => {
        eventBus.emit(EVENT_SEASON_REVIEW_PROMO_CLICK, {
          isModal: false,
          url: actReviewLink,
        });
      }}
      title={t(
        "val:seasonReview.actTitle",
        "{{ episode }} {{ act }} Recap is here!",
        {
          episode: actOptions[1]?.episode,
          act: actOptions[1]?.act,
        },
      )}
      backgroundImage={`${appURLs.CDN}/blitz/val/val-review-background.webp`}
    />
  );
}

export default SeasonReviewPromo;
