Perhaps as important as knowing what decisions were made and why, is knowing why certain outcomes were avoided.

There is the obvious question of _why is Blitz not using an off-the-shelf (meta-)framework?_ The short answer is that actually we are (React). The longer answer is that the app actually has a lot of requirements that are unique to making a frontend for native desktop code, along with other niche requirements such as:

- Using the same codebase to do both SSR (website) and CSR (app) on practically _all of the routes_.
- Supporting ads in a single-page application, which is uncommon.
- Being able to load and toggle features in runtime, which makes native ES modules necessary.
- Quick re-renders of complex UI for live game features. Sometimes this involves [solutions other than React](https://gr0uch.github.io/s2/).
- Keeping memory and network usage during prolonged sessions to a minimum, especially since users can see how much resources our app takes.

It might be even be better to build a native-first application that is ported to the web (like [Figma](https://www.figma.com/)), but we don't have the resources for that as of writing.

## Why not use a meta-framework like Next.js, Remix, Expo, SvelteKit, ...?

Let's start with [the argument that you should use a meta-framework](https://twitter.com/acdlite/status/1617611126514266112). It states that you should use a meta-framework because it provides integrated solutions for data, routing, server rendering, and basically that if you are not using a meta-framework, You Are Wrong™.

Then let us examine the recommendations of the React core team in the not-so-distant past that have not aged well: Higher Order Components, Redux, Create React App, Webpack, Babel, ... not to mention, the hubris of other frameworks with bold claims that are not relevant today: Ember, Angular, Meteor... see where this is going? Picking the most popular option of today is being late on the adoption curve and placing bets on the future based on past successes.

### Performance is bad

This mostly matters in the context of server costs. See [benchmarks](https://github.com/eknkc/ssr-benchmark), Next.js is the slowest by far. If paying orders of magnitude more in servers is no big deal, then this isn't really a concern.

### Framework churn is real

The first commit to Blitz App as we know it was in Sep 2017 (see history of blitz-web) using Create React App. Nearly all of the dependencies were no longer in favor at the time when blitz-app 2.0 was initiated in late 2021, with the exception of React, which itself has a life expectancy. Once React is outmoded by other frameworks, the meta-frameworks on top of React will cease to be relevant with it. These meta-framework also impose a framework tax, you must always keep up with the latest or cease getting support from the framework's community.

**The decision to not use a meta-framework for 2.0 is essentially a bet that the app will outlive fashionable opinions of today.** If that is not the case, then whether or not a meta-framework is used is irrelevant because the app will be dead anyways, and _what framework is chosen is hardly the deciding factor in the business_.

### Small, maintainable main modules

As of writing, Next.js itself has 250k lines of code, not including its dependencies. Our `src/__main__` directory has about ~4k. Isn't this the same as writing your own meta-framework? Although it has a high skill floor to contribute to without breaking things and quite a few unit tests, it is easy enough for one person to understand it all and maintain. The same can not be said for most meta-frameworks out there.

## Why not Vercel SWR, TanStack Query, Apollo, MobX, Zustand, Redux, Recoil, ...?

This one is slightly easier to unpack due to relative lack of consensus around data-fetching and state management as of writing. The `app-state` and `get-data` modules are built for our use-case: lots of temporal data moving in and out of memory, with disk persistence by default. None of the aforementioned have memory management as a concern (when the dataset can be large enough to exceed system memory), nor do they optimize for trading off between memory and diskusing a Least Recently Used (LRU) algorithm.

These libraries do not have automated mechanisms to free up memory (ours is implemented in `state-cleanup` module), partly because they don't anticipate large datasets, and rely on the developers to allow their data to be garbage collected, if that is at all possible. Nor do they fallback to disk and then network when data is not in memory, usually it goes straight to network or service worker cache. In these aspects, I think that our state management solution is more advanced than the aforementioned libraries and will likely remain that way. It is also purposely built for our use case, where a lot of the data we deal with comes from the client itself.

## Why not glue blitz-core and blitz-app together by sharing code or puttting them in a mono-repo?

Because of the hope that we can eventually replace Electron with something better 🙃

The more serious answer is that both evolve independently of each other and communicate via an API contract. The web app moves much faster due to UI changes and other frivolities, while the native parts move much slower, and there is no need to release a whole desktop app just to change some text, nor is there overlap in a lot of cases.

The API contract, `blitzMessage`, provides some degree of backward compatibility, so that exact versions of blitz-core do not have to match up exactly with whatever web page is controlling it, and it is this decoupling that enabled us to move from blitz-web to blitz-app in the first place.
