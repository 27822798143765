Our implementation of _feature flags_ is actually more like **feature modules**, where a module provides add-on functionality to the base application. Here is what we define as a _feature flag_:

**Feature flags are a set of booleans that can be toggled on or off at runtime to modify the app. They are actually implemented as native ES modules with a module API contract.**

## Feature flags as ES modules

This is really the primary takeaway of this document: each `feature-` directory compiles into a standalone module using `mod.mjs` as its entry point. Features are expected to be self-contained, and may not leak into the base application.

## How does this differ from services like Optimizely, Launchdarkly, Google Optimize?

These services provide a centralized backend to conditionally toggle a set of boolean values based on user info. To actually integrate these services with application code, requires [hardcoding conditionals in application code](https://docs.launchdarkly.com/guides/best-practices/improving-code).

**We are doing something different**: instead of conditionals in the base application, we are monkeypatching the base application from feature modules. This has a few advantages:

- The base application is free of feature-flag code, it can be agnostic about what features exist.
- Feature flags are toggled in runtime rather than as initialization or build-time, this is a huge benefit for certain use cases like toggling Pro features on/off, or ads.

There are drawbacks:

- Increased complexity, requires entry points to monkeypatch, handling setup/teardown.
- Minor increase in startup time, since the app will wait for all requested feature modules to be loaded.

## When to use feature flags

A feature flag should only be used **if there is functionality that needs to be toggled on or off in runtime.** If it does not meet this criteria, consider implementing the feature as a route instead.

## Similarities to routes

There are actually quite a lot of similarities to routes:

- Both are loaded only upon a runtime trigger (URL or runtime code).
- Both are native ES modules.
- Both have an API contract to fulfill. Routes must export a `default` component, feature flags must implement `setup` and `teardown` functions.

## Adding a feature flag

Adding a feature flag is as easy as creating a `feature-` directory with a `mod.mjs` file. This file must contain:

```js
export function setup() {...}
export function teardown() {...}
```

This file can import from any other file in the `src` directory, code-splitting will handle it intelligently.

The build system will pick up on it automatically, and it can be toggled on/off with:

```js
__BLITZ_DEV__.featureFlags[nameOfFeature] = true || false;
```

Feature flags have their default value set in `feature-flags.mjs`. They can also be toggled programmatically by importing `featureFlags` from that module.

## Runtime modifications

Features have `setup` and `teardown` functions that are runtime only. They can modify existing components, routes, _anything_!

The way that feature flags can add, remove, and modify existing components relies on creating `refs` objects, or entry points in the codebase to hook into. The main `App.jsx` component has a few entry points of its own, which is used by `feature-ads` to modify the entire layout.

To create `refs` in any component that needs to be hooked into is very easy. `Greeting.refs.jsx`:

```js
import React from "react";

const Component = () => {
  return <p>Hello world!</p>;
};

export default {
  Component,
};
```

`Greeting.jsx`:

```js
import EventedRender from "@/shared/EventedRender.jsx";
import GreetingRefs from "@/shared/Greeting.refs.jsx";

export default function Greeting() {
  return (
    <EventedRender>
      <GreetingRefs.Component />
    </EventedRender>
  );
}
```

Then in a feature module (`mod.mjs`):

```js
import { refs as greetingRefs } from "...";
import { reRender } from "@/shared/EventedRender.jsx";

const originals = {};

export function setup() {
  originals.Component = greetingRefs.Component;
  greetingRefs.Component = () => <p>Goodbye cruel world!</p>;
  reRender();
}

export function teardown() {
  greetingRefs.Component = originals.Component;
  reRender();
}
```

This will replace the existing component with one from the feature flag, and force it to be rendered right away. The teardown function does the opposite.

**IMPORTANT**: make sure that the teardown function reverses everything in the setup function.

## Decommissioning a feature

One of the best features of feature modules is being able to remove it entirely without extra effort. To shelf a feature but still keep its code, just add the `-decommissioned` suffix to the directory name. This has the added benefit of reducing build times.
