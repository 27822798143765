## Size

```jsx preview
  <Tag size="small" text="S Tag"/>
  <Tag size="medium" text="M Tag"/>
  <Tag size="large" text="L Tag"/>
```

## Color

```jsx preview
<Tag
  size="medium"
  text="M Tag"
  color="var(--shade2)"
  hoverColor="var(--blue)"
/>
```
