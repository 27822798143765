import getMatch from "@/game-apex/fetches/get-match.mjs";
import { devError } from "@/util/dev.mjs";

export default async function getMatches(
  gameIds: Array<[string, string]>,
  isAsync = false,
): Promise<void> {
  if (isAsync) {
    try {
      await Promise.all(
        gameIds.map(([gameId, seasonId]) => getMatch(gameId, seasonId)),
      );
    } catch (e) {
      if (e instanceof Error && e.message.includes("no records found")) return;
      devError("Failed to async fetch matches", e);
    }
    return;
  }
  for (const [gameId, seasonId] of gameIds) {
    try {
      await getMatch(gameId, seasonId);
    } catch (e) {
      devError(`Failed to fetch match ${gameId}`, e);
    }
  }
}
