**October 9, 2018**
- Adjusted the CZ75-Auto to encourage semi-automatic fire at medium and long ranges.
- Increased recoil and fire inaccuracy
- Slightly improved accuracy recovery rate

**June 12, 2018**
- Reduced base damage of the CZ75-Auto from 33 to 31 (a single shot is no longer lethal against a helmeted opponent).

**December 15, 2015**
- Reverted recent changes to pistols and the AK-47, M4A4, and M4A1-S (see the CS:GO blog for details).

**December 8, 2015**
- Increased move inaccuracy on pistols: Dual Berettas, Five-SeveN, Glock-18, P2000, P250, Tec-9, USP-S, CZ75-Auto

**January 8, 2015**
- Reverted CZ nerf to ammo and fire rate.

**December 10, 2014**
- Magazine size has been reduced to 8 and reserve ammo is now 16 (3 mags total).
- Damage has been reduced slightly.
- Firing rate has been reduced slightly
- The CZ75-Auto takes nearly twice as long to draw, and has an updated draw animation.
- Kill reward is now 1/3 ($100).
- Updated the weapon description.

**September 2, 2014**
- The CZ75-Auto has been moved from the P250 slot to the Tec-9/Five-SeveN slot and its price has been raised to $500.

**July 1, 2014**
- Decreased standing and crouching Accurate Range
