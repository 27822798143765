import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import Card from "clutch/src/Card/Card.jsx";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

import { appURLs } from "@/app/constants.mjs";
import type { ValorantMetaAgents } from "@/data-models/valorant-meta-agents.mjs";
import { onVolumeChange } from "@/game-val/actions.mjs";
import AgentAbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import { AGENT_COLORS } from "@/game-val/constants.mjs";
import Video from "@/game-val/Video.jsx";
import ValAbilityBracketLeft from "@/inline-assets/val-ability-bracket-left.svg";
import ValAbilityBracketRight from "@/inline-assets/val-ability-bracket-right.svg";
import ValAbilityCharge1 from "@/inline-assets/val-ability-charge1.svg";
import ValAbilityCharge2 from "@/inline-assets/val-ability-charge2.svg";
import ValAbilityCharge3 from "@/inline-assets/val-ability-charge3.svg";
import ValAbilityCost from "@/inline-assets/ValAbilityCost.svg";
import { buildHostedSrc } from "@/shared/VideoEmbed";
import { classNames } from "@/util/class-names.mjs";
import keyInObject from "@/util/key-in-object.mjs";

const chargeIcons = {
  1: ValAbilityCharge1,
  2: ValAbilityCharge2,
  3: ValAbilityCharge3,
  shared2_left: ValAbilityCharge2,
  shared2_right: ValAbilityCharge2,
};

const Abilities = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--sp-3);

  .ability-bracket {
    width: var(--sp-7_5);
    height: var(--sp-3);
    transform: translateY(--sp-3);
    fill: none;
    stroke: var(--shade6);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;

    ${mobile} {
      display: none;
    }
  }
`;

const Ability = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--sp-4);
  cursor: pointer;
  color: var(--shade3);
  transition: var(--transition);

  ${mobile} {
    padding: 0 var(--sp-2);
  }

  &:hover {
    .ability-icon {
      opacity: 0.75;
    }
    .keybind {
      color: var(--shade3);
      background: var(--shade6);
    }
  }

  &.selected {
    .ability-icon {
      color: var(--shade0);
      opacity: 1;
    }

    .keybind {
      color: var(--shade1);
      background: var(--shade5);
    }
  }

  &.cost-highlight {
    .charge-icon {
      color: var(--agent-color, var(--shade2));
      opacity: 1;
    }
  }

  &.shared4_left {
    .charge-icon {
      transform: translateX(var(--sp-4));

      ${mobile} {
        transform: translateX(var(--sp-2));
      }
    }
  }
  &.shared4_right {
    .charge-icon {
      transform: translateX(calc(var(--sp-4) * -1));

      ${mobile} {
        transform: translateX(calc(var(--sp-2) * -1));
      }
    }
  }

  .ability-icon {
    height: var(--sp-12);
    width: var(--sp-12);
    opacity: 0.5;
    transition: var(--transition);

    ${mobile} {
      height: var(--sp-8);
      width: var(--sp-8);
    }
  }
  .charge-icon {
    margin-top: calc(var(--sp-5) * -1);
    height: var(--sp-16);
    transition: var(--transition);

    ${mobile} {
      margin-top: calc(var(--sp-3) * -1);
      height: var(--sp-12);
    }
  }
  .keybind {
    padding-left: var(--sp-2);
    padding-right: var(--sp-2);
    margin-top: calc(var(--sp-4) * -1);
    color: var(--shade3);
    background: var(--shade8);
    border-radius: var(--br-sm);
    transition: var(--transition);

    ${mobile} {
      margin-top: calc(var(--sp-2) * -1);
    }
  }
`;

const AbilityInfoContainer = styled("div")`
  margin-top: -44px;

  ${mobile} {
    margin-top: 0;
  }
`;
const AbilityInfo = styled("div")`
  margin-top: -38px;
  position: relative;

  ${mobile} {
    margin-top: 0;
    padding-top: var(--sp-4);
  }
`;
const AbilityText = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0 var(--sp-22);
  padding-bottom: var(--sp-14);

  ${tablet} {
    padding: var(--sp-8);
  }
  ${mobile} {
    padding: var(--sp-4);
  }

  .ability-byline {
    color: var(--shade3);
    margin-bottom: var(--sp-1);
  }
  .ability-title {
    margin-bottom: var(--sp-2);
  }
  .ability-cost {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: var(--sp-3);

    > span:nth-child(1) {
      color: var(--shade3);
      margin-right: var(--sp-1);
    }
    > span:nth-child(2) {
      display: flex;
      align-items: center;
      color: var(--shade1);

      svg {
        margin-right: var(--sp-1);
      }
    }
    .ult-diamonds {
      display: flex;
      align-items: center;
      margin: 0 var(--sp-1);
    }
    .ult-cost span {
      color: var(--shade3);
    }
  }
  .ability-description {
    color: var(--shade1);
  }
  .ability-stats {
    margin-top: var(--sp-6);
    padding-top: var(--sp-6);
    border-top: var(--sp-px) solid var(--shade6);
  }
  .ability-stats--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sp-4);
    color: var(--shade1);
  }
  .ability-stat {
    display: flex;
    justify-content: space-between;
    padding: var(--sp-4);
    color: var(--shade1);
    background: var(--shade8-50);
    border-radius: var(--br);
  }
`;

const VideoCover = styled("svg")`
  display: block;
  margin: 0 auto;
  position: relative;
  max-width: 581px;
  height: auto;
  fill: var(--card-surface) !important;
  filter: drop-shadow(0 -10px 6px rgba(0, 0, 0, 0.2));
  pointer-events: all;

  ${mobile} {
    display: none;
  }
`;

const UltDiamond = styled("div")`
  height: 6px;
  width: 6px;
  background: var(--agent-color, var(--shade0));
  margin-right: 3px;
  transform: rotate(45deg);
`;

const AgentAbilitiesPlayerWrapper = () => css`
  margin-bottom: var(--sp-4);
`;

const VideosComingSoon = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: var(--shade2);
`;

type MetaAgent = ValorantMetaAgents["list"][number];

interface AgentAbilitiesPlayerProps {
  agent: MetaAgent;
}

function isShared(chargeCount: string | number): chargeCount is string {
  return typeof chargeCount === "string" && chargeCount.startsWith("shared");
}

function isSharedLeft(chargeCount: string | number) {
  return isShared(chargeCount) && chargeCount.endsWith("_left");
}

function isSharedRight(chargeCount: string | number) {
  return isShared(chargeCount) && chargeCount.endsWith("_right");
}

function getAgentAbilityVideo(ability: MetaAgent["abilities"][number]) {
  return buildHostedSrc({
    webm:
      ability?.videoWebm &&
      `${appURLs.CDN_VIDEOS}/valorant/agents/${ability.videoWebm}`,
    mp4:
      ability?.video &&
      `${appURLs.CDN_VIDEOS}/valorant/agents/${ability.video}`,
  });
}

export default function AgentAbilitiesPlayer({
  agent,
}: AgentAbilitiesPlayerProps) {
  const { t } = useTranslation();
  const [selectedAbility, setSelectedAbility] = useState(0);

  const handlyAbilitySelect = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >((e) => {
    const clickedIndex = e.currentTarget.dataset.index;
    if (!clickedIndex) return;
    setSelectedAbility(parseInt(clickedIndex));
  }, []);

  const currentAbility = agent.abilities?.[selectedAbility];

  const videos = getAgentAbilityVideo(currentAbility);

  const agentColor = keyInObject(AGENT_COLORS, agent.key)
    ? AGENT_COLORS[agent.key]
    : null;
  const colon = ": ";

  return (
    <Card padding="0" className={AgentAbilitiesPlayerWrapper()}>
      {videos ? (
        <Video
          src={videos}
          showMaximize
          isMuted
          onVolumeChange={onVolumeChange}
        />
      ) : (
        <VideosComingSoon>
          {t("val:videosComingSoon", "{{name}} Ability Videos Coming Soon!", {
            name: agent.name,
          })}
        </VideosComingSoon>
      )}
      <AbilityInfoContainer>
        <VideoCover viewBox="0 0 581 56">
          <path d="M72.6274 0C68.384 0 64.3143 1.68571 61.3137 4.68629L20.6863 45.3137C17.6857 48.3143 13.616 50 9.37258 50H0V56H581V50H571.627C567.384 50 563.314 48.3143 560.314 45.3137L519.686 4.68629C516.686 1.68571 512.616 0 508.373 0H72.6274Z" />
        </VideoCover>
        <AbilityInfo>
          <Abilities>
            <ValAbilityBracketLeft className="ability-bracket" />
            {agent.abilities.map((ability, i) => {
              const ChargeIcon = keyInObject(chargeIcons, ability.chargesCount)
                ? chargeIcons[ability.chargesCount]
                : chargeIcons[2];

              const isSelected = selectedAbility === i;
              // Our data modeler currently filter
              const isCostHighlighted =
                isSelected ||
                (isSharedLeft(ability.chargesCount) &&
                  selectedAbility === i + 1) ||
                (isSharedRight(ability.chargesCount) &&
                  selectedAbility === i - 1);

              return (
                <Ability
                  style={{
                    "--agent-color": agentColor ? `hsl(${agentColor})` : null,
                  }}
                  key={ability.name}
                  data-tip={ability.name}
                  data-delay-show={500}
                  data-index={i}
                  onClick={handlyAbilitySelect}
                  {...classNames(
                    isSelected && "selected",
                    isCostHighlighted && "cost-highlight",
                    `${ability.chargesCount}`,
                  )}
                >
                  <AgentAbilityIcon
                    className="ability-icon"
                    ability={ability.key}
                  />
                  {ChargeIcon && <ChargeIcon className="charge-icon" />}
                  <span className="type-caption--bold keybind">
                    {ability.keybind}
                  </span>
                </Ability>
              );
            })}
            <ValAbilityBracketRight className="ability-bracket" />
          </Abilities>

          <AbilityText>
            <span className="type-overline ability-byline">
              {currentAbility.type}
            </span>
            <span className="type-subtitle1 ability-title">
              {currentAbility.name}
            </span>
            {currentAbility.cost ? (
              <div className="ability-cost">
                <span className="type-caption">
                  {t("val:cost", "Cost")}
                  {colon}
                </span>
                <span>
                  {currentAbility.type?.toLowerCase() === "ultimate" ? (
                    <>
                      <div
                        className="ult-diamonds"
                        style={{
                          "--agent-color": agentColor
                            ? `hsl(${agentColor})`
                            : null,
                        }}
                      >
                        {[...Array(currentAbility.cost).keys()]?.map((_, i) => (
                          <UltDiamond key={i} />
                        ))}
                      </div>
                      <span className="type-caption--bold ult-cost">
                        {currentAbility.cost}{" "}
                        <span>
                          {t(
                            "val:ultResourceCost",
                            "(Kills, Orbs, and Spike Plants/Defuses)",
                          )}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      {typeof currentAbility.cost === "number" && (
                        <ValAbilityCost />
                      )}
                      <span className="type-caption--bold">
                        {currentAbility.cost}
                      </span>
                    </>
                  )}
                </span>
              </div>
            ) : null}
            {currentAbility.description ? (
              <span className="type-body2 ability-description">
                {currentAbility.description}
              </span>
            ) : null}
          </AbilityText>
        </AbilityInfo>
      </AbilityInfoContainer>
    </Card>
  );
}
