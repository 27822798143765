import { appURLs } from "@/app/constants.mjs";

Anubis invites players into a beautifully rendered ancient Egyptian temple complex. Every inch of the map is a blend of authentic hieroglyphs, majestic statues, and towering pillars. Tactically, the map offers an intricate maze of tight corners, hidden alcoves, and high-visibility areas, providing a varied backdrop for strategizing.

**Bombsite A:** Bathed in a mystical golden light, this site is ornamented with grand statues and intricate carvings. Defensive spots are plentiful, yet the multiple entry points give attackers numerous strategies for a successful plant.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/anubis_a_site.webp`} />
</div>

**Bombsite B:** A subterranean chamber dimly lit by torches, offering stone barricades and dark corners for defenders to hold or ambush. Attackers will find the site challenging to breach without well-coordinated utility.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/anubis_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/anubis_b_site_2.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/anubis_b_site_3.webp`} />
</div>
