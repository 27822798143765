**April 10, 2020**
- New collision model on dumpsters found around the map.
- Fixed pixel boost #151 in water by B site.
- Revised world lighting.
- Reworked bombsite A backdrop.

**December 13, 2019**
- Pruned some shrubbery.

**May 17, 2018**
- Lowered volume of ambient sounds in Overpass
- Added grenade and player collision to fence cage near construction
- Removed spooky shadow fading in when approaching T main entrance to B site

**February 3, 2017**
- Fixed various spots where C4 could get stuck (Thanks kame942!)
- Ground in CT spawn now displays decals correctly
- Fixed some surfaces playing the wrong footstep sounds

**March 23, 2016**
- Grenades will no longer fall between cracks in Bombsite B
- Added backface to door in upper park (Thanks Guardian!)
- More accurate collision model on tree and rocks used in upper park

**October 23, 2015**
- Fixed a 3 man boost in back of bombsite A, towards bombsite B
- Red toolcart in connector will no longer kill you

**July 9, 2015**
- Made first train event predictable, train will start 10 seconds after Ts leave their spawn tunnel
- Implemented area sound for train, can now use sound reliably throughout B site to hide grenade sounds, footsteps etc.
- Fixed a see-through texture near Bombsite A towards upper park

**January 28, 2015**
- Rerouted entrance into upper park from bombsite A.
- Changed mid cover in bombsite A, corners can now be shot through with rifles.
- Changed cover in T side of upper park.
- Moved T spawns forward slightly.
- Moved bombsite A plant zone up slightly.

**December 4, 2014**
- Bombsite A plant trigger has been extended
- Added visual hints to the usable bombplant zones
- Removed A pit stairs, replaced with jump
- Fixed some overpowered boosts

**September 30, 2014**
- Fixed a bug where it was possible to defuse a bomb in A site from B balcony
- Added collision to top of pillars in Park
- Added a boiler to room next to A pit (now known as Boiler)

**August 27, 2014**
- Updated based on feedback and observations from ESL One Cologne
- Removed tall sandbags near Bombsite B which could be used to two-man boost onto and peek/shoot into T water
- Added a intended boost spot in its place, where you can two-man boost. Wall near position is bangable from both sides.
- Removed boost on green wall in playground
- Removed position on toilet mid entrance which could be used to get an angle on toilet entrance
- Fixed some spots where C4 could get stuck
- Removed grass on low cover near Bombsite A which obstructed peeking
- Changed some textures and lighting to improve visibility and reduce environment noise
- Moved lightpost at entrance to Bombsite A to remove gap
- Closed off hut at Bombsite A
- Railing outside of squeaky door no longer blocks bullets or grenades

**July 17, 2014**
- Fixed the A box not having its clip brush enabled.
- Fixed some unfair peek and boost spots around the map.
- Added some clip brushes to smooth movement.
- Fixed the sign decal on the bank.

**July 16, 2014**
- The bank side door has been opened to reveal a new path into A.
- The back A stairs have been flipped to enter nearer to the bomb plant zone.
- A site has been opened up, the APC has been moved back and a new box has fallen off of the semi.
- “Quickfall” palettes have been added to the bottom of ladder on T-side tunnels to allow for quick decent (ala Nuke).
- The flower box at the CT sniper spot at Long A is now higher.
- The connector tunnels have become more spacious.
- One of the windows in Connector Tunnels has been opened up (for possibilities…).
- Removed shelf in sniper nest above A to allow grenade damage through.
- Stomped some flowers in the box outside bathrooms leading to A because they got in the way of an important sight-line.
- Added a light to the bathroom connector to Long A. This improves visibility of players looking from mid connector.
- Fixed the steam pipes on connector to no longer leak steam.
- Clipped a few more areas to smooth movement and cleaned up nav mesh.
- Updated radar image. Thanks to iBP steel and c0tt0n

**July 1, 2014**
- Added to the Active Duty maps group

**May 28, 2014**
- Redesigned the connector between canal and park
- Made tunnels under Bombsite A easier to navigate
- Added lights to hut near Bombsite A

**May 1, 2014**
- Removed one-way dropdown near entrance to bombsite B, making the site easier to defend
- Reduced volume of environment sounds
- Removed ramp at back of truck in Bombsite A
- Updated radar
- Added cover in T side of canal
- Made upper park divider wider
- Improved visibility in park

**March 26, 2014**
- Added connector from T water to T tunnels
- Opened up small concrete hut near Bombsite A
- Made wood stack near door to A tunnels climbable
- Made it possible to shoot through wood wall near Bombsite B
- Made area near fountain in park slightly larger
- Tweaked environment light

**March 6, 2014**
- Added more cover in Bombsite A
- Widened gate at back of Bombsite A
- Blocked fence completely at back of Bombsite A
- Color coded tunnels under A
- Removed dropdown into water near bombsite B
- Made cement bags around bombsite B easier to climb
- Added cover near construction
- New connector between upper park and bathrooms
- Widened tunnel near T spawn
- General polish and optimization

**February 20, 2014**
- Smoothed out movement on truck in Bombsite A
- Simplified cover in bombsite B
- Removed some trees in upper park
- Smoothed out ground in canal
- Removed small walls near playground entrance
- Tweaked cover in playground
- Small fence at birthday area no longer block bullets/grenades
- Simplified corridor to CT sniper position
- Improved player visibility
- Improved performance

**February 12, 2014**
- Improved visibility.
- Removed small cover on CT side of Bombsite B.
- Drastically reduced the number of trashcans.
- Widened tunnel near T spawn.
- Widened lower door in park connector.
- Widened corridor near CT sniper room.

**February 5, 2014**
- Changed cover in playground.
- Fixed smoke sorting on water.
- Made players easier to see in park connector stairs.
- Raised upper park divider to prevent peeking over it.
- Increased ambient lighting.
- Made bridge near Bombsite B twice as wide.
- Made it easier to spot players in T side of canal.
- Simplified the layout of Bombsite B slightly.
- Removed small tree at CT side of upper park.
- Blocked visibility through truck in Bombsite A.
- Tweaked soundscape.
- General optimizations.

**January 23, 2014**
- [Bombsite A]
  - Moved CT van to give more effective cover
  - Made CT van climbable
  - Moved fences to give more space
  - Removed red car
  - Widened door into back of A
  - Blocked visibility from bridge to canal
  - Tweaked position/rotation of truck
  - Made corridors and stairs under A wider
- [Bombsite B]
  - Moved Bombsite B to CT side of pillar, extended area with a platform to give more space and cover
  - Made players in sniping room easier to silhouette against the background
  - Made cement sacks climbable
  - Opened up wall on CT side
  - Made bridge wider
- [General]
  - Moved up CT spawns and pushed T spawns further back
  - Increased C4 radius to 500 from 400
  - Railings in sniper room no longer blocks bullets or grenades
  - Added light to sniper room balcony
  - Made upper door in park connector wider
  - Made park connector easier to navigate
  - Made corridor near T spawn wider
  - Brighter ambient lights in canal pipes
  - Made climbing thing in playground more solid
  - Made bathroom corridor wider
  - Made windows in bathrooms breakable
  - Added spectator fixed camera positions
  - Tweaked soundscape settings
  - Fixed various minor bugs

**December 18, 2013**
- Added Cobblestone and Overpass maps, which are available to community servers and offline play. Official matchmaking is limited to Operation Bravo in order to collect additional map balancing data prior to a wider release.
