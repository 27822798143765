import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-deadlock/api.mjs";
import type { ProfileStats } from "@/game-deadlock/models/profileStats.mjs";
import { ProfileStatsValidator } from "@/game-deadlock/models/profileStats.mjs";

const EXPIRY = 1000 * 60 * 10; // 10 minutes

export default async function getProfileStats(props: {
  steamId: string;
}): Promise<ProfileStats | undefined> {
  if (!props?.steamId) return;
  try {
    const profileStats = await getData(
      API.getProfileStats(props),
      ProfileStatsValidator,
      ["deadlock", "profileStats", props.steamId],
      { expiryTime: EXPIRY },
    );
    return profileStats;
  } catch (e) {
    return getData(
      null,
      ProfileStatsValidator,
      ["deadlock", "profileStats", props.steamId],
      { expiryTime: EXPIRY },
    );
  }
}
