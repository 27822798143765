import { appURLs } from "@/app/constants.mjs";

Dust II, set in a North African desert town, is one of the most iconic maps in CS:GO. It features a contrast of tight tunnels and open spaces, providing diverse tactical opportunities. Bombsite A, with its signature "Long A" approach, offers long-range combat, while Bombsite B is accessible through narrower passages that favor close encounters. Its distinct sandy appearance and recognizable callouts like "Catwalk" make it a favorite among players of all levels.

**Bombsite A:** Dominated by its iconic "Goose" area and "Long A" corridor, Bombsite A provides opportunities for both long-range and close-quarters combat. Its open design, punctuated by crates and ramps, demands precision and coordination.
<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/dust2_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/dust2_a_site_2.webp`} />
</div>

**Bombsite B:** Enclosed within a stone structure, Bombsite B is accessible through tight tunnels, making it a hotspot for intense firefights. The window overlooking the site adds an additional tactical layer.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/dust2_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/dust2_b_site2.webp`} />
</div>
