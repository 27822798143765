import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ProfileLegends from "@/game-apex/components/ProfileLegends.jsx";
import ProfileWeapons from "@/game-apex/components/ProfileWeapons.jsx";
import ProfileOverview from "@/game-apex/ProfileOverview.jsx";
import { Modes, Seasons } from "@/game-apex/Selects.jsx";
import staticMediaURLs from "@/game-apex/static.mjs";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile, { UnknownPlayerHeader } from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import ProfileEmpty from "@/shared/ProfileEmpty.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Profile() {
  const { t } = useTranslation();
  const {
    parameters: [profileId, tab],
  } = useRoute();
  const state = useSnapshot(readState);
  const profile = state.apex?.profiles?.[profileId];
  const profileError = profile instanceof Error ? profile : !profileId;
  const iconURL = staticMediaURLs.getLegendImage(
    profile?.hoveredChampionApexId,
  );
  const { xpProgress, xpTotal, lvl } = useMemo(() => {
    if (!profile) return {};
    let lvl = 1;
    let xpProgress = profile.experiencePoints;
    let xpToNextLvl = 100;
    while (xpProgress - xpToNextLvl > 0) {
      xpProgress -= xpToNextLvl;
      lvl++;
      if (xpToNextLvl === 100) {
        xpToNextLvl += 2550;
      } else if (xpToNextLvl === 2650) {
        xpToNextLvl += 1250;
      } else if (xpToNextLvl < 5600) {
        xpToNextLvl += 850;
      } else if (xpToNextLvl < 7850) {
        xpToNextLvl += 750;
      } else if (xpToNextLvl < 8150) {
        xpToNextLvl += 300;
      } else if (xpToNextLvl < 12200) {
        xpToNextLvl += 450;
      } else if (xpToNextLvl < 17900) {
        xpToNextLvl += 150;
      } else if (xpToNextLvl < 18000) {
        xpToNextLvl += 100;
      } else if (xpToNextLvl >= 18000) {
        lvl += Math.floor(xpProgress / 18000);
        xpProgress = xpProgress % 18000;
        break;
      }
    }

    return { xpProgress, xpTotal: xpToNextLvl, lvl };
  }, [profile]);

  const baseUrl = `/apex/profile/${profileId}`;

  const tabs = useMemo(() => {
    return [
      {
        name: t("common:navigation.overview", "Overview"),
        id: "overview",
        url: baseUrl,
        isNew: false,
      },
      {
        name: t("common:navigation.legends", "Legends"),
        id: "legends",
        url: `${baseUrl}/legends`,
        isNew: false,
      },
      {
        name: t("common:navigation.weapons", "Weapons"),
        id: "weapons",
        url: `${baseUrl}/weapons`,
        isNew: false,
      },
    ];
  }, [baseUrl, t]);

  let InnerComponent;
  switch (tab) {
    case "legends":
      InnerComponent = (
        <Container>
          <ProfileLegends />
        </Container>
      );
      break;
    case "weapons":
      InnerComponent = (
        <Container>
          <ProfileWeapons />
        </Container>
      );
      break;
    default: {
      InnerComponent = profileId ? (
        <ProfileOverview profileId={profileId} />
      ) : (
        <ProfileEmpty />
      );
    }
  }

  const links = tabs.map((tab) => ({
    url: tab.url,
    text: tab.name,
  }));

  return (
    <ProfileLayout>
      {!profileError ? (
        <PageHeader
          imageRound
          image={iconURL}
          title={profile?.username}
          accentText={lvl}
          links={profileId ? links : undefined}
          $xpProgress={xpProgress}
          $xpTotal={xpTotal}
        />
      ) : (
        <UnknownPlayerHeader />
      )}
      <Container>
        <div className="flex gap-sp-2">
          <Modes
            hideNoWepStats={tab === "weapons"}
            hideNoLegendStats={tab === "legends"}
          />
          {!tab && <Seasons />}
        </div>
      </Container>
      <Container>
        <SharedProfile>{InnerComponent}</SharedProfile>
      </Container>
    </ProfileLayout>
  );
}

export function meta([profileId]) {
  const profile = readState.apex.profiles[profileId];

  return {
    title: [
      `apex:meta.profile.title`,
      `{{userName}}'s Apex Performance Overview`,
      { userName: profile?.username },
    ],
    description: [
      `apex:meta.profile.description`,
      `View {{userName}}'s Apex profile and see how they perform.`,
      { userName: profile?.username },
    ],
  };
}

export default Profile;
