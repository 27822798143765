import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_DEV } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import ApexColor from "@/game-apex/colors.mjs";
import {
  GAME_MODES,
  LIFETIME_SEASON,
  OVERLAY_IDS,
} from "@/game-apex/constants.mjs";
import { DIVISIONS } from "@/game-apex/constants/constants-divisions.mjs";
import SearchParamsEnum from "@/game-apex/constants/search-params.mjs";
import { apexBenchmarkRefs } from "@/game-apex/refs.mjs";
import staticApexMediaURLs from "@/game-apex/static.mjs";
import useApexLast20 from "@/game-apex/useApexLast20.jsx";
import {
  calcPlayerMatchStats,
  getCurrentSeason,
  getMatchResultData,
  getPlayerStatsByMatch,
} from "@/game-apex/utils.mjs";
import GameIconBoxApex from "@/inline-assets/game-icon-box-apex.svg";
import BenchmarkOverlay from "@/shared/BenchmarkOverlay.jsx";
import { playerImageStyle } from "@/shared/BenchmarkOverlay.style.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent, getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const RIGHT = (35 / 1440) * 100;
// Test Dev WndAttributes
const WND_WIDTH = IS_DEV ? 1980 : 0;
const WND_HEIGHT = IS_DEV ? 1080 : 0;

const NoOpDebugger = ({ children }) => {
  const { searchParams } = useRoute();
  const isDebugger = searchParams.get("isDebugger");
  const [debugElement, setDebugger] = useState(null);
  useEffect(() => {
    if (IS_DEV && !IS_APP && isDebugger)
      (async () => {
        setDebugger(await import("@/game-apex/BenchmarkOverlayDebug.jsx"));
      })();
  }, [isDebugger]);
  return debugElement
    ? React.createElement(debugElement.default, null, children)
    : children;
};

const getInitialPosition = (wndWidth, wndHeight) =>
  wndWidth && wndHeight
    ? css({
        position: "absolute",
        bottom: calcOffsetPercentile(wndWidth, wndHeight) + "%",
        right: RIGHT + "%",
      })
    : undefined;

function ApexBenchmarkOverlay() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const route = useRoute();

  const { searchParams } = route;
  const state = useSnapshot(readState);
  // Math calculation to determine how to absolutely position the Benchmarking UI
  const { width: wndWidth = WND_WIDTH, height: wndHeight = WND_HEIGHT } =
    state.volatile.wndAttributes ?? {};
  const initialPosition = useMemo(
    () => getInitialPosition(wndWidth, wndHeight),
    [wndWidth, wndHeight],
  );
  const profileId = searchParams.get("profileId");
  const seasonId = searchParams.get(SearchParamsEnum.Season);
  const matchId = searchParams.get("matchId");
  const isBenchmarkOverlayEnabled =
    state.settings.apex?.overlays?.isBenchmarkOverlayEnabled;
  const profile = state.apex.profiles[profileId];
  const match = state.apex.matches[matchId];
  const mode = match?.gameMode || GAME_MODES.ALL.key;
  const rankedMode = GAME_MODES[mode]?.rankedMode;
  const season = seasonId || LIFETIME_SEASON.apexSeasonNumber;
  const currentSeason = getCurrentSeason(state.apex.meta.seasons);
  const playerSeasonStats = state.apex.playerStats?.[profileId]?.[season];
  const playerStats = playerSeasonStats?.[mode];
  const playerRankedStats = playerSeasonStats?.[rankedMode];
  const myPlayer = getPlayerStatsByMatch(match, profileId);
  const myPlayerStats = calcPlayerMatchStats(myPlayer);
  const liveGame = state.apex.liveGame;

  const { last20Stats } = useApexLast20({
    profileId,
    season,
    mode,
  });

  const rankedStats =
    state.apex.rankedStatsAgg?.[currentSeason?.apexId]?.[rankedMode];

  const openApp = useCallback(() => {
    blitzMessage(EVENTS.APEX_VIEW_BLITZ_APP, {
      matchId,
      profileId,
      seasonId,
    });
  }, [seasonId, matchId, profileId]);

  const getChartData = useCallback(
    (statType) => {
      const list = [];
      const limit = 10;

      if (last20Stats) {
        list.push({
          key: "avg",
          Icon: GameIconBoxApex,
          imageStyle: playerImageStyle,
          text: t("common:nAvg", "{{numberOfGames}} Avg.", {
            numberOfGames: last20Stats.matches,
          }),
          data: last20Stats[statType],
          dataString:
            statType === "headshotPercentage"
              ? formatToPercent(language, last20Stats[statType])
              : getLocaleString(last20Stats[statType], {
                  maximumFractionDigits: 1,
                }),
          fillColor: "var(--shade0-50)",
          hoverColor: "var(--shade0-50)",
        });
      }

      if (match?.playerMatchStats) {
        for (const player of match?.playerMatchStats || []) {
          const isMyPlayer = player.platformId === profileId;
          if (myPlayer?.team?.apexId === player.team.apexId) {
            const playerStats = calcPlayerMatchStats(player);
            if (typeof playerStats[statType] === "number")
              list.push({
                key: playerStats.platformId,
                image: staticApexMediaURLs.getLegendImage(
                  playerStats.champion.apexId,
                ),
                imageStyle: playerImageStyle,
                text:
                  state.apex.profiles[playerStats.platformId]?.username ||
                  playerStats.playername,
                data: playerStats[statType],
                dataString:
                  statType === "headshotPercentage"
                    ? formatToPercent(language, playerStats[statType])
                    : getLocaleString(playerStats[statType], {
                        maximumFractionDigits: 1,
                      }),
                fillColor: isMyPlayer ? "#EFBF6C" : "var(--shade0-50)",
                hoverColor: isMyPlayer ? "#EFBF6C" : "var(--shade0-50)",
                background: isMyPlayer
                  ? "linear-gradient(270deg, rgba(239, 191, 108, 0) 0%, rgba(239, 191, 108, 0.15) 100%)"
                  : null,
              });
          }
        }
      }

      if (rankedStats) {
        for (const [i, divison] of DIVISIONS.entries()) {
          if (list.length === limit) break;
          const { key, minRp, icon, tDefault, tKey } = divison;
          const nextMinRp = DIVISIONS[i + 1]?.minRp || 0;
          if (
            !rankedStats[key]?.[statType] ||
            rankedStats[key][statType] > 10000 ||
            (statType === "headshotPercentage" && key !== "master") ||
            (key !== "master" &&
              (typeof playerRankedStats?.rankedPoints === "number"
                ? minRp <= playerRankedStats.rankedPoints ||
                  nextMinRp > playerRankedStats.rankedPoints
                : key !== "bronze"))
          )
            continue;

          const stat =
            statType === "headshotPercentage"
              ? rankedStats[key][statType] / 100
              : rankedStats[key][statType];
          const data = {
            key: key,
            Icon: icon,
            text: t(tKey, tDefault, { tier: null }),
            data: stat,
            dataString:
              statType === "headshotPercentage"
                ? formatToPercent(language, stat)
                : getLocaleString(stat, {
                    maximumFractionDigits: 1,
                  }),
            fillColor: "var(--shade0-50)",
            hoverColor: ApexColor.ranks[key]?.fill,
          };

          list.push(data);
        }
      }

      return list.sort((a, b) => b.data - a.data);
    },
    [
      language,
      last20Stats,
      match?.playerMatchStats,
      myPlayer?.team?.apexId,
      playerRankedStats?.rankedPoints,
      profileId,
      rankedStats,
      state.apex.profiles,
      t,
    ],
  );

  const modeObj = GAME_MODES[mode];

  const { title, titleColor } = useMemo(() => {
    if (!match || match instanceof Error) return {};
    const { color, label } = getMatchResultData(t, modeObj, myPlayer);

    return { title: label, titleColor: color };
  }, [match, modeObj, myPlayer, t]);

  const tabs = useMemo(() => {
    return [
      {
        id: "damageDone",
        text: t("common:stats.damage", "Damage"),
        value: myPlayerStats?.damageDone,
        valueStr: getLocaleString(myPlayerStats?.damageDone, {
          maximumFractionDigits: 1,
        }),
        avgValue: last20Stats.damageDone,
        avgValueStr: getLocaleString(last20Stats.damageDone, {
          maximumFractionDigits: 1,
        }),
      },
      {
        id: "kills",
        text: t("common:stats.kills", "Kills"),
        value: myPlayerStats?.kills,
        valueStr: getLocaleString(myPlayerStats?.kills, {
          maximumFractionDigits: 1,
        }),
        avgValue: last20Stats.kills,
        avgValueStr: getLocaleString(last20Stats.kills, {
          maximumFractionDigits: 1,
        }),
      },
      ...(last20Stats?.matchesWithHits > 0
        ? [
            {
              id: "headshotPercentage",
              text: t("common:stats.headshotPercent", "Headshot %"),
              value: myPlayerStats?.headshotPercentage,
              valueStr: formatToPercent(
                language,
                myPlayerStats?.headshotPercentage,
              ),
              avgValue: last20Stats?.headshotPercentage,
              avgValueStr: formatToPercent(
                language,
                last20Stats?.headshotPercentage,
              ),
            },
          ]
        : []),
    ];
  }, [
    language,
    last20Stats.damageDone,
    last20Stats?.headshotPercentage,
    last20Stats.kills,
    last20Stats?.matchesWithHits,
    myPlayerStats?.damageDone,
    myPlayerStats?.headshotPercentage,
    myPlayerStats?.kills,
    t,
  ]);

  if (!profile || !isBenchmarkOverlayEnabled || liveGame) return null;

  return (
    <NoOpDebugger>
      <BenchmarkOverlay
        game={"apex"}
        overlayId={OVERLAY_IDS.apexBenchmark}
        overlayIdAd={OVERLAY_IDS.apexBenchmarkAd}
        matchId={matchId}
        icon={
          <img
            src={staticApexMediaURLs.getLegendImage(
              myPlayer
                ? myPlayer.champion?.apexId
                : profile?.hoveredChampionApexId,
            )}
          />
        }
        title={title}
        titleColor={titleColor}
        subtitle={t("common:stats.winsCount", "{{count, number}} Wins", {
          count: playerStats?.wins,
        })}
        name={profile?.username}
        stats={{ recentMatchesCount: last20Stats.matches }}
        queue={modeObj?.t}
        tabs={tabs}
        getChartData={getChartData}
        openApp={openApp}
        refs={apexBenchmarkRefs}
        initialPosition={initialPosition}
      />
    </NoOpDebugger>
  );
}

export function meta() {
  return {
    title: [null, "Apex - Postmatch Overlay"],
    description: [null, "Apex Postmatch Overlay"],
  };
}

export default ApexBenchmarkOverlay;

const GAME_ASPECT_RATIO = 900 / 1600; // ~0.5625, based off of the true window dimension ratio
const BOT_SCALED_OFFSET = (90 / 810) * 100; // Exact position based off of the true window dimension ratio

function calcOffsetPercentile(windowWidth, windowHeight) {
  // Calculate base offset
  const height = windowWidth * GAME_ASPECT_RATIO;
  const heightDiff = windowHeight - height;
  const bottomMarginInPx = heightDiff / 2; // Divide by 2 because our game window is centered within the OS window
  const bottomMarginInPecentile = (bottomMarginInPx / windowHeight) * 100;
  // Calculate scaled offset
  const bottomMarginScaled = BOT_SCALED_OFFSET / (windowHeight / height);
  return sanitizeNumber(bottomMarginInPecentile + bottomMarginScaled);
}
