/**
 * Purpose of this module is to detect the inert DOM elements from
 * `feature-ads-baseline`. The presence of this feature should assume
 * that ads should be shown, and there shouldn't be any logic
 * to check for whether ads should be shown in here, because that
 * should already be assumed.
 */
import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import router, { EVENT_CHANGE_ROUTE } from "@/__main__/router.mjs";
import { appURLs, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import gamesList from "@/app/games.mjs";
import noopModel from "@/data-models/no-op.mjs";
import { DISPLAY_AD_CLASS } from "@/feature-ads-baseline/constants.mjs";
import getEmail from "@/feature-ads-baseline/get-email.mjs";
import { devError, devLog } from "@/util/dev.mjs";
import { findGameSymbol } from "@/util/game-route.mjs";
import globals from "@/util/global-whitelist.mjs";
import { stringifyPath } from "@/util/path-regexp.mjs";
import { fetchCountryCode } from "@/util/use-country-code.mjs";

// eslint-disable-next-line no-unused-vars
const g = globalThis;

let timer;
let lastGC = 0;
const backOffGC = 60000;

const initialRefresh = 30000;

let countryRefresh = null;

const ADS_STATIC_CONFIG = `${appURLs.UTILS_STATIC}/rev/ads`;

const ADITUDE_SRC =
  "https://dn0qt3r0xannq.cloudfront.net/blitz-ONuZ1Ty9qx/blitz-default/prebid-load.js";

const ADITUDE_MAPPINGS = {
  "display-rr-1": "pb-slot-rightrail-1",
  "display-rr-2": "pb-slot-rightrail-2",
  "display-rr-3": "pb-slot-rightrail-3",
  "display-lr-1": "pb-slot-leftrail-1",
  "display-desktop-anchor": "pb-slot-anchor",
  "display-skin": "pb-slot-CPMStar_Skin",
  "display-desktop-anchor-infeed": "pb-slot-infeed-1",
};

let hasAppendedScript = false;
const cleanup = [];

const obs =
  typeof MutationObserver === "undefined"
    ? null
    : new MutationObserver((mutations) => {
        // mainly concerned with registering slots...
        for (const { addedNodes, removedNodes } of mutations) {
          for (const node of addedNodes) {
            if (!(node instanceof HTMLElement)) continue;
            if (node.classList.contains(DISPLAY_AD_CLASS)) registerAd(node);
            const ads = node.querySelectorAll(`.${DISPLAY_AD_CLASS}`);
            for (const ad of ads) {
              registerAd(ad);
            }
          }
          for (const node of removedNodes) {
            if (!(node instanceof HTMLElement)) continue;
            if (node.classList.contains(DISPLAY_AD_CLASS)) removeAd(node);
            const ads = node.querySelectorAll(`.${DISPLAY_AD_CLASS}`);
            for (const ad of ads) {
              removeAd(ad);
            }
          }
        }
      });

const seenSlotIds = new Set();
const activeSlotIds = new Set();
const slotInfoMap = new Map();
g.BLITZ_ADS_DEBUG = slotInfoMap;

function tudeCmd(fn) {
  g.tude ||= {};
  g.tude.cmd ||= [];
  return g.tude.cmd.push(fn);
}

function tudeserveCmd(fn) {
  g.tudeserve ||= {};
  g.tudeserve.cmd ||= [];
  return g.tudeserve.cmd.push(fn);
}

function googleCmd(fn) {
  g.googletag ||= {};
  g.googletag.cmd ||= [];
  return g.googletag.cmd.push(fn);
}

function removeAd(adElement) {
  const { id } = adElement;
  activeSlotIds.delete(id);
}

function registerAd(adElement) {
  const { id } = adElement;
  seenSlotIds.add(id);
  activeSlotIds.add(id);
  if (!slotInfoMap.get(id)) {
    const info = g.sessionStorage.getItem(`slot ${id}`);
    if (info) {
      slotInfoMap.set(id, JSON.parse(info));
    } else {
      slotInfoMap.set(id, {
        lastRefreshedAt: 0,
        refreshTime: 0,
        slotRequested: 0,
        slotFilled: 0,
      });
    }
  }
  const obj = slotInfoMap.get(id);
  obj.lastRefreshedAt = 0; // force a refresh
}

function garbageCollect() {
  if (!IS_APP) return;

  const now = Date.now();
  if (now - lastGC < backOffGC) return;

  lastGC = now;

  try {
    globals.gc();
  } catch (e) {
    devError("failed to gc", e);
  }
}

function refreshSlot(id) {
  if (!canRefresh()) return null;

  // if we can refresh, try to force manual garbage collection
  // to lower measurable memory usage.
  garbageCollect();

  devLog("refresh called!", id);

  const obj = slotInfoMap.get(id);
  obj.lastRefreshedAt = Date.now();
  obj.slotRequested++;
  g.sessionStorage.setItem(`slot ${id}`, JSON.stringify(obj));

  return tudeCmd(() => {
    g.tude.refreshAdsViaDivMappings([
      {
        divId: id,
        baseDivId: ADITUDE_MAPPINGS[id],
      },
    ]);
  });
}

function checkRefresh() {
  for (const id of activeSlotIds) {
    const obj = slotInfoMap.get(id);

    // target count represents an expected value given a target fill rate.
    const targetCount = obj.slotRequested * 0.8;

    // this represents what we will consider as a penalty to increase refresh time.
    const unfilledCount = targetCount - obj.slotFilled;

    // this is a scaling factor that adjusts refresh time, it should be >1
    // for example: initial 30 sec * 1.02 ^ 20 unfilled ads ~ 45 sec refresh
    const power = 1.0; // should be greater than 1...

    const refreshTime = countryRefresh || initialRefresh;
    obj.refreshTime = Math.max(
      refreshTime,
      refreshTime * Math.pow(power, unfilledCount),
    );

    // This is just a small random time interval to try to space out refreshes,
    // so that they are not all initiated at the same time, particularly for the
    // first refresh.
    const jitter = Math.random() * 1000 * 3;

    if (Date.now() - obj.lastRefreshedAt > obj.refreshTime + jitter) {
      refreshSlot(id);
    }
  }

  timer = setTimeout(checkRefresh, 500);
}

export async function setup() {
  // Sanity check: this should never be the case lol
  if (IS_NODE) return;

  // side effect to get geo-based refresh...
  (async () => {
    const [countryCode, config] = await Promise.all([
      fetchCountryCode({ skipSafetyCheck: true }),
      getData(ADS_STATIC_CONFIG, noopModel, undefined, {
        skipSafetyCheck: true,
      }),
    ]);
    const countryConfig = config?.overrides?.[`region-${countryCode}`];
    if (!countryConfig) return;
    countryRefresh = countryConfig.refresh * 1000;
  })();

  try {
    const email = await getEmail();

    tudeCmd(() => {
      g.tude.setIdProfile({
        e: email,
        // i4: 'Standard IP address of user raw',
        // i6: 'Standard IPV6 address of user raw',
        // idfa: 'Mobile advertising id (IDFA/AAID) raw',
        // ifa: 'Advertising identifier (IFA) raw',
        // ifv: 'Vendor identifier (IFV) raw',
      });
    });
  } catch (_e) {
    // swallow error lol
  }

  if (!hasAppendedScript) {
    const script = g.document.createElement("script");
    script.src = ADITUDE_SRC;
    script.async = true;
    // script.onload = ...;
    g.document.head.appendChild(script);
    g.tudeControl = false;
    hasAppendedScript = true;
    try {
      listenForSlotFilled();
    } catch (e) {
      devError("failed to listen for slot events!", e);
    }
  }

  // re-using this KV, it's hardcoded so we can tell this is the new implementation
  setPageTargeting({
    display_provider: "blitz_new",
  });

  router.events.on(EVENT_CHANGE_ROUTE, routeListener);
  cleanup.push(() => {
    router.events.off(EVENT_CHANGE_ROUTE, routeListener);
  });

  cleanup.push(() => {
    clearTimeout(timer);
  });

  if (obs) {
    const ads = g.document.querySelectorAll(`.${DISPLAY_AD_CLASS}`);
    for (const ad of ads) {
      registerAd(ad);
    }
    checkRefresh();
    obs.observe(g.document.body, {
      childList: true,
      subtree: true,
    });
    cleanup.push(() => {
      obs.disconnect();
    });
  }
}

export function teardown() {
  // There is no way to fully unload this, but listeners can be unsubscribed.
  for (let i = cleanup.length - 1; i >= 0; i--) {
    const fn = cleanup[i];
    fn();
    cleanup.pop();
  }
}

function listenForSlotFilled() {
  tudeserveCmd((ts) => {
    ts.events().on("bid_won", ({ slot }) => {
      const { elementId: id } = slot;
      const obj = slotInfoMap.get(id);
      obj.slotFilled++;
      g.sessionStorage.setItem(`slot ${id}`, JSON.stringify(obj));
    });
  });
  googleCmd(() => {
    g.googletag.pubads().addEventListener("slotRenderEnded", (e) => {
      if (e.isEmpty) return;
      const id = e.slot.getSlotElementId();
      const obj = slotInfoMap.get(id);
      obj.slotFilled++;
      g.sessionStorage.setItem(`slot ${id}`, JSON.stringify(obj));
    });
  });
}

function routeListener() {
  if (!router.route) return;
  const { path } = router.route;
  const gameSymbol = findGameSymbol();
  const shortName = GAME_SHORT_NAMES[gameSymbol];
  setPageTargeting({
    game: shortName ?? "n/a",
    route: stringifyPath(path),
  });
}

function setPageTargeting(obj) {
  tudeCmd(() => {
    g.tude.setPageTargeting(obj);
  });
}

function canRefresh() {
  if (g.tudeControl) return false;
  if (g.document.visibilityState !== "visible") return false;
  return true;
  // return readState.volatile.isFocused || !hasRunningGame();
}

function _hasRunningGame() {
  const {
    volatile: { runningGamesState, currentGameTuple, currentSummoner },
  } = readState;
  return Boolean(
    Object.getOwnPropertySymbols(runningGamesState || {}).find((s) => {
      if (!gamesList.includes(s)) return false;
      return runningGamesState[s];
    }) ||
      currentGameTuple ||
      currentSummoner,
  );
}
