import { appURLs } from "@/app/constants.mjs";

Transporting players to the picturesque canals of Venice, this map delivers an authentic, scenic experience of a historic Italian city. Gondolas float in the waterways as players navigate a mix of open bridges, intimate corridors, and Venetian plazas filled with opportunities for sharpshooters and close-quarter experts alike.

**Bombsite A:** Located in an open courtyard near the iconic canals, offering only limited cover through small structures and parked boats. Sniping is often key here, but be wary of flanks.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/canals_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/canals_a_site.webp`} />
</div>

**Bombsite B:** Enclosed by historical Venetian architecture, this indoor site presents a choke point that benefits defenders but can be overcome by a well-placed smoke or flash.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/canals_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/canals_b_site_2.webp`} />
</div>
