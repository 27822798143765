Make sure when you are A/B testing you take into account the size of both features. There are ways to skip loading one feature if it comes with a large overhead that most users wont use.

There are helpers for A/B testing in `use-ab-feature.mts`. They use the unique fingerprint of the machine to make sure that the user's view doesn't change even on a refresh.

### Init feature

Normally this will be used in a `mod.mjs` file. Initialize the feature with a `[namespace, featureName]`, enabled percentage (as integer [1,100]), and optionally any options

```js
import { initABFeature } from "@/util/use-ab-feature.mjs";

const enabled = await initABFeature(["battles", "notificationStyle"], 50);
```

### Use A/B feature

After the feature is initialized you can use it in the mod file or with the hook:

```js
import { useABFeature } from "@/util/use-ab-feature.mjs";

const enabled = useABFeature(["battles", "notificationStyle"]);
```

### Options

```ts
interface Options {
  /// Re-roll if feature is enabled with every version
  includeVersionInSalt?: boolean;
}
```
