import { appURLs } from "@/app/constants.mjs";

Mirage transports players to a war-torn, fictional Middle Eastern city, characterized by its blend of traditional architecture and modern warfare markings. The landscape of the map is dotted with narrow alleys, bustling marketplaces, and open plazas that provide a unique tactical experience.

Strategically positioned bombsites lie at the heart of Mirage's gameplay. Bombsite A is notable for its iconic palace structure, providing elevated vantage points and complex entry routes. Bombsite B, on the other hand, is ensconced within an apartment complex, offering numerous nooks and crannies that favor close-quarters combat.

**Bombsite A**, set near a grand palace entrance, is a hub of tactical possibilities with its elevated positions and multi-layered combat areas. Key features include the Tetris blocks, stairs, and a connector, all of which present various hiding spots and sightlines. The palace's balcony offers an additional vantage point, and the site's overall architecture allows for both offensive and defensive strategies.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/mirage_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/mirage_a_site_2.webp`} />
</div>

**Bombsite B** is distinguished by its apartment complexes and tight, enclosed spaces. The famous "Van" and "Bench" positions provide cover, while the market area adds to the map's aesthetic. Entry from both the catwalk and apartments leads to diverse attack strategies, and the site's pale blue color scheme enhances its distinct appearance.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/mirage_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/mirage_b_site_2.webp`} />
</div>
