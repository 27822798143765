**February 8, 2018**
- Enabled shotgun spread patterns on official matchmaking servers as described in https://blog.counter-strike.net/index.php/holiday-spread/

**July 1, 2014**
- Reduced price to $2000
- Penetration Power increased
- Spread reduced slightly

**May 8, 2013**
- Reduced damage to 20.

**May 1, 2013**
- Increased armor ratio. Increased cycletime.

**October 1, 2012**
- Reduced all shotguns' price by $300.
