import { appURLs } from "@/app/constants.mjs";

Nestled within the confines of a grand medieval castle, Cobblestone offers a high-contrast tactical experience. The map features stone battlements, wooden ramparts, and lush courtyards. Players will find multiple levels of verticality and a variety of sightlines that range from narrow choke points to open areas ideal for sniping.

**Bombsite A:** Dominated by a regal courtyard and elevated battlements, providing excellent long-range sightlines but also offering nooks and crannies for close encounters.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/cbble_a_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/cbble_a_site.webp`} />
</div>

**Bombsite B:** A complex area filled with wooden structures and various stone barricades. It's a labyrinthine environment where strategy and utility play can make or break the round.

<div className="images">
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/cbble_b_site.webp`} />
  <img src={`${appURLs.CDN}/blitz/cs2/bombsites/cbble_b_site_2.webp`} />
</div>
