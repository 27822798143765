**November 4, 2019**
- Mac10 UV map has been updated.
- Mac10 cavity map updated to fix wear pattern discrepancies.

**April 30, 2018**
Replaced custom Terrorist one-handed walking animations for the MAC10, MP7, and MP9 with an alternative two-handed version to improve weapon recognition at a glance.

**March 31, 2015**
- The accuracy of the MP9, MP7, and Mac-10 have been improved.

**October 2, 2014**
All SMGs now have ~20% more armor penetration.

**May 8, 2013**
- Mac10 - increased price to 1050.

**May 1, 2013**
- Mac-10: Increased maxspeed. Reduced price.
