import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Union, Unknown } from "@/__main__/data-model.mjs";

const earenasrankedperiodflavor = Union([
  "said00926519318",
  "said00781342567",
  "said00170407135",
  "said00224037796",
  "said00310546825",
  "said00058416642",
]);
const eseasonflavor = Union([
  "said01769158912", // 1
  "said01774506873", // 2
  "said00724938940", // 3
  "said01092263930", // 4
  "said00835358768", // 5
  "said00049860047", // 6
  "said00777894692", // 7
  "said00831495238", // 8
  "said01080534582", // 9
  "said01783977028", // 10
  "said00048930450", // 11
  "said00479992436", // 12
  "said00798342294", // 13
  "said01642977899", // 14
  "said00589335273", // 15 SPLIT
  "said00801928788", // 16
  "said00134970849", // 17
  "said00134750981", // 18
  "said01615095880", // 19
  "said01518645858", // 20
  "said01452791135", // 21
  "said00519237306", // 22
  "said01826978392",
]);
// This is a union of erankedperiodflavor & erankedperiod2pt0flavor
const erankedstatperiod = Union([
  "said00747315762", // 2
  "said00091805734", // 3
  "said00609587000", // 4
  "said00638209737", // 5
  "said01826354644", // 6
  "said00461784517", // 7
  "said01162744308", // 8
  "said01649072598", // 9
  "said01091892544", // 10
  "said01007141711", // 11
  "said01547807959", // 12
  "said02144147554", // 13
  "said01733022993", // 14
  "said00102643758", // 15 SPLIT
  "said02005235415", // 16
  "said01711148254", // 17 erankedperiod2pt0flavor starts from this season
  "said01068186165", // 18
  "said00254133756", // 19
  "said00400418920", // 20
  "said01350373342", // 21
  "said00635449927", // 21.1
  "said00969954275", // 21.2
  "said00858846781", // 21.3
  "said00517565421", // 22
  "said00034291796",
  "said01865753387",
]);
const erankedperiodflavor = Union([
  "said00747315762", // 2
  "said00091805734", // 3
  "said00609587000", // 4
  "said00638209737", // 5
  "said01826354644", // 6
  "said00461784517", // 7
  "said01162744308", // 8
  "said01649072598", // 9
  "said01091892544", // 10
  "said01007141711", // 11
  "said01547807959", // 12
  "said02144147554", // 13
  "said01733022993", // 14
  "said00102643758", // 15
  "said02005235415", // 16
]);
const erankedperiod2pt0flavor = Union([
  "said01711148254", // 17
  "said01068186165", // 18
  "said00254133756", // 19
  "said00400418920", // 20
  "said01350373342", // 21
  "said00635449927", // 21.1
  "said00969954275", // 21.2
  "said00858846781", // 21.3
  "said00517565421", // 22
  "said00034291796",
  "said01865753387",
]);
const ebattlepass = Union([
  "said00093464144",
  "said00260688408",
  "said00295370865",
  "said00299677906",
  "said00304965944",
  "said00423770662",
  "said00445086229",
  "said00450540596",
  "said00681462556",
  "said00735560801",
  "said00784324919",
  "said00821003765",
  "said00828266910",
  "said00871557359",
  "said01354163431",
  "said01440294075",
  "said01574973962",
  "said01670046124",
  "said01719970319",
  "said01727937125",
  "said01809425936",
  "said01838736969",
  "said01851062701",
  "said01894614717",
  "said01914132855",
  "said02006747073",
  "said02095273787",
]);
const eweaponflavor = Union([
  "said01475792313",
  "said00686444189",
  "said00107352658",
  "said00352110003",
  "said00908539842",
  "said01820095990",
  "said01928357643",
  "said01870377113",
  "said00909655776",
  "said01082669812",
  "said01920744447",
  "said00194888968",
  "said00418361060",
  "said00945439943",
  "said01496005473",
  "said01388774763",
  "said00048783537",
  "said00080404303",
  "said00970511567",
  "said00947460272",
  "said01363034817",
  "said01603172346",
  "said02091853053",
  "said00342631558",
  "said01408780340",
  "said01644528094",
  "said02050922236",
  "said01981891556",
  "said01580685432",
]);
const emodesflavors = Union([
  "arenas",
  "winter_express",
  "shadowroyale",
  "control",
  "gungame",
  "ftdm",
  "hardcore_survival",
  "date_night",
  "heatwave",
  "armeddangerous",
  "swat",
  "aprilfools_s16",
  "shadowarmy",
  "threestrikes",
  "turbo",
  "treasurehunt",
  "objective_br",
]);

export type EmodeFlavour = FromModel<typeof emodesflavors>;

const eweaponstatcategory = Union([
  "smg",
  "pistol",
  "sniper",
  "crate_unique",
  "ar",
  "marksman",
  "lmg",
  "shotgun",
]);
const characters = Union([
  "said00064207844",
  "said00080232848",
  "said00088599337",
  "said00089380567",
  "said00126647596",
  "said00147804686",
  "said00182221730",
  "said00187386164",
  "said00216194192",
  "said00405279270",
  "said00435256162",
  "said00496516687",
  "said00596489740",
  "said00725342087",
  "said00827049897",
  "said00843405508",
  "said00898565421",
  "said01014661899",
  "said01111853120",
  "said01399802246",
  "said01409694078",
  "said01464849662",
  "said01579967516",
  "said01847866776",
  "said01897196488",
  "said02045656322",
  "said02105222312",
]);
const platforms = Union(["nx", "pc", "ps", "xb"]);
const eautogenslot = Union([
  "slot_00",
  "slot_01",
  "slot_02",
  "slot_03",
  "slot_04",
  "slot_05",
  "slot_06",
  "slot_07",
  "slot_08",
  "slot_09",
]);

const model = {
  Enums: {
    earenasrankedperiodflavor: [earenasrankedperiodflavor],
    eautogenslot: [eautogenslot],
    ebattlepass: [ebattlepass],
    echaracterflavor: [characters],
    emodesflavors: [emodesflavors],
    equestflavor: [
      Union([
        "said00871160704",
        "said02019265585",
        "said02104205426",
        "said00493824547",
        "said00990593744",
        "said00130232232",
        "said01527750958",
        "said00070721943",
        "said01486156733",
        "said00174598357",
        "said01970985347",
        "said02073798681",
        "said01822215688",
      ]),
    ],
    erankedperiod2pt0flavor: [erankedperiod2pt0flavor],
    erankedperiodflavor: [erankedperiodflavor],
    erankedstatperiod: [erankedstatperiod],
    eseasonflavor: [eseasonflavor],
    eweaponflavor: [eweaponflavor],
    eweaponstatcategory: [eweaponstatcategory],
  },
  accessacknowledged: Boolean,
  activeftueflow: Number,
  activenpp: Boolean,
  allrankeddata: Mapped({
    key: erankedperiodflavor,
    value: {
      currentrankedscore: Number,
      endfirstsplitladderposition: Number,
      endseriesladderposition: Number,
      firstsplithighscore: Number,
      firstsplitrankedscore: Number,
      highestrankedscore: Number,
      rankedinitialized: Boolean,
      rewardseq: Number,
    },
  }),
  appliedprogressionboosts: [Number],
  arenasrankeddata: Mapped({
    key: earenasrankedperiodflavor,
    value: {
      arenasrankedinitialized: Boolean,
      arenasrankedplacementmatchescompleted: Number,
      currentarenasrankedscore: Number,
      endfirstsplitladderposition: Number,
      endseriesladderposition: Number,
      firstsplitrankedscore: Number,
      highestarenasrankedscore: Number,
    },
  }),
  battlepasses: Mapped({
    key: ebattlepass,
    value: {
      cachedpurchasedxp: Number,
      earnedxp: Number,
      grxeaaccesspremium: Number,
      grxfreerewardseq: Number,
      grxpremiumrewardseq: Number,
      lastpopuplevel: Number,
      lastseenearnedxp: Number,
      lastseenelitestate: Boolean,
      lastseenmilestone: Number,
      lastseenpremiumstate: Boolean,
      lastseenpurchasedxp: Number,
      previousearnedxp: Number,
    },
  }),
  behaviorhistory: [
    {
      behaviorflags: Number,
      character: Number,
    },
  ],
  behaviorhistory_nextindex: Number,
  botgraduationstate: Boolean,
  bp_stars_1: Number,
  cachedcharacterownership: Mapped({ key: String, value: Boolean }),
  challengererollsused: Number,
  challenges: [
    {
      currenttier: Number,
      guid: Number,
      statmarker: Number,
      statmarkeralt: Number,
    },
  ],
  challengesetunlocks: [
    {
      guid: Number,
      unlocktimestamp: Number,
    },
  ],
  challengetimezoneoffsethours: Number,
  clubismember: Boolean,
  clubispendingapproval: Boolean,
  clublastviewedannouncementtime: Number,
  clublastviewedchattime: Number,
  clublastviewedtimelinetime: Number,
  cups: [
    {
      active: Boolean,
      cupid: Number,
      nummatches: Number,
      score: Number,
      squadid: String,
      uiseen: Boolean,
    },
  ],
  cupslastmatchplayed: {
    cupid: Number,
    matchnum: Number,
  },
  currencyexppopuplastexptime: Number,
  currentbattlepasschallengeseason: Number,
  currentelitestreak: Number,
  dailyexpirationtime: Number,
  dailyrerollcount: Number,
  demotionbuffer: Number,
  discordacknowledged: Boolean,
  egs_s22_conduitacknowledged: Boolean,
  elitetutorialstate: Number,
  eos: Mapped({
    key: platforms,
    value: {
      puid: String,
    },
  }),
  eventrefactordailyuses: Number,
  eventrefactorlastcurrencygrant: Number,
  eventrefactorlastusetime: Number,
  eventrefactortotalcurrency: Number,
  favoritechallengeguid: [Number],
  founderacknowledged: Boolean,
  frsettingspvars: {
    dummiemovement: Number,
    dummieshieldlvl: Number,
    dummiespawndists: Number,
    dummiespeed: Number,
    dummiesshoot: Number,
    dummiestate: Number,
    frdb_modifiers_packedbools: Number,
    frdb_movements_packedbools: Number,
    frdb_spawnsettings_packedbools: Number,
    frps_packedbools: Number,
    frss_packedbools: Number,
    persistenceready: Boolean,
    targetspeed: Number,
  },
  gamemodeselectorinitializedseasonguid: Number,
  gamemodeselectorseenindex: [Boolean],
  genericentitlementsacknowledgement: Boolean,
  grxaccountrewardseq: Number,
  grxaccountv2rewardseq: Number,
  grxqueuedrewards: [
    {
      rewardacknowledged: Boolean,
      rewardgiven: Boolean,
      rewardguids: Number,
      rewardquantities: Number,
      sourceguid: Number,
      sourceguid_extradata: Number,
    },
  ],
  hascollectedgoldenticket: Boolean,
  haseliteaccess: Boolean,
  haseliteforgiveness: Boolean,
  hashadbasestandingemotesautoequipped: Boolean,
  hasmigratedloadouttrackers: Boolean,
  hasnppalllegendsplayableinbotplayground: Boolean,
  hasseenchallengesinfodialog: Boolean,
  hasseencompletednppinfodialog: Boolean,
  hasseennppchallengesinfodialog: Boolean,
  hasseennppinfodialog: Boolean,
  hasseenrankedrumbletutorial: Boolean,
  heirloomshoplastseen: Number,
  initializedversion: Number,
  installunixtime: Number,
  lastdaybonus: Number,
  lastdayplayed: Number,
  lastdaytopfive: Number,
  lastelitegameforgivenesstime: Number,
  lastgamebadgeguid: [Number],
  lastgamebattlepassboost: Number,
  lastgamecharacter: Number,
  lastgamecontrolabandon: Boolean,
  lastgamemode: String,
  lastgameplayers: Number,
  lastgamerank: Number,
  lastgameresultflags: Number,
  lastgamescoreflags: Number,
  lastgamesquads: Number,
  lastgamesquadstats: [
    {
      assists: Number,
      character: Number,
      damagedealt: Number,
      displaydata2: Number,
      displaydata3: Number,
      displaydata3istime: Boolean,
      displaydata4: Number,
      displaydata5: Number,
      ehandle: Number,
      hardwareid: Number,
      kills: Number,
      knockdowns: Number,
      nucleusid: String,
      platformuid: String,
      playername: String,
      respawnsgiven: Number,
      revivesgiven: Number,
      survivaltime: Number,
      unused: Number,
    },
  ],
  lastgametime: Number,
  lastgametypeflags: Number,
  lastgameuirules: String,
  lastgamewasheadtohead: Boolean,
  lastgamewasjumpmaster: Boolean,
  lasthubresetseason: String,
  lastinitializedrankedperiodguid: Number,
  lastleagueintroshown: Number,
  lastpartysize: Number,
  lastplaylist: String,
  lastseenexoticcurrency: Number,
  lastseenlobbyltm: String,
  lastseenpremiumcurrency: Number,
  lasttimegamewascontrolabandoned: Number,
  lasttimegamewasrankedabandoned: Number,
  lasttimeloggedin: Number,
  lasttimeplayed: Number,
  latestgameisrankedrumble: Boolean,
  legendtokenspopuplastseen: Number,
  loadouts: Mapped({ key: String, value: Number }),
  lobastarterpackacknowledged: Boolean,
  lossforgivenessmessage: Number,
  mastery_rewardseq_all_weapon: Number,
  mastery_weapons: Mapped({
    key: String,
    value: {
      level: Number,
      pm_bonus: Number,
      rewardseq: Number,
      sopm_xp: Number,
      trial_list: [
        {
          sopm_stat: Number,
          stat: Number,
        },
      ],
      xp: Number,
      xp_modifier_list: [Number],
    },
  }),
  matchpreferences: Number,
  matchresults: {
    damagedealt: Number,
    placement: Number,
    placementof: Number,
    playerskilled: Number,
    startunixtime: Number,
  },
  matchresults_nextindex: Number,
  mayhem_pack_fuseacknowledged: Boolean,
  mythic_bloodhound_make_good: Number,
  newitemflavorsparselist: [Number],
  newmodeversion: Number,
  notfirsttimenewplayerpass: Boolean,
  notifyqualifiedforleague: Boolean,
  nppassignedguid: Number,
  nppcompleteacknowledged: Number,
  nppexpirationtime: Number,
  numcontrolabandons: Number,
  numpenalizedabandons: Number,
  numusedforgivenessabandons: Number,
  oldxpprogressionplusone: Number,
  placementwins: Number,
  postgamechallengesprogress: [
    {
      bonus: Number,
      guid: Number,
      mythicprogressbonus: Number,
      progressmatchstart: Number,
      tiermatchstart: Number,
      usealt: Boolean,
    },
  ],
  postgamegrindstarttier: Number,
  postgamegrindstartvalue: Number,
  postmatchsurveymatchid: String,
  postmatchsurveysampleratelowerbound: Number,
  previousxp: Number,
  privatematchstate: Number,
  progressionboostplaylistver: Number,
  promoversionseen: Number,
  ps_sie_pack_01acknowledged: Boolean,
  psplus_pack_20acknowledged: Boolean,
  psplus_pack_21acknowledged: Boolean,
  psplus_pack_22acknowledged: Boolean,
  psplus_pack_23acknowledged: Boolean,
  quest_reward_bp_stars_10: Number,
  quest_reward_bp_stars_2: Number,
  questdatas: Mapped({
    key: String,
    value: {
      comicpagesread: Number,
      comicpanel_displayfinalrewards: Boolean,
      havestartedquest: Boolean,
      lastmissionunlocktime: Number,
      missionprogress: Number,
      treasurebox_displaycount: Number,
      treasurebox_displaystartnum: Number,
      treasurebox_lastpickuptime: Number,
      treasurebox_lastseenpurchased: Number,
      treasurebox_totalpickups: Number,
    },
  }),
  rankedgamedata: {
    lastgameabandonforgiveness: Number,
    lastgameassistcount: Number,
    lastgameassistunique: Number,
    lastgamebonus: [Number],
    lastgamekillscore: Number,
    lastgamekillsunique: Number,
    lastgamelossprotectionadjustment: Number,
    lastgameparticipationcount: Number,
    lastgamepenaltypointsforabandoning: Number,
    lastgameplacementscore: Number,
    lastgamerankedabandon: Number,
    lastgamerankedforgiveness: Number,
    lastgamescorediff: Number,
    lastgamestartingscore: Number,
    lastgametierderankingprotectionadjustment: Number,
    trialstate: Number,
  },
  rankedhasprogressedoutofprovisional: Boolean,
  rankedperioddata: Mapped({
    key: erankedperiod2pt0flavor,
    value: {
      currentrankedscore: Number,
      endseriesladderposition: Number,
      highestrankedscore: Number,
      rewardseq: Number,
    },
  }),
  rankedprovisionalmatchescompleted: Number,
  rankedprovisionalmatchscores: [Number],
  rankedrewardsacknowledged: Boolean,
  rankedtop5streakcount: Number,
  reconnectparams: String,
  reconnecttime: Number,
  revenantstarterpackacknowledged: Boolean,
  seencupspopup: Boolean,
  seenprogressionboosts: [Number],
  shouldforceeliteplaylist: Boolean,
  showarenasrankedsummary: Boolean,
  showgamesummary: Boolean,
  showorientationmatchgraduationdialog: Boolean,
  showrankedsummary: Boolean,
  starteracknowledged: Boolean,
  stats: {
    akimbo_damage_done: Number,
    akimbo_damage_done_any_mode: Number,
    akimbo_dooms: Number,
    akimbo_dooms_any_mode: Number,
    akimbo_kills: Number,
    akimbo_kills_any_mode: Number,
    alter_allies_regrouped: Number,
    alter_breach_killer: Number,
    alter_breaches_used: Number,
    alter_deathbox_items_seized: Number,
    alter_surfaces_breached: Number,
    apexcups_ssr_damage: Number,
    apexcups_ssr_kills: Number,
    arenas_badge_no_down_win: Number,
    arenas_badge_perfect_game: Number,
    arenas_cash_stations_captured: Number,
    arenas_heals_purchased: Number,
    arenas_ordnance_purchased: Number,
    arenas_rounds_won: Number,
    arenas_skills_purchased: Number,
    arenas_weapons_upgraded: Number,
    arenas_win_with_5_kill_ko_assist: Number,
    arenasrankedperiods: Mapped({
      key: earenasrankedperiodflavor,
      value: {
        assists: Number,
        badge_award_level: Number,
        current_rank_score: Number,
        damage_done: Number,
        damage_done_max_single_game: Number,
        deaths: Number,
        dooms: Number,
        gained_rank_score_only: Number,
        games_played: Number,
        kills: Number,
        kills_max_single_game: Number,
        placements_win: Number,
        revived_ally: Number,
        win_streak_current_new: Number,
        win_streak_longest_new: Number,
      },
    }),
    armor_break: Number,
    ash_enemies_marked: Number,
    ash_enemies_tethered: Number,
    ash_players_portaled: Number,
    assault_damage_done: Number,
    assault_games_played: Number,
    assault_kills: Number,
    assists: Number,
    autogenslot: Mapped({
      key: eautogenslot,
      value: {
        autogen_any_mode: Number,
      },
    }),
    ballistic_passive_sling_kills: Number,
    ballistic_tactical_enemies_hit: Number,
    ballistic_tactical_enemies_overheated: Number,
    ballistic_ultimate_damage: Number,
    bangalore_passive_distance: Number,
    bangalore_tactical_smoke_hits: Number,
    bangalore_ultimate_damage_done: Number,
    beacon_scanned_squads_killed: Number,
    bloodhound_tactical_enemies_detected: Number,
    bloodhound_tactical_traps_detected: Number,
    bloodhound_ultimate_kills_while_active: Number,
    br_top_10_or_arena_win: Number,
    care_packages_revealed: Number,
    catalyst_dark_veil_enemies_crossed: Number,
    catalyst_doors_barricaded: Number,
    catalyst_spikes_damage: Number,
    caustic_gas_damage_dealt: Number,
    caustic_passive_enemies_in_gas_killed: Number,
    caustic_tactical_barrels_traps_detonated: Number,
    challenge_stars_earned: Number,
    challenge_xp_earned: Number,
    characters: Mapped({
      key: characters,
      value: {
        arenas_character_damage_done_max_single_game: Number,
        arenas_character_kills_max_single_game: Number,
        arenas_character_rounds_won: Number,
        arenas_character_win_streak_current_new: Number,
        arenas_character_win_streak_longest_new: Number,
        character_banner_once_filled: Number,
        character_damage_done_max_single_game: Number,
        character_elite_game_8kill_wins: Number,
        character_elite_game_wins: Number,
        character_games_with_respawning_both_teammates: Number,
        character_hot_streaks: Number,
        character_placements_win_as_jumpmaster: Number,
        character_placements_win_as_killleader: Number,
        character_placements_win_as_killleader_and_champion: Number,
        character_placements_win_flawless_1: Number,
        character_placements_win_flawless_2: Number,
        character_placements_win_with_5_headshots: Number,
        character_rapid_eliminations: Number,
        character_reinforcement_recalls: Number,
        character_solo_placements_top_5: Number,
        character_solo_wins: Number,
        character_won_last_game: Number,
        damage_done: Number,
        damage_done_any_mode: Number,
        deaths: Number,
        dooms: Number,
        executions: Number,
        games_played: Number,
        games_played_any_mode: Number,
        games_with_20_kills: Number,
        games_with_5_kills: Number,
        headshots: Number,
        hits: Number,
        kills: Number,
        kills_while_killleader: Number,
        looted_legendary_item: Number,
        most_squad_squad_kills_in_1_game: Number,
        neutral_airdrops_looted: Number,
        placements_top_10: Number,
        placements_top_3: Number,
        placements_win: Number,
        placements_win_with_squad_alive: Number,
        revived_ally: Number,
        ring_closings_survived: Number,
        season02_event01_character_solo_wins: Number,
        shots: Number,
        solo_dooms: Number,
        thanks: Number,
        weaponcategories: Mapped({
          key: eweaponstatcategory,
          value: {
            damage_done: Number,
            dooms: Number,
            headshots: Number,
            hits: Number,
            kills: Number,
            shots: Number,
          },
        }),
        winning_kills: Number,
      },
    }),
    clubs_games_played_with_club: Number,
    clubs_placements_win_with_club: Number,
    clubs_placements_win_with_club_no_deaths: Number,
    clubs_placements_win_with_club_no_deaths_or_knockouts: Number,
    clubs_placements_win_with_club_squad_alive: Number,
    clubs_placements_win_with_club_streak: Number,
    conduit_boosted_distance: Number,
    conduit_temp_shields_applied: Number,
    conduit_ult_damage: Number,
    controller_damage_done: Number,
    controller_games_played: Number,
    controller_kills: Number,
    crafting_harvester_used_any_mode: Number,
    crafting_items_crafted_any_mode: Number,
    crypto_passive_enemies_scanned: Number,
    crypto_ultimate_shield_damage: Number,
    crypto_ultimate_traps_destroyed: Number,
    daily_challenges_completed: Number,
    damage_done: Number,
    damage_done_any_mode: Number,
    damage_done_first_ring_round_br: Number,
    damage_done_hipfire_any_mode: Number,
    damage_done_midair: Number,
    damage_done_ordnance_any_mode: Number,
    damage_done_to_prowler_on_storm_point: Number,
    damage_done_to_spider_on_storm_point: Number,
    damage_taken: Number,
    damage_with_red_weapons_any_mode: Number,
    deaths: Number,
    distance_kill: Number,
    dooms: Number,
    dooms_any_mode: Number,
    drop_suggestion_from_plane: Number,
    dropped_items: Number,
    drops_from_dropship: Number,
    elite_badge_unlock: Number,
    energy_ammo_collected: Number,
    energy_weapon_collected: Number,
    equipped_attachment: Number,
    eventshop_currency_total: Number,
    eventshop_current_guid: Number,
    evoarmor_evolves: Number,
    evoarmor_evolves_to_purple: Number,
    evoarmor_evolves_to_red: Number,
    evoarmor_evolves_to_red_any_mode: Number,
    executions: Number,
    executions_any_mode: Number,
    extended_supply_bins_looted: Number,
    fashionista: Number,
    ftue_damage_done: Number,
    ftue_equipped_attachment: Number,
    ftue_health_restored_any_mode: Number,
    ftue_placements_top_10: Number,
    ftue_ring_closings_survived: Number,
    ftue_shield_restored: Number,
    ftue_slide_count: Number,
    fuse_cluster_bomb_hits: Number,
    fuse_grenade_barrage_thrown: Number,
    fuse_grenade_distance_thrown: Number,
    fuse_motherlode_enemies_captured: Number,
    gamemode_control_became_expleader: Number,
    gamemode_control_capture_bonuses_claimed_by_team: Number,
    gamemode_control_highest_exp_as_expleader: Number,
    gamemode_control_killed_enemy_1_tier_higher: Number,
    gamemode_control_killed_enemy_2_tier_higher: Number,
    gamemode_control_killed_expleader: Number,
    gamemode_control_killed_objective_attacker: Number,
    gamemode_control_killed_objective_defender: Number,
    gamemode_control_objectives_captured: Number,
    gamemode_control_personal_exp_points_earned: Number,
    gamemode_control_time_on_objective: Number,
    gamemode_treasurehunt_objectives_captured: Number,
    gamemode_turbo_games_played: Number,
    gamemode_turbo_knockdowns: Number,
    games_played: Number,
    games_played_any_mode: Number,
    gibraltar_passive_gunshield_enemy_damage_absorbed: Number,
    gibraltar_tactical_bubble_enemy_damage_absorbed: Number,
    gibraltar_ultimate_bombardment_kills: Number,
    headshots: Number,
    headshots_any_mode: Number,
    health_restored_any_mode: Number,
    heavy_ammo_collected: Number,
    heavy_weapon_collected: Number,
    hits: Number,
    horizon_blackhole_damage_done: Number,
    horizon_hard_landings_avoided: Number,
    horizon_teammates_gravity_lifted: Number,
    hovervehicle_occupant_meters_traveled: Number,
    items_crafted: Number,
    kills: Number,
    kills_max_single_game: Number,
    kills_or_assists: Number,
    kills_or_assists_any_mode: Number,
    kills_while_killleader: Number,
    kills_with_red_weapons_any_mode: Number,
    kills_with_throwing_knife_gungame: Number,
    lastgameparticipationscore: Number,
    legend_upgrades_selected: Number,
    lifeline_passive_allies_revived: Number,
    lifeline_passive_enemy_damage_absorbed: Number,
    lifeline_tactical_drone_healing_given: Number,
    lifeline_ultimate_droppod_items_grabbed_by_allies: Number,
    light_ammo_collected: Number,
    light_weapon_collected: Number,
    loba_passive_loot_pinged_through_walls: Number,
    loba_tactical_meters_teleported: Number,
    loba_ultimate_loot_taken_by_allies: Number,
    loot_bins_opened: Number,
    looted_all_launch_loot_once: Number,
    looted_all_nongold_weapons_in_1_match: Number,
    looted_epic_item: Number,
    looted_legendary_armor: Number,
    looted_legendary_helmet: Number,
    looted_legendary_item: Number,
    maggie_riot_drill_drill_distance: Number,
    maggie_warlords_ire_highlight_time: Number,
    maggie_wrecking_ball_boost_distance: Number,
    map_challenges_viewed: Number,
    map_viewed_from_plane: Number,
    master_of_all: Number,
    materials_collected: Number,
    max_placement_win_streak_with_premade_squad: Number,
    med_kit_consumed: Number,
    melee_damage_any_mode: Number,
    meters_crouch_walked: Number,
    meters_slid: Number,
    meters_sprinted_guns_holstered: Number,
    mirage_decoys_created: Number,
    mirage_successful_bamboozles: Number,
    mirage_successful_revives: Number,
    mirror_legend_kills_any_mode: Number,
    modes: Mapped({
      key: emodesflavors,
      value: {
        assists: Number,
        characters: Mapped({
          key: characters,
          value: {
            damage_done: Number,
            deaths: Number,
            dooms: Number,
            games_played: Number,
            kills: Number,
            placements_win: Number,
          },
        }),
        damage_done: Number,
        damage_done_max_single_game: Number,
        deaths: Number,
        dooms: Number,
        executions: Number,
        games_played: Number,
        headshots: Number,
        kills: Number,
        kills_max_single_game: Number,
        kills_or_assists: Number,
        placements_win: Number,
        revived_ally: Number,
        seasons: Mapped({
          key: eseasonflavor,
          value: {
            assists: Number,
            damage_done: Number,
            damage_done_max_single_game: Number,
            deaths: Number,
            dooms: Number,
            games_played: Number,
            kills: Number,
            kills_max_single_game: Number,
            placements_win: Number,
            revived_ally: Number,
            win_streak_current: Number,
            win_streak_longest: Number,
          },
        }),
        weaponcategories: Mapped({ key: eweaponstatcategory, value: Unknown }),
        weapons: Mapped({
          key: eweaponflavor,
          value: {
            damage_done: Number,
            dooms: Number,
            headshots: Number,
            kills: Number,
          },
        }),
        win_streak_current: Number,
        win_streak_longest: Number,
      },
    }),
    neutral_airdrops_looted: Number,
    newcastle_allies_rescued: Number,
    newcastle_mobile_shield_damage: Number,
    newcastle_revive_distance: Number,
    npp_assault_games_played: Number,
    npp_cards_earned: Number,
    npp_care_packages_revealed: Number,
    npp_controller_games_played: Number,
    npp_crafting_harvester_used_any_mode: Number,
    npp_damage_done_any_mode: Number,
    npp_damage_done_hipfire_any_mode: Number,
    npp_dooms_any_mode: Number,
    npp_executions_any_mode: Number,
    npp_extended_supply_bins_looted: Number,
    npp_kills_or_assists_any_mode: Number,
    npp_legend_upgrades_selected: Number,
    npp_level: Number,
    npp_recon_games_played: Number,
    npp_recon_passive_beacons_scanned: Number,
    npp_ring_consoles_used: Number,
    npp_skirmisher_games_played: Number,
    npp_support_games_played: Number,
    npp_weapon_supply_bins_looted: Number,
    num_characters_with_banners_once_filled: Number,
    octane_jump_pad_allies_launched: Number,
    octane_passive_health_regenerated: Number,
    octane_stim_distance_traveled: Number,
    opponents_outlived: Number,
    pathfinder_passive_beacons_scanned: Number,
    pathfinder_tactical_meters_moved_by_grapple: Number,
    pathfinder_ultimate_ziplines_used_by_allies: Number,
    phoenix_kit_consumed: Number,
    ping_assault_bin: Number,
    ping_attachment_or_optics_request: Number,
    ping_controller_console: Number,
    ping_dropped_attachment_or_optic: Number,
    ping_dropped_healing_or_shielding: Number,
    ping_enemy_specific: Number,
    ping_replicator: Number,
    ping_supply_bin: Number,
    ping_support_bin: Number,
    ping_survey_beacon: Number,
    placements_top_10: Number,
    placements_top_3: Number,
    placements_top_5: Number,
    placements_win: Number,
    placements_win_with_friends: Number,
    placements_win_with_min_executions_per_person: Number,
    placements_win_with_no_witnessess: Number,
    placements_win_with_squad_alive: Number,
    rampart_tactical_bullets_amped: Number,
    rampart_tactical_damage_blocked: Number,
    rampart_ultimate_bullets_fired: Number,
    rankedperiods: Mapped({
      key: erankedstatperiod,
      value: {
        assists: Number,
        badge_award_level: Number,
        characters: Mapped({
          key: String,
          value: {
            character_damage_done_max_single_game: Number,
          },
        }),
        current_rank_score: Number,
        damage_done: Number,
        deaths: Number,
        dooms: Number,
        gained_rank_score_only: Number,
        games_played: Number,
        kills: Number,
        kills_max_single_game: Number,
        placements_top_10: Number,
        placements_top_5: Number,
        placements_win: Number,
        revived_ally: Number,
        times_respawned_ally: Number,
        win_streak_current: Number,
        win_streak_longest: Number,
      },
    }),
    recon_damage_done: Number,
    recon_games_played: Number,
    recon_kills: Number,
    recon_passive_beacons_scanned: Number,
    respawn_banners_crafted: Number,
    respawn_dev: Number,
    respawned_ally: Number,
    revealed_care_packages_looted: Number,
    revealed_care_packages_looted_any_mode: Number,
    revenant_death_totem_activates: Number,
    revenant_passive_marked_enemies: Number,
    revenant_silenced_target: Number,
    revenant_silenced_target_knockdowns: Number,
    revenant_tactical_distance: Number,
    revenant_ultimate_knocks_kills: Number,
    revived_ally: Number,
    ring_closings_survived: Number,
    ring_consoles_used: Number,
    ring_damage_avoided_with_heat_shield: Number,
    rings_outran: Number,
    s03_duos_badge_play: Number,
    s03_duos_badge_win: Number,
    s03e01_badge: Number,
    s03e02_winter_express_captures: Number,
    s03e03_buffet_points: Number,
    s03e03_placement_top_5: Number,
    s03e03_play_each_game_mode: Number,
    s04e01_buffet_points: Number,
    s04e02_buffet_points: Number,
    s05e01_armed_and_dangerous_evolved_kills: Number,
    s05e01_armed_and_dangerous_evolved_times_respawned_ally: Number,
    s05e01_armed_and_dangerous_evolved_wins: Number,
    s05e01_buffet_points: Number,
    s06e01_buffet_points: Number,
    s06e01_campfire_damage_dealt_in_zone: Number,
    s06e01_campfire_players_killed_in_zone: Number,
    s06e01_campfire_wins: Number,
    s06e02_buffet_points: Number,
    s06e02_shadow_royale_kills: Number,
    s06e02_shadow_royale_revives: Number,
    s06e02_shadow_royale_wins: Number,
    s07e01_buffet_points: Number,
    s07e01_winter_express_captures: Number,
    s07e01_winter_express_damage: Number,
    s07e01_winter_express_kills: Number,
    s07e02_airdropkitted_damage_dealt: Number,
    s07e02_airdropkitted_knockdowns: Number,
    s07e02_airdropkitted_wins: Number,
    s07e02_buffet_points: Number,
    s08ace_buffet_points: Number,
    s08e01_buffet_points: Number,
    s08e01_ringfury_challenge_2: Number,
    s08e01_ringfury_challenge_3: Number,
    s08e01_ringfury_challenge_4: Number,
    s08e02_buffet_points: Number,
    s08e02_wargames_challenge_2: Number,
    s08e02_wargames_challenge_3: Number,
    s08e02_wargames_challenge_4: Number,
    s08e04_finale_completed: Number,
    s08e04_finale_not_completed: Number,
    s08seasonal_character_wins: Number,
    s08seasonal_nessie_dailies: Number,
    s08seasonal_weapon_damage: Number,
    s09e01_arenas_placements_win: Number,
    s09e01_br_damage_done: Number,
    s09e01_br_dooms: Number,
    s09e01_buffet_points: Number,
    s09e02_01_arenas_matches_played: Number,
    s09e02_01_buffet_points: Number,
    s09e02_02_arenas_dooms: Number,
    s09e02_02_buffet_points: Number,
    s09e02_03_arenas_damage_done: Number,
    s09e02_03_buffet_points: Number,
    s11e01_arenas_damage_done: Number,
    s11e01_br_dooms: Number,
    s11e01_buffet_points: Number,
    s11e01_winter_express_damage_done: Number,
    s11e02_01_br_dooms: Number,
    s11e02_01_buffet_points: Number,
    s11e02_02_arenas_games_played: Number,
    s11e02_02_buffet_points: Number,
    s11e02_03_br_damage_done: Number,
    s11e02_03_buffet_points: Number,
    s12ace_br_damage_done: Number,
    s12ace_br_dooms: Number,
    s12ace_buffet_points: Number,
    s12ace_control_captures: Number,
    s12e01_br_damage_done: Number,
    s12e01_br_dooms: Number,
    s12e01_buffet_points: Number,
    s12e01_control_captures: Number,
    s12e02_01_br_dooms: Number,
    s12e02_01_buffet_points: Number,
    s12e02_02_br_damage_done: Number,
    s12e02_02_buffet_points: Number,
    s12e04_challenge_1: Number,
    s12e04_challenge_2: Number,
    s12e04_challenge_3: Number,
    s13_flash_01_buffet_points: Number,
    s22ce01_buffet_points: Number,
    s22rc01_games_played_trios_edistrict: Number,
    scanned_ring_locations_reached: Number,
    season01_event_placements_top_5: Number,
    season02_event01_top5s_badge: Number,
    seasons: Mapped({
      key: eseasonflavor,
      value: {
        assists: Number,
        battlepass_bonus_xp_with_7_characters: Number,
        battlepass_level: Number,
        characters: Mapped({
          key: characters,
          value: {
            character_damage_done_max_single_game: Number,
            damage_done: Number,
            kills: Number,
            placements_top_5: Number,
            placements_win: Number,
          },
        }),
        damage_done: Number,
        deaths: Number,
        dooms: Number,
        games_played: Number,
        kills: Number,
        kills_max_single_game: Number,
        kills_with_7_characters: Number,
        placements_top_5_with_7_characters: Number,
        placements_win: Number,
        placements_wins_with_7_characters: Number,
        revived_ally: Number,
        team_work_kill_count: Number,
        times_respawned_ally: Number,
        win_streak_current: Number,
        win_streak_longest: Number,
      },
    }),
    seer_enemy_heartbeats_heard: Number,
    seer_exhibit_enemies_inside: Number,
    seer_focus_of_attention_hits: Number,
    self_revives: Number,
    shield_battery_consumed: Number,
    shield_cell_consumed: Number,
    shield_restored: Number,
    shotgun_ammo_collected: Number,
    shotgun_weapon_collected: Number,
    shots: Number,
    skirmisher_damage_done: Number,
    skirmisher_games_played: Number,
    skirmisher_kills: Number,
    sniper_ammo_collected: Number,
    sniper_weapon_collected: Number,
    support_damage_done: Number,
    support_games_played: Number,
    support_kills: Number,
    survival_time_seconds: Number,
    syringe_consumed: Number,
    team_work_kill_count: Number,
    thanks: Number,
    times_fully_kitted: Number,
    times_got_doomed: Number,
    times_healed_ring_damage: Number,
    times_jumpmaster: Number,
    times_jumpmaster_post_fix: Number,
    times_respawned_ally: Number,
    times_used_ability_tactical: Number,
    times_used_ability_ultimate: Number,
    top_3_with_3_kill_ko_assist: Number,
    total_legendary_items: Number,
    ult_accel_consumed: Number,
    valentines_revives_and_respawns: Number,
    valk_distance_travelled_passive: Number,
    valk_enemies_hit_tactical: Number,
    valk_teammates_carried_ult: Number,
    vantage_passive_enemies_pinged: Number,
    vantage_tactical_distance: Number,
    vantage_ultimate_damage: Number,
    vantage_ultimate_marked_hits: Number,
    voidring_damage_prevented: Number,
    wattson_breaches_detected: Number,
    wattson_enemy_ordnance_intercepted: Number,
    wattson_friendly_shields_charged: Number,
    weapon_damage_done_ads_any_mode: Number,
    weapon_damage_done_any_mode: Number,
    weapon_supply_bin_epics_looted: Number,
    weapon_supply_bins_looted: Number,
    weaponcategories: Mapped({ key: eweaponstatcategory, value: Unknown }),
    weapons: Mapped({
      key: eweaponflavor,
      value: {
        damage_done: Number,
        dooms: Number,
        headshots: Number,
        hits: Number,
        kills: Number,
        shots: Number,
      },
    }),
    weapons_with_legendary_skin: Number,
    well_rounded: Number,
    win_streak_account_badge_current: Number,
    win_streak_account_badge_longest: Number,
    win_streak_current: Number,
    win_streak_longest: Number,
    winning_kills: Number,
    wins_any_mode: Number,
    wraith_passive_voices_heard: Number,
    wraith_tactical_time_spent_in_phase: Number,
    wraith_ultimate_phase_tunnel_ally_uses: Number,
  },
  steamacknowledged: Boolean,
  storyeventchallengespopuplastseen: Number,
  storyeventdialoguepopuplastseen: Number,
  totalrankedgamesabandoned: Number,
  totalsecondsplayed: Number,
  trainingcompleted: Number,
  tutorialactioncount: [Number],
  v2xpprogressionplusone: Number,
  weeklygrouporder: [Number],
  weeklyrecurringlastresetweek: Number,
  xbox_ea_play_charm_gold_apex_logo_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_box_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_explosive_tick_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_gib_shield_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_grapple_hook_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_health_syringe_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_hunters_knife_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_lifelinekit_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_lightbulb_01acknowledged: Boolean,
  xbox_ea_play_charm_gold_tsukumo_01acknowledged: Boolean,
  xbox_gold_pack_10acknowledged: Boolean,
  xbox_gold_pack_11acknowledged: Boolean,
  xbox_gold_pack_12acknowledged: Boolean,
  xbox_gold_pack_13acknowledged: Boolean,
  xmergedplat: Mapped({
    key: platforms,
    value: {
      xprogranked: {
        currentrankedscore: Number,
        rankedhasprogressedoutofprovisional: Boolean,
        rankedprovisionalmatchescompleted: Number,
        rankedprovisionalmatchscores: [Number],
        rankedtop5streakcount: Number,
        rankedtrials: {
          gamesplayedintrialstate: Number,
          guid: Number,
          netlpduringtrial: Number,
          primarystatprogress: Number,
          secondarystatprogressone: Number,
          secondarystatprogresstwo: Number,
          secondarytrialcombinedprogress: Number,
          state: Number,
          timesfailedtrial: Number,
        },
        showrankedsummary: Boolean,
      },
    },
  }),
  xp: Number,
  xp_bonus: [Number],
  xp_count: [Number],
  xp_match: [Number],
  ...Mapped({ key: String, value: Unknown }),
} satisfies ModelDefinition;

export const EventApexDataValidate = createModel(model);
export const EventSeasonsDataValidate = createModel([eseasonflavor]);
export const EventRankedStatPeriodDataValidate = createModel([
  erankedstatperiod,
]);
export const EventRankedPeriodDataValidate = createModel([erankedperiodflavor]);
export const EventRankedPeriod2DataValidate = createModel([
  erankedperiod2pt0flavor,
]);
export const EventCharactersDataValidate = createModel([characters]);
export const EventWeaponsDataValidate = createModel([eweaponflavor]);

export type EventApexData = FromModel<typeof EventApexDataValidate>;
